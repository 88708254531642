import { Button, Link, Typography } from "@mui/material";
import GoogleArrow from '../../../assets/Group_394.svg';


export const playStoreLink = 'https://play.google.com/store/apps/details?id=com.okoleainternational.okoleamobile&hl=en_IN&pli=1'

const GetTheAppMobile = () => {
  return (
    <Link underline="none"
    href={playStoreLink}
    target="_blank"
    rel="noopener"
    >
    <Button
      variant="contained"
      disableElevation
      sx={{
        minWidth: {
          xs: "100%",
          // sm: "231px",
        },
        height: {
          xs: "48px",
        },
        // mt: {
        //   xs: "16px",
        // },
        "&:hover": {
          bgcolor: "primary.light",
        },
      }}>
      <Typography sx={{
        fontWeight: 500,
        mr: {
          xs: "14.17px",
        },
      }}>
        Get The App
      </Typography>
      <img
        src={GoogleArrow}
        alt="Leja Playstore"
        style={{
          width: "17.89px",
          height: "19.75px",
        }}
        loading={'lazy'}
      />
    </Button>
    </Link>
  )
}

export default GetTheAppMobile