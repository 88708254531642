import { Button, Link, Typography } from '@mui/material'
import Group_394 from '../../assets/Group_394.svg'
import { playStoreLink } from '../utils/GetTheAppMobile/GetTheAppMobile'

const GetappButton = () => {
  return (
    <>
      <Link underline="none"
        href={playStoreLink}
        target="_blank"
        rel="noopener">
      <Button
        variant='contained'
        sx={{
          display: { md: 'none', sm: 'none', xs: 'flex' },
          justifyContent: 'center',
          bgcolor:'primary.main',
          height: '3rem',
          width: '91.11vw',
          textTransform: 'uppercase',
          alignItems: 'center',
          "&:hover": {
            bgcolor: "primary.light"
          },
          padding: '0.5rem 1.88rem 0.5rem 1.88rem',
          //marginX: '1rem',
          borderRadius: '0.25rem',
          marginBottom: '1.44rem',
          ml:'16px'
        }}
      >
        <Typography sx={{
          color: '#F5F7F2',
          mr: '2.22vw',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: 'normal',

        }}>
          Get the app
        </Typography>
        <img
          src={Group_394}
          style={{
            width: '7.78vw',
            height: '7.78vw',
          }}
          alt="playstore logo"
          loading={'lazy'}
        />
      </Button>
      </Link>
    </>

  )
}

export default GetappButton