import React from 'react';
import Carousel from "react-multi-carousel";
import { Backdrop, Box, Button, Link, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import 'react-multi-carousel/lib/styles.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Group_394 from '../../assets/Group_394.svg';
import StarIcon from '@mui/icons-material/Star';
import QuotationMarkIcon from '../../assets/LejaApp/JoinTheFuture/quotation.svg';
import Profile1 from '../../assets/LejaApp/JoinTheFuture/profile1.svg';
import { playStoreLink } from '../utils/GetTheAppMobile/GetTheAppMobile';
import PlayButton from '../../assets/OurStories/PlayButton.png';
//import Judy from '../../assets/OurStories/stories.png';
import DefaultIcon from '../../assets/LejaApp/JoinTheFuture/avatar.jpg'
import Jackson from "../../assets/jackson.jpg";
import Paul from "../../assets/Paul.jpg";
import Peter from "../../assets/OurStories/Peter.jpg";

type Testimonials = {
  [key: string]: string;
};

const testimonials: Testimonials = {
  'Jackson': 'As an Uber driver, it\'s easy to mix business and personal finances. ' +
      'Leja has helped me to keep track of my transactions and better understand where ' +
      'my money goes through their easy-to-use bookkeeping feature\n',

  'Paul': 'Leja has greatly helped me reduce on transaction costs. Additionally, ' +
      'when you use Leja to send money, you get 3 free transactions below Kes ' +
      '1000 every day.\n',

  'Peter': 'I use Leja to pay employees at work because Leja allows me to send money ' +
      'to many people at once. The App is easy to use and also cost-effective for ' +
      'business owners.\n',
};

const imageTestimonialMap = [
  {
    image: Jackson,
    testimonial: testimonials['Jackson'],
    name: 'JACKSON | Uber driver in Nairobi',
    src: "https://www.youtube.com/embed/HltrrS9uRkE",
  },
  {
    image: Paul,
    testimonial: testimonials['Paul'],
    name: 'PAUL | Shop owner in Nairobi',
    src: "https://www.youtube.com/embed/ojIqxu4I4ek",
  },
  {
    image: Peter,
    testimonial: testimonials['Peter'],
    name: 'PETER | Restaurant owner in Nairobi',
    src: "https://www.youtube.com/embed/3r95bRvmbPk",
  },
  // {
  //   image: Judy,
  //   testimonial: testimonials['Judy'],
  //   name: 'JUDY | Fish Trader in Gikomba Market in Nairobi',
  //   src: "https://www.youtube.com/embed/p4XzO2EWZNc",
  // },
];

const reviewData = [
  {
    review: 'A very good app for business people to send money with less charges, though you have to update to be stable to avoid inconveniences.',
    date: '21 August 2023',
    bgcolor: 'primary.main',
    rating: '5',
    user: 'joseph kitetu',
    profilePicture: Profile1
  },
  {
    review: 'I have the best experience with Leja especially considering how affordable their transaction costs are!',
    date: '21 August 2023',
    bgcolor: '#5299D3',
    rating: '5',
    user: 'joseph kitetu',
    profilePicture: Profile1
  },
  {
    review: 'Leja is the best app and saves alot on transaction charges, also it has the best customer care service.',
    date: '22 July 2023',
    bgcolor: '#4E00BF',
    rating: '5',
    user: 'Victor Ndeti',
    profilePicture: DefaultIcon
  },
  {
    review: "Very good Loan app. They give 4 months installments to clear your loan, and they don't keep calling and sending sms to you like other app. Thats why I give them 5 stars.",
    date: '21 August 2023',
    bgcolor: '#FFAAFF',
    rating: '5',
    user: 'Paul Mungai',
    profilePicture: DefaultIcon
  },
  {
    review: 'Having Used different apps, this one is the best. Registration is simple, Loan limit is ok, Interest is low ,and there is flexibility in payment. You have my credit. Keep it up.',
    date: '21 August 2023',
    bgcolor: '#2AB7CA',
    rating: '5',
    user: 'Google Play user',
    profilePicture: DefaultIcon
  },
  {
    review: 'the app is very fantastic and easy to use, kindly make sure you download leja app and install from Google play store, it will help you in your m-pesa transactions.',
    date: '12 Nov 2023',
    bgcolor: '#64002C',
    rating: '5',
    user: 'Walter Munda',
    profilePicture: DefaultIcon
  },
  {
    review: 'I love leja the most for daily free three transactions charges daily.',
    date: '15 August 2023',
    bgcolor: '#FF934F',
    rating: '5',
    user: 'John Wokabi',
    profilePicture: DefaultIcon
  },
  {
    review: 'Nice app to buy airtime and send money. thank you leja.',
    date: '12 August 2023',
    bgcolor: 'secondary.main',
    rating: '5',
    user: 'Pamela Mwombi',
    profilePicture: DefaultIcon
  },
  {
    review: 'I give leja 5 stars cause their services are fast and reliable. I have been to leja for quite some months . Thank you Leja.',
    date: '18 Oct 2023',
    bgcolor: '#2AB7CA',
    rating: '5',
    user: 'samuel kariuki',
    profilePicture: DefaultIcon
  },
  {
    review: 'the app is very fantastic and easy to use, kindly make sure you download leja app and install from Google play store, it will help you in your m-pesa transactions.',
    date: '12 Nov 2023',
    bgcolor: '#9C47D4',
    rating: '5',
    user: 'Walter Munda',
    profilePicture: DefaultIcon
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1100 },
    items: 1,
    partialVisibilityGutter: 362
  },
  tablet: {
    breakpoint: { max: 1100, min: 600 },
    items: 1,
    partialVisibilityGutter: 24
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    partialVisibilityGutter: 16
  }
};

const responsive2 = {
  desktop: {
    breakpoint: { max: 3000, min: 2000 },
    items: 1,
    partialVisibilityGutter: 500
  },
  desktop8: {
    breakpoint: { max: 1999, min: 1900 },
    items: 1,
    partialVisibilityGutter: 470
  },
  desktop2: {
    breakpoint: { max: 1899, min: 1800 },
    items: 1,
    partialVisibilityGutter: 440
  },
  desktop3: {
    breakpoint: { max: 1799, min: 1700 },
    items: 1,
    partialVisibilityGutter: 420
  },
  desktop4: {
    breakpoint: { max: 1699, min: 1600 },
    items: 1,
    partialVisibilityGutter: 390
  },
  desktop5: {
    breakpoint: { max: 1599, min: 1500 },
    items: 1,
    partialVisibilityGutter: 360
  },
  desktop6: {
    breakpoint: { max: 1499, min: 1400 },
    items: 1,
    partialVisibilityGutter: 340
  },
  desktop7: {
    breakpoint: { max: 1399, min: 1200 },
    items: 1,
    partialVisibilityGutter: 290
  },
  tablet: {
    breakpoint: { max: 1100, min: 600 },
    items: 1,
    partialVisibilityGutter: 24
  },
  mobile: {
    breakpoint: { max: 600, min: 400 },
    items: 1,
    partialVisibilityGutter: 16
  },
  mobile2: {
    breakpoint: { max: 399, min: 300 },
    items: 1,
    partialVisibilityGutter: 13
  }
};

const ButtonGroup = ({ next, previous, ...rest }: any) => {
  const { carouselState: { currentSlide } } = rest;

  return (
    <Box
      sx={{
        position: "absolute",
        top: "16px",
        right: "30px",
        display: {
          xs: "none",
          md: "block",
        },
      }}>
      <Button
        disableElevation
        className={currentSlide === 0 ? 'disable' : ''}
        sx={{
          bgcolor: "#F4EEE3",
          "&:hover": {
            bgcolor: "#FFF",
          },
        }}
        onClick={() => previous()}
      >
        <ArrowBackIcon sx={{ color: 'primary.main', width: '28px', height: '28px' }} />
      </Button>

      <Button
        disableElevation
        sx={{
          bgcolor: "#F4EEE3",
          ml: "30px",
          "&:hover": {
            bgcolor: "#FFF",
          },
        }}
        onClick={() => next()}
      >
        <ArrowForwardIcon sx={{ color: 'primary.main', width: '28px', height: '28px' }} />
      </Button>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const JTFTabs = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = (index: number) => {
    setOpen(true);
    setSelectedIndex(index);
  };
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{
      position: "relative",
    }}>
      <Box sx={{
        textTransform: "uppercase",
        display: "flex",
        flexDirection: { md: 'row', sm: 'row', xs: 'column' },
        columnGap: { md: '1.875vw', sm: '3.125vw', xs: '0' },
        rowGap: { md: '0px', sm: '0px', xs: '8px' },
        mb: { md: '16px', sm: '16px', xs: '12px' },
        pr: { xs: '16px' },
      }}>
        <Button
          variant='contained'
          onClick={() => handleChange(0)}
          sx={{
            width: { md: '22.625vw', sm: '29.167vw', xs: '100%' },
            height: '48px',
            ml: { md: '24.594vw', sm: '32.292vw', xs: '0px' },
            bgcolor: value === 0 ? '#FFB5CC' : 'white',
            '&:hover': {
              bgcolor: '#FFB5CC',
              boxShadow: 'none',
            },
            boxShadow: 'none',
            borderRadius: '4px',
          }}
        >
          <Typography sx={{
            fontSize: { md: '1vw', sm: '16px', xs: '16px' },
            fontWeight: 500,
            textTransform: 'uppercase',
            color: '#012317'
          }}>
            Testimonials
          </Typography>
        </Button>
        <Button
          variant='contained'
          onClick={() => handleChange(1)}
          sx={{
            width: { md: '22.625vw', sm: '29.167vw', xs: '100%' },
            height: '48px',
            mr: { md: '14.125vw', sm: '0px', xs: '0px' },
            bgcolor: value === 1 ? '#FFB5CC' : 'white',
            '&:hover': {
              bgcolor: '#FFB5CC',
              boxShadow: 'none',
            },
            boxShadow: 'none',
            borderRadius: '4px'
          }}
        >
          <Typography sx={{
            fontSize: { md: '1vw', sm: '16px', xs: '16px' },
            fontWeight: 500,
            textTransform: 'uppercase',
            color: '#012317'
          }}>
            playstore reviews
          </Typography>
        </Button>
      </Box>

      <TabPanel value={value} index={0}>
        <Box>
          <Carousel
            partialVisible={mdDown}
            responsive={responsive}
            autoPlay={mdDown}
            infinite={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside
          >
              {imageTestimonialMap.map((item, index) => (
                  <Box sx={{
                    display: 'flex',
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                    gap: '30px',
                    mr: { sm: '24px', xs: '16px' },
                  }}>
                    <Box key={index} sx={{
                      position: 'relative',
                      padding: { md: '30px', sm: '24px', xs: '16px' },
                      width: { md: '71.75vw', sm: '100%', xs: '100%' },
                      height: { md: '74.222vh', sm: '422px', xs: '203px' },
                      bgcolor: '#FFF',
                      borderRadius: '20px'
                    }}>
                      <img
                          src={item.image}
                          alt={`${item.name} Image`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: 'cover'
                          }}
                          onClick={() => handleOpen(index)}
                          loading={'lazy'}
                      />
                      <img
                          src={PlayButton}
                          alt="Play Button"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                          onClick={() => handleOpen(index)}
                          loading={'lazy'}
                      />
                      <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                      >
                        <Box
                            sx={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'relative',
                              background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0.95))',
                            }}>

                          <Backdrop
                              open={open}
                              onClick={handleClose}
                          >
                            <Box
                                sx={{
                                  bgcolor: '#FFF',
                                  mx: { md: '261px', sm: 'auto', xs: 'auto' },
                                  my: { md: '78px 81px', sm: 'auto', xs: 'auto' },
                                  width: { md: 'calc(100% - 522px)', sm: '85%', xs: '85%' },
                                  height: { md: 'calc(100% - 159px)', sm: 'calc(100% - 159px)', xs: '50%' },
                                  padding: { md: '30px', sm: '24px', xs: '12px' },
                                  borderRadius: '20px',
                                }}>
                              <iframe
                                  width="100%"
                                  height="100%"
                                  src={imageTestimonialMap[selectedIndex].src}
                                  title="Unlocking growth for microbusinesses"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowFullScreen
                              ></iframe>
                            </Box>
                          </Backdrop>
                        </Box>
                      </Modal>
                    </Box>
                  <Box sx={{
                  mt: 'auto',
                  width: { md: '20vw', sm: '100%', xs: '100%' },
                }}>
                <Typography sx={{
                  fontSize: { md: '0.9vw', sm: '2.083vw', xs: '4.444vw' },
                  fontWeight: 400,
                  textTransform: 'uppercase',
                  mb: { md: '16px', sm: '16px', xs: '12px' },
                  color: '#012317'
                }}>
                  {item.testimonial}
                </Typography>
                <Typography sx={{
                  mb: { md: '30px', sm: '24px', xs: '16px' },
                  fontSize: { md: '0.9vw', sm: '2.083vw', xs: '4.444vw' },
                  fontWeight: 300,
                  alignSelf: 'stretch',
                  lineHeight: '130%',
                  opacity: '0.6',
                  color: '#012317'
                }}>
                  <span style={{ fontWeight: '500', }}>{item.name}</span>
                </Typography>
                <Link underline="none"
                      href={playStoreLink}
                      target="_blank"
                      rel="noopener">
                  <Button
                      variant='contained'
                      sx={{
                        display: 'flex',
                        width: '100%',
                        height: '48px',
                        alignItems: 'center',
                        mr: '16px',
                        flexShrink: 0,
                        "&:hover": {
                          bgcolor: "primary.light",
                          boxShadow: 'none',
                        },
                        boxShadow: 'none',
                      }}
                  >
                    <Typography sx={{
                      color: '#F5F7F2',
                      mr: '16px',
                      fontSize: '16px',
                      fontWeight: 500
                    }}>
                      Get the app
                    </Typography>
                    <img
                        src={Group_394}
                        alt="playstore logo"
                        style={{
                          height: '20px',
                          width: '20px',
                        }}
                        loading={'lazy'}
                    />
                  </Button>
                </Link>
              </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
          <Carousel
            partialVisible={true}
            responsive={responsive2}
            autoPlay={mdDown}
            infinite={true}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            renderButtonGroupOutside
          >
            {reviewData.map((item, index) => (
              <Box key={index} sx={{
                mr: { md: '30px', sm: '24px', xs: '16px' },
                bgcolor: item.bgcolor,
                borderRadius: { md: '20px', sm: '20px', xs: '12px' },
                width: { md: '71.75vw', sm: '88.542vw', xs: '89.333vw' },
                height: { md: '74.333vh', sm: '669px', xs: '600px' },
                position: 'relative'
              }}>
                <Box sx={{
                  display: 'flex',
                }}>
                  <img src={QuotationMarkIcon}
                    alt="Quotation Mark"
                    style={{
                      width: smDown ? '10.833vw' : (mdDown && !smDown) ? '8.854vw' : '4.219vw',
                      height: smDown ? '30px' : (mdDown && !smDown) ? '52px' : '51.726px',
                      marginLeft: smDown ? '20px' : (mdDown && !smDown) ? '39px' : '33.86px',
                      marginTop: smDown ? '40px' : (mdDown && !smDown) ? '40px' : '57.27px',
                      marginBottom: smDown ? '30px' : (mdDown && !smDown) ? '30px' : 'auto',
                      marginRight: smDown ? '0px' : (mdDown && !smDown) ? '0px' : '33.65px',
                    }}
                       loading={'lazy'}
                  />
                  <Typography sx={{
                    fontSize: { md: '2.7vw' },
                    fontWeight: 500,
                    width: '100%',
                    lineHeight: '100%',
                    letterSpacing: '-1.5%',
                    textTransform: 'uppercase',
                    color: '#F5F7F2',
                    mt: { md: '48px' },
                    display: { md: 'block', sm: 'none', xs: 'none' },
                    mr: '8.25vw'
                  }}>
                    {item.review}
                  </Typography>
                  <Typography sx={{
                    display: { md: 'none', sm: 'none', xs: 'block' },
                    width: 'auto',
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    color: '#F5F7F2',
                    mt: '40px',
                    mr: '1.25vw',
                    ml: '6.625vw'
                  }}>
                    {item.date}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{
                    fontSize: { sm: '5vw', xs: '7.333vw' },
                    fontWeight: 500,
                    width: { sm: '78.385vw', xs: '90%' },
                    lineHeight: '100%',
                    letterSpacing: '-1.5%',
                    textTransform: 'uppercase',
                    color: '#F5F7F2',
                    display: { md: 'none', sm: 'block', xs: 'block' },
                    mr: { sm: '5.078vw', xs: '5.556vw' },
                    ml: { sm: '5.078vw', xs: '5.556vw' },
                    mb: { sm: '135px', xs: '30px' }
                  }}>
                    {item.review}
                  </Typography>
                  <Box sx={{
                    display: { xs: 'flex', sm: 'none', md: 'none' },
                    gap: '16px',
                  }}>
                    <img
                      src={typeof item.profilePicture === 'string' ? item.profilePicture : ''}
                      alt="profilePicture"
                      style={{
                        width: '32px',
                        height: '32px',
                        marginLeft: '20px',
                        borderRadius: '50px'
                      }}
                      loading={'lazy'}
                    />
                    <Typography sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      textTransform: 'uppercase',
                      width: '129px',
                      mt: '6px',
                      color: '#F5F7F2'
                    }}>
                      {item.user}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{
                  width: { md: 'auto', sm: '610px', xs: '140px' },
                  height: { md: '32px', sm: '32px', xs: '24px' },
                  mr: { md: '132px', sm: '31px', xs: '140px' },
                  ml: { md: '135px', sm: '39px', xs: '20px' },
                  display: 'flex',
                  position: 'absolute',
                  bottom: '30px',
                }}>
                  <Box sx={{
                    display: 'flex'
                  }}>
                    {Array.from({ length: Number(item.rating) }, (_, i) => <StarIcon key={i}
                      sx={{
                        color: 'primary.light',
                        width: '24px',
                        height: '24px',
                      }} />)}
                  </Box>
                  <Box sx={{
                    display: { xs: 'none', sm: 'flex', md: 'flex' },
                    mr: { md: '15.563vw', sm: '12vw' }
                  }}>
                    <img
                      src={typeof item.profilePicture === 'string' ? item.profilePicture : ''}
                      alt="profilePicture"
                      style={{
                        width: '32px',
                        height: '32px',
                        marginLeft: (mdDown && !smDown) ? '8.984vw' : '7.375vw',
                        marginRight: (mdDown && !smDown) ? '2.083vw' : '0.813vw',
                        borderRadius: '50px'
                      }}
                      loading={'lazy'}
                    />
                    <Typography sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      textTransform: 'uppercase',
                      width: { md: '13.563vw', sm: '18.797vw' },
                      mt: '6px',
                      color: '#F5F7F2'
                    }}>
                      {item.user}
                    </Typography>
                  </Box>
                  <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    display: { md: 'block', sm: 'block', xs: 'none' },
                    mt: '8px',
                    width: { md: '7vw', sm: 'auto' },
                    color: '#F5F7F2',
                    pr: { md: '1.3vw' }
                  }}>
                    {item.date}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Carousel>
          <Box sx={{
            mr: { md: '1.875vw', sm: '3.125vw', xs: '4.444vw' },
          }}>
            <Link underline="none"
              href={playStoreLink}
              target="_blank"
              rel="noopener">
              <Button
                variant='contained'
                sx={{
                  width: { md: '47.313vw', sm: '100%', xs: '100%' },
                  height: '48px',
                  ml: { md: '24.438vw', sm: '0px', xs: '0px' },
                  mt: { md: '30px', sm: '24px', xs: '16px' },
                  '&:hover': {
                    bgcolor: 'primary.light',
                    boxShadow: 'none',
                  },
                  boxShadow: 'none',
                }}
              >
                <Typography sx={{ color: '#F5F7F2', mr: '16px', fontSize: '16px', fontWeight: 500 }}>Get the app</Typography>
                <img
                  src={Group_394}
                  alt="playstore logo"
                  style={{
                    width: '20px',
                    height: '20px'
                  }}
                />
              </Button>
            </Link>
          </Box>

        </Box>
      </TabPanel>
    </Box>
  )
}

export default JTFTabs;