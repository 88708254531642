import { Box, Button, Typography } from "@mui/material";
import RightArrow from "../../assets/RightArrow.svg";
import TheTeamLess from "./TheTeamLess";
import TheTeamLessMobile from "./TheTeamLessMobile";
import { useState } from "react";
import TheTeamMore from "./TheTeamMore";
import TheTeamMoreMobile from "./TheTeamMoreMobile";

const AboutUsTheTeam = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSeeMoreClick = () => {
    setIsExpanded(true);
  };

  return (
    <Box sx={{
      margin: {
        xs: "60px 16px 60px 16px",
        sm: "100px 24px 100px 24px",
        lg: "140px 31px 140px 31px",
      },
    }}>
      <Box sx={{
        display: {
          xs: "flex",
        },
        gap: {
          sm: "3.125vw",
          lg: "1.813vw",
        },
        textTransform: "uppercase",
      }}>
        <Box sx={{
          display: {
            xs: "none",
            sm: "block",
          }
        }}>
          <Typography sx={{
            color: "rgba(1, 35, 23, 0.60)",
            fontSize: {
              sm: "2.083vw",
              lg: "0.875vw",
            },
            minWidth: {
              sm: "28.385vw",
              lg: "22.75vw",
            },
          }}>
            [Driven Enthusiasts]
          </Typography>
        </Box>

        <Box sx={{
          display: {
            xs: "flex"
          },
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          width: {
            lg: "100%",
          },
          gap: {
            xs: "3.333vw",
            sm: "3.125vw",
            lg: "1.938vw",
          },
        }}>
          <Box>
            <Typography sx={{
              fontWeight: 500,
              lineHeight: "100%",
              letterSpacing: "-1.5px",
              fontSize: {
                xs: "8.333vw",
                sm: "6.51vw",
                lg: '3.125vw'
              },
              width: {
                lg: "22.688vw",
              },
            }}>
              the team
            </Typography>
          </Box>

          <Box sx={{
            flexGrow: {
              lg: 1,
            },
          }}>
            <Typography sx={{
              fontSize: {
                xs: "3.889vw",
                sm: "2.083vw",
                lg: '1vw'
              },
              width: {
                lg: "100%",
              },
            }}>
              We are a passionate team dedicated to helping micro-businesses in Africa achieve their full potential.
              We harness research and our professional experience, in tech and finance, to create practical,
              user-friendly digital solutions that promote financial inclusion.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{
        mt: {
          xs: "16px",
        },
      }}>
        {isExpanded ?
          (
            <>
              <TheTeamMore />
              <TheTeamMoreMobile />
            </>
          ) :
          (
            <>
              <TheTeamLess />
              <TheTeamLessMobile />
            </>
          )
        }
      </Box>

      {!isExpanded && (
        <Button
          disableElevation
          onClick={() => handleSeeMoreClick()}
          variant="contained"
          sx={{
            bgcolor: "#F4EEE3",
            "&:hover": {
              bgcolor: "#FFF",
            },
            width: "100%",
            borderRadius: "4px",
            height: "48px",
            padding: {
              xs: "8px 30px"
            },
            mt: {
              xs: "16px",
              sm: "30px",
              lg: "31px",
            },
          }}>
          <Typography sx={{
            fontWeight: 500,
            color: "primary.main",
          }}>
            See More
          </Typography>

          <Box sx={{
            ml: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: "rotate(90deg)",
          }}>
            <img
              src={RightArrow}
              alt="See More"
              width={"28px"}
            />
          </Box>
        </Button>
      )}
    </Box>
  )
};

export default AboutUsTheTeam;
