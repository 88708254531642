import { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import lotti from '../../assets/Animations/lotti.json';
import HomeHero from "../../components/HomeHero/HomeHero";
import Blog from "../../components/Blog/Blog";
import InspireUs from "../../components/InspireUs/InspireUs";
import OurNumbers from "../../components/OurNumbers/OurNumbers";
import TheyLoveUs from "../../components/TheyLoveUs/TheyLoveUs";
import TheRevolution from "../../components/TheRevolution";
import TheyTrustUs from "../../components/TheyTrustUs/TheyTrustUs";
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import ScrollToTop from '../../components/ComponentUtils/ScrollToTop';
import { Box } from '@mui/material';
import './HomePage.css';
import CookiesFile from "../../components/Cookies/CookiesFile";
import FAQSweb from '../../components/FAQS/FAQSweb';
import { useLocation } from 'react-router-dom';
import TheyLoveUsWeb from "../../components/TheyLoveUs/TheyLoveUsWeb";
import FaqHome from '../../components/FAQS/FaqHome/FaqHome';

const HomePage = () => {
  const container: any = useRef(null);
  const [animationComplete, setAnimationComplete] = useState(false);
  const location = useLocation()

  useEffect(
    () => {
      document.title = `Leja`
    }, [location]
  );

  const playAnimation = () => {
    const anim = lottie.loadAnimation({
      container: container.current,
      animationData: lotti,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      rendererSettings: {
        className: "homepage-lottie-animation",
        preserveAspectRatio: 'xMidYMax slice'
      }
    });

    anim.addEventListener('complete', () => {
      setAnimationComplete(true);
      anim.destroy();
      sessionStorage.setItem('animationPlayed', 'true');
    });
  };

  useEffect(() => {
    const animationPlayed = sessionStorage.getItem('animationPlayed');
    if (animationPlayed === 'true') {
      setAnimationComplete(true);
    } else {
      playAnimation();
    }
  }, []);


  window.addEventListener('beforeunload', () => {
    sessionStorage.setItem('animationPlayed', 'false');
  });

  return (
    <Box>
      {!animationComplete ? (
        <div ref={container} />
      ) : (
        <>
          <CookiesFile />
          <ScrollToTop />
          <NavBar />
          <HomeHero />
          <OurNumbers />
          <TheRevolution />
          <Box sx={{
            display: {md:'none', xs:'block'}
          }}>
            <TheyLoveUs />
          </Box>
          <Box sx={{
            display: {md:'block', xs:'none'}
          }}>
            <TheyLoveUsWeb />
          </Box>
          <FaqHome />
          <TheyTrustUs />
          <InspireUs />
          <Blog />
          <Footer />
        </>
      )}
    </Box>
  );
};

export default HomePage;
