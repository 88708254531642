import { Typography, useTheme } from '@mui/material';
import { playStoreLink } from '../utils/GetTheAppMobile/GetTheAppMobile';
import React from 'react';

const DefinitionofTerms = () => {
  const theme = useTheme();
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        <ol style={{ margin: 0 }}>
            <li>LEJA means Leja Limited, a Limited Liability company incorporated in accordance to Cap. 486 Laws of Kenya with its offices situated at Maple Court, Galana Road Kilimani and of P.O.BOX 711, Kilimani. This may also include its Holding company, Affiliates and/or subsidiaries;</li>
            <li>APPLICATION/APP means Leja Application available at <a href={playStoreLink} target="_blank" rel="noopener noreferrer" style={{color: theme.palette.primary.main}}>Google Playstore.</a></li>
            <li>LENDER means any Money lending institutions who have partnered with Leja with an intent to lend money to customers through the Leja Platform;</li>
            <li>YOU or YOUR means the Customer and includes the personal representatives, assigns and heirs of the Customer;</li>
            <li>CUSTOMER means a person whose name is registered in the App with valid identification details and a registered mobile phone number; The word “Customer" shall include the masculine and the feminine gender as well as juristic persons;</li>
            <li>LEJA PLATFORM means Leja Limited’s electronic lending and communication software enabling the Customer to communicate with Leja for the purpose of accessing the Facility and Services;</li>
            <li>ACCOUNT means your Loan account with Leja;</li>
            <li>CREDENTIALS means your personal credentials used to access the Leja Platform, initiate requests and access the Services;</li>
            <li>SERVICE means any Product offered on the Leja Platform or USSD Short code and shall include but not limited to</li>
            <ul>
                <li>DIGITAL BOOK KEEPING. Leja provides users with a platform to digitally record their debit and credit transactions with their respective customers. The Users may upload information relating to transactions with their customers or relating to their businesses, including the sale of goods or services, costs, amounts paid and payable, and details of goods and services, on the Platform as shown below;</li>
                <li>SEND MONEY. This feature provides users with a platform to send money to other MPESA users at a zero transaction fee. Users can track their transaction history from the app.</li>
                <li>BUY AIRTIME. This feature allows users to recharge airtime to their or other mobile network users using local Based mobile service providers; Safaricom, Airtel and Telkom sim cards. Users can track their transaction history from the app.</li>
                <li>BORROW LOANS. Leja has partnered with Money lending institutions who through Leja, provide individuals and microbusinesses with access to loans that are of low interest rates. The types of loans available on Leja include Airtime loans and Business loans. Business loans are further classified into Instant mobile loans (amount is deposited directly to user’s registered MPESA number) and Business loans (allows users to pay for goods and services directly to their suppliers).</li>
                <li>WALLET. Leja has partnered with money lending institutions who through Leja, provide a wallet where customers can deposit money, perform transactions via the wallet including but not limited to: Transfers, withdrawals, payment of bills, access to loans etcetera.</li>
                <li>SERVICES also include any other future services provided by Leja and/or proposes to provide.</li>
            </ul>
            <li>FACILITY means the Loan which the Customer requests from the Leja Platform through their registered and recognised Mobile Phone Number;</li>
            <li>PROCESSING FEE is the fee charged on loan disbursal and is a fixed percentage of the Loan Amount;</li>
            <li>LOAN means a loan disbursed electronically by Leja to a Customer M-PESA account, electronic wallet account (E-Wallet) or to any other available payment option selected by the Customer upon a Customer’s request.</li>
            <li>REPAYMENT DATA means the day that the Loan instalment shall be due for repayment;</li>
            <li>PENALTY means a charge which will be a percentage on any outstanding amounts which have become due but remain unpaid on the Repayment Date;</li>
            <li>REQUEST means a request or instruction received by Leja from you or purportedly from you through your Mobile Network Operator and the Leja Platform and upon which Leja is authorized to act;</li>
            <li>LOAN LIMIT means the maximum pre-approved amount that a Customer can borrow on the App;</li>
            <li>CREDIT REFERENCE BUREAU means a credit reference bureau duly licensed and registered under the Credit Reference Bureau Regulations 2013 to inter alia, collect and facilitate the sharing of customer credit information;</li>
            <li>MOBILE MONEY means the money transfer and payments service operated by the Mobile Money Providers in Kenya;</li>
            <li>MOBILE MONEY ACCOUNT means your mobile money store of value, being the record maintained by Mobile Money Providers in Kenya of the amount of E-Money from time to time held by you in the Mobile Money Provider’s System;</li>
            <li>MOBILE NETWORK OPERATOR means a mobile network operator in Kenya registered with the Communications Authority of Kenya;</li>
            <li>MOBILE MONEY PROVIDER means a Mobile Network Operator that has been duly authorized by the Central Bank of Kenya under applicable law to offer Mobile Money Services in Kenya;</li>
            <li>EQUIPMENT means and shall include your mobile phone handset, SIM Card and/or other equipment which when used together enables you to access the Just Cash System and use the products and services availed to you by the Mobile Network Operators subject to such term and conditions agreed between you and the Mobile Network Operators;</li>
        </ol>
    </Typography>
  )
}

export default DefinitionofTerms