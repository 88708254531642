import { Box, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CookieButton from './CookieButton';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const CookiesFile = () => {
  const [showCookies, setShowCookies] = useState(() => {
    const cookieSettings = localStorage.getItem('cookieSettings');
    return cookieSettings !== 'accepted' && cookieSettings !== 'rejected';
  });

  useEffect(() => {
    const handleBeforeUnload = () => {
      const cookieSettings = localStorage.getItem('cookieSettings');
      if (cookieSettings === 'rejected') {
        // listen for tab close and clear cookie status in local storage
        localStorage.removeItem('cookieSettings'); 
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleAcceptCookies = () => {
    localStorage.setItem('cookieSettings', 'accepted');
    setShowCookies(false);
  };

  const handleRejectCookies = () => {
    localStorage.setItem('cookieSettings', 'rejected');
    setShowCookies(false);
  };

  if (!showCookies) {
    return null; // Return null if showCookies is false
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#F5F7F2',
        padding: { md: '40px', sm: '40px', xs: '40px' },
        width: { sm: '468px', xs: '100%' },
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        position: 'fixed',
        bottom: 0,
        left: { md: '33%', sm: '20%', xs: '0%' },
        right: { md: '50%', sm: '50%', xs: '0%' },
        zIndex: 1300
      }}>
        <Button sx={{
          ml: 'auto',
          color: '#012317',
          '&:hover': {
            bgcolor: '#F5F7F2',
            textDecoration: 'none',
          }
        }} onClick={handleRejectCookies}>X</Button>
        <Box sx={{ mb: '20px' }}>
          <Typography sx={{
            textTransform: 'uppercase',
            fontSize: '30px',
            fontWeight: 500,
            letterSpacing: '-2px',
            lineHeight: '30px',
          }}>Cookies settings</Typography>
        </Box>
        <Box sx={{ mb: '20px' }}>
          <Typography sx={{
            fontWeight: 300,
            fontSize: '16px',
            lineHeight: '20.8px',
          }}>
            We use cookies to help personalise content and provide a better experience.
            <span><Link style={{ color: 'green' }} to='privacy-policy'>Cookies Policy</Link></span>
          </Typography>
        </Box>
        <Box sx={{ mb: "20px" }}>
          <CookieButton onAccept={handleAcceptCookies} />
        </Box>
      </Box>
    </motion.div>

  );
}

export default CookiesFile;
