import { AppBar, IconButton, Toolbar } from "@mui/material"
import { Box } from "@mui/system"
import CloseIcon from '@mui/icons-material/Close';

interface NavMenuProps {
    handleCloseDrawer: () => void;
}

const NavMenu: React.FC<NavMenuProps> = ({ handleCloseDrawer }) => {
    return (
        <AppBar
            sx={{
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                display: { md: 'none' },
                mt: { xs: '3.2vw', sm: '2.37vw' },
                ml: { xs: '4.27vw', sm: '3.125vw' },
                mr: { xs: '4.27vw', sm: '3.125vw' },
                width: { xs: '92vw', sm: '93.76vw' },
                height: { xs: '16vw', sm: '10.13vw' },
                borderRadius: { xs: '3.2vw', sm: '2.6vw' },
                boxShadow: 'none'
            }}
        >
            <Toolbar disableGutters>
                <Box sx={{
                    ml: 'auto',
                    mr: { xs: '3.2vw', sm: '2.08vw' },
                    mt: {  sm: '2.84vw' },
                    mb: {  sm: '3.65vw' },
                    display: {xs:'flex'},
                    alignContent: {xs:'center'},
                    fontSize: '28px'
                }}>
                    <IconButton onClick={handleCloseDrawer} sx={{
                        opacity: 1
                    }}>
                        <CloseIcon sx={{ color: '#F5F7F2'}} />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default NavMenu