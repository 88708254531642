import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  Button,
  CardMedia,
  Link,
  Typography,
  createSvgIcon,
} from '@mui/material';
import RectangleImage from '../../assets/Rectangle.png';
import Group_394 from '../../assets/Group_394.svg'
import Features from './Features';
import { playStoreLink } from '../utils/GetTheAppMobile/GetTheAppMobile';
import { motion } from "framer-motion";
import GetappButton from './GetappButton';

const PlayIcon = createSvgIcon(
  <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 394">
      <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M0.03125 1.25068V25.1732C0.03125 25.3795 0.0521987 25.5558 0.0870275 25.7051L12.5733 13.2113L0.0868162 0.717773C0.0520885 0.867147 0.03125 1.04365 0.03125 1.25068Z" fill="#F5F7F2" />
      <path id="Subtract_2" fill-rule="evenodd" clip-rule="evenodd" d="M14.4688 13.2763L18.1589 16.9693L23.1769 14.1162C23.6993 13.8193 23.913 13.5061 23.9748 13.2767C23.9131 13.0467 23.6994 12.7338 23.1768 12.4366L18.1586 9.58398L14.4688 13.2763Z" fill="#F5F7F2" />
      <path id="Subtract_3" fill-rule="evenodd" clip-rule="evenodd" d="M13.5214 14.1641L1.25781 26.435C1.42216 26.3923 1.60849 26.3179 1.81387 26.2014L16.9506 17.5959L13.5214 14.1641Z" fill="#F5F7F2" />
      <path id="Subtract_4" fill-rule="evenodd" clip-rule="evenodd" d="M1.25781 -0.00683594L13.5214 12.2636L16.9501 8.83251L1.81385 0.227032C1.60833 0.110455 1.42221 0.0359892 1.25781 -0.00683594Z" fill="#F5F7F2" />
    </g>
  </svg>
  ,
  'Play',
);

export default function HomeHero() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#009166",
        dark: "#012317",
        light: "#00C774",
      },
      secondary: {
        main: "#64002C",
        light: "#FFBDD4",
      },
    },
    typography: {
      fontFamily: [
        "Rubik"
      ].join(',')
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1150,
        lg: 2400,
        xl: 3200,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          padding: {
            xs: "0 16px",
            sm: "0 24px 0 25px",
            md: "0 30px 0 31px",
          }
        }}
      >
        <Grid container sx={{
          display: 'flex', flexDirection: { md: 'row', sm: 'column-reverse', xs: 'column-reverse' },
        }}>
          <Grid item xs={12} md={3} sx={{
            paddingBottom: {
              xs: "12px",
              sm: "16px"
            },
          }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 1 }}
            >
              <Typography
                sx={{
                  color: '#012317',
                  '@media only screen and (min-width: 1024px)': {
                    marginTop: '7px'
                  },
                  fontSize: {md:'1vw', sm:'16px', xs:'16px'},
                  lineHeight: 'normal',
                  textTransform: 'uppercase',
                  width: {
                    md: "18vw",
                  },
                }}
              >
                WE PROVIDE ENTREPRENEURS WITH TAILORED SOLUTIONS, STREAMLINING THEIR BUSINESS PAYMENTS AND OFFERING FINANCIAL SERVICES DESIGNED TO CATALYZE RAPID BUSINESS GROWTH.
              </Typography>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={9} sx={{
            paddingBottom: {
              xs: "12px",
              sm: "16px"
            },
          }}
          >
            <motion.div
              initial={{ x: "-400px", clipPath: "inset(0px 100% 0px 0px)" }}
              animate={{ x: 0, clipPath: "inset(0px 0px 0px 0px)" }}
              transition={{ duration: 1.5, ease: "easeInOut", delay: 0.5 }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  mt: '0px',
                  color: '#012317',
                  lineHeight: "100%",
                  letterSpacing: '-0.094vw',
                  fontSize: {
                    xs: "9.722vw",
                    sm: "9.766vw",
                    md: '4.688vw'
                  },
                  width: {
                    md: "71.625vw",
                  },
                }}
              >
                LEJA REVOLUTIONIZING MSMES FINANCIAL JOURNEYS
              </Typography>
            </motion.div>
          </Grid>
        </Grid>

        <Box sx={{
          mb: { md: '3.333vh', sm: '24px', xs: '26px' }
        }}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 2 }}
          >
            <CardMedia
              component="img"
              sx={{
                borderRadius: {
                  xs: "12px",
                  width: '100%'
                },
                height: {
                  xs: "387px",
                  md: '45vh',
                },
              }}
              src={RectangleImage} alt="market1" style={{ objectFit: 'cover' }}
            />
          </motion.div>
        </Box>
      </Box>
      <GetappButton />
      <Features />
    </ThemeProvider>
  );
}