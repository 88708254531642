import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ContactForm from "../ContactForm/ContactForm";
import TopFooter from "./TopFooter";
import Addresses from "./Addresses";
import SocialMediaLinks from "./SocialMediaLinks";
import { HashLink as RoLink } from 'react-router-hash-link';
import Achievements from "./Achievements";

const linkData = {
    'Leja App': '/leja-app',
    'About Us': '/about-us',
    'Blog': '/blog',
    // 'FAQ': '/faq',
    'Privacy Policy': '/privacy-policy',
    'Terms and Conditions': '/terms-and-conditions',
    'Complaints Policy': '/complaints-policy',
};

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <>
            <TopFooter />
            <Box sx={{
                width: '100%',
                height: 'auto',
                bgcolor: '#012317',
                borderTopLeftRadius: { md: '1.25vw', sm: '2.6vw', xs: '3.2vw' },
                borderTopRightRadius: { md: '1.25vw', sm: '2.6vw', xs: '3.2vw' },
                paddingTop: { md: '3.75vw', sm: '7.81vw', xs: '10.67vw' },
                paddingBottom: { md: '3.75vw', sm: '7.81vw', xs: '10.67vw' },
                paddingLeft: { md: '1.875vw', sm: '3.125vw', xs: '4.27vw' },
                paddingRight: { md: '1.875vw', sm: '3.125vw', xs: '4.27vw' },
            }}>
                {isMobile && (
                    //mobile view
                    <>
                        <Box>
                            <ContactForm />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            gap: '4.44vw',
                            width: '84.72vw',
                            height: 'auto',
                            mt: '11.11vw'
                        }}>
                            {Object.entries(linkData).slice(0, 3).map(([text, path]) => (
                                <Link key={text} to={path} style={{ textDecoration: 'none' }}>
                                    <Typography sx={{
                                        color: '#F5F7F2',
                                        textDecoration: 'underline',
                                        fontSize: '4.44vw',
                                        mb: '1vw',
                                        fontWeight: 300,
                                        lineHeight: '130%',

                                    }}>{text}</Typography>
                                </Link>
                            ))}
                            <RoLink to='#FAQS'
                                smooth
                                style={{ textDecoration: 'none' }}><Typography sx={{
                                    color: '#F5F7F2',
                                    textDecoration: 'underline',
                                    fontSize: '4.44vw',
                                    mb: '1vw',
                                    fontWeight: 300,
                                    lineHeight: '130%',

                                }}>FAQS</Typography></RoLink>
                            {Object.entries(linkData).slice(3).map(([text, path]) => (
                                <Link key={text} to={path} style={{ textDecoration: 'none' }}>
                                    <Typography sx={{
                                        color: '#F5F7F2',
                                        textDecoration: 'underline',
                                        fontSize: '4.44vw',
                                        mb: '1vw',
                                        fontWeight: 300,
                                        lineHeight: '130%',

                                    }}>{text}</Typography>
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{
                            mt: '40px'
                        }}>
                            <Addresses />
                        </Box>
                        <Box sx={{ mt: '40px' }}>
                            <Achievements />
                        </Box>
                        
                    </>
                )}

                {(isTablet && !(isMobile)) && (
                    //tablet view
                    <>
                        <Box>
                            <ContactForm />
                        </Box>
                        <Box
                            //row with address and links
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                mb: '80px',
                                mt: '7.81vw',
                                mr: '6.51vw'
                            }}
                        >
                            <Box
                                //links
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginRight: '30.86vw',
                                    textDecorationColor: '#F5F7F2',
                                    gap: '2.08vw'
                                }}>
                                {Object.entries(linkData).slice(0, 3).map(([text, path]) => (
                                    <Link key={text} to={path} style={{ textDecoration: 'none' }}>
                                        <Typography sx={{
                                            color: '#F5F7F2',
                                            textDecoration: 'underline',
                                            fontSize: '2.08vw',
                                            fontWeight: 300,
                                            lineHeight: '130%',
                                            width: '21vw'

                                        }}>{text}</Typography>
                                    </Link>
                                ))}
                                <RoLink to='#FAQS'
                                    smooth
                                    style={{ textDecoration: 'none' }}>
                                    <Typography sx={{
                                        color: '#F5F7F2',
                                        textDecoration: 'underline',
                                        fontSize: '2.08vw',
                                        fontWeight: 300,
                                        lineHeight: '130%',
                                        width: '21vw'

                                    }}>FAQS</Typography>
                                </RoLink>
                                {Object.entries(linkData).slice(3).map(([text, path]) => (
                                    <Link key={text} to={path} style={{ textDecoration: 'none' }}>
                                        <Typography sx={{
                                            color: '#F5F7F2',
                                            textDecoration: 'underline',
                                            fontSize: '2.08vw',
                                            fontWeight: 300,
                                            lineHeight: '130%',
                                            width: '21vw'

                                        }}>{text}</Typography>
                                    </Link>
                                ))}
                            </Box>
                            <Box
                                //addresses
                                sx={{
                                    width: 'auto',
                                    height: 'auto',
                                    padding: '0',
                                    mr: '4.3vw'
                                }}
                            >
                                <Addresses />
                            </Box>
                        </Box>
                        <Box sx={{ mb: '80px' }}>
                            <Achievements />
                        </Box>
                        
                    </>

                )}

                {!(isMobile || isTablet) && (
                    //webview
                    <>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <Box
                                //column1
                                sx={{
                                    marginRight: '6.5vw',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <Box
                                    //row with address and links
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        mb:'auto'
                                    }}
                                >
                                    <Box
                                        //links
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginRight: '14.81vw',
                                            textDecorationColor: '#F5F7F2',
                                            width: '11vw'
                                        }}>
                                        {Object.entries(linkData).slice(0, 3).map(([text, path]) => (
                                            <Link key={text} to={path} style={{ textDecoration: 'none' }}>
                                                <Typography sx={{
                                                    color: '#F5F7F2',
                                                    textDecoration: 'underline',
                                                    fontSize: '1vw',
                                                    mb: '1vw',
                                                    fontWeight: 300,
                                                    lineHeight: '130%',

                                                }}>{text}</Typography>
                                            </Link>
                                        ))}
                                        <RoLink to='#FAQS'
                                            smooth
                                            style={{ textDecoration: 'none' }}><Typography sx={{
                                                color: '#F5F7F2',
                                                textDecoration: 'underline',
                                                fontSize: '1vw',
                                                mb: '1vw',
                                                fontWeight: 300,
                                                lineHeight: '130%',

                                            }}>FAQS</Typography></RoLink>
                                        {Object.entries(linkData).slice(3).map(([text, path]) => (
                                            <Link key={text} to={path} style={{ textDecoration: 'none' }}>
                                                <Typography sx={{
                                                    color: '#F5F7F2',
                                                    textDecoration: 'underline',
                                                    fontSize: '1vw',
                                                    mb: '1vw',
                                                    fontWeight: 300,
                                                    lineHeight: '130%',

                                                }}>{text}</Typography>
                                            </Link>
                                        ))}
                                        <Box sx={{
                                            m:'25px 0px',
                                        }}>
                                            <Achievements />
                                        </Box>
                                    </Box>
                                    <Box
                                        //addresses
                                        sx={{
                                            width: '14vw',
                                            height: 'auto',
                                            padding: '0',
                                            mr: '2.0625vw'
                                        }}
                                    >
                                        <Addresses />
                                    </Box>
                                </Box>
                                
                            </Box>
                            <Box sx={{
                                width: '46.8vw'
                            }}>
                                <ContactForm />
                            </Box>
                        </Box>
                    </>
                )}

            </Box>
        </>
    )
}

export default Footer;