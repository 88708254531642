import {Button, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import AboutRole from "./AboutRole";
import ApplyForRole from "./ApplyForRole";
import {OpenCareer} from "../../models/open-career";

const CareerDetails = ({career}: {career: OpenCareer}) => {
    const actionButtons = ['About The Role', 'Apply'];
    const [selectedAction, setSelectedAction] = React.useState(0);


    return (
        <Box sx={{
            marginLeft: { md: '24.25vw', sm: '8px' },
            mr: { md: '3.75vw', sm: '98px' },
            border: '2px solid #E3D9C5',
            borderRadius: '20px',
            padding: '18px'
        }}>
            <Grid container spacing={3} sx={{pb: '15px'}} >
                {actionButtons.map((item, index) => (
                    <Grid item xs={6}>
                        <Button
                            variant='contained'
                            onClick={() => setSelectedAction(index)}
                            sx={{
                                width: '100%',
                                height: '48px',
                                boxShadow: 'none',
                                borderRadius: '10px',
                                border:'none',
                                outline: 'none',
                                padding:'5px 0px',
                                bgcolor: index === selectedAction ? "primary.dark" : "#E3D9C5",
                                '&:hover': {
                                    bgcolor: index === selectedAction ? "primary.dark" : "#E3D9C5",
                                },
                                color: index === selectedAction ? '#FFF' : 'primary.main'
                            }}
                            disableElevation
                        >
                            <Typography sx={{
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '120%',
                                textTransform: 'uppercase'
                            }}>
                                {item}
                            </Typography>
                        </Button>
                    </Grid>
                ))}
            </Grid>

            { selectedAction === 0 ? <AboutRole career={career}/> : <ApplyForRole career={career}/>}

        </Box>
    );
}

export default CareerDetails;