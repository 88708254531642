import { Box, Button, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ArrowForward } from '@mui/icons-material';

interface myProps {
    onAccept:any;
    
}
const CookieButton = ({ onAccept }:myProps) => {
  return (
    <Box>
        <Button endIcon={<ArrowForward style={{color:'white'}} />} sx={{
            bgcolor:'primary.main',
            width:'100%',
            '&:hover': {
                bgcolor: 'primary.main',
                textDecoration: 'none',
              }
        }}
        onClick={onAccept}
        >
            <Typography sx={{
                textTransform:'uppercase',
                color:'#F5F7F2'
            }}>
            i’m ok with it
            </Typography>
        </Button>
    </Box>
  )
}

export default CookieButton