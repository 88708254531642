import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SvgIcon } from '@mui/material';
import CareersTabs from "./CareersTabs";
import NavBar from '../NavBar/NavBar';
import CareerUsHero from '../AboutUsHero/CareerUsHero';
function Careers({ faqs }: any) {


    return (
        <Box>
            <NavBar/>
            <CareerUsHero/>
        <Box id="FAQS" sx={{ bgcolor: 'primary.main', borderRadius: { sm: '20px', xs: '12px' }, flexDirection: 'column', pt: { sm: '60px', xs: '40px' }, pb: { sm: '40px', xs: '40px' } }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginBottom: { md: '24px', sm: '24px', xs: '20px' },
                    ml: { md: '1.875vw', sm: '24px', xs: '20px' },
                }}

            >
                <Typography
                    sx={{
                        display: { md: 'block', sm: 'block', xs: 'none' },
                        width: { md: '22.75vw', sm: '200px' },
                        flexShrink: 0,
                        alignItems: 'center',
                        marginRight: { md: '0.5vw', sm: '24px' },
                        color: '#FFF',
                        fontSize: { xs: '0.875rem', md: '0.875vw' },
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                    }}
                >

                    [VACANCIES]
                </Typography>
                <Typography
                    sx={{
                        flexShrink: '0',
                        color: '#F5F7F2',
                        fontSize: { md: '3.125rem', sm: '3.125rem', xs: '30px' },
                        fontWeight: 500,
                        lineHeight: '50px',
                        letterSpacing: '-0.09375rem',
                        textTransform: 'uppercase',
                        mb: '15px'
                    }}
                >
                    Work with us
                </Typography>
            </Box>

            <CareersTabs />
        </Box>
        </Box>
    );
}

export default Careers;