import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import LearnMoreBtn from "../utils/LearnMoreBtn/LearnMoreBtn";
import JudyImage from '../../assets/OurStories/stories.png';
import React from 'react';
import PlayButton from '../../assets/OurStories/PlayButton.png';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';

const AboutUsHustleRevolution = () => {
  const theme = useTheme();
  const isHigherThanXS = useMediaQuery(theme.breakpoints.up('xs'));
  const isLowerThanSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{
      padding: {
        xs: "0px 16px 0px 16px",
        sm: "0px 24px 0px 24px",
        md: "0px 1.875vw 0px 1.875vw",
      },
      bgcolor: "rgba(0, 145, 102, 0.10)",
      textTransform: "uppercase",
    }}>
      <Box sx={{
        display: {
          xs: "flex",
        },
        gap: {
          sm: "3.125vw",
          md: "1.813vw",
        },
      }}>
        <Box sx={{
          display: {
            xs: "none",
            sm: "block",
          },
          mt: {
            sm: "60px",
            md: '6.667vh'
          },
        }}>
          <Typography sx={{
            color: "rgba(1, 35, 23, 0.60)",
            fontSize: {
              sm: "2.083vw",
              md: "0.875vw",
            },
            minWidth: {
              sm: "28.385vw",
              md: "22.75vw",
            },
          }}>
            [empowering growth]
          </Typography>
        </Box>

        <Box sx={{
          display: {
            xs: "flex"
          },
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: {
            md: "space-between",
          },
          width: {
            md: "100%",
          },
          gap: {
            xs: "3.333vw",
            sm: "3.125vw",
            md: '1.5vw'
          },
        }}>
          <Box>
            <Typography sx={{
              fontWeight: 500,
              lineHeight: "100%",
              letterSpacing: "-1.5px",
              fontSize: {
                xs: "8.333vw",
                sm: "6.51vw",
                md: '3.125vw'
              },
              mt: {
                xs: "40px",
                sm: "60px",
                md: '6.667vh'
              },
            }}>
              hustle revolution
            </Typography>
          </Box>

          <Box>
            <Typography sx={{
              fontSize: {
                xs: "3.889vw",
                sm: "2.083vw",
                md: '1vw'
              },
              mt: {
                md: "6.667vh",
              },
              width: {
                md: "22.688vw",
              },
            }}>
              There are 150+ million micro to medium sized businesses across the continent of Africa.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{
        position: 'relative',
        height: {
          xs: "243px",
          sm: "377px",
          md: "73.111vh",
        },
        borderRadius: {
          xs: "20px",
        },
        mt: {
          xs: "12px",
          md: "1.778vh",
        },
      }}>
        <img
          src={JudyImage}
          alt="Judy Image"
          style={{
            width: "100%",
            height: "100%",
            objectFit: 'cover',
            borderRadius: '20px',
          }}
          onClick={() => handleOpen()}
          loading={'lazy'}
        />
        <img
          src={PlayButton}
          alt="Play Button"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          loading={'lazy'}
          onClick={() => handleOpen()}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0.95))',
            }}>
            <Backdrop
              open={open}
              onClick={handleClose}
            >
              <Box
                sx={{
                  bgcolor: '#FFF',
                  mx: { md: '16.313vw', sm: 'auto', xs: 'auto' },
                  my: { md: '8.667vh 9vh', sm: 'auto', xs: 'auto' },
                  width: { md: 'calc(100% - 32.625vw)', sm: '85%', xs: '85%' },
                  height: { md: 'calc(100% - 17.667vh)', sm: 'calc(100% - 17.667vh)', xs: '50%' },
                  padding: { md: '30px', sm: '24px', xs: '12px' },
                  borderRadius: '20px',
                }}>
                <iframe
                  width='100%'
                  height='100%'
                  style={{
                    borderRadius: (isHigherThanXS && isLowerThanSM) ? "12px" : "20px",
                  }}
                  src="https://www.youtube.com/embed/p4XzO2EWZNc"
                  title="Join the movement of small business owners making a lasting impact on their communities."
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen>
                </iframe>
              </Box>
            </Backdrop>
          </Box>
        </Modal>
      </Box>

      <Box sx={{
        display: {
          md: "flex",
        },
        justifyContent: {
          md: "center",
        },
        alignItems: {
          md: "center",
        },
      }}>
        <Box sx={{
          width: {
            md: "47.188vw",
          },
          mt: {
            xs: "12px",
            sm: "16px",
            md: '1.778vh'
          },
          pb: {
            xs: "40px",
            sm: "60px",
          },
        }}>
          <Box sx={{
            fontWeight: 500,
            lineHeight: "100%",
            letterSpacing: "-0.6px",
            fontFamily: 'Rubik',
            fontSize: {
              xs: "6.667vw",
              sm: "3.906vw",
              md: '1.875vw'
            },
          }}>
            LEJA is positioned to help unlock the tremendous potential of this youth driven market.
          </Box>

          <Box sx={{
            mt: {
              xs: "16px",
              sm: "30px",
              md: '3.333vh'
            },
          }}>
            <LearnMoreBtn />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AboutUsHustleRevolution