import TandCComponent from "../../components/TermsAndConditions/TermsAndConditions";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TermsAndConditions = () => {
  const location = useLocation()

  useEffect(
    () => {
      document.title = `Leja / ${location.pathname.slice(1)}`
    }, [location]
  );

  return (
    <TandCComponent />
  )
};

export default TermsAndConditions;
