import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const boxStyles = {
  position: 'relative',
  width: '100%',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  bgcolor: 'primary.main',
  padding: { xs: '30px 24px', sm: '24px 24px', md: '30px 30px' }
};

const topTypographyStyles = {
  fontWeight: 600,
  lineHeight: '100%',
  letterSpacing: '-1.5px',
  textTransform: 'uppercase',
  color: '#F5F7F2',
  padding: '0',
};

const bottomRightTypography = {
  position: 'absolute',
  bottom: {
    xs: '12%',
    sm: '8%',
    md: '8%',
  },
  right: '8%',
  width: {
    xs: "71%",
    sm: "80%",
    md: "65%",
  },
  color: '#F5F7F2',
  fontWeight: 500,
  lineHeight: '120%',
  textTransform: 'uppercase',
  textAlign: 'right',
};

interface Stat {
  id: number;
  title: string;
  actualValue: number;
  displayValue: string;
  currency: string;
}

const AboutUsOurNumbers = () => {
  const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [stats, setStats] = useState<Stat[]>([]);

    useEffect(() => {
      fetch(`${REACT_APP_API_ENDPOINT}/stats/our-numbers-about-us`)
      .then((response) => response.json())
      .then((data) => setStats(data.data))
      // .then((data) => console.log(data.data))
  },[])
  return (
    <Box sx={{
      padding: {
        xs: "60px 12px",
        sm: "100px 24px",
        md: "0px 30px 0px 30px",
      }
    }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 2, md: 3 }}>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ ...boxStyles, height: { xs: '58.67vw', sm: '45.7vw', md: '23.06vw' }, width: { xs: '100%', sm: '100%', md: '100%' } }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography sx={{ ...topTypographyStyles, fontSize: { xs: '11.1vw', sm: '9.77vw', md: '4.69vw' } }}> {stats[0]?.displayValue}</Typography>
              <Typography sx={{ ...bottomRightTypography, fontSize: { xs: '5vw', sm: '2.73vw', md: '1.1vw' } }}>
              {stats[0]?.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{
            ...boxStyles,
            bgcolor: 'primary.light',
            height: { xs: '58.67vw', sm: '45.7vw', md: '23.06vw' },
            width: { xs: '100%', sm: '100%', md: '100%' },
            display: 'flex',
            justifyContent: 'center',
            padding:{md:'0px 3.85vw', sm:'0px 60px',xs:'0px 19.667vw'}
          }}>
            <CardContent>
              <Typography sx={{ ...topTypographyStyles, 
                fontSize: { xs: '6.667vw', sm: '3.906vw', md: '1.875vw' }, textAlign: 'center', fontWeight: 500 }}>
              {stats[2]?.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ ...boxStyles, height: { xs: '58.67vw', sm: '45.7vw', md: '23.06vw' }, width: { xs: '100%', sm: '100%', md: '100%' } }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography sx={{ ...topTypographyStyles, fontSize: { xs: '11.1vw', sm: '9.77vw', md: '4.69vw' } }}>{stats[1]?.currency}{stats[1]?.displayValue}</Typography>
              <Typography sx={{ ...bottomRightTypography, fontSize: { xs: '5vw', sm: '2.73vw', md: '1.1vw' } }}>
              {stats[1]?.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ ...boxStyles, height: { xs: '58.67vw', sm: '45.7vw', md: '23.06vw' }, width: { xs: '100%', sm: '100%', md: '100%' } }}>
            <CardContent sx={{ padding: 0 }}>
              <Typography sx={{ ...topTypographyStyles, fontSize: { xs: '11.1vw', sm: '9.77vw', md: '4.69vw' } }}>{stats[3]?.currency}{stats[3]?.displayValue}</Typography>
              <Typography sx={{ ...bottomRightTypography, fontSize: { xs: '5vw', sm: '2.73vw', md: '1.1vw' } }}>
              {stats[3]?.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUsOurNumbers;