import React from 'react';
import { Box, Typography, Button, Grid, useMediaQuery } from "@mui/material";
import BlogMainPage from './BlogMainPage';
import { Theme } from '@mui/material/styles';
import BlogNewsPage from "./BlogNewsPage";
import BlogPeoplePage from "./BlogPeoplePage";
import BlogPlatformPage from "./BlogPlatformPage";

const Tabs = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (newValue: any) => {
    setValue(newValue);
  };
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box>
      <Grid container spacing={(isMobile ? '8px' : isTablet ? '24px' : '1.875vw')}>
        <Grid item xs={6} sm={3}>
          <Button
            variant='contained'
            onClick={() => handleChange(0)}
            sx={{
              width: '100%',
              height: '48px',
              bgcolor: value === 0 ? 'secondary.light' : '#FFF',
              '&:hover': {
                bgcolor: 'secondary.light',
                boxShadow: 'none',
              },
              boxShadow: 'none',
              borderRadius: '4px',
              // mb: "8px"
            }}
          >
            <Typography sx={{
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'uppercase',
              color: '#012317'
            }}>All</Typography>
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            variant='contained'
            onClick={() => handleChange(1)}
            sx={{
              width: '100%',
              height: '48px',
              bgcolor: value === 1 ? 'secondary.light' : '#FFF',
              '&:hover': {
                bgcolor: 'secondary.light',
                boxShadow: 'none',
              },
              boxShadow: 'none',
              borderRadius: '4px',
              // mb: '8px'
            }}
          >
            <Typography sx={{
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'uppercase',
              color: '#012317'
            }}>NEWS</Typography>
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            variant='contained'
            onClick={() => handleChange(2)}
            sx={{
              width: '100%',
              height: '48px',
              bgcolor: value === 2 ? 'secondary.light' : '#FFF',
              '&:hover': {
                bgcolor: 'secondary.light',
                boxShadow: 'none',
              },
              boxShadow: 'none',
              borderRadius: '4px',
              // mb: "8px"
            }}
          >
            <Typography sx={{
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'uppercase',
              color: '#012317'
            }}>PEOPLE</Typography>
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            variant='contained'
            onClick={() => handleChange(3)}
            sx={{
              mb: '8px',
              width: '100%',
              height: '48px',
              bgcolor: value === 3 ? 'secondary.light' : '#FFF',
              '&:hover': {
                bgcolor: 'secondary.light',
                boxShadow: 'none',
              },
              boxShadow: 'none',
              borderRadius: '4px',
            }}
          >
            <Typography sx={{
              fontSize: '16px',
              fontWeight: 500,
              textTransform: 'uppercase',
              color: '#012317'
            }}>PLATFORM</Typography>
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        {value === 0 && <BlogMainPage />}
        {value === 1 && <BlogNewsPage />}
        {value === 2 && <BlogPeoplePage />}
        {value === 3 && <BlogPlatformPage />}
      </Box>
    </Box>
  );
}

export default Tabs;
