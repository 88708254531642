import AboutUsFuture from "../../components/AboutUsFuture/AboutUsFuture";
import AboutUsHero from "../../components/AboutUsHero/AboutUsHero";
import TheyTrustUs from "../../components/TheyTrustUs/TheyTrustUs";
import InspireUs from "../../components/InspireUs/InspireUs";
import TheyLoveUs from "../../components/TheyLoveUs/TheyLoveUs";
import Blog from "../../components/Blog/Blog";
import AboutUsHustleRevolution from "../../components/AboutUsHustleRevolution/AboutUsHustleRevolution";
import AboutUsBuildBetter from "../../components/AboutUsBuildBetter/AboutUsBuildBetter";
import AboutUsOurNumbers from "../../components/AboutUsOurNumbers/AboutUsOurNumbers";
import AboutUsBankingStyle from "../../components/AboutUsBankingStyle/AboutUsBankingStyle";
import AboutUsTheTeam from "../../components/AboutUsTheTeam/AboutUsTheTeam";
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import ScrollToTop from '../../components/ComponentUtils/ScrollToTop';
import { useState, useEffect } from "react";
import TransitionPage from "../../components/SemiCircleAnimation/SemiCircleAnimation";
import { motion } from "framer-motion";
import { Box, useMediaQuery } from "@mui/material";
import { Theme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import FaqHome from "../../components/FAQS/FaqHome/FaqHome";
import Careers from "../../components/Careers/Careers";

const AboutUs = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation()

  useEffect(
    () => {
      document.title = `Leja / ${location.pathname.slice(1)}`
    }, [location]
  );

  return (
    <>
      {isTablet && (
        <>
          <ScrollToTop />
          <Box sx={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 2, delay: 0.3 }}>
              <NavBar />
            </motion.div>
          </Box>
          <AboutUsHero />
          <AboutUsFuture />
          <AboutUsHustleRevolution />
          <AboutUsTheTeam />
          {/*<Careers/>*/}
          <AboutUsBuildBetter />
          <AboutUsOurNumbers />
          <AboutUsBankingStyle />
          <TheyLoveUs />
          <FaqHome />
          <TheyTrustUs />
          <InspireUs />
          <Blog />
          <Footer />
        </>
      )}
      {!isTablet && !animationComplete && <TransitionPage onAnimationComplete={handleAnimationComplete} />}
      {!isTablet && animationComplete && (
        <>
          <ScrollToTop />
          <Box sx={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 2, delay: 0.3 }}>
              <NavBar />
            </motion.div>
          </Box>
          <AboutUsHero />
          <AboutUsFuture />
          <AboutUsHustleRevolution />
          <AboutUsTheTeam />
            {/*<Careers/>*/}
          <AboutUsBuildBetter />
          <AboutUsOurNumbers />
          <AboutUsBankingStyle />
          <TheyLoveUs />
          <FaqHome />
          <TheyTrustUs />
          <InspireUs />
          <Blog />
          <Footer />
        </>
      )}
    </>
  );
};

export default AboutUs;