import React from 'react';
import { useDropzone } from 'react-dropzone';
import Typography from "@mui/material/Typography";
import {Box} from "@mui/system";
import {CheckCircle, CheckCircleOutlined, Delete, UploadFile} from "@mui/icons-material";
import {Avatar, Card} from "@mui/material";

interface FileUploadProps {
    onFileUpload: (file: File | null) => void;
    hasError?: boolean;
}

const FileUpload = ({  onFileUpload, hasError  }: FileUploadProps) => {
    const [file, setFile] = React.useState<File | null>(null);
    const [ fileError, setFileError ] = React.useState<string | null>(null);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setFile(acceptedFiles[0]);
            onFileUpload(acceptedFiles[0]);
            setFileError(null)
        },
        accept: {
            'application/pdf': ['.pdf']
        },
        multiple: false,
        maxSize: 5242880,
        onDropRejected: (fileRejections) => {
            console.log(fileRejections);
            setFileError(fileRejections[0].errors[0].message);
        }
    });

    return (
        <>
            { file ? (
                <>
                    <Card sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderRadius: '10px',
                        padding: '16px',
                        backgroundColor: '#FCF7ED',
                        gap: '16px'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            gap: '16px',
                            alignItems: 'center'
                        }}>
                            <Avatar sx={{ bgcolor: 'transparent', color: '#009166' }}>
                                <UploadFile />
                            </Avatar>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '4px'
                            }}>
                                <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>
                                    {file.name}
                                </Typography>
                                <Typography sx={{ fontWeight: 400, fontSize: '14px', color: '#00000099'}}>
                                    {/* Round size to the nearest two decimal places and if it's greater than 1MB show value in MB otherwise show in KB in two decimal places*/}
                                    {file.size > 1000 * 1000 ? `${(file.size / (1000 * 1000)).toFixed(2)} MB` : `${(file.size / 1000).toFixed(2)} KB`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            gap: '16px'
                        }}>
                            <Delete sx={{
                                // background: var(--action-active, #0000008F);
                                color: '#0000008F',
                                cursor: 'pointer',
                                padding: '2px'
                            }} onClick={() => {
                                setFile(null)
                                onFileUpload(null)
                                setFileError(null)
                            }} />
                            <CheckCircle
                                sx={{
                                    color: '#009166',
                                    cursor: 'pointer'
                                }}
                            />
                        </Box>
                    </Card>
                    { fileError && (
                        <Typography sx={{ color: 'error.main', fontSize: '14px', pt: '8px' }}>
                            {fileError}
                        </Typography>
                    )}
                </>
            ) : (
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '152px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        backgroundColor: { hasError: '#FEEFEE', noError: '#FCF7ED' }[hasError ? 'hasError' : 'noError'],
                        borderRadius: '8px',
                        border: { hasError: '1px solid #FEEFEE', noError: '1px dashed #E3D9C5' }[hasError ? 'hasError' : 'noError']
                    }}>
                        <Box>
                            <Avatar variant="square" sx={{
                                bgcolor: 'transparent',
                                color: 'primary.main',
                                height: "24px",
                                width: "24px",
                                margin: 'auto'
                            }}>
                                <UploadFile/>
                            </Avatar>
                            <Typography sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                pt: '10px',
                                textAlign: 'center'
                            }}>
                                <span style={{
                                    color: '#009166',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}>Click to upload</span> or drag and drop.
                            </Typography>
                            <Typography sx={{
                                fontWeight: 400,
                                fontSize: '16px',
                                pt: '10px',
                                textAlign: 'center'
                            }}>
                                PDF (max. 5MB)
                            </Typography>
                        </Box>
                    </Box>
                    { hasError && (
                        <Typography sx={{ color: 'error.main', fontSize: '14px', pt: '8px' }}>
                            This document is required
                        </Typography>
                    )}
                </div>
            )}
        </>

    );
};

export default FileUpload;