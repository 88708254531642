import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {NavLink} from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {LejaBlog} from "../../models/leja-blog";
import {Theme} from "@mui/material/styles";
import {useState} from "react";

const BlogSummaryCard = ({ blog }: { blog: LejaBlog }) => {
    const { slug, title, date, image, summary } = blog;
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [hovered, setHovered] = useState(false);

    return (
        <>
            <Grid item md={6} xs={12} >
                <Box sx={{
                    borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                    padding: { xs: '4.44vw', sm: '3.125vw', md: '1.875vw' },
                    backgroundColor: hovered ? '#FFB5CC' : '#FFF',
                }}>
                    <NavLink to={'/'+slug} onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)} style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                        marginRight: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '1.875vw'),
                    }}>
                        <Box sx={{
                            height: { xs: '164.44vw', sm: '77.08vw', md: '34vw' },
                        }}>
                            <img
                                src={image}
                                alt={title}
                                style={{
                                    objectFit: "cover",
                                    width: '100%',
                                    height: (isMobile ? '91.67vw' : (isTablet && !isMobile) ? '48.44vw' : '23.25vw'),
                                    marginBottom: (isMobile ? '4.44vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw')
                                }}
                                loading={'lazy'}
                            />
                            <Box sx={{
                                mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' }
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' },
                                    alignItems: 'flex-start',
                                }}>
                                    <Typography sx={{
                                        width: '100%',
                                        fontSize: { xs: '5vw', sm: '2.73vw', md: '1.32vw' },
                                        fontWeight: 500,
                                        lineHeight: '120%',
                                        textTransform: 'uppercase',
                                        color: '#012317',
                                    }}>
                                        {title}
                                    </Typography>
                                    <ArrowForwardIcon sx={{
                                        color: 'primary.main',
                                        width: { xs: '8.89vw', sm: '4.17vw', md: '2vw' },
                                        height: { xs: '8.89vw', sm: '4.17vw', md: '2vw' }
                                    }} />
                                </Box>
                                <Box>
                                    <Typography sx={{
                                        color: '#012317',
                                        fontSize: { xs: '4.44vw', sm: '2.08vw', md: '1vw' },
                                        fontWeight: 300,
                                    }}>
                                        {summary}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography sx={{
                                    color: '#01231799',
                                    fontSize: { md: '0.875vw', sm: '1.82vw', xs: '3.89vw' }
                                }}>
                                    {date}
                                </Typography>
                            </Box>
                        </Box>
                    </NavLink>
                </Box>
            </Grid>
        </>
    )
}

export default BlogSummaryCard