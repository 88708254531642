import { Typography, useTheme } from '@mui/material'
import React from 'react'

const Notices = () => {
    const theme = useTheme();
    return (
        <Typography sx={{
            mb: '10px',
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
        }}>
            <ol style={{ margin: 0 }}>
                <li>LEJA shall send information concerning the Loan or Facility or Services via the APP and/or Short Messaging Service (SMS) to you through your Mobile Number, registered with a Mobile Network Operators, associated with your Loan or Facility or Service and registered on the Leja Platform.</li>
                <li>You may also reach to the Company via the Company’s email <a href="mailto: support@leja.co.ke" target="_blank" rel="noopener noreferrer" style={{color: theme.palette.primary.main}}> support@leja.co.ke</a> or call center <a href="tel:++254111052280" target="_blank" rel="noopener noreferrer" style={{color: theme.palette.primary.main}}>+254 111 052280</a></li>
                <li>You acknowledge that you have no claim against LEJA for damages resulting from losses, delays, misunderstandings, mutilations, duplications or any other irregularities due to transmission of any communication pertaining to the Facility or Loan or Services.</li>
            </ol>
        </Typography>
    )
}

export default Notices