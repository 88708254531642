import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import { FormInputText } from "../Common/FormInputText";
import { useForm } from "react-hook-form";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowForward, Check, CheckCircleOutline } from "@mui/icons-material";

const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    resume: null,
    coverLetter: null,
    portfolio: null,
    portfolioLink: "",
};

interface IFormInput {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    resume: File | null;
    coverLetter: File | null;
    portfolio: File | null;
    portfolioLink: string;
}

const Appendix3Complaintinvestigation = () => {
    const { handleSubmit, reset, control, setValue, getValues, formState } =
        useForm<IFormInput>({
            defaultValues: defaultValues,
        });
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");

    const onSubmit = async (data: IFormInput) => {
        if (loading) {
            return;
        }

        if (!data.coverLetter || !data.resume) {
            return;
        }

        const formData = new FormData();
        formData.append("position", "OOL");
        formData.append("name", `${data.firstName} ${data.lastName}`);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("resume", data.resume);
        formData.append("coverLetter", data.coverLetter);
        if (data.portfolio) formData.append("portfolio", data.portfolio);
        if (data.portfolioLink)
            formData.append("portfolioLink", data.portfolioLink);

        try {
            setLoading(true);

            const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/mail/careers`, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const jsonResponse = await response.json();

            setSnackbarMessage("Your application has been sent successfully");
            setOpen(true);
            console.log(jsonResponse);
        } catch (error) {
            setSnackbarMessage("Failed to send application. Please try again later");
            setOpen(true);
        }

        setLoading(false);
    };


    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const styles = {
        snackbarStyleViaContentProps: {
            backgroundColor: "#fbf5ec",
            color: "black",
        },
        snackbarStyleViaNestedContent: {
            backgroundColor: "lightgreen",
            color: "black",
        },
    };

    return (
        <>
            <Box
                sx={{
                    // marginLeft: { md: "24.25vw", sm: "8px" },
                    // mr: { md: "3.75vw", sm: "98px" },
                    border: "1px solid #E3D9C5",
                    borderRadius: "20px",
                    padding: "18px",
                    marginTop:"20px",
                    marginBottom:"20px",
                    backgroundColor:"#FCF7ED",
                }}
            >

                <Box
                    sx={{
                        backgroundColor: "#FFF",
                        padding: "18px",
                        borderRadius: "10px",
                    }}
                >
                    <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "21px",
                                        textTransform: "uppercase",
                                        color:"black"
                                    }}
                                >
                                    NAME OF INVESTIGATING MANAGER
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                }}
                            >
                                <FormInputText
                                    name="Name"
                                    control={control}
                                    label="Name"
                                    rules={{
                                        required: "Name is required",
                                    }}
                                />

                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    sx={{
                        backgroundColor: "#FFF",
                        padding: "18px",
                        borderRadius: "10px",
                        marginTop: "15px",
                        marginBottom: "15px",
                    }}
                >
                    <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "21px",
                                        textTransform: "uppercase",
                                        color:"black"
                                    }}
                                >
                                    DEPARTMENT
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                }}
                            >
                                <FormInputText
                                    name="department"
                                    control={control}
                                    label="Department"
                                    rules={{
                                        required: "Department is required",
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>




            <Box
                sx={{
                    // marginLeft: { md: "24.25vw", sm: "8px" },
                    // mr: { md: "3.75vw", sm: "98px" },
                    border:  "1px solid #E3D9C5",
                    borderRadius: "20px",
                    padding: "18px",
                }}
            >

                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <Button onClick={() => setOpen(true)}>Open Snackbar</Button> */}

                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "25px",
                            textTransform: "uppercase",
                            marginBottom: "25px",
                            color:"black"
                        }}
                    >
                        PERSONAL DETAILS
                    </Typography>

                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message={snackbarMessage}
                        action={action}
                        ContentProps={{
                            sx: styles.snackbarStyleViaContentProps,
                        }}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    />

                    {/* Job Description */}
                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        Full Name
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="firstName"
                                        control={control}
                                        label="First Name"
                                        rules={{
                                            required: "First Name is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",

                                            color:"black"

                                        }}
                                    >
                                        EMAIL ADDRESS(optional)
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="emailAddress"
                                        control={control}
                                        label="Email address"
                                        rules={{
                                            required: "Email Address is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        PHONE NUMBER
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="phonenumber"
                                        control={control}
                                        label="Phone number"
                                        rules={{
                                            required: "Phone number is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        LOCATION
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="location"
                                        control={control}
                                        label="Location"
                                        rules={{
                                            required: "Location is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>

            <Box
                sx={{
                    // marginLeft: { md: "24.25vw", sm: "8px" },
                    // mr: { md: "3.75vw", sm: "98px" },
                    marginTop: "30px",
                    border:  "1px solid #E3D9C5",
                    borderRadius: "20px",
                    padding: "18px",
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <Button onClick={() => setOpen(true)}>Open Snackbar</Button> */}

                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "25px",
                            textTransform: "uppercase",
                            marginBottom: "25px",
                            color:"black"
                        }}
                    >
                        COMPLAINT DETAILS
                    </Typography>

                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message={snackbarMessage}
                        action={action}
                        ContentProps={{
                            sx: styles.snackbarStyleViaContentProps,
                        }}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    />

                    {/* Job Description */}
                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        SERVICE
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="demo-simple-select-filled-label">Service</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            value=""
                                            onChange={() => { }}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Send Money">Send Money</MenuItem>
                                            <MenuItem value="Payments">Payments</MenuItem>
                                            <MenuItem value="Buy Float">Buy Float</MenuItem>
                                            <MenuItem value="Buy Airtime">Buy Airtime</MenuItem>
                                            <MenuItem value="Loans">Loans</MenuItem>
                                            <MenuItem value="Book keeping">Book keeping</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color:"black"
                                        }}
                                    >
                                        SUMMARY OF COMPLAINT (background and issues-attach original complaints form)
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="summary of complaint"
                                        control={control}
                                        label="summary of complaint"
                                        rules={{
                                            required: "Summary of complaint is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        DATE COMPLAINT RECEIVED
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="date"
                                        control={control}
                                        label="dd/mm/yy"
                                        rules={{
                                            required: "Date received is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        STAFF INVOLVED:
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="name"
                                        control={control}
                                        label="name"
                                        rules={{
                                            required: "Name of staff is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            color:"black"
                                        }}
                                    >
                                        DEADLINE FOR REPLY
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="date"
                                        control={control}
                                        label="dd/mm/yy"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>


                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        INVESTIGATION TIME LINE PROCESS
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="date"
                                        control={control}
                                        label="dd/mm/yy"
                                        rules={{
                                            required: "Date is required",
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        BRIEF DESCRIPTION
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="description"
                                        control={control}
                                        label="Description"
                                        rules={{
                                            required: "Description is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box>


                    </Box>
                </form>
            </Box>
            <Box sx={{
                color:"black",

            }}>

            <FindingsAndRecommendationDetails control={control} />
            </Box>


            <Box
                sx={{
                    // marginLeft: { md: "24.25vw", sm: "8px" },
                    // mr: { md: "3.75vw", sm: "98px" },
                    marginTop: "30px",
                    border:  "1px solid #E3D9C5",
                    borderRadius: "20px",
                    padding: "18px",
                }}
            >

                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: "25px",
                        textTransform: "uppercase",
                        marginBottom: "25px",
                        color:"black"
                    }}
                >
                    Response & Singnature DETAILS
                </Typography>
                <Box
                    sx={{
                        padding: "10px",
                        borderRadius: "10px",
                    }}
                >
                    <Box

                        sx={{
                            backgroundColor: "#FFF",
                            borderRadius: "10px",
                            padding: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}

                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        REPLY LETTER
                                    </Typography>
                                </Box>
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="response"
                                        control={control}
                                        label="Response"
                                        rules={{
                                            required: "Response is required",
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        SENT BY
                                    </Typography>
                                </Box>
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="name"
                                        control={control}
                                        label="Name"
                                        rules={{
                                            required: "Name is required",
                                        }}
                                    />
                                </Box>
                            </Grid>


                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black"
                                        }}
                                    >
                                        DATE
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="date"
                                        control={control}
                                        label="dd/mm/yy"
                                        rules={{
                                            required: "Date is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{
                        backgroundColor: "#FFF",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingBottom: "15px",
                        borderRadius: "10px",
                    }}>

                        <Grid container spacing={3} sx={{ alignItems: "normal", marginTop: "30px" }}>

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "20px",
                                            color:"black"
                                        }}
                                    >
                                        SIGNATURE ( Investigating Manager )
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="date"
                                        control={control}
                                        label="Signature"
                                        rules={{
                                            required: "Signature is required",
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color:"black",
                                        }}
                                    >
                                        Date
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="date"
                                        control={control}
                                        label="dd/mm/yy"
                                        rules={{
                                            required: "Date is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const FindingsAndRecommendationDetails = ({ control }: { control: any }) => {
    return (
        <Box
            sx={{
                padding: "18px",
                marginTop: "30px",
                borderRadius: "20px",
                border: "1px solid #E3D9C5",
            }}>
            <Box sx={{
                padding: "20px",
                marginBottom: "30px",
                borderRadius: "10px",
                paddingBottom: "15px",
                backgroundColor: "#FFF",
            }}>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                        textTransform: "uppercase",
                    }}
                >
                    FINDINGS
                </Typography>
                <List>
                    <ListItem>
                        <ListItemButton>
                            <ListItemIcon>
                                <Check htmlColor="black" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="List Item" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem >
                        <ListItemButton>
                            <ListItemIcon>
                                <Check htmlColor="black" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="List Item" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <ListItemIcon>
                                <Check htmlColor="black" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="List Item" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>

            <Box sx={{
                backgroundColor: "#FFF",
                padding: "20px",
                paddingBottom: "15px",
                borderRadius: "10px",
            }}>
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: "20px",
                        
                    }}
                >
                    RECOMMENDATIONS
                </Typography>

                <List>
                    {Array.from({ length: 3 }, (_, i) => (
                        <ListItem key={i}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <Check htmlColor="black" fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="List Item" />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

                <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "21px",
                                textTransform: "uppercase",
                            }}
                        >
                            Recommended By
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormInputText
                            name="name"
                            control={control}
                            label="Name"
                            rules={{
                                required: "Name is required",
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Appendix3Complaintinvestigation;