import {Backdrop, Box, Grid, Modal, Typography} from '@mui/material'
import SharePost from './SharePost'
import Blog from '../Blog/Blog'
import BlogHero from '../Blog/BlogHero'
import PlayButton from "../../assets/OurStories/PlayButton.png";
import React from "react";

export interface BlogContentProps{
    heroHeading: string;
    heroText: string;
    heroDate: string;
    heroImageUrl: string;

    contentHeading: string;
    content: Array<BlogContent | BlogContentMedia>;
}

interface BlogContent{
    heading?: string;
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    content: string;
    isQuote?: boolean;
}

interface BlogContentMedia {
    imageUrl?: string;
    videoUrl?: string;
    caption?: string;
    credits?: string;
    altText?: string;
}

const BlogContentV2 = ({article}: {article: BlogContentProps}) => {
    const {heroHeading, heroText, heroDate,heroImageUrl, contentHeading, content} = article;
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            <BlogHero imageUrl={heroImageUrl}
                heading={heroHeading}
                text={heroText}
                date={heroDate}
            />
            <Box
                  sx={{
                      mt: '40px',
                      ml: '32px',
                      mr: '32px',
                      display: 'flex',
                  }}
            >
                <Box sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block'
                    },
                    minWidth: {
                        md: '200px',
                        lg: '300px',
                        xl: '350px'
                    },
                    maxWidth: {
                        md: '300px',
                        lg: '400px',
                        xl: '500px'
                    }
                }}>
                </Box>
                <Grid item sm={12} md={5}>
                    <Typography
                        sx={{
                            color: '#012317',
                            fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                            lineHeight: '100%',
                            letterSpacing: '-1.5px',
                            fontWeight: 500,
                            fontStyle: 'normal',
                            textTransform: 'uppercase',
                            mb: { md: '30px', sm: '30px', xs: '16px' }
                        }}>
                        {contentHeading}
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Grid container rowSpacing={3}>

                            {content.map((item, index) => {

                                let headingFontSize = {md: '2.5vw', sm: '40px', xs: '24px'}
                                switch ("heading" in item && item.headingLevel) {
                                    case 'h2':
                                        headingFontSize = {md: '2vw', sm: '30px', xs: '18px'}
                                        break;
                                    case 'h3':
                                        headingFontSize = {md: '1.5vw', sm: '24px', xs: '16px'}
                                        break;
                                    case 'h4':
                                        headingFontSize = {md: '1.25vw', sm: '20px', xs: '14px'}
                                        break;
                                    case 'h5':
                                        headingFontSize = {md: '1vw', sm: '16px', xs: '12px'}
                                        break;
                                    case 'h6':
                                        headingFontSize = {md: '0.75vw', sm: '12px', xs: '10px'}
                                        break;
                                }


                                return (
                                    <>
                                        {"heading" in item && item.heading && <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    color: '#012317',
                                                    fontSize: headingFontSize,
                                                    lineHeight: '100%',
                                                    fontWeight: 500,
                                                    fontStyle: 'normal',
                                                    textTransform: 'uppercase',
                                                }}>
                                                {item.heading}
                                            </Typography>
                                        </Grid>}

                                        {"content" in item && item.content && item.isQuote && <Grid item xs={12}>
                                            <Box sx={{
                                                bgcolor: 'primary.main',
                                                padding: '30px',
                                                borderTopLeftRadius: '20px',
                                                borderTopRightRadius: '20px',
                                                borderBottomRightRadius: '20px',
                                                borderBottomLeftRadius: '0px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                                <Typography sx={{
                                                    fontSize: { xs: '16px', md: '1vw' },
                                                    fontstyle: 'normal',
                                                    fontWeight: '300',
                                                    lineHeight: '130%',
                                                    color: '#FFF',
                                                }}>
                                                    {item.content}
                                                </Typography>
                                            </Box>
                                        </Grid>}

                                        {"content" in item && item.content && !item.isQuote && <Grid item xs={12}>
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '16px', md: '1vw' },
                                                    fontstyle: 'normal',
                                                    fontWeight: '300',
                                                    lineHeight: '130%',
                                                    color: '#012317',
                                                }}>
                                                {item.content}
                                            </Typography>
                                        </Grid>}

                                        {"imageUrl" in item && item.imageUrl && !item.videoUrl && <Grid item xs={12}>
                                            <Box
                                                sx={{
                                                    objectFit:'cover',
                                                    mb: '10px'
                                                }}>
                                                <img src={item.imageUrl}
                                                     style={{
                                                         width:'100%',
                                                         maxHeight:'400px',
                                                         objectFit:'cover',
                                                         borderRadius:'20px'
                                                    }}
                                                     loading={'lazy'} />
                                            </Box>
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '15px', md: '21px' },
                                                    fontWeight: '500',
                                                    color: '#012317',
                                                    mb: '10px',
                                                    textTransform: 'uppercase'
                                                }}>
                                                {item.caption}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '10px', md: '17px' },
                                                    color: '#012317',
                                                    opacity: 0.6,
                                                }}>
                                                {item.credits}
                                            </Typography>
                                        </Grid>}

                                        {"videoUrl" in item && item.videoUrl && <Grid item xs={12}>
                                            <Box key={index} sx={{
                                                position: 'relative',
                                                padding: { md: '30px', sm: '24px', xs: '16px' },
                                                width: { sm: '100%', xs: '100%' },
                                                bgcolor: '#FFF',
                                                borderRadius: '20px',
                                                mb: '15px'
                                            }}>
                                                <img
                                                    src={item.imageUrl}
                                                    alt={`${item.altText} Image`}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: 'cover'
                                                    }}
                                                    onClick={() => handleOpen()}
                                                    loading={'lazy'}
                                                />
                                                <img
                                                    src={PlayButton}
                                                    alt="Play Button"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                    }}
                                                    onClick={() => handleOpen()}
                                                    loading={'lazy'}
                                                />
                                                <Modal
                                                    open={open}
                                                    onClose={handleClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            position: 'relative',
                                                            background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0.95))',
                                                        }}>
                                                        <Backdrop
                                                            open={open}
                                                            onClick={handleClose}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    bgcolor: '#FFF',
                                                                    mx: { md: '261px', sm: 'auto', xs: 'auto' },
                                                                    my: { md: '78px 81px', sm: 'auto', xs: 'auto' },
                                                                    width: { md: 'calc(100% - 522px)', sm: '85%', xs: '85%' },
                                                                    height: { md: 'calc(100% - 159px)', sm: 'calc(100% - 159px)', xs: '50%' },
                                                                    padding: { md: '30px', sm: '24px', xs: '12px' },
                                                                    borderRadius: '20px',
                                                                }}>
                                                                <iframe
                                                                    width="100%"
                                                                    height="100%"
                                                                    src={item.videoUrl}
                                                                    title="Unlocking growth for microbusinesses"
                                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                    allowFullScreen
                                                                ></iframe>
                                                            </Box>
                                                        </Backdrop>
                                                    </Box>
                                                </Modal>
                                            </Box>
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '15px', md: '21px' },
                                                    fontWeight: '500',
                                                    color: '#012317',
                                                    mb: '10px',
                                                    textTransform: 'uppercase'
                                                }}>
                                                {item.caption}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: { xs: '10px', md: '17px' },
                                                    color: '#012317',
                                                    opacity: 0.6,
                                                    mb: '10px',
                                                }}>
                                                {item.credits}
                                            </Typography>
                                        </Grid>}
                                    </>
                                )
                            })}
                        </Grid>
                    </Box>
                    <Box sx={{
                        display: { md: 'inline-flex', sm: 'flex', xs: 'flex' },
                        alignItems: 'center',
                        // flexDirection:{md:'row', sm:'row', xs:'column'},
                        // justifyContent: 'center',
                        gap: '17px',
                        // mt: '60px',

                    }}>
                        <Typography sx={{
                            color: 'primary.main',
                            fontSize: { xs: '16px', md: '1rem' },
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: '130%',
                            marginRight: '17px',

                        }}>Share this post:</Typography>
                        <SharePost />
                    </Box>
                </Grid>
                <Box sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block'
                    },
                    minWidth: {
                        md: '200px',
                        lg: '300px',
                        xl: '350px'
                    },
                    maxWidth: {
                        md: '300px',
                        lg: '400px',
                        xl: '500px'
                    }
                }}>
                </Box>
            </Box>
            <Blog title='MORE NEWS' subtitle='[NEWS AND INSIGHTS]' />
        </>
    );
};

export default BlogContentV2