import { Typography } from '@mui/material'
import React from 'react'

const DisputeResolution = () => {
    return (
        <Typography sx={{
            mb: '10px',
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
        }}>
            <ol style={{ margin: 0 }}>
                <li>Any dispute arising out of or relating to this Agreement, or the breach, termination or validity thereof (a Dispute) will be settled amicably between the parties.</li>
                <li>In the event that the dispute is not settled either Party has freedom to commence legal proceedings of any nature for the purposes of seeking legal redress.</li>
            </ol>
        </Typography>
    )
}

export default DisputeResolution