import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AppButton from './AppButton';
import bookKeeping from '../../assets/Features/bookkeeping_import_tutorial_mobile.mp4';
import buyFloat from '../../assets/Features/buy_float_tutorial_ussd.mp4';
import payment from '../../assets/Features/pay_bill_tutorial_mobile.mp4';
import loans from '../../assets/Features/loans_borrow_tutorial_mobile.mp4'
import wallet from '../../assets/Features/wallet_deposit_tutorial_mobile.mp4';

interface FeaturePrototypes {
    [key: string]: string;
}

const featurePrototypes: FeaturePrototypes = {
    'panel0': payment,
    'panel1': bookKeeping,
    'panel2': buyFloat,
    'panel3': wallet,
    'panel4': loans,
};

function MobileAccordion() {
    const [expanded, setExpanded] = useState<string | false>('panel0');
    const [selectedFeaturePrototype, setSelectedFeaturePrototype] = useState<string>(featurePrototypes['panel0']);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setSelectedFeaturePrototype(featurePrototypes[panel])
        setExpanded(isExpanded ? panel : false);
    };

    const features = [
        { summary: 'Payments', details: 'Simplify your mobile payments with Leja. Enjoy a seamless process and lower transaction costs while making secure transactions from your mobile device.' },
        { summary: 'Bookkeeping', details: 'Simplify your financial management with Lejas digital bookkeeping solution. Record daily sales, expenses, and liabilities effortlessly. Keep track of pending payments and effortlessly send reminders to customers with outstanding balances. Stay organized and in control of your finances with Leja.' },
        { summary: 'Buy float', details: 'Keep your Mpesa Agent business thriving with our Mpesa float service on the Leja app. Easily purchase float for your agency and others, ensuring smooth operations and uninterrupted service for your customers.' },
        // { summary: 'Leja Wallet', details: 'Secure and convenient, the Leja wallet ensures your money is always within reach. Enjoy limitless transactions and manage your finances with ease using our intuitive app.' },
        { summary: 'Loans', details: 'Need quick and affordable loans? Look no further than the Leja app. Access hassle-free loans with competitive rates, providing financial flexibility whenever you need it.' }
    ];

    return (
        <Box>
            {features.map((panel, index) => (
                <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                    sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                >
                    <AccordionSummary
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${index}` ? <RemoveIcon sx={{ color: "primary.main" }} /> : <AddIcon sx={{ color: "primary.main" }} />}
                            </IconButton>
                        }
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: '16.517px',
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: '-0.495px',
                            color: (expanded === `panel${index}` ? 'primary.main' : '#012317')
                        }}
                    >
                        {panel.summary}
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            fontSize: '14.681px',
                            fontWeight: 300,
                            lineHeight: '130%',
                        }}>
                        {panel.details}
                    </AccordionDetails>
                    <Box sx={{ mb: '19.64px' }}>
                        <AppButton />
                    </Box>
                    <Box>
                        <video
                            src={selectedFeaturePrototype}
                            controls
                            datatype="video/mp4"
                            style={{
                                marginTop: '10px',
                                width: '100%',
                                height: '550px',
                                border: '10px solid black',
                                borderRadius: '20px',
                                objectFit: 'cover'
                            }}
                        />
                    </Box>
                </Accordion>
            ))}
        </Box>
    );
}

export default MobileAccordion;
