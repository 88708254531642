import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { SvgIcon, useTheme } from '@mui/material';


const question = {
    fontSize: { sm: '21px', md: '1.313vw', xs: '4.44vw' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    textTransform: 'uppercase',
    '@media only screen and (min-width:768px) and (max-width: 1100px)': {
        ml: '8px'
    },
}

const answer = {
    fontSize: { sm: '16px', md: '1vw', xs: '4.44vw' },
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'secondary.main',
    lineHeight: '130%',
    marginLeft: { md: '24.25vw', sm: '8px' },
    mr: { md: '18.75vw', sm: '98px' }
}

const Accounts = () => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const theme = useTheme();

    const handleAccordionChange = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };
    const AddIcon = (props: any) => (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="#F5F7F2">
                <path d="M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14Z" fill="#F5F7F2" />
                <path d="M14 3.5C14.2321 3.5 14.4546 3.59219 14.6187 3.75628C14.7828 3.92038 14.875 4.14293 14.875 4.375L14.875 23.625C14.875 23.8571 14.7828 24.0796 14.6187 24.2437C14.4546 24.4078 14.2321 24.5 14 24.5C13.7679 24.5 13.5454 24.4078 13.3813 24.2437C13.2172 24.0796 13.125 23.8571 13.125 23.625L13.125 4.375C13.125 4.14293 13.2172 3.92038 13.3813 3.75628C13.5454 3.59219 13.7679 3.5 14 3.5Z" fill="#F5F7F2" />
            </svg>
        </SvgIcon>
    );

    const RemoveIcon = (props: any) => (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path d="M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14Z" fill="#64002C" />
            </svg>
        </SvgIcon>
    );

    return (
        <Box id="FAQS" sx={{
            bgcolor: 'secondary.main', flexDirection: 'column',

            // borderBottom:'1px solid rgba(245,247,242,0.30)'
        }}>
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleAccordionChange('panel1')}
                sx={{
                    bgcolor: expanded === `panel1` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },

                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel1` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel1` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '21.5vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        01
                    </Box>
                    <Typography sx={question}>
                    How to update your profile Details
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        <ol>
                            <li>Click on the “Profile” Button</li>
                            <li>Enter your “First, Middle & Last name”</li>
                            <li>Enter Email Address</li>
                            <li>Click “Save Details”.</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleAccordionChange('panel2')}
                sx={{
                    bgcolor: expanded === `panel2` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel2` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel2` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '21.5vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        02
                    </Box>
                    <Typography sx={question}>
                    How to raise a support ticket on Leja App?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                       <ol>
                                        <li>Click on the “Help” icon</li>
                                        <li>Select  product category you need support e.g. Airtime, Buy goods, Pay bill, send money, or my account</li>
                                        <li>Select subject line</li>
                                        <li>Add a brief description of your issue</li>
                                        <li>Add a picture of the issue/concern</li>
                                        <li>Click Submit</li>
                                    </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleAccordionChange('panel3')}
                sx={{
                    bgcolor: expanded === `panel3` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel3` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel3` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '21.5vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        03
                    </Box>
                    <Typography sx={question}>
                    How to refer others to use Leja?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        <ol>
                            <li>Click “Profile” Icon</li>
                            <li>Click “Share & Refer”</li>
                            <li>Copy code -Code copied to the clipboard</li>
                            <li>Share code via SMS, WhatsApp and Facebook -Open platform with preloaded message</li>
                            <li>Copy link- Code copied to clipboard</li>
                            <li>Ensure that your friend uses the referral code as is. If it is 123TRF, your friend should input the code 123TRF NOT 123trf or 123tRf.</li>
                            <li>Your friend then has to proceed to Register AND Verify their phone number.</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleAccordionChange('panel4')}
                sx={{
                    bgcolor: expanded === `panel4` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel4` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel4` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '21.5vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        04
                    </Box>
                    <Typography sx={question}>
                    What is LEJA's customer care number?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                       To get assistance on LEJA  service, customers need to call the LEJA contact center on 0111052280 and follow the prompts
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleAccordionChange('panel5')}
                sx={{
                    bgcolor: expanded === `panel5` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                    '&::after': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        // top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel5' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel5` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel5` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '21.5vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        05
                    </Box>
                    <Typography sx={question}>
                    How to review Leja App in Playstore?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                       <ol>
                        <li>Go to Play Store and search Leja App</li>
                        <li>Click on the Leja App Icon</li>
                        <li>Rate out of 5 stars (5 being the best)</li>
                        <li>Describe your experience while using the app</li>
                        <li>Click “Post”</li>
                       </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default Accounts;
