import { Box, Button, Typography } from "@mui/material"
import ArrowRight from "../../../assets/Arrowr.svg";
import { useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";

const LearnMoreBtn = ({ to = "/" }: { to?: string }) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(to);
  };

  return (
    <Link
          underline="none"
          color="inherit">
          <RouterLink to={"/leja-app"} style={{ textDecoration: "none" }}>
    <Button disableElevation onClick={() => onClick()} variant="contained" sx={{
      "&:hover": {
        bgcolor: "primary.light",
      },
      width: "100%",
      borderRadius: "5px",
      height: "48px",
    }}>
      <Typography sx={{
        fontWeight: 500,
      }}>
        Learn More
      </Typography>

      <Box sx={{
        ml: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <img src={ArrowRight} alt="Learn More" />
      </Box>
    </Button>
    </RouterLink>
    </Link>
  )
}

export default LearnMoreBtn