import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import LearnMoreBtn from "../utils/LearnMoreBtn/LearnMoreBtn";
import AboutUsPhone from "../../assets/AboutUs/about_us_phone.svg";
import AboutUsPhoneSmall from "../../assets/AboutUs/about_us_phone_mobile.svg";
import buildBetter from '../../assets/AboutUs/build_better_demo.mp4';

const AboutUsBuildBetter = () => {
  const theme = useTheme();
  const isLowerThanSM = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Box sx={{
      margin: {
        xs: "60px 16px 60px 16px",
        sm: "100px 24px 100px 24px",
        md: "140px 31px 140px 31px",
      },
    }}>
      <Box sx={{
        display: {
          xs: "flex",
        },
        flexDirection: {
          xs: "column",
          md: "row",
        },
        gap: {
          xs: "16px",
          sm: "24px",
          md: "1.875vw",
        },
      }}>
        <Box sx={{
          bgcolor: "secondary.light",
          padding: {
            xs: "24px",
            sm: "124px",
            md: "18.667vh 7.938vw",
          },
          borderRadius: {
            xs: "12px",
            sm: "20px",
          },
          flexBasis: {
            md: "50%",
          },
        }}>
          <Box sx={{
            bgcolor: "#FFF",
            padding: {
              xs: "24px",
              sm: "60px",
              md: "6.667vh 3.75vw"
            },
            borderRadius: {
              xs: "12px",
              sm: "20px",
            },
          }}>
            <Typography sx={{
              textTransform: "uppercase",
              fontWeight: 500,
              fontSize: {
                xs: "30px",
                sm: "50px",
                md: '3.125vw'
              },
              lineHeight: "100%",
              letterSpacing: "-1.5px",
            }}>
              build better
            </Typography>

            <Typography sx={{
              mt: {
                xs: "12px",
                sm: "20px",
                md: '1.25vw'
              },
              lineHeight: "130%",
              fontWeight: 300,
              fontSize: { md: '1vw', sm: '16px', xs: '16px' }
            }}>
              Leja empowers small businesses by streamlining payment management with customers and
              suppliers, providing them with the tools to save precious time and effort.
            </Typography>
            <Typography sx={{
              mt: {
                xs: "12px",
                md: '0.75vw'
              },
              lineHeight: "130%",
              fontWeight: 300,
              fontSize: { md: '1vw', sm: '16px', xs: '16px' }
            }}>
              Our platform helps these businesses establish a solid foundation, paving the way for growth, scalability,
              and enhanced access to formal financial resources.
            </Typography>

            <Box sx={{
              mt: {
                xs: "24px",
                sm: "30px",
                md: '1.875vw'
              },
            }}>
              <LearnMoreBtn to="/leja-app" />
            </Box>
          </Box>
        </Box>

        <Box sx={{
          bgcolor: "#FFF",
          borderRadius: "20px",
          flexBasis: {
            md: "50%",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: {
            xs: "24px",
            sm: "60px",
            md: "75.5px",
          },
        }}>
          <video
            src={buildBetter}
            autoPlay
            loop
            muted
            datatype="video/mp4"
            style={{
              width: (isLowerThanSM ? "270px" : '300px'),
              height: (isLowerThanSM ? "487px" : '550px'),
              border: '10px solid black',
              borderRadius: '20px',
              objectFit: 'cover'
            }}
          />
        </Box>
      </Box>
    </Box>
  )
};

export default AboutUsBuildBetter;
