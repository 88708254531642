import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const Addresses = () => {
    const addressData = [
        { country: "Kenya", location: "24 Chalbi Drive, Lavington, Nairobi", coordinates: "-1.271047, 36.764584" },
        { country: "France", location: "5 rue Frédéric Phélypeaux, 78640 Villiers-Saint-Frédéric France", coordinates: "48.811999, 1.88981" },
        { country: "United Kingdom", location: "20 St Andrews St, Holborn Circus, EC4A 3AG", coordinates: "51.517040, -0.107060" }
    ];

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: { md: '0', sm: '0', xs: '10.67vwvw' },
        }}>
            {addressData.map((address, index) => (
                <Box key={index} sx={{
                    width: 'auto',
                    height: 'auto',
                    mb: { md: '2.5vw', sm: '5.2vw', xs: '6.4vw' },
                }}>
                    <Typography sx={{
                        fontWeight: 500,
                        fontSize: { md: '1.3vw', sm: '2.73vw', xs: '4.8vw' },
                        color: '#F5F7F2',
                        lineHeight: '120%',
                        textTransform: 'uppercase',
                        mb: { md: '0.25vw', sm: '0.52vw', xs: '1.11vw' }
                    }}>
                        {address.country}
                    </Typography>
                    <Typography sx={{
                        fontWeight: 300,
                        fontSize: { md: '1vw', sm: '2.08vw', xs: '4.27vw' },
                        color: '#F5F7F2',
                        lineHeight: '130%',
                    }}>
                        {isMobile ? (
                            //check if device is mobile and open default browser : open new web tab
                            <Link
                                href={`geo:${address.coordinates}?q=${encodeURIComponent(address.location)}`}
                                sx={{ color: "white", textDecoration: "none" }}
                            >
                                {address.location}
                            </Link>
                        ) : (
                            <Link
                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address.coordinates)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ color: "white", textDecoration: "none" }}
                            >
                                {address.location}
                            </Link>
                        )}
                    </Typography>
                </Box>
            ))}
            <Box>
                <Typography sx={{
                    fontWeight: 500,
                    fontSize: { md: '1.3vw', sm: '2.73vw', xs: '4.8vw' },
                    color: '#F5F7F2',
                    lineHeight: '120%',
                    textTransform: 'uppercase'
                }}>
                    <Link href="tel:++254111052280" sx={{ color: "white", textDecoration: "none" }}>
                        +254 111 052280
                    </Link>
                </Typography>
                <Typography sx={{
                    fontWeight: 300,
                    fontSize: { md: '1vw', sm: '2.08vw', xs: '4.27vw' },
                    color: '#F5F7F2',
                    lineHeight: '130%',
                }}>
                    <Link href="mailto: support@leja.co.ke" sx={{ color: "white", textDecoration: "none" }}>
                    support@leja.co.ke
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default Addresses;
