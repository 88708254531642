import Blog from "../../components/Blog/Blog";
import OurStoriesHero from "../../components/OurStoriesPage/OurStoriesHero";
import OurStoriesComp from "../../components/OurStories/OurStoriesComp";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import TransitionPage from "../../components/SemiCircleAnimation/SemiCircleAnimation";
import NavBar from "../../components/NavBar/NavBar";
import ScrollToTop from "../../components/ComponentUtils/ScrollToTop";
import Footer from "../../components/Footer/Footer";
import { Box, useMediaQuery } from "@mui/material";
import { Theme } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import FaqHome from "../../components/FAQS/FaqHome/FaqHome";

const OurStories = () => {
  const [animationComplete, setAnimationComplete] = useState(false);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation()

  useEffect(
    () => {
      document.title = `Leja / ${location.pathname.slice(1)}`
    }, [location]
  );

  return (
    <>
      {isTablet && (
        <>
          <ScrollToTop />
          <Box sx={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 2, delay: 0.3 }}>
              <NavBar />
            </motion.div>
          </Box>
          <OurStoriesHero />
          <OurStoriesComp />
          <FaqHome />
          <Blog />
          <Footer />
        </>
      )}
      {!isTablet && !animationComplete && <TransitionPage onAnimationComplete={handleAnimationComplete} />}
      {!isTablet && animationComplete && (
        <>
          <ScrollToTop />
          <Box sx={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
          }}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 2, delay: 0.3 }}>
              <NavBar />
            </motion.div>
          </Box>
          <OurStoriesHero />
          <OurStoriesComp />
          <FaqHome />
          <Blog />
          <Footer />
        </>
      )}
    </>
  );
};

export default OurStories;