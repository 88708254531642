import React from 'react'
import BookKeeping from './BookKeeping'
import RecordTransaction from './RecordTransaction'

const Records = () => {
  return (
    <div>
        <BookKeeping />
        <RecordTransaction />
    </div>
  )
}

export default Records