import React, {useCallback, useEffect, useRef, useState} from 'react';
import Hammer from 'hammerjs';
import Judy from '../../assets/OurStories/stories.png';
import Peter from '../../assets/OurStories/Peter.jpg';
import Jackson from '../../assets/jackson.jpg';
import Testimonial4 from '../../assets/OurStories/testimonial_4.jpg';
import Testimonial5 from '../../assets/OurStories/testimonial_5.jpg';
import Paul from '../../assets/Paul.jpg';
import LeftArrow from '../../assets/LeftArrow.svg';
import RightArrow from '../../assets/RightArrow.svg';
import './TheyLoveUsWeb.css';
import {Box, Button, Modal, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import { playStoreLink } from "../utils/GetTheAppMobile/GetTheAppMobile";
import GoogleArrow from '../../assets/Group_394.svg';
import PlayButton from '../../assets/OurStories/PlayButton.png';
import Backdrop from '@mui/material/Backdrop';

type Testimonials = {
    [key: string]: string;
};

function TheyLoveUsWeb() {
    const slide = (element: any) => {
        // Your slide logic here
        if (element.classList.contains('next')) {
            next();
        } else if (element.classList.contains('prev')) {
            prev();
        }
    };

    const swipeRef = useRef(null);
    const images = [Jackson, Paul, Peter, Testimonial4, Testimonial5];
    const testimonials: Testimonials = {
        'Jackson': 'As an Uber driver, it\'s easy to mix business and personal finances. ' +
            'Leja has helped me to keep track of my transactions and better understand where ' +
            'my money goes through their easy-to-use bookkeeping feature\n',

        'Paul': 'Leja has greatly helped me reduce on transaction costs. Additionally, ' +
            'when you use Leja to send money, you get 3 free transactions below Kes ' +
            '1000 every day.\n',

        'Peter': 'I use Leja to pay employees at work because Leja allows me to send money ' +
            'to many people at once. The App is easy to use and also cost-effective for ' +
            'business owners.\n',
        'Testimonial4': 'I love Leja because l save on transaction fees. By not paying transaction fees, l save money and re-invest it back into the business',
        'Testimonial5': 'Leja helps me absorb shock in case of an emergency and puts me on a track to gain independence and thrive at home and in the market'
    };

    const imageTestimonialMap = [
        {
            image: Jackson,
            testimonial: testimonials['Jackson'],
            name: 'JACKSON | Uber driver in Nairobi',
            src: "https://www.youtube.com/embed/HltrrS9uRkE",
        },
        {
            image: Peter,
            testimonial: testimonials['Peter'],
            name: 'PETER | Restaurant owner in Nairobi',
            src: "https://www.youtube.com/embed/3r95bRvmbPk",
        },
        {
            image: Paul,
            testimonial: testimonials['Paul'],
            name: 'PAUL | Shop owner in Nairobi',
            src: "https://www.youtube.com/embed/ojIqxu4I4ek",
        },
        {
            image: Testimonial4,
            testimonial: testimonials['Testimonial4'],
            name: 'Caroline | Restaurant cashier in Nairobi',
            src: "https://www.youtube.com/embed/wUafTVLmHCA",
        },
        {
            image: Testimonial5,
            testimonial: testimonials['Testimonial5'],
            name: 'Teresina Auma | Fish monger in Gikomba',
            src: "https://www.youtube.com/embed/EwKsCj08qjc",
        },

    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const next = useCallback(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, [images.length]);

    const prev = useCallback(() => {
        setCurrentImageIndex((prevIndex) => ((prevIndex - 1 + images.length) % images.length));
    }, [images.length]);

    const [modalStates, setModalStates] = useState(new Array(images.length).fill(false));

    const handleOpenModal = (index: number) => {
        setModalStates(prevStates => prevStates.map((state, i) => i === index));
    };

    const handleCloseModal = (index: number) => {
        setModalStates(prevStates => prevStates.map((state, i) => i === index ? false : state));
    };

    useEffect(() => {
        if (swipeRef.current) {
            const hammer = new Hammer(swipeRef.current);
            hammer.on('swipeleft', () => {
                next();
            });

            hammer.on('swiperight', () => {
                prev();
            });
        }
    }, [next, prev]);

    return (
        <Box sx={{
            position: 'relative',
            pb: '200px',
            background: `linear-gradient(to top, rgba(255, 192, 203, 0.3), rgba(255, 192, 203, 0))`,
        }}>
            <Box sx={{
                textTransform: "uppercase",
                display: "flex",
                padding: {
                    xs: "12px 16px",
                    sm: "16px 24px",
                    md: "16px 30px 16px 30px",
                },
            }}>
                <Typography sx={{
                    color: "rgba(1, 35, 23, 0.60)",
                    display: {
                        xs: "none",
                        sm: "block",
                    },
                    width: {
                        sm: "113px",
                        md: "22vw",
                    },
                    mr: {
                        sm: "14.063vw",
                        md: "1.813vw",
                    },
                    fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: '1vw'
                    },
                }}>
                    [testimonials]
                </Typography>

                <Typography sx={{
                    lineHeight: "100%",
                    fontWeight: 500,
                    fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                }}>
                    They Love Us
                </Typography>

                <Button
                    disableElevation
                    sx={{
                        ml:'auto',
                        bgcolor: "#F4EEE3",
                        "&:hover": {
                            bgcolor: "#FFF",
                        },
                    }}
                    onClick={() => prev()}
                >
                    <img src={LeftArrow} alt="previous" loading={'lazy'}/>
                </Button>

                <Button
                    disableElevation
                    sx={{
                        bgcolor: "#F4EEE3",
                        ml: "30px",
                        "&:hover": {
                            bgcolor: "#FFF",
                        },
                    }}
                    onClick={() => next()}
                >
                    <img src={RightArrow} alt="next" />
                </Button>
            </Box>
            <Box className="App" ref={swipeRef}>
                <Box onClick={(event) => slide(event.target)} sx={{
                    position: 'relative',
                    overflowX: 'hidden',
                }}>
                    <ul className="list">
                        {imageTestimonialMap.map((item, index) => (
                            <li key={index}
                                className={index === currentImageIndex ? "act" : index === (currentImageIndex + 1) % images.length ? "next" : index === ((currentImageIndex - 1 + images.length) % images.length) ? "prev" : "hide"}>
                                <img src={item.image} alt="small business owner" loading={'lazy'}/>
                                <img src={PlayButton} alt="Play Button" className="play-button" onClick={() => handleOpenModal(index)} loading={'lazy'}/>
                                <Modal
                                    key={index}
                                    open={modalStates[index]}
                                    onClose={() => handleCloseModal(index)}
                                    aria-labelledby="youtube-video-modal"
                                    aria-describedby="modal-to-display-youtube-video"
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                            background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0.95))',
                                        }}>
                                        <Backdrop
                                            open={modalStates[index]}
                                            onClick={() => handleCloseModal(index)}
                                        >
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '90%',
                                                    height: '90%',
                                                    background: 'white',
                                                    borderRadius: '20px',
                                                    outline: 'none',
                                                    boxShadow: 24,
                                                    p: {md:'30px', sm:'24px', xs:'12px'},
                                                }}
                                            >
                                                <iframe
                                                    src={item.src}
                                                    title="Join the movement of small business owners making a lasting impact on their communities."
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        border: 'none',
                                                    }}
                                                />
                                            </Box>
                                        </Backdrop>
                                    </Box>
                                </Modal>
                            </li>
                        ))}
                    </ul>
                    <Box className="testimonial" sx={{
                        width: '720px',
                        ml:'auto',
                        mr:'auto',
                        display: 'flex',
                        gap: '30px',
                    }}>
                        <Typography sx={{
                            fontWeight: 400,
                            fontSize: '16px',
                            textTransform: 'uppercase',
                            lineHeight: '18.96px'
                        }}>
                            {imageTestimonialMap[currentImageIndex].testimonial}
                        </Typography>
                        <Link underline="none"
                              href={playStoreLink}
                              target="_blank"
                              rel="noopener">
                            <Button
                                variant="contained"
                                sx={{
                                    minWidth: {
                                        xs: "100%",
                                        sm: "30.078vw",
                                        md: '14.438vw',
                                    },
                                    height: {
                                        xs: "48px",
                                    },
                                    mt: {
                                        xs: "16px",
                                        sm: '0px'
                                    },
                                    "&:hover": {
                                        bgcolor: "primary.light",
                                    },
                                }}>
                                <Typography sx={{
                                    mr: {
                                        xs: "14.17px",
                                    },
                                    fontSize: '16px',
                                    fontWeight: 500
                                }}>
                                    Get The App
                                </Typography>

                                <img
                                    src={GoogleArrow}
                                    alt="Leja Playstore"
                                    style={{ width: '20px', height: '20px' }}
                                    loading={'lazy'}
                                />
                            </Button>
                        </Link>
                    </Box>
                    <Box sx={{
                        width: '720px',
                        ml:'auto',
                        mr:'auto',
                        mt: '5px'
                    }}>
                        <Typography sx={{
                            fontWeight: 300,
                            lineHeight: "130%",
                            color: "rgba(1, 35, 23, 0.60)",
                            fontSize: '16px',
                        }}>
                            {imageTestimonialMap[currentImageIndex].name}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default TheyLoveUsWeb;