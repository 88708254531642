import { Box, Button, Typography } from '@mui/material';
import Arrowr from '../../assets/Arrowr.svg'
import { HashLink as Link } from 'react-router-hash-link';


const GetinTouchBtn = ({linkTo, label}: {linkTo?: string, label?: string}) => {

  return (
    <>
      <Link to={linkTo ?? '#contact'} smooth style={{ textDecoration: 'none' }}>
          <Button
            variant='contained'
            sx={{
              display: 'flex',
              bgcolor: 'primary.light',
              justifyContent: 'center',
              height: '48px',
              width: { md: '47.188vw', sm: '100%', xs: '100%' },
              textTransform: 'uppercase',
              alignItems: 'center',
              "&:hover": {
                bgcolor: "primary.light"
              },
              padding: '8px 30px',
              borderRadius: '4px',
              ml: {md:'24vw', sm:'0px', xs:'0px'},
              // marginX:{xs:'16px', md:'400px'},
              mt: { md: '8px', sm: '24px', xs: '24px' }

            }}
          >
            <Typography sx={{
              color: '#F5F7F2',
              //   mr: '2.22vw',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: 'normal',

            }}>
                {label ?? 'GET IN TOUCH'}
            </Typography>
            <img
              src={Arrowr}
              style={{

              }}
              alt="getintouch"
              loading={'lazy'}
            />
          </Button>
      </Link>

    </>

  )
}

export default GetinTouchBtn