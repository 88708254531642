import { Grid } from "@mui/material"
import TekwaneCup from "../../assets/AboutUs/team/TekwaneCup.jpg";
import AzuImage from "../../assets/AboutUs/team/azu.jpg";
import BeckyImage from "../../assets/AboutUs/team/becky.jpg";
import RoseImage from "../../assets/AboutUs/team/Rose.jpg";
import TekwaneImage from "../../assets/AboutUs/team/tekwane.jpg";
import MorganeImage from '../../assets/AboutUs/team/morgane.jpg';
import AbstractedStyles from "./AbstractedStyles";
import DonaldImage from '../../assets/AboutUs/team/Donald.jpg'

const teamMembers = [
  {
    name: "Donald Chepkutwo",
    image: DonaldImage,
    isBlank: false,
    role: 'Project Manager',
    bgHoverColor: "secondary.main",
  },
  {
    isBlank: true,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Tekwane Mwendwa",
    image: TekwaneCup,
       role: 'Co-Founder & CEO',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Azadi Gathui",
    image: AzuImage,
    role: 'Product Designer',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "RoseMary Nyaga",
    image: RoseImage,
    role: 'Human Resource Manager',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Rebecca Kimani",
    image: BeckyImage,
    role: 'Product Manager',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
];

const TheTeamLessMobile = () => {
  return (
    <Grid container spacing={"12px"} sx={{
      display: {
        xs: "flex",
        sm: "none",
      }
    }}>
      <Grid item xs={6} sx={{
        height: "217px",
        // visibility: teamMember.isBlank ? "hidden" : "visible",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        color: "#FFF",
      }}>
        <AbstractedStyles
          imageUrl={MorganeImage}
          name='Morgane Kablan'
          role='COO & Co-Founder'
        />
      </Grid>

      {teamMembers.map((teamMember, index) => {
        return (
          <Grid key={index} item xs={6} sx={{
            height: "217px",
            visibility: teamMember.isBlank ? "hidden" : "visible",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            color: "#FFF",
          }}>
            <AbstractedStyles
              imageUrl={teamMember.image}
              name={teamMember.name}
              role={teamMember.role}
            />
          </Grid>
        );
      })}
    </Grid>
  )
}

export default TheTeamLessMobile