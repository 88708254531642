import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { NavLink } from "react-router-dom";
import { Theme } from '@mui/material/styles';
import { useState } from "react";
import twif from '../../assets/Blog/twif.jpg'
import sankalp from '../../assets/Blog/sankalp.jpg'

const BlogNewsPage = () => {
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [hovered5, setHovered5] = useState(false);
    const [hovered6, setHovered6] = useState(false);


    return (
        <>
            <Grid container
                  spacing={(isMobile ? '8px' : isTablet ? '24px' : '1.875vw')}
                  sx={{
                      mb: { md: '24px', sm: '24px', xs: '16px' },
                      alignSelf: 'stretch'
                  }}>
                    <Grid item md={6} xs={12} >
                    <Box sx={{
                        borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                        padding: { xs: '4.44vw', sm: '3.125vw', md: '1.875vw' },
                        backgroundColor: hovered6 ? '#FFB5CC' : '#FFF',
                    }}>
                        <NavLink to='/leja-at-sankalp' onMouseOver={() => setHovered6(true)} onMouseOut={() => setHovered6(false)} style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                            //backgroundColor: hovered1 ? '#FFB5CC' : '#FFF',
                            marginRight: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '1.875vw'),
                        }}>
                            <Box sx={{

                                // display: 'flex',
                                // flexDirection: 'column',
                                // width: { xs: '100%', sm: '100%', md: '46.8vw' },
                                height: { xs: '164.44vw', sm: '77.08vw', md: '34vw' },
                            }}>
                                <img
                                    // src={isMobile ? LadyBlogMobile : LadyBlog}
                                    src={sankalp}
                                    alt="sankalp"
                                    style={{
                                        objectFit: "cover",
                                        width: '100%',
                                        height: (isMobile ? '91.67vw' : (isTablet && !isMobile) ? '48.44vw' : '23.25vw'),
                                        marginBottom: (isMobile ? '4.44vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw')
                                    }}
                                    loading={'lazy'}
                                />
                                <Box sx={{
                                    mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' }
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' },
                                        alignItems: 'flex-start',
                                    }}>
                                        <Typography sx={{
                                            width: '100%',
                                            fontSize: { xs: '5vw', sm: '2.73vw', md: '1.32vw' },
                                            fontWeight: 500,
                                            lineHeight: '120%',
                                            textTransform: 'uppercase',
                                            color: '#012317',
                                        }}>
                                            Leja’s participation at Sankalp Africa Summit 2024
                                        </Typography>
                                        <ArrowForwardIcon sx={{
                                            color: 'primary.main',
                                            width: { xs: '8.89vw', sm: '4.17vw', md: '2vw' },
                                            height: { xs: '8.89vw', sm: '4.17vw', md: '2vw' }
                                        }} />
                                    </Box>
                                    <Box>
                                        <Typography sx={{
                                            color: '#012317',
                                            fontSize: { xs: '4.44vw', sm: '2.08vw', md: '1vw' },
                                            fontWeight: 300,
                                        }}>
                                            Leja's recent participation in the Sankalp Africa Summit 2024 was more than just a presence; it was a confirmation of our dedication to advancing sustainable development in the finance sector across Africa.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography sx={{
                                        color: '#01231799',
                                        fontSize: { md: '0.875vw', sm: '1.82vw', xs: '3.89vw' }
                                    }}>
                                        29/02/24
                                    </Typography>
                                </Box>
                            </Box>
                        </NavLink>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12} >
                    <Box sx={{
                        borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                        padding: { xs: '4.44vw', sm: '3.125vw', md: '1.875vw' },
                        backgroundColor: hovered5 ? '#FFB5CC' : '#FFF',
                    }}>
                        <NavLink to='/leja-at-twif' onMouseOver={() => setHovered5(true)} onMouseOut={() => setHovered5(false)} style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                            //backgroundColor: hovered1 ? '#FFB5CC' : '#FFF',
                            marginRight: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '1.875vw'),
                        }}>
                            <Box sx={{

                                // display: 'flex',
                                // flexDirection: 'column',
                                // width: { xs: '100%', sm: '100%', md: '46.8vw' },
                                height: { xs: '164.44vw', sm: '77.08vw', md: '34vw' },
                            }}>
                                <img
                                    src={twif}
                                    alt="Twif event"
                                    style={{
                                        objectFit: "cover",
                                        width: '100%',
                                        height: (isMobile ? '91.67vw' : (isTablet && !isMobile) ? '48.44vw' : '23.25vw'),
                                        marginBottom: (isMobile ? '4.44vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw')
                                    }}
                                    loading={'lazy'}
                                />
                                <Box sx={{
                                    mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' }
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' },
                                        alignItems: 'flex-start',
                                    }}>
                                        <Typography sx={{
                                            width: '100%',
                                            fontSize: { xs: '5vw', sm: '2.73vw', md: '1.32vw' },
                                            fontWeight: 500,
                                            lineHeight: '120%',
                                            textTransform: 'uppercase',
                                            color: '#012317',
                                        }}>
                                            Leja as lead sponsor at the TWIF Nairobi Fintech happy hour 2024
                                        </Typography>
                                        <ArrowForwardIcon sx={{
                                            color: 'primary.main',
                                        }} />
                                    </Box>
                                    <Box>
                                        <Typography sx={{
                                            color: '#012317',
                                            fontSize: { xs: '4.44vw', sm: '2.08vw', md: '1vw' },
                                            fontWeight: 300,
                                        }}>
                                            As the lead sponsor of TWIF 2024, Leja played a pivotal role in the success of the event, by providing essential resources and funding.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography sx={{
                                        color: '#01231799',
                                        fontSize: { md: '0.875vw', sm: '1.82vw', xs: '3.89vw' }
                                    }}>
                                        16/02/24
                                    </Typography>
                                </Box>
                            </Box>
                        </NavLink>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default BlogNewsPage