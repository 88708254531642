import * as React from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { Box, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TreeItem, TreeItemProps, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import AppButton from './AppButton';
import MobileAccordion from './MobileAccordion';
import './Features.css';
import bookKeeping from '../../assets/Features/bookkeeping_import_tutorial_mobile.mp4';
import buyFloat from '../../assets/Features/buy_float_tutorial_ussd.mp4';
import payment from '../../assets/Features/pay_bill_tutorial_mobile.mp4';
import loans from '../../assets/Features/loans_borrow_tutorial_mobile.mp4'
import wallet from '../../assets/Features/wallet_deposit_tutorial_mobile.mp4';

interface FeaturePrototypes {
    [key: string]: string;
}

type CustomTreeItemProps = TreeItemProps & { expanded: boolean };

const CustomTreeItem = React.forwardRef(
    (props: CustomTreeItemProps, ref: React.Ref<HTMLLIElement>) => (
        <TreeItem {...props} ref={ref} />
    ),
);

const StyledTreeItem = styled(CustomTreeItem)<{ expanded: boolean }>(({ theme }) => {
    const { breakpoints } = useTheme();

    return {
        marginBottom: '0px',
        [`& .${treeItemClasses.group}`]: {
            paddingLeft: '46px',
            borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
            marginLeft: '15px',
            marginBottom: '10px',
            paddingTop: '10px',
            marginTop: '-10px',
        },
        [`& .${treeItemClasses.label}`]: {
            marginLeft: '30px',
            fontSize: '21px !important',
            fontWeight: '500 !important',
            textTransform: 'uppercase',
            lineHeight: '120% !important',
            ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
                background: "none"
            },
            ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label": {
                background: "none"
            },
            ".MuiTreeItem-root:hover > .MuiTreeItem-content .MuiTreeItem-label": {
                background: "none"
            },
            "& .MuiTreeItem-content.Mui-selected ": {
                background: "none"
            },
        },
        [breakpoints.down('md')]: {
            marginBottom: '0px', // For tablet view
        },
        [breakpoints.down('sm')]: {
            marginBottom: '20px', // For mobile view
        },
    };
});

const featurePrototypes: FeaturePrototypes = {
    '1': payment,
    '2': bookKeeping,
    '3': buyFloat,
    '4': wallet,
    '5': loans,
};

export default function CustomizedTreeView() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const [expanded, setExpanded] = useState<string[]>(['1']);
    const [selectedFeaturePrototype, setSelectedFeaturePrototype] = useState<string>(featurePrototypes[1]);

    const handleNodeSelect = (event: React.ChangeEvent<{}>, nodeId: string) => {
        setExpanded((prevExpanded) => {
            if (prevExpanded.includes(nodeId)) {
                return []; // Collapse all items if the selected item is already expanded
            } else {
                setSelectedFeaturePrototype(featurePrototypes[nodeId]);
                return [nodeId]; // Expand the selected item and collapse all others
            }
        });
    };

    return (
        <Box sx={{
            height: 'auto',
            // background: 'linear-gradient(to right, #FFDDEE 50%, transparent 50%), linear-gradient(to bottom, #FFDDEE 50%, #FFBDD4 100%), linear-gradient(to bottom, #FFCCE0, #FFDDEE)',
            backgroundColor: 'secondary.light',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'row',
            ml: { md: '30px', sm: '24px', xs: '16px' },
            mr: { md: '30px', sm: '24px', xs: '16px' },
            padding: { md: '0px', sm: '0px', xs: '40px 16px' },
            mt: { md: '140px', sm: '100px', xs: '60px' },
        }}>
            <Box sx={{
                width: { md: 'auto', sm: '39.063vw' },
                ml: { md: '13vw' },
                mr: { md: '23.875vw', sm: 'auto' }
            }}>
                <Typography sx={{
                    fontSize: { md: '3.125vw', sm: '6.510vw', xs: '30px' },
                    fontWeight: 500,
                    lineHeight: '100%',
                    letterSpacing: '-1.5px',
                    textTransform: 'uppercase',
                    mb: { md: '30px', sm: '30px', xs: '18.35px' },
                    mt: { md: '75px', sm: '58px' },
                    ml: '31px',
                }}>
                    features
                </Typography>
                <Box sx={{
                    display: { md: 'none', sm: 'none', xs: 'block' }
                }}>
                    <MobileAccordion />
                </Box>
                <Box sx={{
                    ml: { md: '1.563vw', sm: '3.255vw' },
                    mb: '157px',
                    display: { md: 'block', sm: 'block', xs: 'none' }
                }}>
                    <TreeView
                        aria-label="customized"
                        defaultCollapseIcon={<FiberManualRecordIcon sx={{ width: '13px', color: '#009166' }} />}
                        defaultExpandIcon={<FiberManualRecordIcon sx={{ width: '13px' }} />}
                        defaultEndIcon={<FiberManualRecordIcon sx={{ width: '13px' }} />}
                        sx={{
                            overflowX: 'hidden',
                        }}
                        expanded={expanded}
                        onNodeSelect={handleNodeSelect}
                    >
                        <StyledTreeItem
                            nodeId="1"
                            label="Payments"
                            expanded={expanded.includes('1')}
                            sx={{
                                color: expanded.includes('1') ? 'primary.main' : 'black',
                            }}
                        >
                            <Box>
                                <Typography sx={{
                                    fontSize: { md: '1vw', sm: '16px', xs: '16px' },
                                    lineHeight: '130%',
                                    fontWeight: 300,
                                    color: 'black',
                                    mt: '10px',
                                    mb: '20px',
                                }}>
                                    Simplify your mobile payments with Leja.
                                    Enjoy a seamless process and lower transaction costs
                                    while making secure transactions from your mobile device.
                                </Typography>
                                <AppButton />
                            </Box>
                        </StyledTreeItem>
                        <Box sx={{
                            height: '40px',
                            borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
                            marginLeft: '15px',
                            mt: '-10px',
                            mb: '-10px',
                        }}></Box>
                        <StyledTreeItem
                            nodeId="2"
                            label="Bookkeeping"
                            expanded={expanded.includes('2')}
                            sx={{
                                color: expanded.includes('2') ? 'primary.main' : 'black',
                            }}
                        >
                            <Box>
                                <Typography sx={{
                                    fontSize: { md: '1vw', sm: '16px', xs: '16px' },
                                    lineHeight: '130%',
                                    fontWeight: 300,
                                    color: 'black',
                                    mt: '10px',
                                    mb: '20px',
                                }}>
                                    Simplify your financial management with Leja's digital bookkeeping solution.
                                    Record daily sales, expenses, and liabilities effortlessly. Keep track of
                                    pending payments and effortlessly send reminders to customers with outstanding
                                    balances. Stay organized and in control of your finances with Leja.
                                </Typography>
                                <AppButton />
                            </Box>
                        </StyledTreeItem>
                        <Box sx={{
                            height: '40px',
                            borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
                            marginLeft: '15px',
                            mt: '-10px',
                            mb: '-10px',
                        }}></Box>
                        <StyledTreeItem
                            nodeId="3"
                            label="Buy float"
                            expanded={expanded.includes('3')}
                            sx={{
                                color: expanded.includes('3') ? 'primary.main' : 'black',
                            }}
                        >
                            <Box>
                                <Typography sx={{
                                    fontSize: { md: '1vw', sm: '16px', xs: '16px' },
                                    lineHeight: '130%',
                                    fontWeight: 300,
                                    color: 'black',
                                    mt: '10px',
                                    mb: '20px',
                                }}>
                                    Keep your Mpesa Agent business thriving with our Mpesa float service on the Leja app.
                                    Easily purchase float for your agency and others, ensuring smooth operations and
                                    uninterrupted service for your customers.
                                </Typography>
                                <AppButton />
                            </Box>
                        </StyledTreeItem>
                        {/*<Box sx={{*/}
                        {/*    height: '40px',*/}
                        {/*    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,*/}
                        {/*    marginLeft: '15px',*/}
                        {/*    mt: '-10px',*/}
                        {/*    mb: '-10px',*/}
                        {/*}}></Box>*/}
                        {/*<StyledTreeItem*/}
                        {/*    nodeId="4"*/}
                        {/*    label="Leja Wallet"*/}
                        {/*    expanded={expanded.includes('4')}*/}
                        {/*    sx={{*/}
                        {/*        color: expanded.includes('4') ? 'primary.main' : 'black',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <Box>*/}
                        {/*        <Typography sx={{*/}
                        {/*            fontSize: { md: '1vw', sm: '16px', xs: '16px' },*/}
                        {/*            lineHeight: '130%',*/}
                        {/*            fontWeight: 300,*/}
                        {/*            color: 'black',*/}
                        {/*            mt: '10px',*/}
                        {/*            mb: '20px',*/}
                        {/*        }}>*/}
                        {/*            Secure and convenient, the Leja wallet ensures your money is always within reach.*/}
                        {/*            Enjoy limitless transactions and manage your finances with ease using our intuitive app.*/}
                        {/*        </Typography>*/}
                        {/*        <AppButton />*/}
                        {/*    </Box>*/}
                        {/*</StyledTreeItem>*/}
                        <Box sx={{
                            height: '40px',
                            borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
                            marginLeft: '15px',
                            mt: '-10px',
                            mb: '-10px',
                        }}></Box>
                        <Box sx={{ overflow: 'hidden' }}>
                            <StyledTreeItem
                                nodeId="5"
                                label="Loans"
                                expanded={expanded.includes('5')}
                                sx={{
                                    color: expanded.includes('5') ? 'primary.main' : 'black',
                                }}
                            >
                                <Box>
                                    <Typography sx={{
                                        fontSize: { md: '1vw', sm: '16px', xs: '16px' },
                                        lineHeight: '130%',
                                        fontWeight: 300,
                                        color: 'black',
                                        mt: '10px',
                                        mb: '20px',
                                    }}>
                                        Need quick and affordable loans? Look no further than the Leja app.
                                        Access hassle-free loans with competitive rates, providing financial
                                        flexibility whenever you need it.
                                    </Typography>
                                    <AppButton />
                                </Box>
                            </StyledTreeItem>
                        </Box>

                    </TreeView>
                </Box>
            </Box >
            <Box sx={{
                display: { md: 'block', sm: 'block', xs: 'none' },
            }}>
                <video
                    src={selectedFeaturePrototype}
                    controls
                    autoPlay
                    datatype="video/mp4"
                    style={{
                        marginTop: ((isTablet && !isMobile) ? '58px' : '75px'),
                        marginRight: ((isTablet && !isMobile) ? '3.255vw' : '13vw'),
                        width: ((isTablet && !isMobile) ? '300px' : '300px'),
                        height: ((isTablet && !isMobile) ? '550px' : '550px'),
                        border: '10px solid black',
                        borderRadius: '20px',
                        objectFit: 'cover'
                    }}
                />
            </Box>
        </Box >
    );
}
