import { Box, Grid, Typography } from '@mui/material'
import airtime from '../../assets/LejaHero/airtime.png'
import mjanja from '../../assets/LejaHero/mjanja.png'
import GetappButton from '../LejaHero/GetappButton'
import airtimes from '../../assets/LejaHero/airtimes.png'
import airtimesm from '../../assets/LejaHero/airtimesm.png'
import intro from '../../assets/LejaHero/intro.png'
import introsm from '../../assets/LejaHero/introsm.png'
// import { StayPrimaryLandscape } from '@mui/icons-material'
import LeftLogo from '../../assets/LejaHero/LeftLogo.png'
import RightLogo from '../../assets/LejaHero/RightLogo.png'
import { motion } from 'framer-motion'
import './LejaHero.css'
import Lottie from 'lottie-web';
import lejapage from '../../assets/Animations/lejapage.json';
import { useEffect, useRef } from 'react'

const LejaHero = () => {
  const container: any = useRef(null);
  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: container.current,
      animationData: lejapage,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      rendererSettings: {
        className: "lejapage-animation",
        preserveAspectRatio: 'xMidYMax slice',

      }
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <Box
      sx={{ overflow: 'hidden' }}
    >
      <Grid item md={12}
        sx={{
          // width: { md: '96.25rem', sm: '45rem', xs: '20.5rem' },
          //height: { md: 'auto', sm: 'auto', xs: 'auto' },
          display: { md: 'flex', sm: 'flex', xs: 'flex' }, // Use 'flex' for all breakpoints
          alignItems: 'flex-start',
          marginLeft: { md: '1.87rem', sm: '1.5rem', xs: '1rem' },
          marginRight: { md: '1.87rem', sm: '1.5rem', xs: '1rem' },
          flexDirection: { md: 'row', sm: 'column-reverse', xs: 'column-reverse' },
          //marginTop:{md:'16px', xs:'12px', sm:'16px'}
        }}
      >
        <Box
          sx={{
            width: { md: '22.625rem', sm: '39.974vw', xs: '91.11vw' },
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            //marginLeft:{md:'1.88rem'},
            //justifyContent: { sm: 'flex-end', md: 'flex-start', xs:'flex-start' }
            marginBottom: '16px'
          }}
        >
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Typography
              sx={{
                width: { md: '16.625vw', sm: '19.1875rem', xs: '91.11vw' },
                // height: { md: '5.3125rem', sm: '4.25rem', xs: '4.25rem' },
                fontSize: { xs: '14px', md: '0.875vw' },
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'uppercase',
                color: '#012317',
                display: { md: 'block', sm: 'none', xs: 'block' }
              }}
            >
              LEJA is more than an app - it symbolizes our unwavering commitment to the men and women who form the backbone of the continent's economic life.
            </Typography>
          </motion.div>
        </Box>
        <motion.div
          initial={{ x: "-150px", clipPath: "inset(0px 100% 0px 0px)" }}
          animate={{ x: 0, clipPath: "inset(0px 0px 0px 0px)" }}
          transition={{ duration: 1.5, ease: "easeInOut", delay: 1 }}
        >
          <Typography
            sx={{
              color: '#012317',
              fontSize: { md: '4.6875vw', sm: '4.6875rem', xs: '2.5rem' },
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '100%',
              letterSpacing: { md: '-0.09375rem', sm: '-0.09375rem', xs: '-0.125rem' },
              width: { md: '71.375vw', sm: '93.75vw', xs: '91.11vw' },
              mb: { xs: '16px', md: '30px' },
              mt: { md: '-7.5px' }
            }}
          >
            TAKE CONTROL OF YOUR BUSINESS FINANCES
          </Typography>
        </motion.div>
      </Grid>

      <Grid item md={12} sx={{ display: 'flex', position: 'relative' }}>
        <Box sx={{
          display: { md: 'block', xs: 'none' },
          width: '100vw',
          marginBottom: '60px',
          zIndex: 2,
          marginTop: { sm: '-160px', xs: '-30px' }
          }}>
          <div ref={container} />
        </Box>
        <Box className='animation-c' sx={{ display: { md: 'none' }, bgcolor: 'primary.light', pt: '30px', pb: '30px', position: 'absolute', top: { sm: '180px', xs: '80px' } }}>
          {/* <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            transition={{ duration: 1.5, delay: 0, ease: 'linear', }}

          > */}

            <img src={LeftLogo} className='left-logo' style={{ overflow: 'hidden', width: '100%', marginBottom: '40px' }} loading={'lazy'}/>
            <img src={RightLogo} className='right-logo' style={{ overflow: 'hidden', width: '100%' }} loading={'lazy'} />

          {/* </motion.div> */}
        </Box>
        {/* <motion.div
          initial={{ opacity: 1, x: -900 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, delay: 2 }}
          style={{ position: 'relative', zIndex: 2 }}
        > */}
          <Box sx={{
            marginLeft: { md: '26.4375vw', sm: '4.557vw', xs: '5.55vw' },
            marginRight: { md: '2.5vw', sm: '5.208vw', xs: '4.44vw' },
            zIndex: 2,
            display: { md: 'none' },
          }}>
            <Box sx={{ display: { xs: 'none', md: 'block', sm: 'none' } }}><img src={mjanja} loading={'lazy'} /></Box>
            <Box sx={{ display: { md: 'none', sm: 'none', xs: 'block' } }}><img src={intro}  loading={'lazy'}/></Box>
            <Box sx={{ display: { md: 'none', sm: 'block', xs: 'none' } }}><img src={introsm} loading={'lazy'} /></Box>

          </Box>
        {/* </motion.div> */}

        <Box sx={{
          marginTop: { md: '127px' },
          // mr:'234px',
          //zIndex:1300

        }}>
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <Typography
              sx={{

                marginRight: '24px',
                marginLeft: '22.41px',
                mb: '69px',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: 'normal',
                textTransform: 'uppercase',
                color: '#012317',
                display: { md: 'none', sm: 'block', xs: 'none' },
                textAlign: 'right'
              }}
            >
              [ LEJA is more than an app - it symbolizes our unwavering commitment to the men and women who form the backbone of the continent's economic life. ]
            </Typography>
          </motion.div>

          <motion.div
            // initial={{ opacity: 0, x: -20, y: -40 }}
            // animate={{ opacity: 1, x: 0, y: 0 }}
            // transition={{ duration: 2, delay: 2.5 }}
            style={{ position: 'relative', zIndex: 2 }}
          >
            {/* <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}><img src={airtime} /></Box> */}
            <Box sx={{ marginRight: '23.59px', display: { xs: 'none', sm: 'block', md: 'none' }, mr: '23.59px'}}><img src={airtimesm} loading={'lazy'} /></Box>
            <Box sx={{ marginTop: { sm: '0px', xs: '41px', md: '0' }, display: { xs: 'block', sm: 'none', md: 'none' } }}><img src={airtimes} loading={'lazy'} /></Box>
          </motion.div>
        </Box>
      </Grid>
      <GetappButton />
    </Box>

  )
}

export default LejaHero