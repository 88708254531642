import { Typography, useTheme } from '@mui/material'
import React from 'react'

const PersonalInformation = () => {
  const theme = useTheme();
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        <ol style={{ margin: 0 }}>
            <li>You hereby agree and authorise LEJA to verify information provided by you to LEJA against the information held by the Mobile Money Providers or any other Personal information database including but not limited to the Integrated Population Registration System and Anti-Money Laundering database in relation to your Mobile Money Account pursuant to the agreement between you and the relevant Mobile Money Provider for the provision of its products and services and the Mobile Money Service.</li>
            <li>The information that LEJA may verify against the information held by the Mobile Money Providers or any other Personal information database including but not limited to the Integrated Population Registration System and Anti-Money Laundering database in relation to: your phone number, name, date of birth, Identification Number (“ID”) or Passport Number and any other such other information that will enable LEJA to identify you and comply with the regulatory “Know Your Customer” requirements (Collectively referred to as “Personal Information”).</li>
            <li>You hereby agree and authorise LEJA to verify information including, but not limited to, data relating to your phone (including, without limitation, your phone’s history) from your Equipment, from any SMS sent to you by the Mobile Money Providers and any financial services providers relating to your use of the Mobile Money Service and such other information as LEJA shall require for purposes of providing you the Services (Collectively referred to as the “Relevant Information”).</li>
            <li>You hereby consent to LEJA verifying the Personal Information and the Relevant Information with the Mobile Money Providers or any other Personal information database and using of the Personal Information and the Relevant Information to the extent necessary in the opinion of LEJA to provide you with the Services.</li>
            <li>You hereby agree and authorise LEJA to obtain and procure your Personal Information and Relevant Information from your respective Mobile Money Provider or any other Personal information database and you further agree and consent to the disclosure and provision of such Personal Information by the Mobile Money Provider and further to indemnify and hold LEJA and the Mobile Money Provider harmless with respect to any claims, losses, liabilities and expenses (including legal fees and expenses) that may arise as a result of the disclosure and reliance on such Personal Information and/or Relevant Information.</li>
            <li>You hereby authorise LEJA to access your information and use it in the manner prescribed in LEJA’s <a href="/privacy-policy" target="_blank" rel="noopener noreferrer" style={{color: theme.palette.primary.main}}>Updated privacy policy.</a></li>
            <li>LEJA reserves the right to request for further information from you pertaining to your request for a Loan, Service or to access the Leja Platform at any time. Failure to provide such information within the time required by LEJA may result in LEJA declining to accept your request.</li>
        </ol>
    </Typography>
  )
}

export default PersonalInformation