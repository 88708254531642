import { Box, Typography } from '@mui/material';

// ImageWithTypographies component

interface ImageProps {
    imageUrl: any;
    name: any;
    role: any;
}

 // restricting colors to those given on figma
 const colorList = [
    '#012317', '#64002C', '#00C774', '#009166',
    '#FF934F', '#FFAAFF', '#9C47D4', '#2AB7CA',
    '#5299D3', '#4E00BF'
];

const generateRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colorList.length);
    return colorList[randomIndex];
};

const AbstractedStyles = ({ imageUrl, name, role }: ImageProps) => {
    const randomColor = generateRandomColor();

    return (
        <Box
            sx={{
                height: "100%",
                borderRadius: "20px",
                position: 'relative',
                overflow: 'hidden',
                "&:hover .typographiesBox": {
                    top: 0,
                },
                '@media only screen and (max-width:360px)': {
                    width: "156px" 
                  }
            }}
        >
            <img
                src={imageUrl}
                alt={name}
                // width={xs:"156px",sm:'100%'},
                height="100%"
                style={{
                    objectFit: "cover",
                    borderRadius: "20px",
                    width:'100%'
                }}
                loading={'lazy'}
            />
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '-100%',
                    right: 0,
                    //textWrap:'wrap',
                    background: randomColor,
                    borderRadius: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: '1s'
                }}
                className="typographiesBox"
            >
                <Typography
                    sx={{
                        textTransform: "uppercase",
                        fontSize: "21px",
                        color: 'white',
                        fontWeight: 600,
                        lineHeight: "120%",
                    }}
                >
                    {name}
                </Typography>
                
                <Typography
                    sx={{
                        mt: "8px",
                        color: 'white',
                        fontWeight: 300,
                        lineHeight: "130%",
                        }}
                >
                    {role}
                </Typography>
            </Box>
        </Box>
    );
};

export default AbstractedStyles;
