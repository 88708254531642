import {Box, Typography} from '@mui/material'
import Visa from '../../assets/Footer/visa.png';
import DataProtection from '../../assets/Footer/dataProtection.png';

const Achievements = () => {
    const awards = [
        { source: Visa, desc: 'Visa everywhere initiative' },
    ]

    const regulators = [
        { source: DataProtection, desc: 'Visa everywhere initiative' },
    ]

    return (
        <Box sx={{
            display: {xs:'block'},
        }}>
            <Box sx={{
                width: '25.5vw',
            }}>
                <Typography sx={{
                    textTransform: 'uppercase',
                    fontSize:'14px',
                    color:'#F5F7F2',
                    mb: {sm:'10px', xs:'8px'}
                }}>
                    Awards:
                </Typography>
                <Box sx={{
                    display: 'flex'
                }}>
                    {awards.map((award, index) => (
                        <Box key={index}
                             sx={{
                                 width: {sm:'114px', xs:'86px'},
                                 height: {md:'80px', sm:'80px', xs:'70px'},
                                 mr: '24px',
                                 mt: {md: '4px'},
                                 mb: {sm:'0', xs:'24px'}
                             }}
                        >
                            <img
                                src={award.source}
                                alt={award.desc}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                loading={'lazy'}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>

            <Box sx={{
                width: 'auto',
                ml: {md:'0px', sm:'26.51vw', xs:'0'},
                mt: { md: '32px'}
            }}>
                <Typography sx={{
                    textTransform: 'uppercase',
                    fontSize:'14px',
                    color:'#F5F7F2',
                    mb: {sm:'10px', xs:'8px'}
                }}>
                    Regulated by:
                </Typography>
                <Box sx={{
                    display: 'flex',
                }}>
                    {regulators.map((regulator, index) => (
                        <Box key={index}
                             sx={{
                                 width:{md:'90px', sm:'90px', xs:'70px'},
                                 height: {md:'90px', sm:'90px', xs:'70px'},
                                 mr: '24px'
                             }}
                        >
                            <img
                                src={regulator.source}
                                alt={regulator.desc}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                loading={'lazy'}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default Achievements