import {Box, Typography} from "@mui/material";
import React from "react";

const ComplaintPolicySectionAppendix = ({title, children}: {title: string, children: React.ReactNode}) => {

    return (
        <>
            <Box sx={{
                display: { md: 'flex', sm: 'block', xs: 'block' },
                ml: { md: '30px', sm: '24px', xs: '16px' },
                mr: { md: '30px', sm: '24px', xs: '16px' },
                mt: { md: '11.667vh', sm: '38px', xs: '62px' },
                color:'white',
                paddingTop:'6px',
            }}>
                <Typography sx={{
                    textTransform: 'uppercase',
                    mr: { lg: "113px",  md: '15px', sm: '543px', xs: '151px' },
                    mb: { sm: '30px', xs: '24px' },
                    fontSize: { md: '0.875vw', sm: '14px', xs: '14px' },
                    fontWeight: 400,
                    width: { md: '283px' },
                    paddingTop:'6px',
                }}>
                    [COMPLAINT POLICY AND PROCEDURE]
                </Typography>
                <Typography sx={{
                    fontSize: { md: '2.75vw', sm: '50px', xs: '30px' },
                    fontWeight: 500,
                    lineHeight: '100%',
                    lineSpacing: '-1.5px',
                    textTransform: 'uppercase',
                    paddingTop:'6px',
                }}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{
                mb: { md: '15.556vh', sm: '100px', xs: '58px' },
                mt: { md: '5.444vh', sm: '72px', xs: '40px' },
                borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                borderBottom: '1px solid rgba(1, 35, 23, 0.15)',
            }}>
                {children}
            </Box>
        </>
    )
}

export default ComplaintPolicySectionAppendix