import { Typography } from '@mui/material'
import React from 'react'

const Operations = () => {
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        <ol style={{ margin: 0 }}>
            <li>You acknowledge that LEJA shall appraise your Facility request according to LEJA’s loan appraisal and vetting processes and that LEJA reserves the right, at its sole discretion, and without assigning any reason to approve or decline your application for a Facility.</li>
            <li>Subject to approval of your Facility request, LEJA shall lend and disburse to you an amount to be determined by LEJA in its sole discretion subject to a minimum amount of <strong>Kenya Shillings One Thousand (KES. 1,000) only</strong> and a maximum amount of <strong>Kenya Shillings Twenty Thousand (KES. 20,000) only</strong> which maximum shall be based on the Customer’s advised Loan Limit.</li>
            <li>The proceeds of the Loan shall be credited into either your M-PESA Account, E-Wallet or any other option availed by LEJA which you select, subject to any deductions on account of applicable Processing Fee and or Transaction Fees.</li>
            <li>In consideration of LEJA granting you the Loan, you shall pay an Processing Fee for the provision of the Service being Fifteen Percent (15%) which is exclusive of Excise Duty or at such other rate that LEJA shall determine and effect for the provision of the Services to You from time to time.</li>
            <li>You shall make payments due from you to LEJA in respect of the Loan on the Repayment Date using the LEJA APP unless otherwise agreed and advised by LEJA in its discretion.</li>
            <li>If you do not pay the Loan on the Repayment Date LEJA shall automatically charge a Late Penalty Charge without notice.</li>
            <li>You shall pay to LEJA any charges, fees, expenses, taxes, duties, impositions and expenses incurred by LEJA in complying with your Facility request.</li>
            <li>You hereby expressly give consent to LEJA to recover all monies due that you will not have settled on the Repayment Date through reminders via Calls and/or SMS or cause the Lenders who have partnered with Leja to list you as a defaulter with the Credit Reference Bureau.</li>
            <li>LEJA reserves the right to vary the terms of the Loan including the Processing Fee and Transaction Fees payable thereon from time to time.</li>
            <li>Any addition, alteration, modification or deletion to these Terms and Conditions which may be made from time to time by LEJA and of which notice may be given to you by way of Short Messaging Service or such other method that LEJA shall adopt and use to communicate with you shall be binding upon you as fully as if the same were contained in these Terms and conditions.</li>
            <li>You hereby undertake to notify LEJA immediately of any situation, circumstance or event, which materially changes the provision, use, operation and management of the Loan, Services or your access to the Leja Platform.</li>
            <li>If LEJA holds any money or you have a credit balance with LEJA at any time as a result of overpayment of your Loan, you may issue a Request to LEJA for payment of such credit balance and LEJA will return any such balance to you, less any applicable fees, provided that such amount falls above the minimum transfer amounts specified by the relevant Mobile Money Provider.</li>
        </ol>
    </Typography>
  )
}

export default Operations