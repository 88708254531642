import { Box, Typography } from "@mui/material"
import LejaIconImage from '../../assets/LejaTextImageWhite.svg';

const AboutUsFuture = () => {
  return (
    <Box sx={{
      margin: {
        xs: "60px 16px 60px 16px",
        sm: "106px 24px 106px 24px",
        md: "50px 1.875vw 50px 1.875vw",
      },
    }}>
      <Box sx={{
        height: {
          xs: "390px",
          sm:'390px',
          md:'43.333vh'
        },
        display: {
          xs: "flex",
        },
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        alignItems: {
          xs: "center",
        },
        gap: {
          sm: "5.599vw",
          md: "7.5vw",
        },
      }}>
        <Box sx={{
          borderRadius: "100%",
          bgcolor: "primary.main",
          ml: {
            sm: "4.427vw",
            md: "6.313vw",
          },
          width: {
            xs: "171px",
          },
          height: {
            xs: "175px",
          },
          display: {
            xs: "flex",
          },
          justifyContent: {
            xs: "center",
          },
          alignItems: {
            xs: "center",
          },
          flexShrink: {
            sm: 0,
          },
        }}>
          <img
            src={LejaIconImage}
            alt=""
            loading={'lazy'}
          />
        </Box>

        <Typography sx={{
          mt: {
            xs: "30px",
            md: '-30px'
          },
          textAlign: {
            xs: "center",
            sm: "left",
          },
          fontSize: {
            xs: "11.111vw",
            sm: "9.766vw",
            md:'4.688vw'
          },
          fontWeight: 600,
          lineHeight: "100%",
          letterSpacing: {
            xs: "-2px",
            sm: "-1.5px",
          },
        }}>
          THIS IS THE FUTURE OF BUSINESS IN AFRICA
        </Typography>
      </Box>
    </Box>
  )
}

export default AboutUsFuture