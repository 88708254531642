import { Box } from '@mui/material'
import Accordiont from './About'
import SignUp from './SignUp'
import LogIn from './LogIn'

const AboutLeja = () => {
  return (
    <Box>
        <Accordiont />
        <SignUp />
        <LogIn />
    </Box>
  )
}

export default AboutLeja
