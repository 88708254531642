import Carousel from "react-multi-carousel";
import { Box, Button, Link, Modal, Typography, useMediaQuery, useTheme } from "@mui/material";
import 'react-multi-carousel/lib/styles.css';
import styles from './TheyLoveUs.module.css'
// import Judy from '../../assets/OurStories/stories.png';
import Peter from '../../assets/OurStories/Peter.jpg';
import Jackson from '../../assets/jackson.jpg';
import Paul from '../../assets/Paul.jpg';
import GoogleArrow from '../../assets/Group_394.svg';
import LeftArrow from '../../assets/LeftArrow.svg';
import RightArrow from '../../assets/RightArrow.svg';
import PlayButton from '../../assets/OurStories/PlayButton.png';
import { playStoreLink } from "../utils/GetTheAppMobile/GetTheAppMobile";
import { useState } from "react";
import Backdrop from '@mui/material/Backdrop';

const transition = "all .2s ease-in-out";
const scaleUpTransform = "scale(1.05) translateY(1.5vh)";
const scaleDownTransform = "scale(0.9) translateY(-3vh)";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 250
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30
  }
};

const images = [
  // {
  //   heading: "As a fish monger, Leja has helped me reduce the cost of paying my suppliers. By using Leja to send money, I have been able to save up to 60% on transaction costs.",
  //   subHeading: "JUDY | Fish Trader in Gikomba Market in Nairobi",
  //   url: Judy,
  // },
  // {
  //   heading: "As an Uber driver, it's easy to mix business and personal finances. Leja has helped me to keep track of my transactions and better understand where my money goes through their easy-to-use bookkeeping feature.",
  //   subHeading: "JACKSON | Uber driver in Nairobi",
  //   url: Jackson,
  // },
  // {
  //   heading: "Leja has greatly helped me reduce on transaction costs. Additionally, when you use Leja to send money, you get 3 free transactions below Kes 1000 every day.",
  //   subHeading: "PAUL | Shop owner in Nairobi",
  //   url: Paul,
  // },
  {
    heading: "I use Leja to pay employees at work because Leja allows me to send money to many people at once. The App is easy to use and also cost-effective for business owners.",
    subHeading: "PETER | Restaurant owner in Nairobi",
    url: Peter,
    src: "https://www.youtube.com/embed/3r95bRvmbPk"
  },
  {
    heading: "As an Uber driver, it's easy to mix business and personal finances. Leja has helped me to keep track of my transactions and better understand where my money goes through their easy-to-use bookkeeping feature.",
    subHeading: "JACKSON | Uber driver in Nairobi",
    url: Jackson,
    src: "https://www.youtube.com/embed/HltrrS9uRkE"
  },
  {
    heading: "Leja has greatly helped me reduce on transaction costs. Additionally, when you use Leja to send money, you get 3 free transactions below Kes 1000 every day.",
    subHeading: "PAUL | Shop owner in Nairobi",
    url: Paul,
    src: "https://www.youtube.com/embed/ojIqxu4I4ek"
  },
];

const ButtonGroup = ({ next, previous, ...rest }: any) => {
  const { carouselState: { currentSlide } } = rest;

  return (
    <Box
      sx={{
        position: "absolute",
        top: "16px",
        right: "30px",
        display: {
          xs: "none",
          md: "block",
        },
      }}>
      <Button
        disableElevation
        className={currentSlide === 0 ? 'disable' : ''}
        sx={{
          bgcolor: "#F4EEE3",
          "&:hover": {
            bgcolor: "#FFF",
          },
        }}
        onClick={() => previous()}
      >
        <img src={LeftArrow} alt="previous" loading={'lazy'} />
      </Button>

      <Button
        disableElevation
        sx={{
          bgcolor: "#F4EEE3",
          ml: "30px",
          "&:hover": {
            bgcolor: "#FFF",
          },
        }}
        onClick={() => next()}
      >
        <img src={RightArrow} alt="next" loading={'lazy'} />
      </Button>
    </Box>
  );
};

const initialModalState = images.map(() => false);

const TheyLoveUsMobile = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [modalStates, setModalStates] = useState(initialModalState);

  const [isCurrentSlideIndex, setIsCurrentSlideIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (index: any) => {
    const newModalStates = [...modalStates];
    newModalStates[index] = true;
    setModalStates(newModalStates);
  };
  const handleCloseModal = (index: any) => {
    const newModalStates = [...modalStates];
    newModalStates[index] = false;
    setModalStates(newModalStates);
  };

  const animateSlides = (nextSlide: number, currentSlide: number) => {

    const isForwardMovement = (nextSlide > currentSlide);

    let centerItemIndex = isForwardMovement ? ((currentSlide - 1) % images.length) : ((nextSlide - 2) % images.length);
    if (centerItemIndex < 0) centerItemIndex += images.length

    setIsCurrentSlideIndex(centerItemIndex);

    const centerElements = document.querySelectorAll(`#they-love-us-${centerItemIndex}`);
    const centerElement = isForwardMovement ? (centerElements[(centerItemIndex >= 4) ? 1 : 0] as HTMLElement) : (centerElements[(centerItemIndex === 4) ? 1 : 0] as HTMLElement);

    if (centerElement) centerElement.style.transition = transition;
    if (centerElement) centerElement.style.transform = scaleUpTransform;

    let leftItemIndex = centerItemIndex - 1;
    if (leftItemIndex < 0) leftItemIndex += images.length;

    const leftElements = document.querySelectorAll(`#they-love-us-${leftItemIndex}`);
    const leftElement = isForwardMovement ? (leftElements[(centerItemIndex >= 5) ? 1 : 0] as HTMLElement) : (leftElements[(centerItemIndex <= 5) ? 0 : 1] as HTMLElement);

    if (leftElement) centerElement.style.transition = transition;
    if (leftElement) leftElement.style.transform = scaleDownTransform


    let rightItemIndex = centerItemIndex + 1;
    if (rightItemIndex > (images.length - 1)) rightItemIndex -= images.length;

    const rightElements = document.querySelectorAll(`#they-love-us-${rightItemIndex}`);
    const rElemCondition = (centerItemIndex >= 3) ? 1 : 0;
    const rightElement = isForwardMovement ? (rightElements[rElemCondition] as HTMLElement) : (rightElements[(centerItemIndex === 3) ? 1 : (centerItemIndex === 5) ? 0 : rElemCondition] as HTMLElement);

    if (rightElement) centerElement.style.transition = transition;
    if (rightElement) rightElement.style.transform = scaleDownTransform
  };

  return (
    <Box sx={{
      position: "relative",
      margin: {
        xs: "60px 0px 0px 0px",
        sm: "100px 0px 0px 0px",
        md: "0px",
      },
      pb: { md: '100px', sm: '100px', xs: '100px' },
      background: `linear-gradient(to top, rgba(255, 192, 203, 0.3), rgba(255, 192, 203, 0))`,
    }}>
      <Box sx={{
        textTransform: "uppercase",
        display: "flex",
        padding: {
          xs: "12px 16px",
          sm: "16px 24px",
          md: "16px 30px 16px 30px",
        },
      }}>
        <Typography sx={{
          color: "rgba(1, 35, 23, 0.60)",
          display: {
            xs: "none",
            sm: "block",
          },
          width: {
            sm: "113px",
            md: "22vw",
          },
          mr: {
            sm: "14.063vw",
            md: "1.813vw",
          },
          fontSize: {
            xs: "14px",
            sm: "14px",
            md: '1vw'
          },
        }}>
          [testimonials]
        </Typography>

        <Typography sx={{
          lineHeight: "100%",
          fontWeight: 500,
          fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
        }}>
          They Love Us
        </Typography>
      </Box>

      <Box sx={{
        padding: {
          xs: "0px 0px 0px 16px",
          sm: "0px 0px 0px 24px",
          md: "0"
        },
      }}>
        <Carousel
          partialVisible={mdDown}
          centerMode={!mdDown}
          itemClass={styles.imageItem}
          responsive={responsive}
          autoPlay={!isModalOpen && mdDown} // Toggle autoplay based on modal state and screen size
          infinite={true}
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside
          beforeChange={(nextSlide, { currentSlide }) => {
            return mdDown ? () => undefined : animateSlides(nextSlide, currentSlide);
          }}
        >
          {images.map((image, index) => {
            return (
              <Box
                key={image.url}
                component="div"
                id={`they-love-us-${index}`}
                sx={{
                  width: "100%",
                  borderRadius: "20px",
                  transition: {
                    md: "all .2s ease-in-out",
                  },
                  transform: {
                    md: (isCurrentSlideIndex === index) ? scaleUpTransform : scaleDownTransform,
                  },
                }}>
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "white",
                    padding: "24px",
                    borderRadius: "20px",
                    position: 'relative',
                  }}>
                  <img
                    src={image.url}
                    alt="inspiration"
                    style={{
                      width: "100%",
                      height: (smDown ? "239px" : (mdDown ? "463px" : "52.222vh")),
                      objectFit: "cover",

                    }}
                    onClick={() => handleOpenModal(index)}
                    loading={'lazy'}
                  />
                  <img
                    src={PlayButton}
                    alt="Play Button"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                    onClick={() => handleOpenModal(index)}
                    loading={'lazy'}
                  />
                </Box>

                <Modal
                  key={index}
                  open={modalStates[index]}
                  onClose={() => handleCloseModal(index)}
                  aria-labelledby="youtube-video-modal"
                  aria-describedby="modal-to-display-youtube-video"
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0.95))',
                    }}>
                    <Backdrop
                      open={modalStates[index]}
                      onClick={() => handleCloseModal(index)}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '90%',
                          height: '90%',
                          background: 'white',
                          borderRadius: '20px',
                          outline: 'none',
                          boxShadow: 24,
                          p: {md:'30px', sm:'24px', xs:'12px'},
                        }}
                      >
                        <iframe
                          src={image.src}
                          title="Join the movement of small business owners making a lasting impact on their communities."
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                          }}
                        />
                        <button onClick={() => handleCloseModal(index)}>Close</button>
                      </Box>
                    </Backdrop>
                  </Box>
                </Modal>


                <Box sx={{
                  mt: {
                    sm: "20px",
                    md: '2.222vh'
                  },
                  display: {
                    xs: "flex",
                    md: (isCurrentSlideIndex === index) ? "flex" : "none",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  gap: {
                    sm: "30px",
                  },
                }}>
                  <Box>
                    <Typography sx={{
                      textTransform: "uppercase",
                      fontSize: { md: '1vw', sm: '16px', xs: '16px' }
                    }}>
                      {image.heading}
                    </Typography>

                    <Typography sx={{
                      fontWeight: 300,
                      lineHeight: "130%",
                      color: "rgba(1, 35, 23, 0.60)",
                      mt: {
                        xs: "5px",
                      },
                      fontSize: { md: '1vw', sm: '16px', xs: '16px' },
                    }}>
                      {image.subHeading}
                    </Typography>
                  </Box>
                  <Link underline="none"
                    href={playStoreLink}
                    target="_blank"
                    rel="noopener">
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        minWidth: {
                          xs: "100%",
                          sm: "30.078vw",
                          md: '14.438vw',
                        },
                        height: {
                          xs: "48px",
                        },
                        mt: {
                          xs: "16px",
                          sm: '0px'
                        },
                        "&:hover": {
                          bgcolor: "primary.light",
                        },
                      }}>
                      <Typography sx={{
                        mr: {
                          xs: "14.17px",
                        },
                        fontSize: '16px',
                        fontWeight: 500
                      }}>
                        Get The App
                      </Typography>

                      <img
                        src={GoogleArrow}
                        alt="Leja Playstore"
                        style={{ width: '20px', height: '20px' }}
                        loading={'lazy'}
                      />
                    </Button>
                  </Link>
                </Box>
              </Box>
            );
          })}
        </Carousel>
      </Box>
    </Box>
  )
}

export default TheyLoveUsMobile;
