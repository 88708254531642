import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, Snackbar, Typography } from "@mui/material";
import { FormInputText } from "../Common/FormInputText";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ArrowForward } from "@mui/icons-material";

const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    resume: null,
    coverLetter: null,
    portfolio: null,
    portfolioLink: "",
};

interface IFormInput {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    resume: File | null;
    coverLetter: File | null;
    portfolio: File | null;
    portfolioLink: string;
}

const Appendix1PersonalDetails = () => {
    const { handleSubmit, reset, control, setValue, getValues, formState } =
        useForm<IFormInput>({
            defaultValues: defaultValues,
        });
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");

    const onSubmit = async (data: IFormInput) => {
        if (loading) {
            return;
        }

        if (!data.coverLetter || !data.resume) {
            return;
        }

        const formData = new FormData();
        formData.append("position", "OOL");
        formData.append("name", `${data.firstName} ${data.lastName}`);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("resume", data.resume);
        formData.append("coverLetter", data.coverLetter);
        if (data.portfolio) formData.append("portfolio", data.portfolio);
        if (data.portfolioLink)
            formData.append("portfolioLink", data.portfolioLink);

        try {
            setLoading(true);

            const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/mail/careers`, {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const jsonResponse = await response.json();

            setSnackbarMessage("Your application has been sent successfully");
            setOpen(true);
            console.log(jsonResponse);
        } catch (error) {
            setSnackbarMessage("Failed to send application. Please try again later");
            setOpen(true);
        }

        setLoading(false);
    };


    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const styles = {
        snackbarStyleViaContentProps: {
            backgroundColor: "#FCF7ED",
            color: "black",
        },
        snackbarStyleViaNestedContent: {
            backgroundColor: "#FCF7ED",
            color: "black",
        },
    };


    const [isNoChecked, setYesChecked] = useState(false);
    const [isYesChecked, setNoChecked] = useState(false);

    const handleNoChange = () => {
        setYesChecked(true);
        setNoChecked(false);
    };

    const handleYesChange = () => {
        setYesChecked(false);
        setNoChecked(true);
    };

    return (
        <>
            <Box
                sx={{
                    // marginLeft: { md: "24.25vw", sm: "8px" },
                    // mr: { md: "3.75vw", sm: "98px" },

                    borderRadius: "20px",
                    padding: "18px",
                    backgroundColor: "#FCF7ED",
                    margin: "0%",
                    borderWidth: "80px",
                    marginLeft: { lg: "433px", md: "313px", sm:"130px", xs: "16px" },
                    border: "1px solid #E3D9C5",
                    marginRight: "40px",
                    paddingTop: "20px",
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <Button onClick={() => setOpen(true)}>Open Snackbar</Button> */}

                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "25px",
                            textTransform: "uppercase",
                            marginBottom: "25px",
                            color: "black",
                        }}
                    >
                        PERSONAL DETAILS
                    </Typography>

                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message={snackbarMessage}
                        action={action}
                        ContentProps={{
                            sx: styles.snackbarStyleViaContentProps,
                        }}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    />

                    {/* Job Description */}
                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                            color: "black",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        Full Name
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="firstName"
                                        control={control}
                                        label="First Name"
                                        rules={{
                                            required: "First Name is required",
                                        }}
                                    />
                                    <FormInputText
                                        name="lastName"
                                        control={control}
                                        label="Last Name"
                                        rules={{
                                            required: "Last Name is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                            color: "black"
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        EMAIL ADDRESS
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="emailAddress"
                                        control={control}
                                        label="Email address"
                                        rules={{
                                            required: "Email Address is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                            color: "black"
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                            color: "black"
                                        }}
                                    >
                                        PHONE NUMBER
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="phonenumber"
                                        control={control}
                                        label="Phone number"
                                        rules={{
                                            required: "Phone number is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                            color: "black"
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        ADDRESS
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="address"
                                        control={control}
                                        label="Address"
                                        rules={{
                                            required: "Address is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                            color: "black"
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        PRODUCT
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="product"
                                        control={control}
                                        label="Product"
                                        rules={{
                                            required: "Product is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                            color: "black"
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Box
                                sx={{
                                    paddingTop: "18px",
                                    paddingLeft: "18px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        textTransform: "uppercase",
                                    }}
                                >
                                    DECLARATION: I HAVE READ AND UNDERSTOOD THE COMPANY
                                    COMPLAINTS POLICY AND PROCEDURE
                                </Typography>
                            </Box>

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        SIGNATURE
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="signature"
                                        control={control}
                                        label="Signature"
                                        rules={{
                                            required: "Signature is required",
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "21px",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        DATE
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="date"
                                        control={control}
                                        label="dd/mm/yy"
                                        rules={{
                                            required: "Date is required",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            justifyContent: "flex-end",
                            marginTop: "15px",
                        }}
                    >
                        <Box
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "50%",
                                },
                                paddingTop: "30px",
                                paddingLeft: "10px",
                                display: "flex",
                            }}
                        ></Box>
                    </Box>
                </form>
            </Box>

            <Box
                sx={{
                    // marginLeft: { md: "24.25vw", sm: "8px" },
                    // mr: { md: "3.75vw", sm: "98px" },
                    marginTop: "30px",
                    borderRadius: "20px",
                    padding: "18px",
                    backgroundColor: "#FCF7ED",
                    marginLeft: { lg: "433px", md: "313px", sm: "130px", xs: "16px"},
                    border: "1px solid #E3D9C5",
                    marginRight: "40px",

                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* <Button onClick={() => setOpen(true)}>Open Snackbar</Button> */}

                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: "25px",
                            textTransform: "uppercase",
                            marginBottom: "25px",
                            color: "black",

                        }}
                    >
                        COMPLAINT DETAILS
                    </Typography>

                    {/* Job Description */}
                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                            color: "black"
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: "18px",
                                            color: "black"
                                        }}
                                    >
                                        Have you raised this issue informally?If YES, please provide details of the outcome
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormGroup
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 2,
                                        justifyContent: "end",
                                        paddingRight: "20px",
                                    }}
                                >
                                    <FormControlLabel
                                        control={<Checkbox checked={isYesChecked} onChange={handleYesChange} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel 
                                    control={<Checkbox checked={isNoChecked} onChange={handleNoChange} />}
                                    label="No" 
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#FFF",
                            padding: "18px",
                            borderRadius: "10px",
                            marginTop: "15px",
                            marginBottom: "15px",
                        }}
                    >
                        <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                            <Grid item xs={12} md={6}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: "18px",
                                            color: "black"
                                        }}
                                    >
                                        How would you like this issue to be solved ?
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 2,
                                    }}
                                >
                                    <FormInputText
                                        name="explain"
                                        control={control}
                                        label="Briefly explain"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Box>
            <Box
                sx={{
                    borderWidth: "80px",
                    marginLeft: { lg: "433px", md: "313px", sm: "150px", xs: "16px" },
                    marginRight: "40px",
                    display: "flex",
                    justifyContent: "end",
                }}
            >
                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            sm: "50%",
                        },
                        paddingTop: "30px",
                        paddingLeft: "10px",
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            height: "48px",
                            width: "100%",
                            boxShadow: "none",
                            borderRadius: "8px",
                            border: "none",
                            outline: "none",
                            bgcolor: "#B5B5B5",
                            
                            "&:hover": {
                                bgcolor: "#B5B5B5",
                            
                            },
                            color: "#FFF",
                        }}
                        type="submit"
                        disableElevation
                    >
                        {loading ? (
                            <>
                                Submitting...
                                <CircularProgress
                                    style={{
                                        display: true ? "inline-block" : "none",
                                        marginLeft: "4px",
                                        marginBottom: "4px",
                                    }}
                                    color="inherit"
                                    size={15}
                                    thickness={5}
                                />
                            </>
                        ) : (
                            <>
                                Submit
                                <ArrowForward />
                            </>
                        )}
                    </Button>
                </Box>
            </Box>


        </>
    )

}

export default Appendix1PersonalDetails;