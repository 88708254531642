import { Typography } from '@mui/material'
import React from 'react'

const AccountOpening = () => {
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        <ol style={{ margin: 0 }}>
            <li>In order to open an account with LEJA, you must have achieved the age of the majority being at least Eighteen (18) years old with legal capacity to contract, and a registered and active Mobile Subscriber with a mobile service provider with which the Company has an agreement with for the provision of this service.</li>
            <li>To avail the Services, a Customer would be required to create an account on the Platform using their personal information as described in clause 4;</li>
            <li>LEJA may require permissions to use your email address while opening an account.</li>
            <li>You may open an Account solely by way of an electronic application made by you using your Equipment via the LEJA Platform on the Registration or Sign Up screen.</li>
            <li>LEJA will require you to click on the “Accept” button on the Registration screen requesting you to confirm that you have read, understood and agreed to abide with these Terms and Conditions.</li>
            <li>You hereby further acknowledge and authorize the Company to verify your Personal Information as received from the Registration Process.</li>
        </ol>
    </Typography>
  )
}

export default AccountOpening