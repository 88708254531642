import { Box, Typography } from '@mui/material'
import maverick from '../../assets/Blog/maverick.png'

interface blogProps{
    heading?:string
    subheading?:string
    imageUrl?:string
    text?:string
    credits?:string
    date?:string
}

const BlogHero = ({
    heading='THE MAVERICK',
    imageUrl=maverick, 
    text = '“I wanted to prove to my friends that anything is possible.”',
    subheading,
    credits,
    date = "02/12/23"
}:blogProps) => {
    return (
        <>
            <Box sx={{
                margin: { md: '16px 1.938vw 0px 1.875vw', sm: '16px 24px 0px 16px', xs: '16px' },
                display: 'flex',
                flexDirection: { md: 'row', sm: 'row', xs: 'column'},
                maxHeight: '700px'
            }}>
                <Box sx={{ mr: { md: '20.375vw', sm: '181px' }, mb: '16px' }}>
                    <Typography sx={{
                        color: '#012317',
                        fontStyle: 'normal',
                        fontSize: {xs:'16px',md:'1vw'},
                        fontWeight: 300,
                        lineHeight: '130%'
                    }}>{date}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', sm: 'column', xs: 'column' } }}>
                    <Box sx={{mb:'16px', width:{md:'47.188vw',sm:'472px',xs:'329px'}}}>
                        <Typography
                            sx={{
                                color: '#012317',
                                textTransform:'uppercase',
                                fontSize: { md: '4.688vw', sm: '75px', xs: '35px' },
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '100%',
                                letterSpacing: { md: '-1.5px', sm: '-1.5px', xs: '-1.75px' },
                                '@media only screen and (max-width: 600px)': {
                                    leadingTrim: 'both',
                                    textEdge: 'cap'
                                },
                            }}>{heading}</Typography>
                    </Box>
                    <Box sx={{width:{md:'24.438vw', sm:'391px', xs:'329px'}}}>
                        <Typography sx={{
                            color:'#012317',
                            fontSize:{md:'1.875vw', sm:'30px', xs:'24px'},
                            fontStyle:'normal',
                            fontWeight:500,
                            lineHeight:'100%',
                            letterSpacing:{md:'-0.6px',sm:'-0.037vw', xs:'-0.72px'},
                            '@media only screen and (max-width: 600px)': {
                            },
                            textTransform:'uppercase'
                        }}>
                            {subheading}
                        </Typography>
                    </Box>
                </Box>

            </Box>
            <Box sx={{
                margin: { md: '16px 1.938vw 0px 30px', sm: '24px 24px 0px 16px', xs: '16px' },
                display:'flex',
                flexDirection:{md:'row', sm:'row',xs:'column'},
                alignSelf:'stretch',
                maxHeight: '700px',
            }}> 
            <Box sx={{
                width:{md:'47.188vw',sm:'224px', xs:'328px'},
                // height:{md:'500px', sm:'269px',xs:'307px'},
                height:'auto',
                mr:'2.375vw',
                // mb:'16px',
                //borderRadius:'20px',
                objectFit:'cover',
                flexGrow: 1
                
            }}>
                <img src={imageUrl} style={{width:'100%', height:'100%', objectFit:'cover',borderRadius:'20px'}} loading={'lazy'}/>
                <Typography sx={{fontWeight:100,fontSize:'10px',display:'flex', justifyContent:'flex-end'}}>{credits}</Typography>
            </Box>
            
            <Box sx={{
                width:{md:'47.188vw',sm:'472px', xs:'328px'},
                // height:{md:'500px', sm:'269px',xs:'208px'},
                height:'auto',
                borderRadius:'20px',
                bgcolor:'secondary.light',
                padding:{md:' 89.5px  ', sm:'89.5px 58px 89.5px 60px', xs:'30px'},
                justifyContent:'center',
                alignItems:'center'
            }}>
                <Typography sx={{
                    color:'secondary.main',
                    width:{md:'31.375vw',sm:'352px',xs:'268px'},
                    height:'auto',
                    fontSize:{md:'3.125vw',sm:'24px', xs:'21px'},
                    fontStyle:'normal',
                    fontWeight:500,
                    alignItems:'center',
                    lineHeight:'100%',
                    letterSpacing:{md:'-1.5px',sm:'-0.6px', xs:'-1.5px'},
                    textTransform:'uppercase'
                }}>{text}</Typography>
            </Box>
            </Box>
            
        </>
    )
}

export default BlogHero