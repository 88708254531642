import { Box, Typography } from '@mui/material'
import LejaTick from '../../assets/LejaTick.png'

const TypoLink = {

  color: '#F5F7F2',
  fontSize: { md: '30px', sm: '30px', xs: '24px' },
  //width:{md:'649.14px', sm:'648.105px', xs:'250px'},
  height: { md: '60px', sm: '60px', xs: '48px' },
  //position:'absolute',
  textAlign: 'center',
  fontWeight: 500,
  lineHeight: '100%',
  letterSpacing: { md: '-0.6px', sm: '-0.6px', xs: '-0.72px' },
  fontStyle: 'normal',
  textTransform: 'uppercase'

}



const Sucess = () => {
  return (
    <Box sx={{
      padding: { md: '236px 3.77vw 224.54px 3.77vw', sm: '208.98px 4.444vw 208.93px 4.681vw', xs: '188.46px 38.79px 188.46px 38.79px' },
      // width:{md:'769px', sm:'718.18px', xs:'327.574px'},
      display: 'flex',
      justifyContent: 'centre',
      alignItems: 'center',
      bgcolor: 'primary.dark',
      borderRadius: '20px',
      mr: { md: '30px', sm: '3.125vw', xs: '16px' },
      ml: { md: '6.5vw', sm: '24px', xs: '16px' },
      borderColor: 'primary.light',
      borderWidth: '1px',
      borderStyle: 'solid',
      height: { md: '520.5px', sm: '477.96px', xs: '424.9248px' },
      mb: '10px'

    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <img src={LejaTick}
            style={{
              backgroundColor: '#009166',
              // marginBottom: {sm:'20px', xs:'60px'},
              marginBottom: '30px',
              borderRadius: '20px',
              padding: '10px',
              // height: '60px',
              // width: '60px'
            }}
           loading={'lazy'}
      />
        <Typography sx={{
          ...TypoLink,
         
        }}>
          Thank you for writing to Leja. We will get back to you soon
        </Typography>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>  
        </Box>
      </Box>
    </Box>
  )
}

export default Sucess