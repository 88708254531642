import InstagramLogo from '../../assets/Blog/InstagramLogo.png';
import LinkedinLogo from '../../assets/Blog/LinkedinLogo.png';
import XLogo from '../../assets/Blog/XLogo.png';
import FacebookLogo from '../../assets/Blog/FacebookLogo.png';
import { useLocation } from 'react-router-dom';
import {Box, IconButton, Link, Snackbar} from '@mui/material';
import XLogos from '../../assets/Blog/XLogos.svg';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
    snackbarStyleViaContentProps: {
        backgroundColor: "#fbf5ec",
        color: "black"
    },
    snackbarStyleViaNestedContent: {
        backgroundColor: "lightgreen",
        color: "black"
    }
};

const socialMediaLinks = [
    {
        src: XLogos,
        link: "https://www.example.com/share?url=YOUR_BLOG_URL",
        platform: "X Platform"
    },
    {
        src: FacebookLogo,
        link: "https://www.facebook.com/sharer/sharer.php?u=YOUR_BLOG_URL",
        platform: "Facebook"
    },
    {
        src: InstagramLogo,
        link: "https://www.instagram.com/",
        platform: "Instagram"
    },
    {
        src: LinkedinLogo,
        link: "https://www.linkedin.com/shareArticle?url=YOUR_BLOG_URL",
        platform: "LinkedIn"
    },
    {
        src: XLogo,
        link: "https://twitter.com/intent/tweet?url=YOUR_BLOG_URL",
        platform: "Twitter"
    },
];

const SharePost = () => {
    const location = useLocation();
    const [open, setOpen] = React.useState(false);

    const sharePost = (shareLink: string) => {
        window.open(shareLink, '_blank');
    };

    const blogUrl = `${window.location.origin}${location.pathname}`;

    const handleOnCopy = () => {
        setOpen(true)
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Copied to clipboard"
                action={action}
                ContentProps={{
                    sx: styles.snackbarStyleViaContentProps
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />

            {socialMediaLinks.map((link, index) => (
                index == 0 ? (
                    <CopyToClipboard text={blogUrl} onCopy={handleOnCopy}>
                        <Box key={index} style={{ cursor: 'pointer'}}>
                            <img src={link.src} alt={link.platform} loading={'lazy'}/>
                        </Box>
                    </CopyToClipboard>
                    ) : (
                    <Box key={index}>
                        <Link onClick={() => sharePost(link.link.replace('YOUR_BLOG_URL', blogUrl))} style={{ cursor: 'pointer' }}>
                            <img src={link.src} alt={link.platform}  loading={'lazy'}/>
                        </Link>
                    </Box>
                )
            ))}
        </>
    );
};

export default SharePost;