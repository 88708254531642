import { Box, ThemeProvider, Typography, createTheme, useMediaQuery, useTheme } from "@mui/material";
import NewBankingStyle from "../../assets/AboutUs/new_banking_style.jpg";
import NewBankingStyleTablet from "../../assets/AboutUs/new_banking_style_tablet.png";
import NewBankingStylePhone from "../../assets/AboutUs/new_banking_style_phone.svg";
import NewBankingStylePhoneMobile from "../../assets/AboutUs/new_banking_style_phone_mobile.svg";
import LearnMoreBtn from "../utils/LearnMoreBtn/LearnMoreBtn";

const AboutUsBankingStyle = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        margin: {
          xs: "60px 16px 60px 16px",
          sm: "99px 24px 100px 24px",
          md: "140px 1.906vw 140px 1.906vw",
        },
      }}>
        <Box sx={{
          borderRadius: "20px",
          backgroundImage: (mdDown ? `url(${NewBankingStyleTablet})` : `url(${NewBankingStyle})`),
          backgroundSize: "cover",
          padding: {
            xs: "40px 33px 40px 33px",
            sm: "132px 124px 124px 124px",
            md: "75px 10.938vw 75px 11.375vw",
          },
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column-reverse",
            md: "row-reverse",
          },
          justifyContent: {
            md: "center",
          },
          gap: {
            xs: "40px",
            sm: "120px",
            md: "33%",
          },
          '@media only screen and (min-width:1200px) and (max-width:1299px)':{
            gap: '22%'
          },
          '@media only screen and (min-width:1300px) and (max-width:1400px)':{
            gap: '25%'
          },
          '@media only screen and (min-width:1700px) and (max-width:1870px)':{
            gap: '35%'
          },
          '@media only screen and (min-width:1870px) and (max-width:2000px)':{
            gap: '38%'
          },
        }}>
          <Box sx={{
            padding: {
              xs: "24px",
              sm: "60px",
            },
            borderRadius: {
              xs: "12px",
              sm: "20px",
            },
            backgroundColor: "#FFF",
            mt: {
              md: "92px",
            },
            mb: {
              md: "115px",
            },
          }}>
            <Typography sx={{
              fontSize: {
                xs: "30px",
                sm: "50px",
                md:'3.25vw'
              },
              '@media only screen and (min-width:1200px) and (max-width:1550px)':{
                fontSize: '3.1vw'
              },
              fontWeight: 500,
              lineHeight: "100%",
              letterSpacing: {
                xs: "-1.5px",
              },
              textTransform: "uppercase",
            }}>
              a new style of banking
            </Typography>

            <Typography sx={{
              fontWeight: 300,
              fontSize: {md:'1vw', xs:'16px'},
              lineHeight: "130%",
              mt: {
                xs: "12px",
                sm: "20px",
              },
            }}>
              Leja is bridging the gap between MSMEs and financial institutions.
              By consolidating business data of MSMEs, we allow for the first time financial
              operators to derisk and access this massive untapped market.
            </Typography>

            <Box sx={{
              mt: {
                xs: "24px",
                sm: "30px",
              },
            }}>
              <LearnMoreBtn />
            </Box>
          </Box>

          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <img
              src={smDown ? NewBankingStylePhoneMobile : NewBankingStylePhone}
              alt="New Style of banking image"
              width={smDown ? '248px' : mdDown ? '320px' : '320px'}
              loading={'lazy'}
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
};

export default AboutUsBankingStyle;
