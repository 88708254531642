import { Typography } from '@mui/material'
import React from 'react'

const Indemnity = () => {
    return (
        <Typography sx={{
            mb: '10px',
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
        }}>
            <ol style={{ margin: 0 }}>
                <li>In consideration of LEJA complying with your instructions or Requests in relation to providing the Services, you undertake to indemnify LEJA and hold it harmless against any loss, charges, damages, expenses, fees or claims which LEJA suffers or incurs or sustains thereby and you absolve LEJA from all liability for loss or damage which you may sustain from LEJA acting on your instructions or requests through the LEJA Platform or in accordance with these Terms and Conditions.</li>
                <li>
                    The indemnity in clause 10(1) shall also cover the following:
                    <ol>
                        <li>All demands, claims, actions, losses and damages of whatever nature which may be brought against LEJA or which it may suffer or incur arising from its acting or not acting on any Request or arising from the malfunction or failure or unavailability of any hardware, software, or equipment, the loss or destruction of any data, power failures, corruption of storage media, natural phenomena, riots, acts of vandalism, sabotage, terrorism, any other event beyond LEJA’s control, interruption or distortion of communication links or arising from reliance on any person or any incorrect, illegible, incomplete or inaccurate information or data contained in any Request received by LEJA.</li>
                        <li>Any loss or damage that may arise from your use, misuse, abuse or possession of any third party software, including without limitation, any operating system, browser software or any other software packages or programs.</li>
                        <li>Any unauthorised access to your Account or any breach of security or any destruction or accessing of your data or any destruction or theft of or damage to any of your Equipment.</li>
                        <li>Any loss or damage occasioned by the failure by you to adhere to these Terms and Conditions and/or by supplying of incorrect information or loss or damage occasioned by the failure or unavailability of third party facilities or systems or the inability of a third party to process a transaction or any loss which may be incurred by LEJA as a consequence of any breach by these Terms and Conditions.</li>
                        <li>Any damages and costs payable to LEJA in respect of any claims against LEJA for recompense for loss where the particular circumstance is within your control.</li>
                    </ol>
                </li>
            </ol>
        </Typography>
    )
}

export default Indemnity