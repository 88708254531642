import { Button, Link, Typography } from '@mui/material'
import Group_394 from '../../assets/Group_394.svg';
import { playStoreLink } from '../utils/GetTheAppMobile/GetTheAppMobile';

const AppButton = () => {
    return (
        <Link underline="none"
            href={playStoreLink}
            target="_blank"
            rel="noopener"
        >
            <Button
                variant='contained'
                sx={{
                    width: '100%',
                    height: '48px',
                    borderRadius: '4px',
                    '&:hover': {
                        backgroundColor: 'primary.light',
                        boxShadow: 'none',
                    },
                    boxShadow: 'none',
                }}>
                <Typography sx={{
                    color: '#F5F7F2',
                    mr: { md: '1vw', sm: '2.083vw', xs: '4.444vw' },
                    fontSize: '16px',
                    fontWeight: 500
                }}>
                    Get the app
                </Typography>
                <img
                    src={Group_394}
                    alt="playstore logo"
                    style={{
                        width: '20px',
                        height: '20px'
                    }}
                    loading={'lazy'}
                />
            </Button></Link>
    )
}

export default AppButton