import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { SvgIcon, useTheme } from '@mui/material';


const question = {
    fontSize: { sm: '21px', md: '1.313vw', xs: '4.44vw' },
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
    textTransform: 'uppercase',
    '@media only screen and (min-width:768px) and (max-width: 1100px)': {
        ml: '8px'
    },
}

const answer = {
    fontSize: { sm: '16px', md: '1vw', xs: '4.44vw' },
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'secondary.main',
    lineHeight: '130%',
    marginLeft: { md: '24.25vw', sm: '8px' },
    mr: { md: '18.75vw', sm: '98px' }
}

const UssdServices = () => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const theme = useTheme();

    const handleAccordionChange = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };
    const AddIcon = (props: any) => (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="#F5F7F2">
                <path d="M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14Z" fill="#F5F7F2" />
                <path d="M14 3.5C14.2321 3.5 14.4546 3.59219 14.6187 3.75628C14.7828 3.92038 14.875 4.14293 14.875 4.375L14.875 23.625C14.875 23.8571 14.7828 24.0796 14.6187 24.2437C14.4546 24.4078 14.2321 24.5 14 24.5C13.7679 24.5 13.5454 24.4078 13.3813 24.2437C13.2172 24.0796 13.125 23.8571 13.125 23.625L13.125 4.375C13.125 4.14293 13.2172 3.92038 13.3813 3.75628C13.5454 3.59219 13.7679 3.5 14 3.5Z" fill="#F5F7F2" />
            </svg>
        </SvgIcon>
    );

    const RemoveIcon = (props: any) => (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path d="M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14Z" fill="#64002C" />
            </svg>
        </SvgIcon>
    );

    return (
        <Box id="FAQS" sx={{ bgcolor: 'secondary.main', flexDirection: 'column',
        
        // borderBottom:'1px solid rgba(245,247,242,0.30)'
        }}>
            {/* <Accordion sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<AddIcon />}
                    sx={{ color: '#FFF', marginBottom: 0 }}> */}
            <Typography sx={{ fontSize: {md:'2.25vw', sm:'4.688vw', xs:'8vw'}, fontWeight: 800, color: 'secondary.light', ml: { md: '25.3vw', sm: '3vw', xs: '4vw' }, textTransform: 'uppercase', mb: '20px', mt: '50px' }}>
                Ussd Service
            </Typography>
            {/* </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px" }}>
                    <Box sx={{ flexDirection: 'column' }}> */}
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleAccordionChange('panel1')}
                sx={{
                    bgcolor: expanded === `panel1` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },

                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel1` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel1` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        01
                    </Box>
                    <Typography sx={question}>
                    What is the USSD code to access LEJA services?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        <ol>
                            <li>To access Leja services, dial *429#</li>
                            <li>To buy float *483*051#</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            {/* </Box>
                    <Box sx={{ flexDirection: 'column' }}> */}
            <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleAccordionChange('panel2')}
                sx={{
                    bgcolor: expanded === `panel2` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },

                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel2` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel2` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        02
                    </Box>
                    <Typography sx={question}>
                        How to register using USSD?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        <ol>
                            <li>Enter first name and last name</li>
                            <li>Enter ID or passport No, which must be 6 digits</li>
                            <li>Enter DOB using the format DDMMYYYY</li>
                            <li>Receive a pop-up indicating successful registration</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            {/* </Box>
                    <Box sx={{ flexDirection: 'column' }}> */}
            
            {/* </Box>
                    <Box sx={{ flexDirection: 'column' }}> */}
            <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleAccordionChange('panel3')}
                sx={{
                    bgcolor: expanded === `panel3` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel3` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel3` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        03
                    </Box>
                    <Typography sx={question}>
                        How to Buy Airtime on USSD.
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        <ol>
                            <li>Select ‘Buy Airtime’</li>
                            <li>Select an airtime provider (Airtel/Equitel/Safaricom/Telkom)</li>
                            <li>Select recipient (Current number/Other number)</li>
                            <li>For Current Number, enter amount</li>
                            <li>For another number, enter the number, then the amount</li>
                            <li>Confirm the amount and recipient number are correct</li>
                            <li>Enter your MPesa PIN to complete your transaction</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            {/* </Box>
                    <Box sx={{ flexDirection: 'column' }}> */}
            <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleAccordionChange('panel4')}
                sx={{
                    bgcolor: expanded === `panel4` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },

                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel4` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel4` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        04
                    </Box>
                    <Typography sx={question}>
                        How to send money
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        <ol>
                            <li>Select ‘Send money’</li>
                            <li>Enter Phone number</li>
                            <li>Enter amount</li>
                            <li>Confirm the amount and recipient number</li>
                            <li>Enter your MPesa PIN to complete your transaction</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            {/* </Box>
                    <Box sx={{ flexDirection: 'column' }}> */}
            <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleAccordionChange('panel5')}
                sx={{
                    bgcolor: expanded === `panel5` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel5' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel5` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel5` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            pl: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        05
                    </Box>
                    <Typography sx={question}>
                        How to buy goods and pay bills
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        buy goods
                        <ol>
                            <li>Select buy goods</li>
                            <li>Enter till number</li>
                            <li>Enter amount</li>
                            <li>Enter your MPesa PIN to complete your transaction</li>
                        </ol>
                        Pay bills
                        <ol>
                            <li>Select Pay Bills</li>
                            <li>Enter  Business number</li>
                            <li>Enter Account number</li>
                            <li>Enter Amount</li>
                            <li>Enter your MPesa PIN to complete your transaction</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel6'}
                onChange={handleAccordionChange('panel6')}
                sx={{
                    bgcolor: expanded === `panel6` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel6' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel6` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel6` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            pl: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        06
                    </Box>
                    <Typography sx={question}>
                    How to request a reversal:
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        <ol>
                            <li>Dial *429#.</li>
                            <li>Select 'My Account'.</li>
                            <li>Choose 'Request Reversal'.</li>
                            <li>Select 'Send Money'.</li>
                            <li>The user will be shown the last 5 transactions.</li>
                            <li>Select the transaction to reverse.</li>
                            <li>The user awaits processing within 2 hours.</li>
                            <li>NB: You can only request reversals for transactions made within the last 48 hours.</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel7'}
                onChange={handleAccordionChange('panel7')}
                sx={{
                    bgcolor: expanded === `panel7` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    }
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel7' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel7` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel7` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            pl: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        07
                    </Box>
                    <Typography sx={question}>
                    How to get a mini-statement
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        <ol>
                            <li>Dial *429#.</li>
                            <li>Select 'My Account'.</li>
                            <li>Choose 'Ministatement'.</li>
                            <li>Select transactions to get mini-statement for;
                                Airtime, BuyGoods/Pay Bill, SendMoney, Payments to LEJA Business</li>
                            <li>You will be displayed with the last five transactions for the services</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel8'}
                onChange={handleAccordionChange('panel8')}
                sx={{
                    bgcolor: expanded === `panel8` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                    '&::after': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        // top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel8' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel8` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel8` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            pl: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        08
                    </Box>
                    <Typography sx={question}>
                    How to get a Full statement 
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={answer}
                    >
                        <ol>
                            <li>Dial *429#.</li>
                            <li>Select 'My Account'.</li>
                            <li>Select full statement</li>
                            <li>Enter the email you want to use to get the statement</li>
                            <li>Enter start period DD/MM/YYYY</li>
                            <li>Enter end period DD/MM/YYYY</li>
                            <li>Confirm the period you have entered</li>
                            <li>You will get an email with the statement requested</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default UssdServices;
