import React, { useEffect } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

import norr from "../../assets/they_inspire_us/norrsken.svg";
import station from "../../assets/TrustUs/station_f.png";
import franceFintech from "../../assets/TrustUs/france_fintech.png";
import { Box, Typography } from '@mui/material';
import lmoney from "../../assets/TrustUs/last_mile_money.png"
import google from "../../assets/TrustUs/google_4_startups.png"
import bpifrance from "../../assets/TrustUs/bpifrance.png"
import mastercard from "../../assets/TrustUs/mastercard.png"

const TheyTrustUs = () => {
  useEffect(() => {
    ($('.owl-carousel') as any).owlCarousel({
      margin: 30,
      loop: true,
      autoWidth: true,
      items: 5,
      autoplay: true,
      Infinity: true
      // autoplayTimeout:1000,
      // autoplayHoverPause:true,
    });
  }, []);

  return (
    <Box sx={{
      margin: {
        xs: "60px 0px",
        sm: "100px 0px",
        md: "67.2px 0px 0px 0px",
      },
      paddingTop: {
        xs: "0px",
        sm: "0px",
        md: "67.2px",
      },
    }}>
      <Box sx={{
        textTransform: "uppercase",
        display: "flex",
        padding: {
          xs: "12px 16px",
          sm: "16px 24px",
          md: "16px 30px 16px 30px",
        },
      }}>
        <Typography sx={{
          color: "rgba(1, 35, 23, 0.60)",
          display: {
            xs: "none",
            sm: "block",
          },
          width: {
            sm: "114px",
            md: "22vw",
          },
          mr: {
            sm: "14.063vw",
            md: "1.813vw",
          },
          fontSize: {
            xs: "14px",
            sm: "14px",
            md: '1vw'
          },
        }}>
          [our partners]
        </Typography>

        <Typography sx={{
          lineHeight: "100%",
          fontWeight: 500,
          fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
        }}>
          They trust us
        </Typography>
      </Box>

      <Box className="owl-carousel owl-theme" sx={{
        padding: {
          xs: "0px 0px 0px 16px",
          sm: "0px 0px 0px 24px",
        },
      }}>
        <Box className="item" sx={{
          width: { xs: '36.94vw', sm: '25.52vw', md: '15.44vw' },
          height: { md: '12.625vw', sm: '198px', xs: '135px' },
          bgcolor: 'white',
          borderRadius: '20px',
          paddingRight: '30px',
          paddingLeft: '30px'
        }}>
          <img src={norr} style={{ width: '100%', height: '100%', }}  loading={'lazy'}/>
        </Box>
        <Box className="item" sx={{
          width: {md:"14.5vw",sm:'25.65vw', xs:"37.22vw"},
          height: { md: '12.625vw', sm: '198px', xs: '135px' },
          bgcolor: 'white',
          borderRadius: '20px',
        }}>
          <img src={station} style={{ width: '100%', height: '100%' }} loading={'lazy'}/>
        </Box>
        <Box className="item" sx={{
          width: {md:"18.5vw",sm:'25.65vw', xs:"37.22vw"},
          height: { md: '12.625vw', sm: '198px', xs: '135px' },
          bgcolor: 'white',
          borderRadius: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <img src={franceFintech} style={{ width: '100%', height: '100%', objectFit: 'contain', color:'green' }} loading={'lazy'}/>
        </Box>
        <Box className="item" sx={{
          width: { xs: '140px', sm: '25.52vw', md: '14.44vw' },
          height: { md: '12.625vw', sm: '198px', xs: '135px' },
          bgcolor: 'white',
          borderRadius: '20px',
          color:'green'
        }}>
          <img src={google} style={{ width: '100%', height: '100%', objectFit: 'contain', color:'green' }} loading={'lazy'}/>
        </Box>
        <Box className="item" sx={{
          width: { xs: '140px', sm: '25.52vw', md: '14.44vw' },
          height: { md: '12.625vw', sm: '198px', xs: '135px' },
          bgcolor: 'white',
          borderRadius: '20px',
          objectFit: 'cover'
        }}>
          <img src={lmoney} style={{ width: '100%', height: '100%', objectFit: 'contain' }} loading={'lazy'}/>
        </Box>
        <Box className="item" sx={{
          width: { xs: '36.94vw', sm: '25.52vw', md: '14.44vw' },
          height: { md: '12.625vw', sm: '198px', xs: '135px' },
          bgcolor: 'white',
          borderRadius: '20px',
        }}>
          <img src={bpifrance} style={{ width: '100%', height: '100%', objectFit: 'contain' }} loading={'lazy'}/>
        </Box>
        {/* <Box className="item" sx={{
          width: {md:"14.5vw",sm:'25.65vw', xs:"37.22vw"},
          height: { md: '12.625vw', sm: '198px', xs: '135px' },
          bgcolor: 'white',
          borderRadius: '20px',
          padding: "20px 20px",
        }}>
          <img src={norr} style={{ width: '100%', height: '100%', objectFit: "contain" }} />
        </Box> */}
        <Box className="item" sx={{
          width: { xs: '36.94vw', sm: '25.52vw', md: '14.44vw' },
          height: { md: '12.625vw', sm: '198px', xs: '135px' },
          bgcolor: 'white',
          borderRadius: '20px',
        }}>
          <img src={station} style={{ width: '100%', height: '100%' }} loading={'lazy'} />
        </Box>
        <Box className="item" sx={{
          width: {md:"14.5vw",sm:'25.65vw', xs:"37.22vw"},
          height: { md: '12.625vw', sm: '198px', xs: '135px' },
          bgcolor: 'white',
          borderRadius: '20px',
        }}>
          <img src={mastercard} style={{ width: '100%', height: '100%', objectFit: 'contain' }} loading={'lazy'}/>
        </Box>
      </Box>
    </Box>
  );
};

export default TheyTrustUs;
