import { Box, Typography } from '@mui/material';
import stories from '../../assets/OurStories/stories.png';
import storiesm from '../../assets/OurStories/storiesm.png';
import storiesxm from '../../assets/OurStories/storiesxm.png';
import { motion } from "framer-motion";

const OurStoriesHero = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 2}}>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{
          m: { md: '16px 29px 0px 31px', sm: '16px 24px 0px 24px', xs: '12px 16px 0px 16px' },
          borderRadius: { md: '20px' },

          // width: { md: '1540px', sm: '720px', xs: '328px' },
          height: { md: '80vh', sm: '888px', xs: '700px' },
          display: { md: 'block', sm: 'none', xs: 'none' },
        }}>
          <img src={stories} style={{ width: '100%', height: '100%',objectFit:'cover', borderRadius: '20px' }} loading={'lazy'} />

        </Box>
        <Box sx={{
          m: { md: '16px 29px 0px 31px', sm: '16px 24px 0px 24px', xs: '12px 16px 0px 16px' },
          borderRadius: { md: '20px' },
          // width: { md: '1540px', sm: '720px', xs: '328px' },
          // height: { md: '604px', sm: '888px', xs: '700px' },
          display: { md: 'none', sm: 'block', xs: 'none' }
        }}>
          <img src={storiesm} style={{ width: '100%', height: '100%' }}  loading={'lazy'}/>
        </Box>
        <Box sx={{
          m: { md: '16px 29px 0px 31px', sm: '16px 24px 0px 24px', xs: '12px 16px 0px 16px' },
          borderRadius: { md: '20px' },
          // width: { md: '1540px', sm: '720px', xs: '328px' },
          // height: { md: '604px', sm: '888px', xs: '700px' },
          display: { md: 'none', sm: 'none', xs: 'block' }
        }}>
          <img src={storiesxm} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '20px' }} loading={'lazy'} />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: { md: 'row', sm: 'column', xs: 'column' }
        }}>
          <Box sx={{
            position: 'absolute',
            display: { md: 'block', sm: 'block', xs: 'none' },
            width: { md: '329px', sm: '289px' },
            top: { md: '73vh', sm: '612px' },
            bottom: '34px',
            left: { md: '33px', sm: '25px' },
            mr: '30px'

          }}><Typography sx={{
            width: { md: '296px', sm: '289px' },
            color: '#F5F7F2',
            fontSize: '14px',
            fontWeight: '400',
            fontStyle: 'normal',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            ml: { md: '33px', sm: '25px' },
            mb: '16px'
            // mt:'503px',
            // mb:'34px',
            // mr:'1211px',
            // top:'90%'
          }}>[ they love us ]</Typography></Box>
          <Box
            sx={{
              // top:{md:'437px',sm:'645px', xs:'503px'},  
              right: { md: '0', sm: '163px', xs: '105px' },
              bottom: { md: '26px', xs: '49px', sm: '18px' },
              width: { md: '757px', sm: '532px', xs: '199px' },
              position: 'absolute',
              '@media only screen and (max-width: 768px)': {
                left: '25px',

              },
              '@media only screen and (min-width: 1000px)': {
                left: '392px',

              },

            }}><Typography
              sx={{

                // mr:'391px',
                // mb:'26px',
                // mt:'437px',

                ml: { sm: '25px' },

                fontSize: { md: '75px', sm: '75px', xs: '40px' },
                fontStretch: 'normal',
                fontWeight: 600,
                lineHeight: '100%',
                letterSpacing: { md: '-1.5px', sm: '-1.5px', xs: '-2px' },
                textTransform: 'uppercase',
                color: '#F5F7F2'
              }}>their stories inspire us</Typography></Box>
        </Box>
      </Box>
    </motion.div>

  )
}

export default OurStoriesHero