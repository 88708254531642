import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { NavLink } from 'react-router-dom';
import { Button, Link, Typography, useMediaQuery } from '@mui/material';
import NavMenu from './NavMenu';
import FacebookLogo from '../../assets/FacebookLogo.svg';
import InstagramLogo from '../../assets/InstagramLogo.svg';
import LinkedinLogo from '../../assets/LinkedinLogo.svg';
import XLogo from '../../assets/XLogo.svg';
import YouTubeLogo from '../../assets/YoutubeLogo.svg';
import { useTheme } from '@mui/material/styles';
import Addresses from './Addresses';
import Group_394 from '../../assets/Group_394.svg';
import { playStoreLink } from '../utils/GetTheAppMobile/GetTheAppMobile';
import styles from './CustomDrawer.module.css'

interface CustomDrawerProps {
    isDrawerOpen: boolean;
    handleCloseDrawer: () => void;
    pageLinks: { text: string; path: string }[];
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({
    isDrawerOpen,
    handleCloseDrawer,
    pageLinks
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleCloseDrawer}
            sx={{
                '& .MuiDrawer-paper': {
                    width: '100%',
                    backgroundColor: '#012317',
                    boxShadow: 'none',
                },
            }}
        >
            <Box sx={{
                mb: { xs: '3.2vw', sm: '14.06vw' },
                zIndex: 1200
            }}>
                <NavMenu handleCloseDrawer={handleCloseDrawer} />
            </Box>

            <Box sx={{ zIndex: 0 }}>
                {isMobile && (
                    //layout for mobile screens 
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    ml: '4.27vw',
                                    mr: '4.27vw',
                                    mt: '16vw',
                                    rowGap: '4.27vw'
                                }}
                                role="presentation"
                            >
                                <Divider />
                                {pageLinks.map((pagelink) => (
                                    <NavLink
                                        key={pagelink.text}
                                        to={pagelink.path}
                                        className={({ isActive, isPending }) =>
                                            isActive
                                                ? styles.active
                                                : isPending
                                                    ? styles.pending
                                                    : styles.default
                                        }
                                    >
                                        <Button
                                            onClick={handleCloseDrawer}
                                            variant='contained'
                                            sx={{
                                                width: '92vw',
                                                height: '12.8vw',
                                                bgcolor: '#FFB5CC',
                                                fontSize: '3.73vw',
                                                justifyContent: 'center',
                                                '&:hover': {
                                                    bgcolor: '#FFF',
                                                },
                                            }}
                                        >
                                            <Typography sx={{
                                                color: "primary.dark"
                                            }}>
                                                {pagelink.text}
                                            </Typography>
                                        </Button>
                                    </NavLink>
                                ))}
                            </Box>
                            <Box>
                                <Addresses />
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '8.53vw',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    columnGap: '8vw',
                                    alignItems: 'center',
                                    mr: '4.27vw',
                                    paddingLeft: '8vw',
                                    paddingRight: '8vw',
                                    mb: '9.87vw'
                                }}
                            >
                                <Link
                                    href={'https://www.facebook.com/people/LEJA/100087714864334/'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={FacebookLogo} alt="Facebook Logo" loading={'lazy'} />
                                </Link>
                                <Link
                                    href={'https://www.instagram.com/lejaapp/'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={InstagramLogo} alt="Instagram Logo" loading={'lazy'}/>
                                </Link>
                                <Link
                                    href={'https://www.linkedin.com/company/leja-app/'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={LinkedinLogo} alt="Linkedin Logo"  loading={'lazy'}/>
                                </Link>
                                <Link
                                    href={'https://twitter.com/LejaApp'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={XLogo} alt="X Logo"  loading={'lazy'}/>
                                </Link>
                                <Link
                                    href={'https://www.youtube.com/@lejaApp'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={YouTubeLogo} alt="YouTube Logo" loading={'lazy'} />
                                </Link>
                            </Box>
                            <Link underline="none"
                                href={playStoreLink}
                                target="_blank"
                                rel="noopener"
                            >
                                <Button
                                    variant='contained'
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textTransform: 'uppercase',
                                        width: '92vw',
                                        height: '12.8vw',
                                        padding: '0px 6.93vw 0px 8vw',
                                        alignItems: 'center',
                                        gap: '2.13vw',
                                        mr: '4.27vw',
                                        ml: '4.27vw',
                                        mb: '4.8vw',
                                        flexShrink: 0,
                                        "&:hover": {
                                            bgcolor: "primary.light"
                                        }
                                    }}
                                >
                                    <Typography sx={{ color: '#F5F7F2', mr: '4.27vw' }}>Get the app</Typography>
                                    <img src={Group_394} alt="playstore logo" loading={'lazy'}/>
                                </Button>
                            </Link>
                        </Box>
                    </>
                )}
                {(isTablet && !(isMobile)) && (
                    //layout for tablet screens
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '4.17vw',
                                    height: '42.7vw',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '3.9vw',
                                    columnGap: '8vw',
                                    alignItems: 'center',
                                    ml: '4.27vw',
                                    mt: '15.1vw',
                                    paddingTop: '3.9vw',
                                }}
                            >
                                <Link
                                    href={'https://www.facebook.com/people/LEJA/100087714864334/'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={FacebookLogo} alt="Facebook Logo"  loading={'lazy'}/>
                                </Link>
                                <Link
                                    href={'https://www.instagram.com/lejaapp/'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={InstagramLogo} alt="Instagram Logo" loading={'lazy'} />
                                </Link>
                                <Link
                                    href={'https://www.linkedin.com/company/leja-app/'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={LinkedinLogo} alt="Linkedin Logo" loading={'lazy'}/>
                                </Link>
                                <Link
                                    href={'https://twitter.com/LejaApp'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={XLogo} alt="X Logo"  loading={'lazy'}/>
                                </Link>
                                <Link
                                    href={'https://www.youtube.com/@leja2916'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ color: 'white', textDecoration: 'none' }}
                                >
                                    <img src={YouTubeLogo} alt="YouTube Logo" loading={'lazy'}/>
                                </Link>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    ml: 'auto',
                                    mr: '3.125vw',
                                    mt: '14vw'
                                }}
                                role="presentation"
                            >
                                <Divider />
                                {pageLinks.map((pagelink) => (
                                    <NavLink
                                        key={pagelink.text}
                                        to={pagelink.path}
                                        className={({ isActive, isPending }) =>
                                            isActive
                                                ? styles.active
                                                : isPending
                                                    ? styles.pending
                                                    : styles.default
                                        }
                                    >
                                        <Button
                                            onClick={handleCloseDrawer}
                                            variant='contained'
                                            sx={{
                                                width: '61.45vw',
                                                height: '6.25vw',
                                                bgcolor: '#FFB5CC',
                                                fontSize: '1.82vw',
                                                justifyContent: 'center',
                                                '&:hover': {
                                                    bgcolor: '#FFF',
                                                },
                                                mb: '3.125vw'
                                            }}
                                        >
                                            <Typography sx={{
                                                color: "primary.dark"
                                            }}>
                                                {pagelink.text}
                                            </Typography>
                                        </Button>
                                    </NavLink>
                                ))}
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <Addresses />
                        </Box>
                    </>

                )}
            </Box>

        </Drawer>
    );
};

export default CustomDrawer;
