import { Typography } from '@mui/material'
import React from 'react'

const IntellectualPropertyRights = () => {
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        You agree that LEJA may disclose details of the relating to your account to any Third party (including credit agencies) if in LEJA’s opinion such disclosure is necessary for the purpose of evaluating any application made to it or such Third Party or maintaining the operative account with LEJA or other purpose as the Company shall deem appropriate.
    </Typography>
  )
}

export default IntellectualPropertyRights