import { Typography } from '@mui/material'
import React from 'react'

const ClosureDeletionAndRecovery = () => {
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        <ol style={{ margin: 0 }}>
            <li>You may opt to Close and/or Delete their Account with LEJA when they no longer wish to access LEJA’s Services;</li>
            <li>
                An account can only be deleted and/or closed if there are no dues/loan owing and there is no money in the customer’s E-wallet. For clarity purposes an account cannot be deleted if:
                <ol>
                    <li>There is any pending loan which you have not paid and/or cleared;</li>
                    <li>There is any amount of money in your E-Wallet; and</li>
                    <li>If there is any money in any account opened with a Lender through the LEJA Platform;</li>
                </ol>
            </li>
            <li>Where there is money in the E-Wallet or Account with the Lenders through LEJA, or non-withdrawable shares (where applicable), you may write to LEJA requesting to have the same Liquidated prior to the deletion of the Account.</li>
            <li>Where an account has been deleted successfully LEJA will retain the Application Data for Sixty (60) Days within which period if you decide to access LEJA’s services, you will not be required to register a new account. A new registration will only be required where the Sixty (60) Day period has lapsed after an account has been deleted.</li>
            <li>Without prejudice to the foregoing, you hereby consent to LEJA retaining Transactional records and your personal information for a minimum of Seven (7) years during which period the information may be used in the manner consented to on this agreement.</li>
        </ol>
    </Typography>
  )
}

export default ClosureDeletionAndRecovery