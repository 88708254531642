import calculatorIcon from '../../assets/calculatorIcon.svg';
import MathOperations from '../../assets/MathOperations.svg';
import ChartLineUp from '../../assets/ChartLineUp.svg';
import { Box, Grid, Typography } from '@mui/material';
import { motion } from "framer-motion";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

const Features = () => {
    const itemVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 }
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Grid container spacing={isMobile?2: isTablet?3 : 4} sx={{
            paddingLeft: { xs: '16px', sm: '24px', md: '1.875vw' },
            paddingRight: { xs: '16px', sm: '24px', md: '1.875vw' },
            alignSelf: 'stretch'
        }}>
            <Grid item md={4} sm={4} xs={12}>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={itemVariants}
                    transition={{ duration: 1, delay: 3 }}
                    style={{height:'100%'}}
                >
                    <Box sx={{
                        padding: { xs: '16px', sm: '24px', md: '30px' },
                        display: 'flex',
                        gap: { md: '1.875vw', sm: '24px', xs: '20px' },
                        alignItems: { md: 'center', sm: 'flex-start' },
                        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                        borderRadius: '20px',
                        bgcolor: '#FFF',
                        height: '100%'
                    }}>
                        <Box sx=
                                 {{
                                     width: '90px', height: '90px',
                                     borderRadius: '100%',
                                     bgcolor: 'secondary.light',
                                     padding: '24px',
                                     display: 'flex',
                                     flexShrink: 0,
                                     justifyContent: 'center'
                                 }}>
                            <img
                                src={calculatorIcon}
                                alt="Calculator Icon"
                                loading={'lazy'}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px'
                        }}>
                            <Typography
                                sx={{
                                    // ml: { md: '30px' },
                                    // width: '275px',
                                    fontSize: { xs: '21px', md: '21px' },
                                    fontStyle: 'normal',
                                    color: '#012317',
                                    fontWeight: '500',
                                    lineHeight: '120%',
                                    textTransform: 'uppercase'
                                }}
                            >Automated record-keeping
                            </Typography>
                            <Typography
                                sx={{
                                    // ml: { md: '30px' },
                                    // width: '275px',
                                    fontSize: { xs: '14px', sm: '14px', md: '0.875vw' },
                                    fontStyle: 'normal',
                                    color: '#012317',
                                    fontWeight: 300,
                                    lineHeight: '120%',
                                }}
                            >Streamlining and managing financial records and transactions.
                            </Typography>
                        </Box>
                    </Box>
                </motion.div>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={itemVariants}
                    transition={{ duration: 1, delay: 3 }}
                    style={{height:'100%'}}
                >
                    <Box sx={{
                        padding: { xs: '16px', sm: '24px', md: '30px' },
                        display: 'flex',
                        gap: { md: '1.875vw', sm: '24px', xs: '20px' },
                        alignItems: { md: 'center', sm: 'flex-start' },
                        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                        borderRadius: '20px',
                        bgcolor: '#FFF',
                        height: '100%'
                    }}>
                        <Box sx=
                                 {{
                                     width: '90px', height: '90px',
                                     borderRadius: '100%',
                                     bgcolor: 'secondary.light',
                                     padding: '24px',
                                     display: 'flex',
                                     flexShrink: 0,
                                     justifyContent: 'center'
                                 }}>
                            <img
                                src={MathOperations}
                                alt="Math Operations Icon"
                                loading={'lazy'}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px'
                        }}>
                            <Typography
                                sx={{
                                    // ml: { md: '30px' },
                                    // width: '275px',
                                    fontSize: { xs: '21px', md: '21px' },
                                    fontStyle: 'normal',
                                    color: '#012317',
                                    fontWeight: '500',
                                    lineHeight: '120%',
                                    textTransform: 'uppercase'
                                }}
                            >360° payment solution
                            </Typography>
                            <Typography
                                sx={{
                                    // ml: { md: '30px' },
                                    // width: '275px',
                                    fontSize: { xs: '14px', sm: '14px', md: '0.875vw' },
                                    fontStyle: 'normal',
                                    color: '#012317',
                                    fontWeight: 300,
                                    lineHeight: '120%',
                                }}
                            >Delivering the ultimate, cost-effective digital payment experience for small businesses.
                            </Typography>
                        </Box>
                    </Box>
                </motion.div>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={itemVariants}
                    transition={{ duration: 1, delay: 3 }}
                    style={{height:'100%'}}
                >
                    <Box sx={{
                        padding: { xs: '16px', sm: '24px', md: '30px' },
                        display: 'flex',
                        gap: { md: '1.875vw', sm: '24px', xs: '20px' },
                        alignItems: { md: 'center', sm: 'flex-start' },
                        flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                        borderRadius: '20px',
                        bgcolor: '#FFF',
                        height: '100%'
                    }}>
                        <Box sx=
                                 {{
                                     width: '90px', height: '90px',
                                     borderRadius: '100%',
                                     bgcolor: 'secondary.light',
                                     padding: '24px',
                                     display: 'flex',
                                     flexShrink: 0,
                                     justifyContent: 'center'
                                 }}>
                            <img
                                src={ChartLineUp}
                                alt="Chart Line Up Icon"
                                loading={'lazy'}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px'
                        }}>
                            <Typography
                                sx={{
                                    // ml: { md: '30px' },
                                    // width: '275px',
                                    fontSize: { xs: '21px', md: '21px' },
                                    fontStyle: 'normal',
                                    color: '#012317',
                                    fontWeight: '500',
                                    lineHeight: '120%',
                                    textTransform: 'uppercase'
                                }}
                            >Financial services
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: { xs: '14px', sm: '14px', md: '0.875vw' },
                                    fontStyle: 'normal',
                                    color: '#012317',
                                    fontWeight: 300,
                                    lineHeight: '120%',
                                }}
                            >Unlocking business growth potential with tailored funding solutions.
                            </Typography>
                        </Box>
                    </Box>
                </motion.div>
            </Grid>
        </Grid>
    )
}

export default Features

