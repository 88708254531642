import sankalp from "../assets/Blog/sankalp.jpg";
import twif from "../assets/Blog/twif.jpg";
import TekwaneBlogMobile from '../assets/TekwaneBlogMobile.png';
import registerPicture from '../assets/registerPicture.jpg';
import introducingLeja from '../assets/introducingLeja.jpg';
import LadyBlog from '../assets/LadyBlog.png';
import Barcelona from '../assets/Blog/barcelona.png';
import DataProtection from '../assets/Blog/data_protection.png';

export interface LejaBlog {
    slug: string;
    title: string;
    summary: string;
    image: string;
    date: string;
}

export const lejaBlogs: LejaBlog[] = [
    {
        slug: 'leja-at-sankalp',
        title: 'LEJA’S PARTICIPATION AT SANKALP AFRICA SUMMIT 2024',
        summary: "Leja's recent participation in the Sankalp Africa Summit 2024 was more than just a presence; it was a confirmation of our dedication to advancing sustainable development in the finance sector across Africa.",
        image: sankalp,
        date: "29/02/24"
    },
    {
        slug: 'leja-at-twif',
        title: 'Leja as lead sponsor at the TWIF Nairobi Fintech happy hour 2024',
        summary: "As the lead sponsor of TWIF 2024, Leja played a pivotal role in the success of the event, by providing essential resources and funding.",
        image: twif,
        date: "16/02/24"
    },
    {
        slug: 'the-maverick',
        title: 'Founder’s Story: The Maverick',
        summary: "His story embodies the grit and insight that makes LEJA an essential tool. “I wanted to prove to my friends that anything is possible.”",
        image: TekwaneBlogMobile,
        date: "20/01/24"
    },
    {
        slug: 'budgeting-made-simple',
        title: "Budgeting Made Simple",
        summary: "Running a small business comes with its own set of challenges, and one of the key aspects that can significantly impact its success is budgeting.",
        image: registerPicture,
        date: "10/01/24"
    },
    {
        slug: 'introducing-leja',
        title: "Introducing Leja",
        summary: "Leja App is a one-stop solution aimed to revolutionize the way MSMEs manage their finances, providing a secure, efficient, and convenient platform for growth.",
        image: introducingLeja,
        date: "03/01/24"
    },
    {
        slug: 'registering-a-business',
        title: "Registering a Business",
        summary: "Starting a business in Kenya can be an exciting and rewarding venture. However, before you can officially operate, you need to go through the process of registering your business.",
        image: LadyBlog,
        date: "02/12/24"
    },
    {
        slug: 'mwc-barcelona-2024',
        title: "MWC Barcelona 2024",
        summary: "The MWC Barcelona 2024 event brought together industry leaders and participants from across the mobile ecosystem, attracting over 70,000 attendees.",
        image: Barcelona,
        date: ""
    },
    // {
    //     slug: 'data-protection',
    //     title: "A guide for Leja Customers on Data Privacy and Protection",
    //     summary: "In our digital world, information is the currency of the age, making data privacy and protection a paramount concern for anyone navigating the online space.",
    //     image: DataProtection,
    //     date: "02/04/24"
    // }
]