import { Box, Link, Typography } from '@mui/material'
import Goat from '../../assets/Blog/Goat.svg'
import man from '../../assets/Blog/man.png'
import Tekwane from '../../assets/Blog/Tekwane.svg'
import SharePost from './SharePost'
import sankalp from '../../assets/Blog/sankalp.jpg'
import Blog from '../Blog/Blog'
import BlogHero from '../Blog/BlogHero'
import sankalpin from "../../assets/Blog/sankalpin.jpg"

const Sankalp = () => {
    return (
        <>
            <BlogHero imageUrl={sankalp}
                heading='LEJA AT SANKALP AFRICA SUMMIT 2024'
                text="Participating in these engagements enabled us to showcase our solutions while increasing our visibility and credibility."
                // credits='Photocredits: [freepik]'
                date="29/02/24"
            />
            <Box sx={{ margin: { md: '80px 26.438vw 0px 26.375vw', sm: '40px 26px 0px 24px', xs: '62px 16px 0px 16px' } }}>
                <Typography
                    sx={{
                        color: '#012317',
                        fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                        lineHeight: '100%',
                        letterSpacing: '-1.5px',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        textTransform: 'uppercase',
                        //mb: { md: '30px', sm: '30px', xs: '16px' }
                    }}> 
                    Leja’s participation at Sankalp Africa Summit 2024
                    </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ margin: { md: '30px 10vw 0px 26.375vw', sm: '30px 25px 0px 24px', xs: '16px' } }}>
                    <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                    }}>
                    Leja's recent participation in the Sankalp Africa Summit 2024 was more than just presence; it was a confirmation of our dedication to advancing sustainable development in the finance sector across Africa. 
                    </Box>
                    <Box
                        sx={{
                            fontSize: { xs: '16px', md: '1vw' },
                            fontstyle: 'normal',
                            fontWeight: '300',
                            lineHeight: '130%',
                            color: '#012317',
                            mt: '16px',
                        }}>
                        At the exhibition, we captivated the Sankalp attendees by showcasing our brand through a dynamic demo featuring our services. The demonstration illustrated our seamless onboarding process, providing a hands-on look at the functionality and benefits of our app. 
                    </Box>
                    <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                        mt: '16px',
                    }}>
                        Our team also shared our innovative solutions tailored to  drive financial inclusion through providing accessible and affordable financing for MSMEs while leveraging technology for sustainable development in the finance sector. Through dynamic demonstrations and interactive illustrations, we illustrated how our digital financial tools empower micro-entrepreneurs, while aligning with our core mission of transforming lives through business empowerment.
                        </Box>
                    <Box
                        sx={{
                            fontSize: { xs: '16px', md: '1vw' },
                            fontstyle: 'normal',
                            fontWeight: '300',
                            lineHeight: '130%',
                            color: '#012317',
                            mt: '16px',
                            mb: { md: '0px', sm: '30px', xs: '16px' }
                        }}>
                        Additionally, we incorporated a QR code strategically linked to our pitch deck, offering a convenient gateway for potential investors to delve deeper into the comprehensive solution we provide to our customers. The interactive and visually compelling exhibition not only highlighted our technological expertise but also facilitated a more immersive understanding of the value Leja brings to the financial inclusion landscape.
                    </Box>
                    <Box
                        sx={{
                            fontSize: { xs: '16px', md: '1vw' },
                            fontstyle: 'normal',
                            fontWeight: '300',
                            lineHeight: '130%',
                            color: '#012317',
                            mt: '16px',
                        }}>
                        Participating in these engagements enabled us to showcase our solutions while increasing our visibility and credibility within the African fintech community. Simultaneously, we contributed to the cause of driving financial inclusion and providing support to micro-entrepreneurs at a substantial scale.
                    </Box>
                    <Box
                        sx={{
                            fontSize: { xs: '16px', md: '1vw' },
                            fontstyle: 'normal',
                            fontWeight: '300',
                            lineHeight: '130%',
                            color: '#012317',
                            mt: '16px',
                        }}>
                        With the Sankalp Summit attracting a diverse group of stakeholders, including investors, policy makers, development organizations, and fellow entrepreneurs, it provided a platform for forging partnerships and collaborations with like-minded impact entrepreneurs and organizations. And through strategic networking and leveraging LinkedIn, we established valuable connections aimed at amplifying our impact and expanding our reach across Africa. These collaborations play a crucial role in driving social impact, financial inclusion, and sustainable change at scale.
                    </Box>
                </Box>
                <Box sx={{mr:'1.875vw', display:{md:'block', sm:'none', xs:'none'}, objectFit:'cover'}}><img src={sankalpin} style={{width:'232px', height:'292px', objectFit:'cover', borderRadius:'20px'}} loading={'lazy'} /></Box>
            </Box>
            <Box sx={{
                m: { md: '30px 26.438vw 30px 26.438vw', sm: '9px 25px 30px 25px', xs: '16px 17px 20px 16px', },
                bgcolor: 'primary.main',
                padding: '30px',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '0px',
                justifyContent: 'center',
                alignItems: 'center',

                // display:{md:'inline-flex', sm:'flex', xs:'flex'},

            }}>
                <Typography sx={{
                    fontSize: { xs: '16px', md: '1vw' },
                    fontstyle: 'normal',
                    fontWeight: '300',
                    lineHeight: '130%',
                    color: '#FFF',
                }}>
                    In conclusion, our participation at the Sankalp Africa Summit 2024 was a remarkable journey, as we gained exposure and visibility within the African fintech community and beyond. This sentiment was echoed by our Co-founder, Morgane Kablan in an interview conducted by a prominent media house, where she emphasized our commitment to providing accessible and affordable financing for MSMEs in Africa.
                </Typography>
            </Box>
            <Box sx={{
                display: { md: 'inline-flex', sm: 'flex', xs: 'flex' },
                alignItems: 'center',
                // flexDirection:{md:'row', sm:'row', xs:'column'},
                // justifyContent: 'center',
                gap: '17px',
                // mt: '60px',
                ml: { md: '26.438vw', sm: '24px', xs: '16px' },
            }}>
                <Typography sx={{
                    color: 'primary.main',
                    fontSize: { xs: '16px', md: '1rem' },
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '130%',
                    marginRight: '17px',

                }}>Share this post:</Typography>
                <SharePost />
            </Box>
            <Blog title='MORE NEWS' subtitle='[NEWS AND INSIGHTS]' parentSlug={'leja-at-sankalp'}/>
        </>
    );
};

export default Sankalp