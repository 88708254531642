import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import ScrollToTop from "../ComponentUtils/ScrollToTop";

const Layout = () => {
  return (
    <Box sx={{ 
      fontFamily: "Rubik",
      maxWidth: "2160px",
      margin: "0 auto",
    }}>
      <ScrollToTop />
      <NavBar />

      <Outlet />

      <Footer />
    </Box>
  )
};

export default Layout;
