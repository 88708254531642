import LejaHero from "../../components/LejaHero/LejaHero";
import Benefits from "../../components/LejaBenefits/Benefits";
import InspireUs from "../../components/InspireUs/InspireUs";
import Blog from "../../components/Blog/Blog";
import JoinTheFuture from "../../components/JoinTheFuture/JoinTheFuture";
import Features from "../../components/Features/Features";
import FAQSweb from "../../components/FAQS/FAQSweb";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const LejaApp = () => {
  console.log("API ENDPOINT ::", process.env.REACT_APP_API_ENDPOINT);
  const location = useLocation()

  useEffect(
    () => {
      document.title = `Leja / ${location.pathname.slice(1)}`
    }, [location]
  );
  return (
    <>
      <LejaHero />
      <Benefits />
      <Features />
      <JoinTheFuture />
      <FAQSweb />
      <InspireUs />
      <Blog />
    </>
  )
};

export default LejaApp;
