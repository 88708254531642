import { Box, Grid } from "@mui/material";
import CateImage from "../../assets/AboutUs/team/Cate.jpg";
import JamesImage from "../../assets/AboutUs/team/James.jpg";
import PhenaImage from "../../assets/AboutUs/team/Phena.jpg";
import KiruiImage from "../../assets/AboutUs/team/Kirui.jpg";
import SydneyImage from "../../assets/AboutUs/team/Sydney.jpg";
import ChesireImage from "../../assets/AboutUs/team/Chesire.jpg";
import AbstractedStyles from "./AbstractedStyles";
import NdanuImage from "../../assets/AboutUs/team/Ndanu.jpg";
import JecintaImage from "../../assets/AboutUs/team/Jecinta.jpg";
import CollinsImage from "../../assets/AboutUs/team/Collins.jpg";
import FlorenceImage from "../../assets/AboutUs/team/Florence.jpg";
import EstherImage from "../../assets/AboutUs/team/Esther.jpg";
import WillysImage from "../../assets/AboutUs/team/Willys.jpg";
import MartinImage from "../../assets/AboutUs/team/Martin.jpg";
import MosesImage from "../../assets/AboutUs/team/Moses.jpg";
import GilbertImage from "../../assets/AboutUs/team/Gilbert.jpg";
import CynthiaImage from "../../assets/AboutUs/team/cynthia.jpg";
import ShawnImage from "../../assets/AboutUs/team/Shawn.jpg";
import MarkImage from "../../assets/AboutUs/team/Mark.jpg";
import AngelaImage from "../../assets/AboutUs/team/Angela.jpg";
import MaryImage from "../../assets/AboutUs/team/Mary.jpg";
import Murigi from "../../assets/AboutUs/team/Murigi.jpg";
import Rolex from "../../assets/AboutUs/team/Rolex.jpg";
import Chivu from "../../assets/AboutUs/team/Chivu.jpg";
import Nancy from "../../assets/AboutUs/team/Nancy.jpg";

const teamMembers = [
  {
    isBlank: true,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Florence Leah",
    image: FlorenceImage,
    role: 'Customer Support Officer',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Jecintah Syotini",
    image: JecintaImage,
    role: 'Customer Support Officer',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Collins Rotich",
    image: CollinsImage,
    isBlank: false,
    role: 'Customer Support Officer',
    bgHoverColor: "secondary.main",
  },
  {
    name: "Catherine Wanjiru",
    image: CateImage,
    role: 'Customer Support Manager',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
];

const teamMembers1 = [
  {
    isBlank: true,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Willys Kimotho",
    image: WillysImage,
    role: 'Sales Manager ',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Nancy Kawira",
    image: Nancy,
    role: 'Operations Administrator',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  // {
  //   isBlank: true,
  //   bgHoverColor: "secondary.main",
  // },
  {
    name: "Esther  Wanjiru",
    image: EstherImage,
    role: 'Operations Administrator',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Martin Njenga",
    image: MartinImage,
    role: 'Sales Manager',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  // {
  //   name: "James Murigi",
  //   image: Murigi,
  //   role: 'Operations Administrator',
  //   isBlank: false,
  //   bgHoverColor: "secondary.main",
  // },
  // {
  //   name: "Mary Kawira",
  //   image: MaryImage,
  //   role: 'Sales Manager',
  //   isBlank: false,
  //   bgHoverColor: "secondary.main",
  // },
];

const teamMembers2 = [
  {
    isBlank: true,
    bgHoverColor: "secondary.main",
  },
  {
    name: "James Dennis",
    image: JamesImage,
    role: 'Senior Mobile Developer ',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Sidney Wesonga",
    image: SydneyImage,
    role: 'Senior Software Engineer',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Vincent Kirui",
    image: KiruiImage,
    role: 'Backend Engineer',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Phena Mwema",
    image: PhenaImage,
    role: 'Senior Android Developer',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },

];

const teamMembers3 = [
  {
    isBlank: true,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Shawn Mitaki",
    image: ShawnImage,
    role: 'Operations  Associate',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Angela Munde",
    image: AngelaImage,
    role: 'UI/UX Designer',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },

  // {
  //   isBlank: true,
  //   bgHoverColor: "secondary.main",
  // },
  {
    name: "Mark Kithinji",
    image: MarkImage,
    role: 'Operations  Associate',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  {
    name: "Cynthia Boro",
    image: CynthiaImage,
    role: 'Operations  Associate',
    isBlank: false,
    bgHoverColor: "secondary.main",
  },
  // {
  //   name: "Rolex Omondi",
  //   image: Rolex,
  //   role: 'Software Engineer',
  //   isBlank: false,
  //   bgHoverColor: "secondary.main",
  // },
  // {
  //   name: "Emmanuel Chivunira",
  //   image: Chivu,
  //   role: 'Software Engineer',
  //   isBlank: false,
  //   bgHoverColor: "secondary.main",
  // },
];

const TheTeamMoreMobile = () => {
  return (
    <>
      <Grid container spacing={"12px"} sx={{
        display: {
          xs: "flex",
          sm: "none",
        },
        mt: "12px",
      }}>
        {teamMembers.map((teamMember, index) => {
          return (
            <Grid key={index} item xs={6} sx={{
              height: "217px",
              visibility: teamMember.isBlank ? "hidden" : "visible",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#FFF",
            }}>
              <Box sx={{
                height: "100%",
                borderRadius: "12px",
              }}>
                <AbstractedStyles
                  imageUrl={teamMember.image}
                  name={teamMember.name}
                  role={teamMember.role} />
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={"12px"} sx={{
        display: {
          xs: "flex",
          sm: "none",
        },
        mt: "12px",
      }}>
        <Grid item xs={6} sx={{
          height: "217px",
          visibility: "visible",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#FFF",
        }}>
          <AbstractedStyles
            imageUrl={GilbertImage}
            name='Gilbert Wambua'
            role='Customer Support Officer' />
        </Grid>


        <Grid item xs={6} sx={{
          height: "217px",
          visibility: "visible",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#FFF",
        }}>
          <AbstractedStyles
            imageUrl={NdanuImage}
            name='Rebecca Ndanu'
            role='Marketing Officer' />
        </Grid>
      </Grid>

      {/* <Grid container spacing={"12px"} sx={{
          display: {
            xs: "flex",
            sm: "none",
          },
          mt: "12px",
        }}>
          {teamMembers1.map((teamMember, index) => {
            return (
              <Grid key={index} item xs={6} sx={{
                height: "217px",
                visibility: teamMember.isBlank ? "hidden" : "visible",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#FFF",
              }}>
                
                <AbstractedStyles
                  imageUrl={teamMember.image}
                  name={teamMember.name}
                  role={teamMember.role} />
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={"12px"} sx={{
          display: {
            xs: "flex",
            sm: "none",
          },
          mt: "12px",
        }}>
          {teamMembers2.map((teamMember, index) => {
            return (
              <Grid key={index} item xs={6} sx={{
                height: "217px",
                visibility: teamMember.isBlank ? "hidden" : "visible",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#FFF",
              }}>
                <AbstractedStyles
                  imageUrl={teamMember.image}
                  name={teamMember.name}
                  role={teamMember.role} />
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={"12px"} sx={{
          display: {
            xs: "flex",
            sm: "none",
          },
          mt: "12px",
        }}>
          {teamMembers3.map((teamMember, index) => {
            return (
              <Grid key={index} item xs={6} sx={{
                height: "217px",
                visibility: teamMember.isBlank ? "hidden" : "visible",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#FFF",
              }}>
                
                <AbstractedStyles
                  imageUrl={teamMember.image}
                  name={teamMember.name}
                  role={teamMember.role} />
              </Grid>
            );
          })}     
        </Grid> */}
      <Grid container spacing={"12px"} sx={{
        display: {
          xs: "flex",
          sm: "none",
        },
        mt: "12px",
      }}>
        {teamMembers1.map((teamMember, index) => {
          return (
            <Grid key={index} item xs={6} sx={{
              height: "217px",
              visibility: teamMember.isBlank ? "hidden" : "visible",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#FFF",
            }}>
              <Box sx={{
                height: "100%",
                borderRadius: "12px",
              }}>
                <AbstractedStyles
                  imageUrl={teamMember.image}
                  name={teamMember.name}
                  role={teamMember.role} />
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={"12px"} sx={{
        display: {
          xs: "flex",
          sm: "none",
        },
        mt: "12px",
      }}>
        <Grid item xs={6} sx={{
          height: "217px",
          visibility: "visible",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#FFF",
        }}>
          <AbstractedStyles
            imageUrl={MaryImage}
            name='Mary Kawira'
            role='Sales Manager' />
        </Grid>
        <Grid item xs={6} sx={{
          height: "217px",
          visibility: "visible",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#FFF",
        }}>
          <AbstractedStyles
            imageUrl={Murigi}
            name='James Murigi'
            role='Operations Administrator' />
        </Grid>
      </Grid>
      <Grid container spacing={"12px"} sx={{
        display: {
          xs: "flex",
          sm: "none",
        },
        mt: "12px",
      }}>
        {teamMembers2.map((teamMember, index) => {
          return (
            <Grid key={index} item xs={6} sx={{
              height: "217px",
              visibility: teamMember.isBlank ? "hidden" : "visible",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#FFF",
            }}>
              <Box sx={{
                height: "100%",
                borderRadius: "12px",
              }}>
                <AbstractedStyles
                  imageUrl={teamMember.image}
                  name={teamMember.name}
                  role={teamMember.role} />
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={"12px"} sx={{
        display: {
          xs: "flex",
          sm: "none",
        },
        mt: "12px",
      }}>
        <Grid item xs={6} sx={{
          height: "217px",
          visibility: "visible",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#FFF",
        }}>

        </Grid>


        <Grid item xs={6} sx={{
          height: "217px",
          visibility: "visible",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#FFF",
        }}>
          <AbstractedStyles
            imageUrl={ChesireImage}
            name='Emmanuel Chesire'
            role='Software Engineer' />
        </Grid>
      </Grid>
      <Grid container spacing={"12px"} sx={{
        display: {
          xs: "flex",
          sm: "none",
        },
        mt: "12px",
      }}>
        {teamMembers3.map((teamMember, index) => {
          return (
            <Grid key={index} item xs={6} sx={{
              height: "217px",
              visibility: teamMember.isBlank ? "hidden" : "visible",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              color: "#FFF",
            }}>
              <Box sx={{
                height: "100%",
                borderRadius: "12px",
              }}>
                <AbstractedStyles
                  imageUrl={teamMember.image}
                  name={teamMember.name}
                  role={teamMember.role} />
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Grid container spacing={"12px"} sx={{
        display: {
          xs: "flex",
          sm: "none",
        },
        mt: "12px",
      }}>
        <Grid item xs={6} sx={{
          height: "217px",
          visibility: "visible",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#FFF",
        }}>

        </Grid>


        <Grid item xs={6} sx={{
          height: "217px",
          visibility: "visible",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "#FFF",
        }}>

        </Grid>
      </Grid>

    </>
  )
};

export default TheTeamMoreMobile;

