import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { SvgIcon, useTheme } from '@mui/material';
import { playStoreLink } from '../../utils/GetTheAppMobile/GetTheAppMobile';


const AccountDeletion = () => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const theme = useTheme();

    const handleAccordionChange = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false);
    };
    const AddIcon = (props: any) => (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="#F5F7F2">
                <path d="M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14Z" fill="#F5F7F2" />
                <path d="M14 3.5C14.2321 3.5 14.4546 3.59219 14.6187 3.75628C14.7828 3.92038 14.875 4.14293 14.875 4.375L14.875 23.625C14.875 23.8571 14.7828 24.0796 14.6187 24.2437C14.4546 24.4078 14.2321 24.5 14 24.5C13.7679 24.5 13.5454 24.4078 13.3813 24.2437C13.2172 24.0796 13.125 23.8571 13.125 23.625L13.125 4.375C13.125 4.14293 13.2172 3.92038 13.3813 3.75628C13.5454 3.59219 13.7679 3.5 14 3.5Z" fill="#F5F7F2" />
            </svg>
        </SvgIcon>
    );

    const RemoveIcon = (props: any) => (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <path d="M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14Z" fill="#64002C" />
            </svg>
        </SvgIcon>
    );

    return (
        <Box id="FAQS" sx={{ bgcolor: 'secondary.main', borderRadius: '20px', flexDirection: 'column' }}>
            {/* <Accordion sx={{ bgcolor: 'transparent', boxShadow: 'none' }}> */}
            {/* <AccordionSummary
                    expandIcon={<AddIcon />}
                    sx={{ color: '#FFF', marginBottom: 0 }}> */}
            <Typography sx={{ fontSize: {md:'2.25vw', sm:'4.688vw', xs:'8vw'}, fontWeight: 800,color:'secondary.light', ml:{md:'25.3vw',sm:'3vw',xs:'4vw'}, textTransform:'uppercase', mb:'20px',mt:'20px',pt:'50px',
         position: 'relative',
        //  '&::before': {
        //    position: 'absolute',
        //    left: { md: '-280px', sm: '6px',xs:'5px' },
        //      '@media only screen and (min-width:1600px)':{left:'-440px'},
        //    top: '-20.5px',
        //    right: '24px',
        //    height: '1px',
        //    content: '""',
        //    opacity: 1,
        //    backgroundColor: 'rgba(245,247,242,0.30)',
        //    transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        //  },
        }}>
                Account Deletion
            </Typography>
            {/* </AccordionSummary> */}
            {/* <AccordionDetails sx={{ padding: "0px" }}> */}

            <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleAccordionChange('panel3')}
                sx={{
                    bgcolor: expanded === `panel3` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px',xs:'16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },

                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel3` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel3` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        01
                    </Box>
                    <Typography sx={{
                  fontSize: { sm: '21px', md: '1.313vw',xs:'4.44vw' },
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '120%',
                  textTransform: 'uppercase',
                  '@media only screen and (min-width:768px) and (max-width: 1100px)': {
                    ml: '8px'
                  },
                }}>
                        Where can I request for my LEJA account deletion?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography sx={{
                    fontSize: { sm: '16px', md: '1vw',xs:'4.44vw' },
                    fontStyle: 'normal',
                    fontWeight: '300',
                    color: 'secondary.main',
                    lineHeight: '130%',
                    marginLeft: { md: '24.25vw', sm: '8px' },
                    mr: { md: '18.75vw', sm: '98px' }
                  }}
                    >
                        LEJA account deletion can be initiated from the profile & settings section of the LEJA application
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleAccordionChange('panel4')}
                sx={{
                    bgcolor: expanded === `panel4` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px',xs:'16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },

                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel4` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel4` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        02
                    </Box>
                    <Typography sx={{
                  fontSize: { sm: '21px', md: '1.313vw',xs:'4.44vw' },
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '120%',
                  textTransform: 'uppercase',
                  '@media only screen and (min-width:768px) and (max-width: 1100px)': {
                    ml: '8px'
                  },
                }}>
                        How do I delete my LEJA account?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: { sm: '16px', md: '1vw',xs:'4.44vw' },
                    fontStyle: 'normal',
                    fontWeight: '300',
                    color: 'secondary.main',
                    lineHeight: '130%',
                    marginLeft: { md: '24.25vw', sm: '8px' },
                    mr: { md: '18.75vw', sm: '98px' }
                  }}
                >
                        <ol>
                            <li>Login to your LEJA application using your account credentials.</li>
                            <li>Navigate to the side menu drawer.</li>
                            <li>Click on "Profile & Settings".</li>
                            <li>Select “Delete Account”.</li>
                            <li>Read “Terms and conditions for LEJA account deletion”.</li>
                            <li>Click “PROCEED” to continue with the deletion or Call support +254 111 052280 if you need assistance.</li>
                            <li>Enter your LEJA PIN.</li>
                            <li>Enter/upload Reason for requesting LEJA account deletion.</li>
                            <li>Confirm your request by manually typing “Delete my account”.</li>
                            <li>Click “Confirm Delete” to finalize the process.</li>
                            <li>Your account is successfully scheduled for deletion in 60 days. You may receive periodic SMS reminders regarding the state of your account within this period.</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>


            <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleAccordionChange('panel5')}
                sx={{
                    bgcolor: expanded === `panel5` ? '#F5F7F2' : 'secondary.main',
                    boxShadow: 'none',
                    pb: '16px',
                    position: 'relative',
                    '&::before': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                    '&::after': {
                        position: 'absolute',
                        left: { md: '40px', sm: '24px', xs: '16px' },
                        // top: '-15.5px',
                        right: '24px',
                        height: '1px',
                        content: '""',
                        opacity: 1,
                        backgroundColor: 'rgba(245,247,242,0.30)',
                        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={expanded === 'panel5' ? <RemoveIcon /> : <AddIcon />}
                    sx={{
                        color: expanded === `panel5` ? '#64002C' : '#F5F7F2',
                        bgColor: expanded === `panel5` ? '#64002C' : '#FFF',
                        marginBottom: 0
                    }}>
                    <Box
                        component="div"
                        sx={{
                            //marginRight: '21.9rem',
                            ml: '24px',
                            mr: '20.90vw',
                            '@media only screen and (min-width: 1600px)': {
                                mr: '22vw'
                            },
                            display: { md: 'block', sm: 'none', xs: 'none' }
                        }}
                    >
                        03
                    </Box>
                    <Typography sx={{
                        fontSize: { sm: '21px', md: '1.313vw', xs: '4.44vw' },
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '120%',
                        textTransform: 'uppercase',
                        '@media only screen and (min-width:768px) and (max-width: 1100px)': {
                            ml: '8px'
                        },
                    }}>
                        What user data does LEJA retain after an account is deleted?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        sx={{
                            fontSize: { sm: '16px', md: '1vw', xs: '4.44vw' },
                            fontStyle: 'normal',
                            fontWeight: '300',
                            color: 'secondary.main',
                            lineHeight: '130%',
                            marginLeft: { md: '24.25vw', sm: '8px' },
                            mr: { md: '18.75vw', sm: '98px' }
                        }}
                    >
                        <ol>
                            <li>Where an account has been deleted successfully, LEJA will retain the Application Data for Sixty (60) Days; within which, if you decide to access LEJA’s services, you will not be required to register a new account. A new registration will only be required where the Sixty (60) Day period has lapsed after an account has been deleted.</li>
                            <li>LEJA retains Transactional records and your personal information for a minimum of Seven (7) years during which period the information may be used in the manner consented to during account opening in our LEJA application terms and conditions</li>
                        </ol>
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* </AccordionDetails>
            </Accordion> */}
        </Box>
    );
};

export default AccountDeletion;
