import { Box, Typography } from '@mui/material'
import React from 'react'
import JTFTabs from './JTFTabs'

const JoinTheFuture = () => {
    return (
        <>
            <Box sx={{
                ml: { md: '30px', sm: '24px', xs: '16px' },
                mt: { md: '140px', sm: '123px', xs: '60px' },
                mb: { md: '140px', sm: '100px', xs: '108px' },
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mb: { md: '20px', sm: '20px', xs: '12px' },
                    mr: { md: '30px', sm: '24px', xs: '16px' },
                }}>
                    <Typography sx={{
                        display: { md: 'block', sm: 'block', xs: 'none' },
                        width: { md: '16.625vw', sm: '29.036vw' },
                        fontSize: '14px',
                        fontWeight: 400,
                        textTransform: 'uppercase',
                        mb: 'auto',
                        mr: { md: '7.938vw', sm: '24px' },
                        mt: '8px'
                    }}>
                        [join the future]
                    </Typography>
                    <Typography sx={{
                        fontSize: { md: '3.125vw', sm: '6.51vw', xs: '8.333vw' },
                        fontWeight: 500,
                        lineHeight: '100%',
                        letterSpacing: '-1.5px',
                        textTransform: 'uppercase',
                        width: { md: '53.5vw', sm: '61.589vw', xs: '91.111vw' }
                    }}>
                        Join the 100k+ entrepreneurs already in our platform
                    </Typography>
                </Box>
                <Box sx={{
                    mb: { md: '16px', sm: '16px', xs: '12px' }
                }}>
                    <JTFTabs />
                </Box>
            </Box>
        </>
    )
}

export default JoinTheFuture