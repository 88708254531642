import { Box, Typography } from '@mui/material'
import Goate from '../../assets/Blog/Goat.jpg'
import man from '../../assets/Blog/man.png'
import Tekwane from '../../assets/Blog/Tekwane.svg'
import SharePost from './SharePost'
import Blog from '../Blog/Blog'
import BlogHero from '../Blog/BlogHero'

const BlogContent = () => {
    return (
        <>
        <BlogHero subheading='Co-Founder & CEO Asilimia + Leja'
        date="20/01/24"
        />
            <Box sx={{ margin: { md: '80px 26.438vw 0px 26.375vw', sm: '40px 26px 0px 24px', xs: '62px 16px 0px 16px' } }}>
                <Typography
                    sx={{
                        color: '#012317',
                        fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                        lineHeight: '100%',
                        letterSpacing: '-1.5px',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        textTransform: 'uppercase',
                        //mb: { md: '30px', sm: '30px', xs: '16px' }
                    }}>His story embodies the grit and insight that makes LEJA an essential tool.</Typography>
            </Box>
            <Box sx={{display:'flex'}}>
            <Box sx={{ margin: { md: '30px 10vw 0px 26.375vw', sm: '30px 25px 0px 24px', xs: '16px' } }}>
                <Typography sx={{
                    fontSize: {xs:'16px',md:'1vw'},
                    fontstyle: 'normal',
                    fontWeight: '300',
                    lineHeight: '130%',
                    color: '#012317',
                }}>
                    TEKWANE MWENDWA, the mind behind LEJA, started out as an independent hustler from a small village. He evolved into a tech visionary, leading an African revolution in micro-finance. Leja’s visionary founder Tekwane Mwendwas' unlikely path to the world of high tech micro-finance began with a goat. Raised in a small village in the shadow of Mount Kilimanjaro, Mwendwa was eighteen-years-old and still in high school when he took his first foray into “business”. Determined to prove to his despondent schoolmates that they could rise above their limited prospects, he decided to lead by example. He headed to the market twenty kilometers away to purchase a goat. The idea was to buy it cheap, bring it back to the village, cut it up and sell the meat pieces at a profit. With the money, he would begin to prove to his friends what his father had always told him: all men and women are equal and with effort and persistence, anything is possible. It didn’t quite go as planned. His first mistake was underestimating the goat herders at the market. The business men knew how to tip the scale to their benefit. “It was a pump and dump scheme, actually,” Mwendwa recalls wistfully. “The herders fed the goat grass and water the night before knowing how elastic their bellies are and then waited for the first fool to come along. That fool was me.” He literally had to drag and carry the stubborn goat back to the village, where he discovered the further dangers in doing business.
                </Typography>
            </Box>
            {/* <Box sx={{mr:'1.875vw', display:{md:'block', sm:'none', xs:'none'}}}><img src={Goat} /></Box> */}
            <Box sx={{mr:'1.875vw', display:{md:'block', sm:'none', xs:'none'}, objectFit:'cover'}}><img src={Goate} style={{width:'232px', height:'292px', objectFit:'cover', borderRadius:'20px'}} loading={'lazy'} /></Box>
            </Box>
            <Box sx={{
                m: { md: '30px 26.438vw 0px 26.438vw', sm: '9px 25px 0px 25px', xs: '16px 17px 0px 16px', },
                bgcolor: 'primary.main',
                padding: '30px',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '0px',
                justifyContent: 'center',
                alignItems: 'center',
                
                // display:{md:'inline-flex', sm:'flex', xs:'flex'},

            }}><Typography
                sx={{
                    fontSize: { md: '1.125vw', sm: '21px', xs: '18px' },
                    color: '#FFF',
                    fontWeight: '500',
                    lineHeight: '120%',
                    textTransform: 'uppercase',
                    '@media only screen and (max-width: 600px)': {
                        letterSpacing: '-0.54px',
                    }
                }}
            >“I lost money on the goat and then when I attempted to sell the meat,
                    some would not pay or pay less than it was worth knowing that without refrigeration your product lost value every second.
                    So I lost money in every possible way.”
                </Typography>
                </Box>
            <Box sx={{
                m: { md: '30px 26.375vw 0px 26.375vw', sm: '9px 25px 0px 24px', xs: '16px' }
            }}><Typography sx={{
                color: '#012317',
                fontWeight: '300',
                fontSize: {xs:'16px',md:'1vw'},
                lineHeight: '130%',
            }}>
                    His initial attempt failed but a true entrepreneur was born. Undeterred, he tried another angle: bread delivery. He convinced a local baker to give him bread on consignment and found a bike which was sometimes not easy to control. People on the street sometimes took bets on whether he would crash or not. Starting out was difficult but when the venture began to show some traction, he expanded to other produce. He further extended his work to developing irrigation and feeding rabbits. “I had an early smart phone,” Tekwane remembers, “and I discovered I could download magazines about business strategy. I discovered the Harvard Business Review. I read the tales of American business and how they came from nothing and grew into leaders of industry. So that inspired me to try more and learn more.” He learned how to fail quickly, pivot and continue to push into both the formal and informal economies of Kenya. By the age of twenty-one, Tekwane, still searching for an opportunity to do the kind of business he read about in Harvard Business Review, found various ways to earn a living. He developed tours of Kenya for people he met in online business forums. But when Nairobi’s Westgate Mall suffered a terrorist attack, tourism dried up. Tekwane pivoted to retail. He found a job in a mall at a store that sold high-end fashion to mostly tourists. Tekwane, however, focused on how to increase sales to local clientele. He used psychology to drive sales.
                </Typography></Box>
            <Box sx={{
                m: { md: '30px 26.375vw 0px 26.438vw', sm: '9px 25px 0px 25px', xs: '16px' }
            }}><Typography sx={{
                fontSize: { md: '1.313vw', sm: '21px', xs: '18px' },
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '120%',
                textTransform: 'uppercase',
                '@media only screen and (max-width: 600px)': {
                    letterSpacing: '-0.54px',
                }
            }}>
                    “I would meet people at the ATM and chat them up or engage with couples and increase the pressure on the boyfriends to purchase an item.” He laughs. “There was probably a special place in hell for me at that time.”
                </Typography></Box>
            <Box sx={{
                m: { md: '30px 26.375vw 0px 26.438vw', sm: '30px 25px 0px 25px', xs: '16px' }
            }}>
                <img src={Tekwane} style={{ width: '100%', objectFit:'cover' }} loading={'lazy'} />
            </Box>
            <Box sx={{display:'flex'}}>
            <Box sx={{position:'absolute',flexShrink:0,mt:'412px',ml:'1.938vw', display:{md:'block', sm:'none', xs:'none'}}}><img src={man}  loading={'lazy'}/></Box>
            <Box sx={{
                m: { sm: '30px 1.563vw 0px 1.5vw', xs: '16px', md: '30px 26.375vw 0px 26.375vw' }
            }}>
                <Typography sx={{
                    color: '#012317',
                    fontSize: {xs:'16px',md:'1vw'},
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '130%',
                    // width: '756px'
                }}>
                    It was an enjoyable job, he admits, and he made many friends at the mall but Tekwane was ready to get back to
                    business. He found his chance while researching ways to increase foot traffic to the store. It was an eureka moment. He
                    noticed most malls in Africa were built to push customers to the anchor franchises on the ground floor — major
                    supermarkets like SPAR or Shoprite. Retailers who were on different floors would be at a distinct disadvantage. Tekwane
                    realized touchscreen maps with 3D modeling offered a technological solution. The scalability and opportunity for steady
                    corporate advertising revenue excited him. Armed with only a pitch deck, he chased after investment for months. He
                    finally found an angel investor and corporate advertisers lined up after the launch. But the project hit a snag. “My investor
                    refused to take on partners, which made it impossible to scale beyond a single mall.” The project foundered but Tekwane
                    had made a conceptual leap. He now actively sought out tech solutions for uniquely African challenges.
                    Soon Tekwane started to make a name for himself as a business
                    savant. He was hired as an adviser and consultant for various
                    projects. He was evolving from hustler to the kind of
                    businessman he aspired to but there was a catch. “Something
                    bothered me,” he remembers. “I was progressing but my friends
                    from the mall who I came up with were stuck. It made me want to
                    help.” He decided to start Sidehustle.co.ke to assist his friends
                    convert their extra time into income. He started with hairdressers,
                    using the tech to book clients. He realized sidehustle.co.ke
                    solved a growing question in Kenya. How do you give the
                    everyday Kenyan more financial options? But in addition, how
                    does one make the application self-sustaining?
                    The questions led him briefly to the world of social impact
                    funding. But he quickly found himself uncomfortable with the
                    dynamics. He attended conferences where they spoke about the
                    issues in Africa in ways that distorted the people and the place he
                    called home. “I didn’t like the image of myself on the conference
                    screen.” There was an inordinate amount of poverty porn. It felt
                    like high-tech begging and “a bit dehumanizing.” The pitches he
                    heard were riddled with negative descriptions of his home.
                </Typography></Box>
            </Box>
            <Box sx={{
                m: { md: '32px 26.375vw 0px 26.375vw', sm: '32px 25px 0px 24px', xs: '16px' },
                bgcolor: 'primary.main',
                padding: {md:'1.875vw',xs:'30px'},
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '0px',
            }}>
                <Typography
                    sx={{
                        color: '#FFF',
                        fontSize: { md: '1.313vw', sm: '21px', xs: '18px' },
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '120%',
                        textTransform: 'uppercase',
                        '@media only screen and (max-width: 600px)': {
                            letterSpacing: '-0.54px',
                        }
                    }}>“Bad
                    this, bad that. I didn’t see anything bad about us. I thought, let me
                    not join this band wagon.” He recognized he needed to take
                    another route.
                </Typography>
            </Box>
            <Box sx={{
                m: { md: '32px 26.375vw 0px 26.375vw', sm: '32px 25px 0px 24px', xs: '16px' }
            }}>
                <Typography
            sx={{
                // width: '756px',
                color: '#012317',
                fontSize: {xs:'16px',md:'1vw'},
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: '130%'
            }}>“The question remained, ‘How do I make this work?’” The answer
            came from an unexpected source. By this time, the
            groundbreaking phone banking app M-pesa became ubiquitous
            in Kenya. It revolutionized money and transfers in the country but
            it wasn’t perfect. “I noticed I was always struggling with the app.
            Mpesa was useful but the fees would sometimes force me to walk
            home.” Through his experience with the app, he saw an
            opportunity. His reasoning was simple. “I read about Amazon and
            how in the US, the richest country in the world, the app was
            leveraging technology to make things cheaper, easier and more
            convenient for its clients. For some reason, it wasn’t quite
            working that way in Kenya.” He recognized this as an opening.
            He had an idea.
            He used rent money to pay a developer. After some difficulty, he
            introduced Asilimia, an app which featured a cashback scheme
            for SMES. He conceived it as a people’s app, leveraging
            technology for the average Kenyan and following the business
            models he read about. It was a runaway success.
        </Typography></Box>
            <Box sx={{
                m: { md: '32px 26.375vw 0px 26.375vw', sm: '32px 25px 0px 24px', xs: '16px' }
            }}>
                <Typography
                        sx={{
                            // width: '756px',
                            fontStyle: 'normal',
                            fontSize: {md:'1.313vw', sm:'21px', xs:'18px'},
                            fontWeight: 500,
                            lineHeight: '120%',
                            textTransform: 'uppercase',
                            '@media only screen and (max-width: 600px)': {
                                letterSpacing: '-0.54px',
                            }
                        }}>
                        His vision broadened with Leja, an app created to open the flood
                        gates of African enterprise. Building off the success of Asilimia,
                        Leja is something for the market women, the drivers and the
                        shop owners. Leja’s micro-financing provides the fuel necessary
                        to transform hustle and soloprenuers into small businesses and
                        small businesses into bigger businesses.
                    </Typography>
            </Box>
            <Box sx={{
                        display: {md:'inline-flex', sm:'flex', xs:'flex'},
                        alignItems:'center',
                        // flexDirection:{md:'row', sm:'row', xs:'column'},
                        // justifyContent: 'center',
                        gap: '17px',
                        mt: '60px',
                        ml:{md:'26.438vw', sm:'24px', xs:'16px'},
                    }}>
                        <Typography sx={{
                            color: 'primary.main',
                            fontSize: {xs:'16px',md:'1rem'},
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: '130%',
                            marginRight: '17px',

                        }}>Share this post:</Typography>
                       <SharePost />
                    </Box>
                    <Blog title='MORE NEWS' subtitle='[NEWS AND INSIGHTS]' parentSlug='the-maverick'/>
        </>
    );
};

export default BlogContent