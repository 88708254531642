import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TekwaneBlog from '../../assets/TekwaneBlog.png';
import LadyBlog from '../../assets/LadyBlog.png';
import TekwaneBlogMobile from '../../assets/TekwaneBlogMobile.png';
import { NavLink } from "react-router-dom";
import { Theme } from '@mui/material/styles';
import { useState } from "react";
import introducingLeja from '../../assets/introducingLeja.jpg';
import registerPicture from '../../assets/registerPicture.jpg';
import BlogMore from "./BlogsMore";


interface BlogProps {
    title?: string
    subtitle?: string
}

const BlogPlatformPage = ({ title = 'Blog', subtitle = '[our stories]' }: BlogProps) => {
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [hovered1, setHovered1] = useState(false); // hover state for blog item 1
    const [hovered2, setHovered2] = useState(false); // hover state for blog item 2
    const [hovered3, setHovered3] = useState(false);
    const [hovered4, setHovered4] = useState(false);
    const [showMoreBlogs, setShowMoreBlogs] = useState(false);

    const handleShowMoreClick = () => {
        setShowMoreBlogs(!showMoreBlogs);
    };


    return (
        <>
            <Grid container
                  spacing={(isMobile ? '8px' : isTablet ? '24px' : '1.875vw')}
                  sx={{
                      alignSelf: 'stretch',
                      mb: { md: '24px', sm: '24px', xs: '16px' },
                  }}>
                <Grid item md={6} xs={12} sx={{ height: '100%' }}>
                    <Box sx={{
                        borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                        padding: { xs: '4.44vw', sm: '3.125vw', md: '1.875vw' },
                        backgroundColor: hovered3 ? '#FFB5CC' : '#FFF',
                    }}>
                        <NavLink to='/introducing-leja' onMouseOver={() => setHovered3(true)} onMouseOut={() => setHovered3(false)} style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                            marginRight: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '1.875vw'),
                        }}>
                            <Box sx={{
                                height: { xs: '164.44vw', sm: '77.08vw', md: '34vw' },
                            }}>
                                <img
                                    src={introducingLeja}
                                    alt="introducingLeja"
                                    style={{
                                        objectFit: "cover",
                                        width: '100%',
                                        height: (isMobile ? '91.67vw' : (isTablet && !isMobile) ? '48.44vw' : '23.25vw'),
                                        marginBottom: (isMobile ? '4.44vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw')
                                    }}
                                    loading={'lazy'}
                                />
                                <Box sx={{
                                    mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' }
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' },
                                        alignItems: 'flex-start',
                                    }}>
                                        <Typography sx={{
                                            width: '100%',
                                            fontSize: { xs: '5vw', sm: '2.73vw', md: '1.32vw' },
                                            fontWeight: 500,
                                            lineHeight: '120%',
                                            textTransform: 'uppercase',
                                            color: '#012317',
                                        }}>
                                            Introducing Leja
                                        </Typography>
                                        <ArrowForwardIcon sx={{
                                            color: 'primary.main',
                                        }} />
                                    </Box>
                                    <Box>
                                        <Typography sx={{
                                            color: '#012317',
                                            fontSize: { xs: '4.44vw', sm: '2.08vw', md: '1vw' },
                                            fontWeight: 300,
                                        }}>
                                            Leja App is a one-stop solution aimed to revolutionize the way MSMEs manage their finances, providing a secure, efficient, and convenient platform for growth.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography sx={{
                                        color: '#01231799',
                                        fontSize: { md: '0.875vw', sm: '1.82vw', xs: '3.89vw' }
                                    }}>
                                        03/01/24
                                    </Typography>
                                </Box>
                            </Box>
                        </NavLink>
                    </Box>
                </Grid>
                <Grid item md={6} xs={12} sx={{ height: '100%' }} hidden={true}>
                    <Box sx={{
                        borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                        padding: { xs: '4.44vw', sm: '3.125vw', md: '1.875vw' },
                        backgroundColor: hovered4 ? '#FFB5CC' : '#FFF',
                    }}>
                        <NavLink to='/registering-a-business' onMouseOver={() => setHovered4(true)} onMouseOut={() => setHovered4(false)} style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                            marginRight: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '1.875vw'),
                        }}>
                            <Box sx={{
                                height: { xs: '164.44vw', sm: '77.08vw', md: '34vw' },
                            }}>
                                <img
                                    src={LadyBlog}
                                    alt="expense-tracker"
                                    style={{
                                        objectFit: "cover",
                                        width: '100%',
                                        height: (isMobile ? '91.67vw' : (isTablet && !isMobile) ? '48.44vw' : '23.25vw'),
                                        marginBottom: (isMobile ? '4.44vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw')
                                    }}
                                    loading={'lazy'}
                                />
                                <Box sx={{
                                    mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' }
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        mb: { md: '1vw', sm: '2.083vw', xs: '5.56vw' },
                                        alignItems: 'flex-start',
                                    }}>
                                        <Typography sx={{
                                            width: '100%',
                                            fontSize: { xs: '5vw', sm: '2.73vw', md: '1.32vw' },
                                            fontWeight: 500,
                                            lineHeight: '120%',
                                            textTransform: 'uppercase',
                                            color: '#012317',
                                        }}>
                                            Registering a Business
                                        </Typography>
                                        <ArrowForwardIcon sx={{
                                            color: 'primary.main',
                                            width: { xs: '8.89vw', sm: '4.17vw', md: '2vw' },
                                            height: { xs: '8.89vw', sm: '4.17vw', md: '2vw' }
                                        }} />
                                    </Box>
                                    <Box>
                                        <Typography sx={{
                                            color: '#012317',
                                            fontSize: { xs: '4.44vw', sm: '2.08vw', md: '1vw' },
                                            fontWeight: 300,
                                        }}>
                                            Starting a business in Kenya can be an exciting and rewarding venture. However, before you can officially operate, you need to go through the process of registering your business.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography sx={{
                                        color: '#01231799',
                                        fontSize: { md: '0.875vw', sm: '1.82vw', xs: '3.89vw' }
                                    }}>
                                        13/03/24
                                    </Typography>
                                </Box>
                            </Box>
                        </NavLink>
                    </Box>
                </Grid>
            </Grid>

            {/*{showMoreBlogs && (*/}
            {/*    <BlogMore />*/}
            {/*)}*/}

            {/*<Button*/}
            {/*    onClick={handleShowMoreClick}*/}
            {/*    variant="contained"*/}
            {/*    sx={{*/}
            {/*        bgcolor: '#F4EEE3',*/}
            {/*        width: '100%',*/}
            {/*        padding: '0.5vw 1.875vw',*/}
            {/*        textAlign: 'center',*/}
            {/*        justifyContent: 'center',*/}
            {/*        "&:hover": {*/}
            {/*            bgcolor: "#FFF"*/}
            {/*        },*/}
            {/*        boxShadow: 'none',*/}
            {/*        // mt:{sm:'30px', xs:'24px'}*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Typography sx={{*/}
            {/*        fontSize: '16px',*/}
            {/*        fontWeight: 500,*/}
            {/*        textTransform: 'uppercase',*/}
            {/*        color: 'primary.main'*/}
            {/*    }}>*/}
            {/*        {showMoreBlogs ? 'View less' : 'View more'}*/}
            {/*    </Typography>*/}
            {/*    <ArrowForwardIcon sx={{*/}
            {/*        width: '28px',*/}
            {/*        height: '28px',*/}
            {/*        color: 'primary.main',*/}
            {/*    }} />*/}
            {/*</Button>*/}
        </>
    )
}

export default BlogPlatformPage