import { Box, Grid, Typography } from '@mui/material'
import ListAltIcon from '@mui/icons-material/ListAlt';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';

const Benefits = () => {
    return (
        <Grid container spacing={4} sx={{
            padding: { xs: '16px', sm: '24px', md: '1.875vw' },
            alignSelf: 'stretch'
        }}>
            <Grid item md={4} sm={4} xs={12}>
                <Box sx={{
                    padding: { xs: '16px', sm: '24px', md: '30px' },
                    display: 'flex',
                    gap: { md: '1.875vw', sm: '24px', xs: '20px' },
                    alignItems: { md: 'center', sm: 'flex-start' },
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                    borderRadius: '20px',
                    bgcolor: '#FFFFFF',
                    height:'100%'
                }}>
                    <Box sx=
                        {{
                            width: {xs:'129px', md:'8.0625vw'},
                            height: {xs:'130px',md:'8.125vw'},
                            borderRadius: '100%',
                            bgcolor: 'secondary.light',
                            // padding: '50px 20px',
                            display: 'flex',
                            alignItems:'center',
                            flexShrink: 0,
                            justifyContent: 'center'
                        }}>
                        <ListAltIcon color="primary" sx={{
                            height: '48px',
                            width: '48px',
                        }}/>
                    </Box>
                    <Typography
                        sx={{
                            // ml: { md: '30px' },
                            // width: '275px',
                            fontSize: { xs: '21px', md: '1.28vw' },
                            '@media only screen and (min-width:1200px) and (max-width:1550px)': {
                                fontSize: '1.23vw'
                            },
                            '@media only screen and (min-width:1850px) and (max-width:2000px)': {
                                fontSize: '1.313vw'
                            },
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '120%',
                            textTransform: 'uppercase'
                        }}
                    >100% of your transactions in one place
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
                <Box sx={{
                    padding: { xs: '16px', sm: '24px', md: '30px' },
                    display: 'flex',
                    gap: { md: '1.875vw', sm: '24px', xs: '20px' },
                    alignItems: { md: 'center', sm: 'flex-start' },
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                    borderRadius: '20px',
                    bgcolor: '#FFFFFF',
                    height: '100%'
                }}>
                    <Box sx=
                        {{
                            width: {xs:'129px', md:'8.0625vw'},
                            height: {xs:'130px',md:'8.125vw'},
                            borderRadius: '100%',
                            bgcolor: 'secondary.light',
                            // padding: '50px 20px',
                            display: 'flex',
                            alignItems:'center',
                            flexShrink: 0,
                            justifyContent: 'center'
                        }}>
                        <SavingsOutlinedIcon color="primary" sx={{
                            height: '48px',
                            width: '48px',
                        }}/>
                        </Box>
                    <Typography
                        sx={{
                            fontSize: { xs: '21px', md: '1.28vw' },
                            '@media only screen and (min-width:1200px) and (max-width:1550px)': {
                                fontSize: '1.23vw'
                            },
                            '@media only screen and (min-width:1850px) and (max-width:2000px)': {
                                fontSize: '1.313vw'
                            },
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '120%',
                            textTransform: 'uppercase'
                        }}
                    >Save up to 90% on transaction fees
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
                <Box sx={{
                    padding: { xs: '16px', sm: '24px', md: '30px' },
                    display: 'flex',
                    gap: { md: '1.875vw', sm: '24px', xs: '20px' },
                    alignItems: { md: 'center', sm: 'flex-start' },
                    flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                    borderRadius: '20px',
                    bgcolor: '#FFFFFF',
                    height: '100%'
                }}>
                    <Box sx=
                        {{
                            width: {xs:'129px', md:'8.0625vw'},
                            height: {xs:'130px',md:'8.125vw'},
                            borderRadius: '100%',
                            bgcolor: 'secondary.light',
                            // padding: '50px 20px',
                            display: 'flex',
                            alignItems:'center',
                            flexShrink: 0,
                            justifyContent: 'center'
                        }}>
                        <PhoneAndroidOutlinedIcon color="primary" sx={{
                            height: '48px',
                            width: '48px',
                        }}/>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: { xs: '21px', md: '1.28vw' },
                            '@media only screen and (min-width:1200px) and (max-width:1550px)': {
                                fontSize: '1.2vw'
                            },
                            '@media only screen and (min-width:1850px) and (max-width:2000px)': {
                                fontSize: '1.313vw'
                            },
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '120%',
                            textTransform: 'uppercase'
                        }}
                    >Accessible on android and ussd platforms
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Benefits