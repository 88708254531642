import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DefinitionofTerms from './DefinitionofTerms';
import AcceptanceOfTAndC from './AcceptanceOfTAndC';
import AccountOpening from './AccountOpening';
import PersonalInformation from './PersonalInformation';
import CustomerResponsibilities from './CustomerResponsibilities';
import Operations from './Operations';
import CreditReferenceBureaus from './CreditReferenceBureaus';
import ExclusionOfLiability from './ExclusionOfLiability';
import Termination from './Termination';
import Indemnity from './Indemnity';
import Notices from './Notices';
import IntellectualPropertyRights from './IntellectualPropertyRights';
import DisclosureOfInformation from './DisclosureOfInformation';
import ClosureDeletionAndRecovery from './ClosureDeletionAndRecovery';
import DisputeResolution from './DisputeResolution';
import MiscellaneousProvisions from './MiscellaneousProvisions';

const TermsAndConditions = () => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);

        if (isExpanded) {
            const summaryElement = document.getElementById(`${panel}-summary`);
            //console.log(summaryElement)
            if (summaryElement) {
                setTimeout(() => {
                    summaryElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 500);
            }
        }
    };

    return (
        <>
            <Box sx={{
                display: { md: 'flex', sm: 'block', xs: 'block' },
                ml: { md: '30px', sm: '24px', xs: '16px' },
                mr: { md: '30px', sm: '24px', xs: '16px' },
                mt: { md: '105px', sm: '38px', xs: '62px' }
            }}>
                <Typography sx={{
                    textTransform: 'uppercase',
                    mr: { md: '113px', sm: '543px', xs: '151px' },
                    mb: { sm: '30px', xs: '24px' },
                    fontSize: '14px',
                    fontWeight: 400,
                    width: { md: '283px' }
                }}>
                    Last updated September 20, 2023
                </Typography>
                <Typography sx={{
                    fontSize: { md: '50px', sm: '50px', xs: '30px' },
                    fontWeight: 500,
                    lineHeight: '100%',
                    lineSpacing: '-1.5px',
                }}>
                    TERMS AND CONDITIONS
                </Typography>
            </Box>
            <Box sx={{
                mr: { md: '30px', sm: '24px', xs: '16px' },
                ml: { md: '30px', sm: '24px', xs: '16px' },
                mb: { md: '140px', sm: '100px', xs: '58px' },
                mt: { md: '94px', sm: '72px', xs: '40px' },
                borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                borderBottom: '1px solid rgba(1, 35, 23, 0.15)',
            }}>
                <Accordion
                    expanded={expanded === `panel${1}`}
                    onChange={handleChange(`panel${1}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent'
                    }}
                >
                    <AccordionSummary
                        id={`panel${1}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${1}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(0 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Definitions of Terms
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <DefinitionofTerms />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${2}`}
                    onChange={handleChange(`panel${2}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${2}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${2}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(1 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Acceptance of the Terms and Conditions
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <AcceptanceOfTAndC />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${3}`}
                    onChange={handleChange(`panel${3}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${3}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${3}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(2 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Account opening
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <AccountOpening />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${4}`}
                    onChange={handleChange(`panel${4}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${4}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${4}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(3 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Personal Information
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <PersonalInformation />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${5}`}
                    onChange={handleChange(`panel${5}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${5}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${5}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(4 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Customer’s Responsibilities
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <CustomerResponsibilities />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${6}`}
                    onChange={handleChange(`panel${6}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${6}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${6}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(5 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Operations
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <Operations />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${7}`}
                    onChange={handleChange(`panel${7}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${7}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${7}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(6 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Credit Reference Bureaus
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <CreditReferenceBureaus />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${8}`}
                    onChange={handleChange(`panel${8}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${8}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${8}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(7 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Exclusion of Liability
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <ExclusionOfLiability />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${9}`}
                    onChange={handleChange(`panel${9}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${9}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${9}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(8 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Termination
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <Termination />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${10}`}
                    onChange={handleChange(`panel${10}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${10}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${10}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(9 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Indemnity
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <Indemnity />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${11}`}
                    onChange={handleChange(`panel${11}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${11}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${11}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(10 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Notices
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <Notices />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${12}`}
                    onChange={handleChange(`panel${12}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${12}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${12}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(11 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Intellectual Property Rights
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <IntellectualPropertyRights />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${13}`}
                    onChange={handleChange(`panel${13}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${13}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${13}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(12 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Disclosure of information
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <DisclosureOfInformation />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${14}`}
                    onChange={handleChange(`panel${14}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${14}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${14}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(13 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Closure, Deletion and Recovery of Account
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <ClosureDeletionAndRecovery />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${15}`}
                    onChange={handleChange(`panel${15}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${15}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${15}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(14 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Dispute Resolution
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <DisputeResolution />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${16}`}
                    onChange={handleChange(`panel${16}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${16}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${16}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(15 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Miscellaneous Provisions
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '380px', sm: '90px', xs: '15px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <MiscellaneousProvisions />
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>

    )
}

export default TermsAndConditions
