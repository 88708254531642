import { Box, Link, Typography } from "@mui/material";

const Addresses = () => {
    const addressData = [
        { country: "Kenya", location: "24 Chalbi Drive, Lavington, Nairobi" },
        { country: "France", location: "5 rue Frédéric Phélypeaux, 78640 Villiers-Saint-Frédéric France" },
        { country: "United Kingdom", location: "20 St Andrews St, Holborn Circus, EC4A 3AG", coordinates: "51.517040, -0.107060" }
    ];

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: {sm:'auto', xs:'4.27vw'},
            mr: {sm:'3.125vw', xs:'4.27vw'},
            mt: {sm:'24.5vw', xs:'9.87vw'},
            mb: {sm:'11.33vw', xs:'9.87vw'}
        }}>
            {addressData.map((address, index) => (
                <Box key={index} sx={{
                    width: {sm:'61.45vw', xs:'87.47vw'},
                    height: {sm:'6.12vw', xs:'auto'},
                    mb: {sm:'3.125vw', xs:'5.33vw'},
                }}>
                    <Typography sx={{
                        fontWeight: 500,
                        fontSize: {sm:'2.34vw', xs:'4.8vw'},
                        color: '#F5F7F2',
                        lineHeight: '120%',
                        letterSpacing: {sm:'-0.07vw', xs:'-0.144vw'},
                        textTransform: 'uppercase'
                    }}>
                        {address.country}
                    </Typography>
                    <Typography sx={{
                        fontWeight: 300,
                        fontSize: {sm:'2.08vw', xs:'4.27vw'},
                        color: '#F5F7F2',
                        lineHeight: '130%',
                        mb: {xs:'5.33vw'},
                    }}>
                        {address.location}
                    </Typography>
                </Box>
            ))}
            <Box>
                <Typography sx={{
                    fontWeight: 500,
                    fontSize: {sm:'2.34vw', xs:'4.8vw'},
                    color: '#F5F7F2',
                    lineHeight: '120%',
                    letterSpacing: {sm:'-0.07vw', xs:'-0.144vw'},
                    textTransform: 'uppercase'
                }}>
                    <Link href="tel:++254111052280" sx={{ color: "white", textDecoration: "none" }}>
                        +254 111 052280
                    </Link>
                </Typography>
                <Typography sx={{
                    fontWeight: 300,
                    fontSize: {sm:'2.08vw', xs:'4.27vw'},
                    color: '#F5F7F2',
                    lineHeight: '130%',
                }}>
                    <Link href="mailto: support@leja.co.ke" sx={{ color: "white", textDecoration: "none" }}>
                    support@leja.co.ke
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default Addresses;
