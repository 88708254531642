import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { SvgIcon } from '@mui/material';
import GetinTouchBtn from '../GetInTouchBtn';
import FaqsTabs from '../FaqsTabs';
import AboutLeja from '../CombinedAbout/AboutLeja';
import Tabs from './Tabs';

const FaqHome = ({ faqs }: any) => {
  const [activeButton, setActiveButton] = useState(0);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleButtonClick = (index: number) => {
    setActiveButton(index);
  };

  const handleAccordionChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  //  the "Add" icon SVG component
  const AddIcon = (props: any) => (
    <SvgIcon {...props}>
      {/* SVG path for the "Add" icon */}
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="#F5F7F2">
        <path d="M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14Z" fill="#F5F7F2" />
        <path d="M14 3.5C14.2321 3.5 14.4546 3.59219 14.6187 3.75628C14.7828 3.92038 14.875 4.14293 14.875 4.375L14.875 23.625C14.875 23.8571 14.7828 24.0796 14.6187 24.2437C14.4546 24.4078 14.2321 24.5 14 24.5C13.7679 24.5 13.5454 24.4078 13.3813 24.2437C13.2172 24.0796 13.125 23.8571 13.125 23.625L13.125 4.375C13.125 4.14293 13.2172 3.92038 13.3813 3.75628C13.5454 3.59219 13.7679 3.5 14 3.5Z" fill="#F5F7F2" />
      </svg>
    </SvgIcon>
  );

  // the "Remove" icon SVG component
  const RemoveIcon = (props: any) => (
    <SvgIcon {...props}>
      {/* SVG path for the "Remove" icon */}
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path d="M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14Z" fill="#64002C" />
      </svg>
    </SvgIcon>
  );

return (
  <Box id="FAQS" sx={{ bgcolor: 'secondary.main', borderRadius: {sm:'20px',xs:'12px'}, flexDirection: 'column', pt: {sm:'60px',xs:'40px'}, pb: {sm:'60px',xs:'40px'} }}>
    <Box
        sx={{
          // height: { md: '50px', sm: "50px" },
          display: 'flex',
          alignItems: 'flex-start',
          marginBottom: { md: '24px', sm: '24px', xs: '20px' },
          ml: { md: '1.875vw', sm: '24px',xs:'20px' },


        }}
      >
        <Typography
          sx={{
            display: { md: 'block', sm: 'block', xs: 'none' },
            width: { md: '22.75vw', sm: '200px' },
            flexShrink: 0,
            alignItems: 'center',
            marginRight: { md: '0.5vw', sm: '24px' },
            color: '#FFF',
            fontSize: {xs:'0.875rem',md:'0.875vw'},
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',

          }}
        >
          [QUESTIONS AND ANSWERS]
        </Typography>
        <Typography
          sx={{
            flexShrink: '0',
            color: '#F5F7F2',
            fontSize: { md: '3.125rem', sm: '3.125rem', xs: '30px' },
            fontWeight: 500,
            lineHeight: '100%',
            letterSpacing: '-0.09375rem',
          }}
        >
          FAQ
        </Typography>
      </Box>
      
    <Tabs />
    <Box sx={{
  padding: '0 16px',
  position: 'relative',
}}>
  <GetinTouchBtn label={'View More'} linkTo={'/leja-app#FAQS'} />
</Box>
  </Box>
);
};

export default FaqHome