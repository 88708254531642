import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import CustomButton, { rightIcon } from './CustomButton'
import { HashLink as Link } from 'react-router-hash-link';
import { useState } from 'react';
import PlayButton from '../assets/OurStories/PlayButton.png';
import Judy from '../assets/OurStories/stories.png';
import Backdrop from '@mui/material/Backdrop';

const TheRevolution = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <Grid container sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            display: { md: 'inline-flex', sm: 'flex', xs: 'inline-flex' },
            padding: {
                xs: "0px 16px",
                sm: "0px 24px 0px 25px",
                md: "0px 28px 0px 30px",
            },
        }}>
            <Grid item md={8} sm={12} xs={12} sx={{
                width: { md: '100%', sm: '100%', xs: '100%' },
                display: 'flex',
                alignItems: 'flex-start',
            }}>
                <Typography sx={{
                    width: { md: "19.5vw", sm: '9.1875rem' },
                    alignSelf: { xs: 'stretch' },
                    height: { sm: '42px' },
                    color: 'rgba(1,35,23,0.60)',
                    fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: '1vw'
                    },
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    display: { xs: 'none', md: 'block', sm: 'inline' }
                }}>
                    [SUPERCHARGE YOUR BUSINESS]
                </Typography>

                <Typography sx={{
                    width: { md: '47.25vw', sm: '476px', xs: '100%' },
                    height: { md: 'auto', sm: 'auto', xs: '30px' },
                    ml: {
                        sm: "99px",
                    },
                    fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                    fontStyle: 'normal',
                    color: '#012317',
                    fontWeight: 500,
                    lineHeight: '100%',
                    letterSpacing: '-1.5px',

                }}>
                    JOIN THE REVOLUTION
                </Typography>
            </Grid>

            <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: {
                    xs: "column",
                    md: "row",
                },
                mb: {
                    md: "140px",
                },
                mt: {
                    xs: "12px",
                    sm: "16px",
                    md: "16px",
                },
                gap: {
                    md: "30px",
                }
            }}>
                <Box
                    sx={{
                        width: {
                            md: "100%",
                        },
                        height: {
                            xs: "219px",
                            sm: "471px",
                            md: "610px",
                            xl: "700px",
                        },
                        borderRadius: {
                            xs: "12px",
                            sm: "20px",
                        },
                        position: 'relative',
                        cursor: 'pointer',
                    }}
                    onClick={handleOpenModal}
                >
                    <img
                        src={Judy}
                        alt="Judy a fish trader in Gikomba, Nairobi"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: 'cover',
                            borderRadius: '20px'
                        }}
                        loading={'lazy'}
                        onClick={handleOpenModal}
                    />
                    <img
                        src={PlayButton}
                        alt="Play Button"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                        loading={'lazy'}
                        onClick={handleOpenModal}
                    />
                </Box>

                <Modal
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="youtube-video-modal"
                    aria-describedby="modal-to-display-youtube-video"
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0.95))',
                        }}>
                        <Backdrop
                            open={isModalOpen}
                            onClick={handleCloseModal}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '90%',
                                    height: '90%',
                                    background: 'white',
                                    borderRadius: '20px',
                                    outline: 'none',
                                    boxShadow: 24,
                                    p: { md: '30px', sm: '24px', xs: '12px' },
                                }}
                            >
                                <iframe
                                    src="https://www.youtube.com/embed/p4XzO2EWZNc"
                                    title="Join the movement of small business owners making a lasting impact on their communities."
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        border: 'none',
                                    }}
                                />
                                <button onClick={handleCloseModal}>Close</button>
                            </Box>
                        </Backdrop>
                    </Box>
                </Modal>

                <Box sx={{
                    mt: {
                        xs: "16px",
                        sm: "24px",
                        md: "0px",
                    },
                    display: {
                        xs: "flex",
                        md: "block",
                    },
                    flexDirection: {
                        xs: "column",
                        sm: "row",
                    },
                    gap: {
                        xs: "16px",
                        sm: "24px",
                    },
                }}>
                    <Box sx={{
                        marginBottom: { md: '30px' },
                        width: {
                            lg: '370px',
                            md: '380px',
                            sm: '100%',
                            xs: '100%',
                        },
                        padding: {
                            xs: '20px',
                            sm: '24px',
                            md: '30px',
                            xl: '27px'
                        },
                        borderRadius: '20px',
                        backgroundColor: '#FFF',
                        display: {
                            sm: "flex",
                            md: "block",
                        },
                        flexDirection: {
                            sm: "column",
                        },
                        justifyContent: {
                            sm: "space-between",
                        },
                    }}>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: '30px',
                                        md: '20px',
                                        lg: '20px',
                                        xl: '30px',
                                    },
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '100%',
                                    color: '#012317',
                                    letterSpacing: '-0.6px',
                                    textTransform: 'uppercase'
                                }}>
                                entrepreneurs
                            </Typography>
                            <Typography
                                sx={{
                                    alignSelf: 'stretch',
                                    fontSize: {
                                        xs: '16px',
                                        md: '14px',
                                        lg: '14px',
                                        xl: '16px',
                                    },
                                    fontStyle: 'normal',
                                    fontWeight: '300',
                                    lineHeight: '130%',
                                    color: 'rgba(1, 35, 23, 0.60)',
                                    mt: "16px",
                                }}
                            >Unleash your business's potential with our user-friendly finance management app, designed for entrepreneurs of all kinds to effortlessly manage every aspect of their operations in one place.
                                From tracking sales and expenses to conducting low-cost money transfers and accessing essential financial services, we're here to supercharge your business growth.
                            </Typography>
                        </Box>

                        <Box sx={{
                            mt: "20px"
                        }}>
                            <CustomButton href='leja-app' title='DISCOVER MORE' />
                        </Box>
                    </Box>

                    <Box sx={{
                        width: {
                            lg: '370px',
                            md: '380px',
                            sm: '100%',
                            xs: '100%',
                        },
                        padding: {
                            xs: '20px',
                            sm: '24px',
                            md: '30px',
                            xl: '27px'
                        },
                        borderRadius: '20px',
                        backgroundColor: '#FFF',
                        display: {
                            sm: "flex",
                            md: "block",
                        },
                        flexDirection: {
                            sm: "column",
                        },
                        justifyContent: {
                            sm: "space-between",
                        },
                    }}>
                        <Box>
                            <Typography sx={{
                                fontSize: {
                                    xs: '30px',
                                    md: '20px',
                                    xl: '30px'
                                },
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '100%',
                                color: '#012317',
                                alignSelf: 'stretch',
                            }}>PARTNERS</Typography>
                            <Typography sx={{
                                alignSelf: 'stretch',
                                fontSize: {
                                    xs: '16px',
                                    md: '14px',
                                    lg: '14px',
                                    xl: '16px'
                                },
                                fontStyle: 'normal',
                                fontWeight: '300',
                                lineHeight: '130%',
                                color: 'rgba(1, 35, 23, 0.60)',
                                mt: "16px",
                            }}>Connect to a high-speed network of hundreds of thousands of microbusinesses across Kenya, utilize our platform to provide your products and services to our vast customer base, and
                                leverage real-time market intelligence for seamless market share expansion.
                            </Typography>
                        </Box>
                        <Box sx={{
                            mt: "20px"
                        }}>
                            <Link to='#contact'
                                  smooth
                                  style={{ textDecoration: 'none' }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        display: 'flex',
                                        padding: '8px 30px 8px 30px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignSelf: 'stretch',
                                        borderRadius: '4px',
                                        height: '48px',
                                        "&:hover": {
                                            bgcolor: "primary.light",
                                            boxShadow: 'none',
                                        },
                                        boxShadow: 'none',
                                    }}
                                    endIcon={rightIcon}
                                    fullWidth
                                >
                                    DISCOVER MORE
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}
export default TheRevolution