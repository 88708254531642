import Carousel from "react-multi-carousel";
import { Box, Button, Link, SvgIcon, Typography, useMediaQuery, useTheme } from "@mui/material";
import 'react-multi-carousel/lib/styles.css';
import LeftArrow from '../../assets/LeftArrow.svg';
import RightArrow from '../../assets/RightArrow.svg';
import Photo1 from '../../assets/Photo1.png';
import Photo2 from '../../assets/Photo2.png';
import Photo3 from '../../assets/Photo3.png';
import Photo4 from '../../assets/Photo4.png';
import Photo5 from '../../assets/Photo5.png';
import Photo6 from '../../assets/Photo6.png';
import Photo7 from '../../assets/Photo7.png';
import Photo8 from '../../assets/Photo8.png';
import Photo9 from '../../assets/Photo9.png';
import Photo10 from '../../assets/Photo10.png';
import Photo11 from '../../assets/Photo11.png';
import Photo12 from '../../assets/Photo12.png';
import { Link as RouterLink } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    partialVisibilityGutter: 0
  },
  tablet: {
    breakpoint: { max: 1024, min: 475 },
    items: 2,
    partialVisibilityGutter: 80
  },
  mobile: {
    breakpoint: { max: 475, min: 0 },
    items: 1,
    partialVisibilityGutter: 80
  }
};

const imageURLs = [
  Photo7, Photo8, Photo9, Photo10, Photo11, Photo12,Photo1, Photo2, Photo3, Photo4, Photo5, Photo6, 
];

const rightIcon = <SvgIcon>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="#009166">
    <path d="M24.2441 14.6186L16.3691 22.4936C16.2049 22.6578 15.9822 22.75 15.75 22.75C15.5178 22.75 15.2951 22.6578 15.1309 22.4936C14.9668 22.3294 14.8745 22.1067 14.8745 21.8745C14.8745 21.6423 14.9668 21.4196 15.1309 21.2554L21.513 14.8745H4.375C4.14294 14.8745 3.92038 14.7823 3.75628 14.6182C3.59219 14.4541 3.5 14.2316 3.5 13.9995C3.5 13.7674 3.59219 13.5449 3.75628 13.3808C3.92038 13.2167 4.14294 13.1245 4.375 13.1245H21.513L15.1309 6.74357C14.9668 6.57939 14.8745 6.3567 14.8745 6.12451C14.8745 5.89232 14.9668 5.66963 15.1309 5.50545C15.2951 5.34126 15.5178 5.24902 15.75 5.24902C15.9822 5.24902 16.2049 5.34126 16.3691 5.50545L24.2441 13.3804C24.3254 13.4617 24.39 13.5582 24.434 13.6644C24.478 13.7707 24.5007 13.8845 24.5007 13.9995C24.5007 14.1145 24.478 14.2284 24.434 14.3346C24.39 14.4408 24.3254 14.5373 24.2441 14.6186Z" fill="#009166" />
  </svg>
</SvgIcon>

const ButtonGroup = ({ next, previous, ...rest }: any) => {
  const { carouselState: { currentSlide } } = rest;

  return (
    <Box
      sx={{
        position: "absolute",
        top: "16px",
        right: "30px",
        display: {
          xs: "none",
          md: "block",
        },
      }}>
      <Button
        disableElevation
        className={currentSlide === 0 ? 'disable' : ''}
        sx={{
          bgcolor: "#F4EEE3",
          "&:hover": {
            bgcolor: "#FFF",
          },
        }}
        onClick={() => previous()}
      >
        <img src={LeftArrow} alt="previous" />
      </Button>

      <Button
        disableElevation
        sx={{
          bgcolor: "#F4EEE3",
          ml: "30px",
          "&:hover": {
            bgcolor: "#FFF",
          },
        }}
        onClick={() => next()}
      >
        <img src={RightArrow} alt="next" />
      </Button>
    </Box>
  );
};

const InspireUs = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const animateCenterSlide = (nextSlide: number, currentSlide: number, transform: any) => {
  };

  return (
    <Box sx={{
      position: "relative",
      margin: {
        xs: "60px 0px",
        sm: "100px 0px",
        md: "67.2px 0px 0px 0px",
      },
      paddingTop: {
        xs: "0px",
        sm: "0px",
        md: "67.2px",
      },
      borderRadius: "4px",
      background: `linear-gradient(to top, rgba(255, 192, 203, 0.3), rgba(255, 192, 203, 0))`,
      pb: {md:'60px', sm:'60px', xs:'40px'},
    }}>
      <Box sx={{
        textTransform: "uppercase",
        display: "flex",
        padding: {
          xs: "12px 16px",
          sm: "16px 24px",
          md: "16px 30px 16px 24px",
        },
      }}>
        <Typography sx={{
          color: "rgba(1, 35, 23, 0.60)",
          display: {
            xs: "none",
            sm: "block",
          },
          width: {
            sm: "113px",
            md: "22vw",
          },
          mr: {
            sm: "14.063vw",
            md: "1.813vw",
          },
          fontSize: {
            xs: "14px",
            sm: "14px",
            md: '1vw'
          },
        }}>
          [Our stories]
        </Typography>

        <Typography sx={{
          lineHeight: "100%",
          fontWeight: 500,
          fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
          letterSpacing: '-1.5px',
        }}>
          they inspired us
        </Typography>
      </Box>

      <Box sx={{
        padding: {
          xs: "0px 0px 0px 16px",
          sm: "0px 0px 0px 30px",

        },
      }}>
        <Carousel
          partialVisible={mdDown}
          centerMode={xsDown}
          responsive={responsive}
          autoPlay={mdDown}
          infinite={true}
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside
          beforeChange={(nextSlide, { currentSlide, transform }) => {
            animateCenterSlide(nextSlide, currentSlide, transform);
          }}
        >
          {imageURLs.map((imageURL, index) => {
            return (
              <Box
                component="div"
                id={`they-love-us-${index}`}
                key={imageURL}
                sx={{
                  borderRadius: "20px",
                  mr: mdDown ? "29px" : '30px', // Adjusted margin for medium screens
                }}
              >
                <img
                  src={imageURL}
                  alt="inspiration"
                  style={{
                    width: "100%",
                  }}
                  loading={'lazy'}
                />
              </Box>
            );
          })}
        </Carousel>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Link
          underline="none"
          color="inherit">
          <RouterLink to={"/our-stories"} style={{ textDecoration: "none" }}>
            <Button variant="contained" sx={{
              display: 'flex',
              padding: '8px 30px',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch',
              borderRadius: '4px',
              marginTop: '30px',
              bgcolor: "#FFF",
              color: 'primary.main',
              height: '48px',
              width: { md: '47.1875rem', sm: '724px', xs: '20.5rem' },
              mr: {md:'0px', sm:'24px', xs:'16px'},
              ml: {md:'0px', sm:'24px', xs:'16px'},
              "&:hover": {
                bgcolor: 'secondary.light',
                boxShadow: 'none',
              },
              boxShadow: 'none'
            }} endIcon={rightIcon} >
              VIEW OUR STORIES
            </Button>
          </RouterLink>
        </Link>
      </Box>

    </Box>
  );
}

export default InspireUs;