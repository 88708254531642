import {Accordion, AccordionDetails, AccordionSummary, IconButton, Typography} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

interface ComplainPolicyItemProps {
    index: number;
    title: string;
    children: React.ReactNode;
}
const ComplainPolicyItem = ({ title, children, index }: ComplainPolicyItemProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    return (
        <>
            <Accordion
                expanded={expanded}
                sx={{
                    boxShadow: 'none',
                    '&.Mui-expanded': {
                        marginBottom: 0,
                        marginTop: 0
                    },
                    background: 'transparent'
                }}
            >
                <AccordionSummary
                    id={`panel${1}-summary`}
                    sx={{ paddingLeft: 0 }}
                    onClick={() => setExpanded(!expanded)}
                    expandIcon={
                        <IconButton>
                            {expanded ? <RemoveIcon /> : <AddIcon />}
                        </IconButton>
                    }
                >
                    <Typography sx={{
                        
                        mr: { md: '113px', sm: '543px', xs: '151px' },
                        fontSize: '14px',
                        fontWeight: 400
                    }}>
                        { index > 9 ? `${index}` : `0${index}`}
                    </Typography>
                    <Typography sx={{
                        fontSize: { md: '21px', sm: '21px', xs: '18px' },
                        fontWeight: 500,
                        lineHeight: '120%',
                        letterSpacing: ' -0.54px',
                        textTransform: 'uppercase',
                    }}>
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{
                    ml: {md: '402px', sm: '110px', xs: '37px'},
                    width: {md: '60vw', sm: '60vw', xs: '70vw'},
                    padding: 0
                }}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default ComplainPolicyItem