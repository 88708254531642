import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import TheTeamLess from "./TheTeamLess";
import TheTeamLessMobile from "./TheTeamLessMobile";
import NdanuImage from "../../assets/AboutUs/team/Ndanu.jpg";
import JecintaImage from "../../assets/AboutUs/team/Jecinta.jpg";
import CollinsImage from "../../assets/AboutUs/team/Collins.jpg";
import FlorenceImage from "../../assets/AboutUs/team/Florence.jpg";
import CateImage from "../../assets/AboutUs/team/Cate.jpg";
import EstherImage from "../../assets/AboutUs/team/Esther.jpg";
import WillysImage from "../../assets/AboutUs/team/Willys.jpg";
import MartinImage from "../../assets/AboutUs/team/Martin.jpg";
import KiruiImage from "../../assets/AboutUs/team/Kirui.jpg";
import ChesireImage from "../../assets/AboutUs/team/Chesire.jpg";
import MosesImage from "../../assets/AboutUs/team/Moses.jpg";
import SydneyImage from "../../assets/AboutUs/team/Sydney.jpg";
import GilbertImage from "../../assets/AboutUs/team/Gilbert.jpg";
import JamesImage from "../../assets/AboutUs/team/James.jpg";
import Murigi from "../../assets/AboutUs/team/Murigi.jpg";
import CynthiaImage from "../../assets/AboutUs/team/cynthia.jpg";
import ShawnImage from "../../assets/AboutUs/team/Shawn.jpg";
import MarkImage from "../../assets/AboutUs/team/Mark.jpg";
import AngelaImage from "../../assets/AboutUs/team/Angela.jpg";
import PhenaImage from "../../assets/AboutUs/team/Phena.jpg";
import MaryImage from "../../assets/AboutUs/team/Mary.jpg";
import Rolex from "../../assets/AboutUs/team/Rolex.jpg";
import Chivu from "../../assets/AboutUs/team/Chivu.jpg";
import AbstractedStyles from "./AbstractedStyles";
import Nancy from "../../assets/AboutUs/team/Nancy.jpg";

const TheTeamMore = () => {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <TheTeamLess />
      <TheTeamLessMobile />

      {/* Customer Support team */}
      <Grid container columnSpacing={lgDown ? "24px" : "30px"} sx={{
        display: {
          xs: "none",
          sm: "flex",
        },
      }}>
        <Grid item lg={6} sx={{
          width: "100%",
        }}>
          <Grid container columnSpacing={lgDown ? "24px" : "30px"} sx={{
            flexDirection: "row-reverse",
          }}>
            <Grid item sm={8} sx={{
              height: { md: '632px', sm: '632px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '800px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={CateImage}
                name="Catherine Wanjiru"
                role="Customer Support Manager"
              />
            </Grid>

            <Grid item sm={4} sx={{
              alignSelf: {
                sm: "end",
              },
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={NdanuImage}
                name=" Rebecca Ndanu"
                role="Marketing Officer"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} sx={{
          width: "100%",
        }}>
          <Grid container columnSpacing={lgDown ? "24px" : "30px"}>
            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={JecintaImage}
                name="Jecintah Syotini"
                role="Customer Support Officer"
              />
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={CollinsImage}
                name="Collins Rotich"
                role="Customer Support Officer"
              />
            </Grid>

            <Grid item sm={4} sx={{
              visibility: "hidden",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <Box sx={{
                height: "100%",
                bgcolor: "secondary.main",
                borderRadius: "20px",
              }}>

              </Box>
            </Grid>

            <Grid item sm={4} sx={{
              visibility: "hidden",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <Box sx={{
                height: "100%",
                bgcolor: "secondary.main",
                borderRadius: "20px",
              }}>

              </Box>
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={FlorenceImage}
                name="Florence Leah"
                role="Customer Support Officer"
              />
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={GilbertImage}
                name="Gilbert Wambua"
                role="Customer Support Officer"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Sales Team */}
      <Grid container columnSpacing={lgDown ? "24px" : "30px"} sx={{
        display: {
          xs: "none",
          sm: "flex",
        },
      }}>
        <Grid item lg={6} sx={{
          width: "100%"
        }}>
          <Grid container columnSpacing={lgDown ? "24px" : "30px"}>
            <Grid item sm={4} sx={{
              visibility: "hidden",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <Box sx={{
                height: "100%",
                bgcolor: "secondary.main",
                borderRadius: "20px",
              }}>

              </Box>
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={EstherImage}
                name="Esther  Wanjiru"
                role="Operations Administrator"
              />
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={WillysImage}
                name="Willys Kimotho"
                role="Sales Manager"
              />
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={Nancy}
                name="Nancy Kawira"
                role="Operations Administrator"
              />
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={Murigi}
                name="James Murigi"
                role="Operations Administrator"
              />
            </Grid>

            <Grid item sm={4} sx={{
              visibility: "hidden",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <Box sx={{
                height: "100%",
                bgcolor: "secondary.main",
                borderRadius: "20px",
              }}>

              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} sx={{
          width: "100%",
        }}>
          <Grid container columnSpacing={lgDown ? "24px" : "30px"}>
            <Grid item sm={8} sx={{
              height: { md: '632px', sm: '632px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '800px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={MartinImage}
                name="Martin Njenga"
                role="Sales Manager "
              />
            </Grid>

            <Grid item sm={4} sx={{
              alignSelf: {
                sm: "end",
              },
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={MaryImage}
                name="Mary Kawira"
                role="Sales Manager"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Tech Team */}
      <Grid container columnSpacing={lgDown ? "24px" : "30px"} sx={{
        display: {
          xs: "none",
          sm: "flex",
        },
      }}>
        <Grid item lg={6} sx={{
          width: "100%",
        }}>
          <Grid container columnSpacing={lgDown ? "24px" : "30px"} sx={{
            flexDirection: "row-reverse",
          }}>
            <Grid item sm={8} sx={{
              height: { md: '632px', sm: '632px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '800px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={PhenaImage}
                name="Phena Mwema"
                role="Senior Android Developer"
              />
            </Grid>

            <Grid item sm={4} sx={{
              alignSelf: {
                sm: "end",
              },
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={SydneyImage}
                name="Sidney Wesonga"
                role="Senior Software Engineer"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} sx={{
          width: "100%",
        }}>
          <Grid container columnSpacing={lgDown ? "24px" : "30px"}>
            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={KiruiImage}
                name="Vincent Kirui"
                role="Backend Engineer"
              />
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={ChesireImage}
                name="Emmanuel Chesire "
                role="Software Engineer "
              />
            </Grid>

            <Grid item sm={4} sx={{
              visibility: "hidden",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <Box sx={{
                height: "100%",
                bgcolor: "secondary.main",
                borderRadius: "20px",
              }}>

              </Box>
            </Grid>

            <Grid item sm={4} sx={{
              visibility: "hidden",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <Box sx={{
                height: "100%",
                borderRadius: "20px",
              }}>

              </Box>
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={JamesImage}
                name="James Dennis"
                role="Senior Mobile Developer"
              />
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Operations Team */}
      <Grid container columnSpacing={lgDown ? "24px" : "30px"} sx={{
        display: {
          xs: "none",
          sm: "flex",
        },
      }}>
        <Grid item lg={6} sx={{
          width: "100%"
        }}>
          <Grid container columnSpacing={lgDown ? "24px" : "30px"}>
            <Grid item sm={4} sx={{
              visibility: "hidden",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <Box sx={{
                height: "100%",
                bgcolor: "secondary.main",
                borderRadius: "20px",
              }}>

              </Box>
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>

            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={ShawnImage}
                name="Shawn Mitaki"
                role="Operations  Associate"
              />
            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>

            </Grid>

            <Grid item sm={4} sx={{
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={AngelaImage}
                name="Angela Munde"
                role="UI/UX Designer"
              />
            </Grid>

            <Grid item sm={4} sx={{
              visibility: "hidden",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <Box sx={{
                height: "100%",
                bgcolor: "secondary.main",
                borderRadius: "20px",
              }}>

              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} sx={{
          width: "100%",
        }}>
          <Grid container columnSpacing={lgDown ? "24px" : "30px"}>
            <Grid item sm={8} sx={{
              height: { md: '632px', sm: '632px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '800px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={CynthiaImage}
                name="Cynthia Boro"
                role="Operations  Associate"
              />
            </Grid>

            <Grid item sm={4} sx={{
              alignSelf: {
                sm: "end",
              },
              height: { md: '301px', sm: '301px' },
              '@media only screen and (min-width:1700px) and (max-width:2000px)': {
                height: '385px'
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              pt: 0,
              mt: { md: '30px', sm: '24px' }
            }}>
              <AbstractedStyles
                imageUrl={MarkImage}
                name="Mark Kithinji"
                role="Operations  Associate"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
};

export default TheTeamMore;
