import {Box, Typography, useMediaQuery} from "@mui/material";
import PlayButton from "../../assets/OurStories/PlayButton.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, {useState} from "react";
import {Testimonial} from "../../models/testimonial";
import {Theme} from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

const TestimonialCard = ({ testimonial }: { testimonial: Testimonial }) => {
    const { text, name, position, videoUrl, image } = testimonial;
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [hovered, setHovered] = useState(false);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' },
                height: { md: 'auto', sm: 'auto', xs: 'auto' },
                mb: { sm: '24px', xs: '16px' },
            }}>
                <Box
                    sx={{
                        '&:hover': {
                            cursor: 'pointer'
                        },
                    }}
                    onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)}
                    style={{
                        textDecoration: 'none',
                        position: 'relative',
                        color: 'inherit',
                        borderRadius: (isMobile ? '5.55vw' : (isTablet && !isMobile) ? '2.6vw' : '1.25vw'),
                        backgroundColor: hovered ? '#FFB5CC' : '#FFF',
                        height: (isMobile ? '66.667vw' : (isTablet && !isMobile) ? '69.531vw' : '33.563vw'),
                        width: (isMobile ? '100%' : (isTablet && !isMobile) ? '100%' : '100%'),
                        padding: (isMobile ? '4.44vw' : (isTablet && !isMobile) ? '3.125vw' : '1.875vw'),
                        marginBottom: (isMobile ? '3.333vw' : (isTablet && !isMobile) ? '2.604vw' : '1.250vw')
                    }}>
                    <img
                        src={image}
                        alt="Paul Image"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: 'cover'
                        }}
                        onClick={() => handleOpen()}
                        loading={'lazy'}
                    />
                    <img
                        src={PlayButton}
                        alt="Play Button"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        onClick={() => handleOpen()}
                        loading={'lazy'}
                    />
                </Box>
                <Box sx={{ display: { md: 'block', sm: 'flex', xs: 'flex' }, gap: { sm: '43px', xs: '24px' } }}>
                    <Typography sx={{
                        fontSize: { md: '16px', sm: '16px', xs: '14px' },
                        fontWeight: 400,
                        textTransform: 'uppercase',
                        width: { md: 'auto', sm: 'auto', xs: 'auto' },
                        mr: { md: '107px', sm: '0px', xs: '0px' },
                        mb: { md: '16px', sm: '16px', xs: '12px' }
                    }}>
                        {text}
                    </Typography>
                    <ArrowForwardIcon sx={{ color: 'primary.main', display: { md: 'none', sm: 'block', xs: 'block' } }} />
                </Box>
                <Typography sx={{
                    width: { md: '100%', sm: '100%', xs: '100%' },
                    fontSize: { md: '16px', sm: '16px', xs: '14px' },
                    fontWeight: 300,
                    lineHeight: '130%',
                    color: 'rgba(1, 35, 23, 0.60)'
                }}>
                    <span style={{ fontWeight: 400, textTransform: 'uppercase' }}>{name} </span>
                    { position && name ? ' | ' : ''}
                    { position }
                </Typography>
            </Box>

            <Box sx={{
                width: { md: '100%', sm: '100%', xs: '100%' },
                height: { md: 'auto', sm: 'auto', xs: 'auto' },
                mb: { sm: '24px', xs: '16px' },
            }}
            >
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0.95))',
                        }}>

                        <Backdrop
                            open={open}
                            onClick={handleClose}
                        >
                            <Box
                                sx={{
                                    bgcolor: '#FFF',
                                    mx: { md: '261px', sm: 'auto', xs: 'auto' },
                                    my: { md: '78px 81px', sm: 'auto', xs: 'auto' },
                                    width: { md: 'calc(100% - 522px)', sm: '85%', xs: '85%' },
                                    height: { md: 'calc(100% - 159px)', sm: 'calc(100% - 159px)', xs: '50%' },
                                    padding: { md: '30px', sm: '24px', xs: '12px' },
                                    borderRadius: '20px',
                                }}>
                                <iframe
                                    width="100%"
                                    height="100%"
                                    src={videoUrl}
                                    title="Unlocking growth for microbusinesses"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                ></iframe>
                            </Box>
                        </Backdrop>
                    </Box>
                </Modal>
            </Box>
        </>
    )
}

export default TestimonialCard;