import {Avatar, Grid, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FileUpload from "../Common/FileUpload";
import React from "react";

interface UploadApplicationDocumentProps {
    title: string;
    description: string;
    onFileUpload: (file: File | null) => void;
    children?: React.ReactNode;
    hasError?: boolean;
}

const UploadApplicationDocument = ({title, description, onFileUpload, children, hasError}: UploadApplicationDocumentProps) => {
    const [file, setFile] = React.useState<File | null>(null);
    return (
        <>
            <Box sx={{
                backgroundColor: "#FFF",
                padding: '18px',
                borderRadius: '10px',
                marginTop: '15px',
                marginBottom: '15px'
            }}>
                <Grid container spacing={3} sx={{alignItems: 'normal'}}>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography sx={{fontWeight: 500, fontSize: '21px', textTransform: 'uppercase'}}>
                                {title}
                            </Typography>
                            <Typography sx={{fontWeight: 300, fontSize: '16px', pt: '10px'}}>
                                {description}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                        }}>

                            <FileUpload
                                hasError={hasError}
                                onFileUpload={(file) => {
                                    onFileUpload(file)
                                    setFile(file)
                                }}
                            />
                        </Box>
                        { children }
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default UploadApplicationDocument;