import { Box, Button, Grid, Link, Typography, useMediaQuery } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Theme } from '@mui/material/styles';
import { Link as RouterLink } from "react-router-dom";
import {lejaBlogs} from "../../models/leja-blog";
import BlogSummaryCard from "./BlogSummaryCard";

interface BlogProps{
    title?:string
    subtitle?:string
    parentSlug?:string
}

const Blog = ({title = 'Blog', subtitle = '[news and insights]', parentSlug }:BlogProps) => {
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const moreBlogs = lejaBlogs
        .filter((blog) => blog.slug !== parentSlug)
        .sort(() => Math.random() - 0.5)
        .slice(0, 2)
        .sort((a, b) => lejaBlogs.findIndex((blog) => blog.slug === a.slug) - lejaBlogs.findIndex((blog) => blog.slug === b.slug))

    return (
        <Box sx={{
            padding: { xs: '0 4.44vw', sm: '0 3.125vw', md: '0 30px' },
            mt: { xs: '16.67vw', sm: '11.46vw', md: '8.75vw' },
            mb: { md: '8.938vw' }
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                mb: { xs: '3.33vw', sm: '2.08vw', md: '1vw' }
            }}>
                <Typography sx={{
                    fontSize: { sm: '1.82vw', md: '0.875vw' },
                    fontWeight: 400,
                    textTransform: 'uppercase',
                    color: 'rgba(1, 35, 23, 0.60)',
                    width: { sm: '29.04vw', md: '22.75vw' },
                    display: { xs: 'none', sm: 'flex', md: 'flex' }
                }}>
                    {subtitle}
                </Typography>

                <Typography sx={{
                    width: { xs: '91.11vw', sm: '61.33vw', md: '47.125vw' },
                    fontSize: { xs: '8.33vw', sm: '6.51vw', md: '3.125VW' },
                    fontWeight: 500,
                    lineHeight: '100%',
                    letterSpacing: { xs: '-0.42vw', sm: '-0.2vw', md: '-0.09vw' },
                    textTransform: 'uppercase',
                    ml: { xs: '0', sm: '3.39vw', md: '1.7vw' },
                    mr: 'auto'
                }}>
                    {title}
                </Typography>

                <Link
                    underline="none"
                    color="inherit">
                    <RouterLink to={"/blog"} style={{ textDecoration: "none" }}>

                        <Button
                            variant="contained"
                            sx={{
                                bgcolor: '#F4EEE3',
                                height: '48px',
                                display: { md: 'flex', sm: 'none', xs: 'none' },
                                padding: '0.5vw 1.875vw',
                                textAlign: 'center',
                                justifyContent: 'center',
                                "&:hover": {
                                    bgcolor: "#FFF",
                                    boxShadow: 'none',
                                },
                                boxShadow: 'none',
                                ml: 'auto',
                            }}>
                            <Typography sx={{
                                fontSize: '16px',
                                fontWeight: 500,
                                textTransform: 'uppercase',
                                mr: '0.5vw',
                                color: 'primary.main'
                            }}>
                                view our blog
                            </Typography>
                            <ArrowForwardIcon sx={{
                                width: '1.75vw',
                                height: '1.75vw',
                                color: 'primary.main',
                            }} />
                        </Button>
                    </RouterLink>
                </Link>
            </Box>
            <Grid container
                spacing={(isMobile ? '8px' : isTablet ? '24px' : '1.875vw')}
                sx={{
                    mb: { md: '24px', sm: '24px', xs: '16px' },
                    alignSelf: 'stretch'
                }}>
                { moreBlogs.map((blog, index) => (
                    <BlogSummaryCard key={index} blog={blog}></BlogSummaryCard>
                ))}
            </Grid>
        </Box>
    )
}

export default Blog