import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { SvgIcon } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";

interface CustomButtonProps {
    title: string;
    href: string;
}

  export const rightIcon = <SvgIcon>
<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
<path d="M24.2441 14.6186L16.3691 22.4936C16.2049 22.6578 15.9822 22.75 15.75 22.75C15.5178 22.75 15.2951 22.6578 15.1309 22.4936C14.9668 22.3294 14.8745 22.1067 14.8745 21.8745C14.8745 21.6423 14.9668 21.4196 15.1309 21.2554L21.513 14.8745H4.375C4.14294 14.8745 3.92038 14.7823 3.75628 14.6182C3.59219 14.4541 3.5 14.2316 3.5 13.9995C3.5 13.7674 3.59219 13.5449 3.75628 13.3808C3.92038 13.2167 4.14294 13.1245 4.375 13.1245H21.513L15.1309 6.74357C14.9668 6.57939 14.8745 6.3567 14.8745 6.12451C14.8745 5.89232 14.9668 5.66963 15.1309 5.50545C15.2951 5.34126 15.5178 5.24902 15.75 5.24902C15.9822 5.24902 16.2049 5.34126 16.3691 5.50545L24.2441 13.3804C24.3254 13.4617 24.39 13.5582 24.434 13.6644C24.478 13.7707 24.5007 13.8845 24.5007 13.9995C24.5007 14.1145 24.478 14.2284 24.434 14.3346C24.39 14.4408 24.3254 14.5373 24.2441 14.6186Z" fill="#F5F7F2"/>
</svg>
</SvgIcon>

export default function CustomButton({ title, href='/our-stories' }: CustomButtonProps) {
  return (
    <Link
        underline="none"
          color="inherit">
          <RouterLink to={href} style={{ textDecoration: "none"}}>
    
      <Button
        variant="contained"
        sx={{
          display: 'flex',
          padding: '8px 30px 8px 30px',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'stretch',
          borderRadius: '4px',
          height: '48px',
          "&:hover": {
            bgcolor: "primary.light",
            boxShadow: 'none',
          },
          boxShadow: 'none',
        }}
        endIcon={rightIcon}
        fullWidth
      >
        {title}
      </Button>
      </RouterLink>
        </Link>
  );
}
