export interface OpenCareers {
    title: string;
    careers: OpenCareer[];
}

export interface OpenCareer {
    title: string;
    deadline: string;
    description: string;
    skills: string[];
    stack: string[];
    requirements: string[];
    responsibilities: string[];
    compensation: string[];
}

const requirementItems = [
    "You have expert knowledge of Node.js and at least one other server side language.",
    "You’re an expert in JavaScript/TypeScript syntax",
    "You’re a database guru! You know the ins and outs of SQL and NoSQL, mainly PostgreSQL and ORM libraries (such as Sequelize)",
    "You have a good understanding of AWS Services such as S3, EKS, SNS and SQS",
    "You have experience with serverless app architecture on AWS",
    "You have at least 8 years of experience as a Software Engineer",
    "You are able to advise on best practices and be a thought leader in your field"
]
const responsibilityItems = [
    "Be a part of a truly cross-functional team; Frontend Engineers, Product Teams, Designers and QA’s",
    "Participate in product planning (From discovery all the way to deployment); We want your input at all stages of the Software Development Lifecycle!",
    "Collaborate! Our teams work together to develop robust new features, API’s, continuously improve our industry-leading products and find and fix any bugs at Deel Speed",
    "Ensure our customers receive a white-glove experience with our products"
]
const compensationItems = [
    "Computer equipment applicable to your role",
    "Stock grant opportunities",
    "Additional perks and benefits based upon your employment status and country",
    "The ability to choose where you work whether it be your home, the beach, or a WeWork"
]

export const sampleOpenCareers: OpenCareers[] = [
    {
        title: "Tech",
        careers: [
            {
                title: "Tech Lead",
                deadline: "22nd March 2024",
                description: "Leja is a global team that helps businesses hire anyone, anywhere, easily. Leja consists of more than three thousand self-driven individuals spanning over 100 countries. Our unified yet diverse culture keeps us continually learning and innovating the Leja platform and our products for customers.",
                skills: ["Visual design", "User interface design", "usability Research & testing", "Prototyping"],
                stack: ["Figma", "Adobe creative suite", "Microsoft office", "Google Office Suite"],
                requirements: requirementItems,
                responsibilities: responsibilityItems,
                compensation: compensationItems
            },
            {
                title: "Quality Assurance Engineer",
                deadline: "22nd March 2024",
                description: "Leja is a global team that helps businesses hire anyone, anywhere, easily. Leja consists of more than three thousand self-driven individuals spanning over 100 countries. Our unified yet diverse culture keeps us continually learning and innovating the Leja platform and our products for customers.",
                skills: ["Visual design", "User interface design", "usability Research & testing", "Prototyping"],
                stack: ["Figma", "Adobe creative suite", "Microsoft office", "Google Office Suite"],
                requirements: requirementItems,
                responsibilities: responsibilityItems,
                compensation: compensationItems
            },
            {
                title: "Mid-level FrontEnd Engineer",
                deadline: "22nd March 2024",
                description: "Leja is a global team that helps businesses hire anyone, anywhere, easily. Leja consists of more than three thousand self-driven individuals spanning over 100 countries. Our unified yet diverse culture keeps us continually learning and innovating the Leja platform and our products for customers.",
                skills: ["Visual design", "User interface design", "usability Research & testing", "Prototyping"],
                stack: ["Figma", "Adobe creative suite", "Microsoft office", "Google Office Suite"],
                requirements: requirementItems,
                responsibilities: responsibilityItems,
                compensation: compensationItems
            }
        ]
    },

    {
        title: "Product",
        careers: []
    },
    {
        title: "Sales & Marketing",
        careers: []
    },
    {
        title: "Customer support",
        careers: []
    },
    {
        title: "Operations",
        careers: []
    }
]