import Box from "@mui/material/Box";
import {Grid, List, ListItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {OpenCareer} from "../../models/open-career";

const AboutRole = ({career}: {career: OpenCareer}) => {
    const SkillItem = ({label}: {label: string}) => {
        return (
            <Box sx={{
                bgcolor: "#FFBDD4",
                color: "#64002C",
                borderRadius: '4px',
                textTransform: 'uppercase',
                padding: '10px 14px',
            }}>
                <Typography sx={{
                    fontWeight: 500,
                    fontSize: '15px'
                }}>
                    {label}
                </Typography>
            </Box>
        )
    }

    const RequirementsList = ({items}: {items: string[]}) => {
        return (
            <List sx={{ listStyleType: 'disc' }}>
                {items.map((item, index) => (
                    <ListItem key={index}>
                        <Typography sx={{fontWeight: 300, fontSize: '16px', display: 'list-item'}}>
                            {item}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        )
    }


    return (
    <>
        {/* Deadline */}
        <Box sx={{
            backgroundColor: "#FFF",
            padding: '18px',
            borderRadius: '10px',
            marginTop: '15px',
            marginBottom: '15px'
        }}>
            <Grid container spacing={3} sx={{alignItems: 'center'}}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography sx={{fontWeight: 500, fontSize: '21px', textTransform: 'uppercase'}}>
                            Deadline
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography sx={{fontWeight: 500, fontSize: '16px'}}>
                            {career.deadline}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

        { /* Job Description */ }
        <Box sx={{
            backgroundColor: "#FFF",
            padding: '18px',
            borderRadius: '10px',
            marginTop: '15px',
            marginBottom: '15px'
        }}>
            <Grid container spacing={3} sx={{alignItems: 'normal'}}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography sx={{fontWeight: 500, fontSize: '21px', textTransform: 'uppercase'}}>
                            Description
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography sx={{fontWeight: 300, fontSize: '16px'}}>
                            {career.description}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>

        { /* Skills */ }
        <Box sx={{
            backgroundColor: "#FFF",
            padding: '18px',
            borderRadius: '10px',
            marginTop: '15px',
            marginBottom: '15px'
        }}>
            <Grid container spacing={3} sx={{alignItems: 'normal'}}>
                <Grid item xs={12} md={6}>
                    <Typography sx={{fontWeight: 400, fontSize: '24px'}}>
                        Skills
                    </Typography>
                    <Box sx={{
                        marginTop: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        {career.skills.map((skill, index) => (
                            <SkillItem key={index} label={skill} />
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{fontWeight: 400, fontSize: '24px'}}>
                        Stack/Software
                    </Typography>
                    <Box sx={{
                        marginTop: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                        {career.stack.map((skill, index) => (
                            <SkillItem key={index} label={skill} />
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </Box>

        { /* Requirements */ }
        <Box sx={{
            backgroundColor: "#FFF",
            padding: '18px',
            borderRadius: '10px',
            marginTop: '15px',
            marginBottom: '15px'
        }}>
            <Grid container spacing={3} sx={{alignItems: 'normal'}}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography sx={{fontWeight: 500, fontSize: '21px', textTransform: 'uppercase'}}>
                            Requirements
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <RequirementsList items={career.requirements} />
                </Grid>
            </Grid>
        </Box>

        { /* Responsibilities */ }
        <Box sx={{
            backgroundColor: "#FFF",
            padding: '18px',
            borderRadius: '10px',
            marginTop: '15px',
            marginBottom: '15px'
        }}>
            <Grid container spacing={3} sx={{alignItems: 'normal'}}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography sx={{fontWeight: 500, fontSize: '21px', textTransform: 'uppercase'}}>
                            Responsibilities
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <RequirementsList items={career.responsibilities} />
                </Grid>
            </Grid>
        </Box>

        { /* Compensation */ }
        <Box sx={{
            backgroundColor: "#FFF",
            padding: '18px',
            borderRadius: '10px',
            marginTop: '15px',
            marginBottom: '15px'
        }}>
            <Grid container spacing={3} sx={{alignItems: 'normal'}}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography sx={{fontWeight: 500, fontSize: '21px', textTransform: 'uppercase'}}>
                            Compensation
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <RequirementsList items={career.compensation} />
                </Grid>
            </Grid>
        </Box>
    </>
    )
}

export default AboutRole;