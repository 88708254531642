import { Typography } from '@mui/material'
import React from 'react'

const CreditReferenceBureaus = () => {
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        <ol style={{ margin: 0 }}>
            <li>You hereby expressly authorise LEJA and its Lending Partners to solicit, query, search, conduct, carry out and perform credit checks with, and/or obtain the Customer’s credit information, or such other information, from any or all licensed Credit Reference Bureaus, with respect to this Agreement, and/or LEJA maintaining a Loan for you.</li>
            <li>You hereby expressly consent, allow and authorise LEJA and its Lending Partners to disclose, advise, exchange and communicate the details pertaining to your Loan to Credit Reference Bureaus (CRBs) and other Credit Providers.</li>
            <li>You authorise LEJA and its Lending Partners to obtain any information that it may require relating to the Loan from credit providers, and from any other source to which LEJA may apply, each source being hereby authorised by you to provide LEJA with such information.</li>
            <li>You hereby authorise LEJA’s lending partners to list you with the Credit Reference Bureau in case of default and/or non-performing loan.</li>
        </ol>
    </Typography>
  )
}

export default CreditReferenceBureaus