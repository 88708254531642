import { Typography } from '@mui/material'
import React from 'react'

const Termination = () => {
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        <ol style={{ margin: 0 }}>
            <li>
                LEJA shall not be obliged to advance the Loan or, where the Loan has been advanced, may demand immediate payment of all amounts owed under this Agreement in the event that:
                <ol>
                    <li>You fail to pay any sum payable hereunder on the Repayment Date.</li>
                    <li>You fail to perform and/or observe any of your obligations under the Agreement.</li>
                    <li>LEJA has reason to believe that you are unlikely to repay the facility</li>
                </ol>
            </li>
            <li>LEJA shall at any time, upon notice to you, terminate or vary its business relationship with you and cease to offer you the Services and in particular but without prejudice to the generality of the foregoing LEJA may cancel the Credit Limit which it has granted and require the repayment of outstanding debts resulting from such credits within such time as LEJA may determine.</li>
            <li>
                Without prejudice to LEJA’s rights under clause 10(a), LEJA may at its sole discretion suspend or close your access to the Services and the Leja Platform where:
                <ol>
                    <li>If you use the Leja Platform for unauthorised purposes or where LEJA detects any abuse/misuse, breach of content, fraud or attempted fraud relating to your use of the Services;</li>
                    <li>If your Mobile Money Account or agreement with a Mobile Network Operator is terminated for whatever reason;</li>
                    <li>LEJA is required or requested to comply with an order or instruction of or a recommendation from the government, court, regulator or other competent authority;</li>
                    <li>LEJA reasonably suspects or believes that you are in breach of these Terms and Conditions (including non-payment of any Loan amount due from you where applicable) which you fail to remedy (if remediable) after the service of notice by email, SMS or other electronic means requiring you to do so;</li>
                    <li>If such a suspension, closure or termination is necessary as a consequence of technical problems or for reasons of safety; to facilitate update or upgrade the contents or functionality of the Services on the LEJA Platform from time to time;</li>
                    <li>If you have, in the opinion of LEJA become inactive or dormant in the use of the Leja Platform or the Services;</li>
                    <li>LEJA decides to suspend or cease the provision of the Services for commercial reasons or for any other reason as it may determine in its absolute discretion; or</li>
                    <li>If LEJA receives notice of your demise. In this instance, any loan or dues owing shall be immediately offset against any money in the E-Wallet.</li>
                </ol>
            </li>
            <li>Without prejudice to the foregoing, termination shall not affect any accrued rights and liabilities of either party at the point of termination;</li>
            <li>In the event that LEJA receives notice of your demise, LEJA will not be obliged to allow any operation or access to the Services or accept Requests through your account by any person except upon production of administration letters from a competent court and/or confirmed grant of letters of administration or confirmed grant of Probate or Administration by your legal representatives duly appointed by a court of competent jurisdiction.</li>
            <li>Without prejudice to the foregoing, upon termination, you hereby consent to LEJA retaining Transactional records and your personal information for a minimum of Seven (7) years during which period the information may be used in the manner consented to on this agreement.</li>
        </ol>
    </Typography>
  )
}

export default Termination