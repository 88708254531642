import { Button, Grid, Typography, useMediaQuery } from "@mui/material"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Theme } from '@mui/material/styles';
import {useEffect, useState} from "react";
import BlogSummaryCard from "./BlogSummaryCard";
import {lejaBlogs} from "../../models/leja-blog";

const BlogMainPage = () => {
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [showMoreBlogs, setShowMoreBlogs] = useState(false);
    const [blogs, setBlogs] = useState(lejaBlogs.slice(0, 4));

    const handleShowMoreClick = () => {
        setShowMoreBlogs(!showMoreBlogs);
    };

    useEffect(() => {
        if (showMoreBlogs) {
            setBlogs(lejaBlogs);
        } else {
            setBlogs(lejaBlogs.slice(0, 4));
        }
    }, [showMoreBlogs]);


    return (
        <>
            <Grid container
                spacing={(isMobile ? '8px' : isTablet ? '24px' : '1.875vw')}
                sx={{
                    mb: { md: '24px', sm: '24px', xs: '16px' },
                    alignSelf: 'stretch'
                }}>
                { blogs.map((blog, index) => (
                    <BlogSummaryCard key={index} blog={blog}></BlogSummaryCard>
                ))}
            </Grid>

            <Button
                onClick={handleShowMoreClick}
                variant="contained"
                sx={{
                    bgcolor: '#F4EEE3',
                    width: '100%',
                    padding: '0.5vw 1.875vw',
                    textAlign: 'center',
                    justifyContent: 'center',
                    "&:hover": {
                        bgcolor: "#FFF",
                        boxShadow: 'none'
                    },
                    boxShadow: 'none',
                }}
            >
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    color: 'primary.main'
                }}>
                    {showMoreBlogs ? 'View less' : 'View more'}
                </Typography>
                <ArrowForwardIcon sx={{
                    width: '28px',
                    height: '28px',
                    color: 'primary.main',
                }} />
            </Button>
        </>
    )
}

export default BlogMainPage