import React from 'react';
import { Box, Typography, Button, Grid } from "@mui/material";
import UssdServices from './USSD/UssdServices';
import Loans from './Loans/Loans';
import AboutLeja from './CombinedAbout/AboutLeja';
import Transacting from './Transacting/Transacting';
import Accounts from './AccountSettings/Accounts';
import Records from './BookKeeping/Records';

const FaqsTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
     
    }}>
      {/* <Grid container sx={{ml:{md:'25vw'}}}> */}
      <Grid container spacing={3} sx={{pl:{md:'25.5vw', sm:'20px', xs:'16px'},pr:{md:'20px', sm:'20px', xs:'16px'},
      '@media only screen and (min-width:1800px)':{pl:'25vw'}
    }}>
       
        {/* Tab 1 */}
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant='contained'
            onClick={() => handleChange(0)}
            sx={{
              // width: { xs: '41.6vw', sm: '28.873vw', md: '8.875vw' },
              width: '100%',
              height: '48px',
              color: 'secondary.main',
              bgcolor: value === 0 ? 'secondary.light' : "#F5F7F2",
              '&:hover': {
                bgcolor: value === 0 ? 'secondary.light' : "#F5F7F2",
              },
              boxShadow: 'none',
              borderRadius: '4px',
              border:'none',
              outline: 'none',
              padding:'5px 0px'
             
              
            }}
          >
            <Typography sx={{
             fontSize: '16px',
             fontStyle: 'normal',
             fontWeight: 500,
             lineHeight: '120%',
             textTransform: 'uppercase'
            }}>About leja</Typography>
          </Button>
        </Grid>
        {/* Tab 2 */}
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant='contained'
            onClick={() => handleChange(1)}
            sx={{
              // width: { xs: '41.6vw', sm: '28.873vw', md: '8.875vw' },
              width: '100%',
              height: '48px',
              color: 'secondary.main',
              bgcolor: value === 1 ? 'secondary.light' : "#F5F7F2",
              '&:hover': {
                bgcolor: value === 1 ? 'secondary.light' : "#F5F7F2",
              },
              boxShadow: 'none',
              borderRadius: '4px',
              border:'none',
              outline: 'none',
              padding:'5px 0px' 
            }}
          >
            <Typography sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '120%',
              textTransform: 'uppercase'
            }}>transacting</Typography>
          </Button>
        </Grid>
        {/* Tab 3 */}
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant='contained'
            onClick={() => handleChange(2)}
            sx={{
              // width: { xs: '41.6vw', sm: '28.873vw', md: '8.875vw' },
              width: '100%',
              height: '48px',
              color: 'secondary.main',
              bgcolor: value === 2 ? 'secondary.light' : "#F5F7F2",
              '&:hover': {
                bgcolor: value === 2 ? 'secondary.light' : "#F5F7F2",
              },
              boxShadow: 'none',
              borderRadius: '4px',
              border:'none',
              outline: 'none', 
              padding:'5px 0px'
            }}
          >
            <Typography sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '120%',
              textTransform: 'uppercase'
            }}>bookkeeping</Typography>
          </Button>
        </Grid>
        {/* Tab 4 */}
        {/* <Grid item xs={6} sm={4} md={2}>
          <Button
            variant='contained'
            onClick={() => handleChange(3)}
            sx={{
              // width: { xs: '41.6vw', sm: '28.873vw', md: '8.875vw' },
              width: '100%',
              height: '48px',
              color: 'secondary.main',
              bgcolor: value === 3 ? 'secondary.light' : "#F5F7F2",
              '&:hover': {
                bgcolor: value === 3 ? 'secondary.light' : "#F5F7F2",
              },
              boxShadow: 'none',
              borderRadius: '4px',
              border:'none',
              outline: 'none', 
              padding:'5px 0px'
            }}
          >
            <Typography sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '120%',
              textTransform: 'uppercase'
            }}>loans</Typography>
          </Button>
        </Grid> */}
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant='contained'
            onClick={() => handleChange(4)}
            sx={{
              // width: { xs: '41.6vw', sm: '28.873vw', md: '12.875vw' },
              width: '100%',
              height: '48px',
              color: 'secondary.main',
              bgcolor: value === 4 ? 'secondary.light' : "#F5F7F2",
              '&:hover': {
                bgcolor: value === 4 ? 'secondary.light' : "#F5F7F2",
              },
              boxShadow: 'none',
              borderRadius: '4px',
              border:'none',
              outline: 'none', 
            }}
          >
            <Typography sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '120%',
              textTransform: 'uppercase'
            }}>ussd service</Typography>
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant='contained'
            onClick={() => handleChange(5)}
            sx={{
              // width: { xs: '41.6vw', sm: '28.873vw', md: '12.875vw' },
              width: '100%',
              height: '48px',
              color: 'secondary.main',
              bgcolor: value === 5 ? 'secondary.light' : "#F5F7F2",
              '&:hover': {
                bgcolor: value === 5 ? 'secondary.light' : "#F5F7F2",
              },
              boxShadow: 'none',
              borderRadius: '4px',
              border:'none',
              outline: 'none',
              padding:'5px 0px'
              
            }}
          >
            <Typography sx={{
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '120%',
              textTransform: 'uppercase'
            }}>
            account settings
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
       
        {value === 0 && <AboutLeja />}
        {value === 1 && <Transacting />}
        {value === 2 && <Records />} 
        {value === 3 && <Loans />}
        {value === 4 && <UssdServices />}  
        {value === 5 && <Accounts />} 
      </Box>
    </Box>
  );
}

export default FaqsTabs;
