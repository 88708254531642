import { Box, Typography } from '@mui/material'
import OurStoriesTabs from './OurStoriesTabs'

const OurStoriesComp = () => {
    return (
        <>
            <Box sx={{
                ml: { md: '30px', sm: '24px', xs: '16px' },
                mr: { md: '30px', sm: '24px', xs: '16px' },
                mt: { md: '140px', sm: '123px', xs: '60px' },
                mb: { md: '140px', sm: '100px', xs: '108px' },
            }}>
                <Box sx={{
                    display: {md:'flex', sm:'block', xs:'block'},
                    flexDirection: {md:'row'},
                    mb: { md: '20px', sm: '20px', xs: '16px' }
                }}>
                    <Typography sx={{
                        width: {md:'296px', sm:'471px', xs:'100%'},
                        fontSize: {md:'0.875vw', sm:'1.823vw', xs:'3.889vw'},
                        fontWeight: 400,
                        textTransform: 'uppercase',
                        mb: {md:'auto', sm:'16px', xs:'12px'},
                        mr: { md: '6vw', sm:'none', xs:'none'},
                        ml: {sm:'auto', md:'none', xs:'none'},
                        mt: '8px'
                    }}>
                        products on our platform give people and businesses quick access to funds. We also build and operate savings products that bear interest.
                    </Typography>
                    <Typography sx={{
                        fontSize: { md: '3.125vw', sm: '6.510vw', xs: '8.333vw' },
                        fontWeight: 500,
                        lineHeight: '100%',
                        letterSpacing: '-1.5px',
                        textTransform: 'uppercase',
                        width: {md:'1147px', sm:'100%', xs:'100%'}
                    }}>
                        Whereas you may have had to use written records in the past, Leja now allows you to access and manage all your business finances anytime and anywhere.
                    </Typography>
                </Box>
                <Box>
                    <OurStoriesTabs />
                </Box>
            </Box>
        </>
    )
}

export default OurStoriesComp