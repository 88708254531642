import {Box, Grid, Typography} from '@mui/material'
import SharePost from './SharePost'
import Blog from '../Blog/Blog'
import BlogHero from '../Blog/BlogHero'
import React from "react";
import dataProtection from '../../assets/Blog/data_protection.png'

const DataProtection = () => {

    return (
        <>
            <BlogHero imageUrl={dataProtection}
                heading=""
                text=""
                date=""
            />
            <Box
                  sx={{
                      mt: '40px',
                      ml: '32px',
                      mr: '32px',
                      display: 'flex',
                  }}
            >
                <Box sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block'
                    },
                    minWidth: {
                        md: '200px',
                        lg: '300px',
                        xl: '350px'
                    },
                    maxWidth: {
                        md: '300px',
                        lg: '400px',
                        xl: '500px'
                    }
                }}>
                </Box>
                <Grid item sm={12} md={5}>
                    <Typography
                        sx={{
                            color: '#012317',
                            fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                            lineHeight: '100%',
                            letterSpacing: '-1.5px',
                            fontWeight: 500,
                            fontStyle: 'normal',
                            textTransform: 'uppercase',
                            mb: { md: '30px', sm: '30px', xs: '16px' }
                        }}>
                        A guide for Leja Customers on Data Privacy and Protection
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Grid container rowSpacing={3} sx={{
                            color: "#012317",
                            fontWeight: 300,
                            lineHeight: '130%',
                            fontSize: '1vw'
                        }}>

                            <p>In our digital world, information is the currency of the age, making data privacy and
                                protection a paramount concern for anyone navigating the online space.
                                At Leja, we understand the importance of keeping our customers' data safe and secure.
                                With Kenya's strong Data Protection Act and regulations in mind, we want to share how
                                Leja prioritizes your data privacy and offer some practical tips for safeguarding your
                                information as a customer.</p>

                            <h2>Understanding Data Privacy and Protection in Kenya:</h2>

                            <p>Data privacy refers to your right to control your personal information, including who can
                                access it and how it's used , and for what purpose under the Kenyan Data Protection Act.
                                Data protection involves the technical and procedural measures organizations, like Leja,
                                take to safeguard this information, as outlined by the <a href="https://www.odpc.go.ke/">Office of the Data Protection
                                    Commissioner</a> (ODPC). And here at Leja we ensure that every piece of information
                                collected from our customers is specifically used for its intended purpose.</p>

                            <h2>Why is Data Privacy Important?</h2>

                            <p>Data breaches can have serious consequences, exposing sensitive information such as
                                financial records, leading to identity theft and financial loss. Data privacy empowers
                                you to protect your data and control how your information as a customer is used,
                                protecting you from targeted advertising and potential discrimination.
                                At Leja, our core commitment to data privacy is overseen by our Data Protection Officer
                                (DPO), who engages our team on all aspects of data privacy, including security best
                                practices in handling customer data.</p>

                            <h2>Key Data Fraudsters Target:</h2>

                            <p>Cybercriminals often target Personal Identifiable Information (PII) such as names,
                                addresses, national ID numbers, and Mobile Money details like PINs. This data can be
                                sold on the dark web, where other cybercriminals can use it for impersonation or commit
                                fraudulent transactions. Financial records and browsing history are also valuable
                                targets for fraudsters. To stay safe, avoid using public Wi-Fi when carrying out
                                financial transactions, as this can expose your information to cybercriminals.</p>

                            <h2>Scenarios that lead to Data Loss, Breaches, or Theft:</h2>

                            <ul>
                                <li>Phishing Attacks such as sending emails and making phone calls (Vishing) where
                                    cybercriminals pretend to be a legitimate source of communication and request or
                                    sensitive data such as PII rom customers
                                </li>
                                <li>Unsecured Wi-Fi networks such as free Wi-Fi networks. These networks may be created
                                    by cyber criminals with the intention of obtaining sensitive information from a
                                    customer such as their PIN when making financial transactions over the network among
                                    other information.
                                </li>
                                <li>Malicious Software (Malware) infected downloads where users unintentionally download
                                    malware online. These software are often disguised as free software (Freeware) or
                                    games that function as intended but harvest sensitive information in the background.
                                </li>
                                <li>Physical loss of your devices such as mobile phones can also lead to data breaches
                                    and loss as perpetrators may be able to access your device and obtain sensitive data
                                    that they may leak online on different online forums.
                                </li>
                            </ul>

                            <h2>Kenya's Data Protection Framework</h2>

                            <p>The Data Protection Act (2019) and its accompanying regulations have established clear
                                guidelines for data collection, storage, and usage, hence empowering individuals to have
                                control over their information and give you the right to access, rectify, or erase their
                                data. At Leja, we strictly comply with these set guidelines. Leja is licensed both as a
                                Data Processor (allowed to process information on behalf of other institutions) and Data
                                Controller ( allowed to process information collected from our customers). Leja has put
                                all the controls in place to protect customers information as per the guidelines
                                provided by ODPC.</p>

                            <h2>Protecting Your Data: Best Practices:</h2>

                            <ul>
                                <li>Use strong, unique passwords and enable two-factor authentication.</li>
                                <li>Be cautious about what information you share online.</li>
                                <li>Beware of suspicious emails and links.</li>
                                <li>Keep software updated and use security software such as anti malware tools to detect
                                    any harmful software on your devices.
                                </li>
                                <li>Be mindful of using public Wi-Fi networks an use a Virtual Private Network (VPN)
                                    when connected to it
                                </li>
                                <li>Avoid making financial transactions and any other sensitive activities over public
                                    Wi-Fi. It is recommended to perform such activities using your mobile data or a
                                    Wi-Fi hotspot that has been created using your phone’s mobile data.
                                </li>
                            </ul>

                            <p>If you have any questions, please don't hesitate to contact us on <a
                                href="mailto: support@leja.co.ke"> support@leja.co.ke</a></p>
                        </Grid>
                    </Box>
                    <Box sx={{
                        display: {md: 'inline-flex', sm: 'flex', xs: 'flex'},
                        alignItems: 'center',
                        // flexDirection:{md:'row', sm:'row', xs:'column'},
                        // justifyContent: 'center',
                        gap: '17px',
                        // mt: '60px',

                    }}>
                        <Typography sx={{
                            color: 'primary.main',
                            fontSize: {xs: '16px', md: '1rem'},
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: '130%',
                            marginRight: '17px',

                        }}>Share this post:</Typography>
                        <SharePost/>
                    </Box>
                </Grid>
                <Box sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block'
                    },
                    minWidth: {
                        md: '200px',
                        lg: '300px',
                        xl: '350px'
                    },
                    maxWidth: {
                        md: '300px',
                        lg: '400px',
                        xl: '500px'
                    }
                }}>
                </Box>
            </Box>
            <Blog title='MORE NEWS' subtitle='[NEWS AND INSIGHTS]'/>
        </>
    );
};

export default DataProtection