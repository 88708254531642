import { Box, Typography, Grid, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles';
import Tabs from './Tabs'

const BlogMainHeading = () => {
  return (
    <>
      <Grid container sx={{
        pl: '1.875vw',
        display: {md:'flex', xs:'none'},
        mb: '16px',
      }}>
        <Grid item md={6}>
          <Box sx={{width:'39.875vw'}}>
            <Typography sx={{
              color: '#012317',
              fontSize: { md: '4.688vw' },
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '100%',
              letterSpacing: { md: '-0.094vw', sm: '-1.5px', xs: '-2px' },
              textTransform: 'uppercase',
            }}>news, Insights & resources
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box sx={{width:'21vw'}}>
            <Typography
              sx={{
                width: { md: '22.75vw' },
                fontSize: { md: '0.875vw' },
                fontStyle: 'normal',
                fontWeight: 400,
                mt: 'auto',
                lineHeight: 'normal',
                textTransform: 'uppercase',
                '@media only screen and (min-width: 900px)': {
                  mt: '4.688vw'
                }
              }}>
              With more than 100 full-time experts trained in leja,
              and many years of building expertise and growing with our clients,
              we’re happy to share what we’ve learned. Feel free to browse our industry insights.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{
        display: {md:'none', xs:'flex'},
        flexDirection: { sm: 'column', xs: 'column' },
        m: { sm: '16px 24px 20px 24px', xs: '16px 16px 12px 16px' }
      }}>
        <Box
          sx={{
            mr: { sm: '24px', xs: '16px' },
            mb: { xs: '16px', sm: '16px'},
            width: { sm: '638px', xs: '328px' },
          }}>
          <Typography sx={{
            color: '#012317',
            fontSize: { sm: '75px', xs: '11.11vw' },
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '100%',
            letterSpacing: { sm: '-1.5px', xs: '-2px' },
            textTransform: 'uppercase',
          }}>
            news, Insights & resources
          </Typography>
        </Box>
        <Box sx={{
          '@media only screen and (min-width:600px) and (max-width: 900px)': {
            ml: 'auto',
          },
          width: { sm: '471px', xs: 'auto' },
        }}>
          <Typography
            sx={{
              fontSize: { sm: '1.823vw', xs: '3.7vw' },
              fontStyle: 'normal',
              fontWeight: 400,
              mt: 'auto',
              lineHeight: 'normal',
              textTransform: 'uppercase',
              '@media only screen and (min-width: 900px)': {
                mt: '4.688vw'
              }
            }}>
            With more than 100 full-time experts trained in leja,
            and many years of building expertise and growing with our clients,
            we’re happy to share what we’ve learned. Feel free to browse our industry insights.
          </Typography>
        </Box>
      </Box>
      <Box sx={{
        ml: { md: '1.875vw', sm: '24px', xs: '16px' },
        mr: { md: '1.875vw', sm: '24px', xs: '16px' },
      }}>
        <Tabs />
      </Box>
    </>
  )
}

export default BlogMainHeading