import React from 'react'
import MyAccount from './MyAccount'
import AccountDeletion from './AccountDeletion'
import Referrals from './Referrals'
import AccountRecovery from './AccountRecovery'
import Support from './Support'
import Review from './Review'

const Accounts = () => {
  return (
    <div>
        <MyAccount />
        <AccountDeletion />
        <AccountRecovery />
        <Referrals />
        <Review />
        <Support />
    </div>
  )
}

export default Accounts