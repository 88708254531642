import { Box, Button, Link, Typography, useMediaQuery } from "@mui/material";
import AsilimiaShape from '../../assets/AsilimiaShape.svg'
import Group_394 from '../../assets/Group_394.svg'
import AppMockup from '../../assets/appmockup.png'
import { useTheme } from '@mui/material/styles';
import { playStoreLink } from "../utils/GetTheAppMobile/GetTheAppMobile";


const TopFooter = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <>
            {isMobile && (
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: 'auto',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: '0'
                    }}>
                        <Box sx={{
                            mt: '16.67vw',
                            ml: '22vw',
                            mr: '26.11vw',
                            mb: '-4vw'
                        }}>
                            <img
                                src={AsilimiaShape}
                                style={{ width: '55vw', height: '55vw' }}
                                alt="Asilimia Shape"
                                loading={'lazy'}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                            mb: '8.33vw',
                        }}>
                            <Box sx={{
                                width: '91.11vw',
                                height: 'auto',
                                mb: '6.67vw',
                                mr: '4.44vw',
                                ml: '4.44vw'
                            }}>
                                <Typography sx={{
                                    fontSize: '11.11vw',
                                    fontWeight: 600,
                                    lineHeight: '100%',
                                    letterSpacing: '-0.56vw',
                                    textTransform: 'uppercase',
                                    textAlign: 'center'
                                }}>
                                    WE ARE THE FUTURE. NOW.
                                </Typography>
                            </Box>
                            <Link underline="none"
                                href={playStoreLink}
                                target="_blank"
                                rel="noopener">
                                <Button
                                    variant='contained'
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textTransform: 'uppercase',
                                        width: '52.22vw',
                                        height: '48px',
                                        padding: '1.04vw 3.9vw',
                                        alignItems: 'center',
                                        "&:hover": {
                                            bgcolor: "primary.light",
                                            boxShadow: 'none',
                                        },
                                        boxShadow: 'none',
                                        ml: '23.89vw',
                                        mr: '23.89vw'
                                    }}
                                >
                                    <Typography sx={{
                                        color: '#F5F7F2',
                                        mr: '2.22vw',
                                        fontSize: '4.44vw',
                                        fontWeight: 500
                                    }}>
                                        Get the app
                                    </Typography>
                                    <img
                                        src={Group_394}
                                        style={{
                                            width: '20px',
                                            height: '20px',
                                        }}
                                        alt="playstore logo"
                                        loading={'lazy'}
                                    />
                                </Button>
                            </Link>
                        </Box>
                        <Box sx={{
                            mb: '-2vw',
                        }}>
                            <img
                                src={AppMockup}
                                alt="phone mockup"
                                style={{
                                    width: '94.44vw',
                                    height: '102.22vw',
                                    marginRight: '2.78vw',
                                    marginLeft: '2.78vw'
                                }}
                                loading={'lazy'}
                            />
                        </Box>
                    </Box>
                </>
            )}
            {(isTablet && !isMobile) && (
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: 'auto',
                        justifyContent: 'center',
                        alignContent: 'center',
                        padding: '0'
                    }}>
                        <Box sx={{
                            mt: '13.02vw',
                            ml: '35vw',
                            mr: '37vw',
                        }}>
                            <img
                                src={AsilimiaShape}
                                style={{ width: '30vw', height: '30vw' }}
                                alt="Asilimia Shape"
                                loading={'lazy'}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                            mb: '5.2vw',
                        }}>
                            <Box sx={{
                                width: '93.75vw',
                                height: 'auto',
                                mb: '3.9vw',
                                mr: '3.125vw',
                                ml: '3.125vw'
                            }}>
                                <Typography sx={{
                                    fontSize: '9.77vw',
                                    fontWeight: 700,
                                    lineHeight: '100%',
                                    letterSpacing: '-0.14vw',
                                    textTransform: 'uppercase',
                                    textAlign: 'center'
                                }}>
                                    WE ARE THE FUTURE. NOW.
                                </Typography>
                            </Box>
                            
                            <Link underline="none"
                                href={playStoreLink}
                                target="_blank"
                                rel="noopener">
                            <Button
                                variant='contained'
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textTransform: 'uppercase',
                                    width: '28.65vw',
                                    height: '48px',
                                    padding: '1.04vw 3.9vw',
                                    alignItems: 'center',
                                    "&:hover": {
                                        bgcolor: "primary.light",
                                        boxShadow: 'none',
                                    },
                                    boxShadow: 'none',
                                    ml: '35.68vw',
                                    mr: '35.68vw'
                                }}
                            >
                                <Typography sx={{
                                    color: '#F5F7F2',
                                    mr: '1.04vw',
                                    fontSize: '2.08vw',
                                    fontWeight: 500
                                }}>
                                    Get the app
                                </Typography>
                                <img
                                    src={Group_394}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                    alt="playstore logo"
                                    loading={'lazy'}
                                />
                            </Button>
                            </Link>
                        </Box>
                        <Box sx={{
                            mb: '-1vw',
                        }}>
                            <img
                                src={AppMockup}
                                alt="phone mockup"
                                style={{
                                    width: '44.27vw',
                                    height: '47.91vw',
                                    marginLeft: '27.86vw',
                                    marginRight: '27.86vw',
                                }}
                                loading={'lazy'}
                            />
                        </Box>
                    </Box>
                </>
            )}
            {!(isMobile || isTablet) && (
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        height: 'auto'
                    }}>
                        <Box sx={{
                            ml: '7.81vw',
                            mr: '5.2vw',
                            mb: '7.5vw',
                        }}>
                            <img
                                src={AsilimiaShape}
                                style={{ width: '15vw', height: '15vw' }}
                                alt="Asilimia Shape"
                                loading={'lazy'}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mr: '5.94vw',
                            mb: '5vw'
                        }}>
                            <Typography sx={{
                                fontSize: '4.68vw',
                                fontWeight: 700,
                                lineHeight: '100%',
                                letterSpacing: '-0.14vw',
                                textTransform: 'uppercase',
                                width: '33.62vw',
                                height: 'auto',
                                mb: '1.5vw'
                            }}>
                                WE ARE THE FUTURE. NOW.
                            </Typography>

                            <Link underline="none"
                                href={playStoreLink}
                                target="_blank"
                                rel="noopener">
                            <Button
                                variant='contained'
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textTransform: 'uppercase',
                                    height: '48px',
                                    padding: '0vw 1.35vw 0vw 1.56vw',
                                    alignItems: 'center',
                                    "&:hover": {
                                        bgcolor: "primary.light",
                                        boxShadow: 'none',
                                    },
                                    boxShadow: 'none',
                                }}
                            >
                                <Typography sx={{
                                    color: '#F5F7F2',
                                    mr: '1vw',
                                    fontSize: '16px',
                                    fontWeight: 500
                                }}>
                                    Get the app
                                </Typography>
                                <img
                                    src={Group_394}
                                    style={{ width: '20px', height: '20px' }}
                                    alt="playstore logo"
                                    loading={'lazy'}
                                />
                            </Button>
                            </Link>
                        </Box>
                        <Box sx={{
                            mb: '-1vw',
                        }}>
                            <img
                                src={AppMockup}
                                alt="phone mockup"
                                style={{
                                    width: '21.25vw',
                                    marginRight: '9vw'
                                }}
                                loading={'lazy'}
                            />
                        </Box>
                    </Box>
                </>
            )}
        </>
    )
}

export default TopFooter