import {BlogContentProps} from "../components/BlogContent/BlogContentV2";
import sankalpin from "../assets/Blog/sankalpin.jpg"
import Jackson from "../assets/jackson.jpg";

export const sampleArticle: BlogContentProps = {
    heroHeading: "THE MAVERICK",
    heroImageUrl: sankalpin,
    heroText: "“I wanted to prove to my friends that anything is possible.”",
    heroDate: "20/01/24",
    contentHeading: "Longer/Actual Title of the article below.",
    content: [
        {
            headingLevel: 'h1',
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            heading: 'Section title',
            headingLevel: 'h1',
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            heading: 'Subsection title',
            headingLevel: 'h2',
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
            heading: 'Subsection title',
            headingLevel: 'h3',
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            isQuote: true
        },
        {
            imageUrl: sankalpin,
            caption: "“Quote about the image”",
            credits: "Name | Context e.g. location, title, image credit etc."
        },
        {
            imageUrl: Jackson,
            videoUrl: 'https://www.youtube.com/embed/HltrrS9uRkE',
            caption: "“Quote about the video”",
            credits: "Name | Context e.g. location, title, image credit etc."
        }
    ]
}

export const dataProtectioArticle: BlogContentProps = {
    heroHeading: "Data Protection",
    heroImageUrl: sankalpin,
    heroText: "A guide for Leja Customers on Data Privacy and Protection",
    heroDate: "20/01/24",
    contentHeading: "A guide for Leja Customers on Data Privacy and Protection",
    content: [
        {
            headingLevel: 'h1',
            content: "In our digital world, information is the currency of the age, making data privacy and protection a paramount concern for anyone navigating the online space."
        },
        {
            content: "At Leja, we understand the importance of keeping our customers' data safe and secure. "
        },
        {
            content: "With Kenya's strong Data Protection Act and regulations in mind, we want to share how Leja prioritizes your data privacy and offer some practical tips for safeguarding your information as a customer."
        },
        {
            heading: 'Understanding Data Privacy and Protection in Kenya',
            headingLevel: 'h2',
            content: "Data privacy refers to your right to control your personal information, including who can access it and how it's used , and for what purpose under the Kenyan Data Protection Act.",
        },
        {
            content: "Data protection involves the technical and procedural measures organizations, like Leja, take to safeguard this information, as outlined by the Office of the Data Protection Commissioner (ODPC). And here at Leja we ensure that every piece of information collected from our customers is specifically used for its intended purpose."
        },
        {
            heading: 'Why is Data Privacy Important?',
            headingLevel: 'h2',
            content: "Data breaches can have serious consequences, exposing sensitive information such as financial records, leading to identity theft and financial loss. Data privacy empowers you to protect your data and control how your information as a customer is used, protecting you from targeted advertising and potential discrimination. ",
        },
        {
            content: "At Leja, our core commitment to data privacy is overseen by our Data Protection Officer (DPO), who engages our team on all aspects of data privacy, including security best practices in handling customer data."
        },
        {
            heading: 'Key Data Fraudsters Target:',
            headingLevel: 'h2',
            content: "Cybercriminals often target Personal Identifiable Information (PII) such as names, addresses, national ID numbers, and Mobile Money details like PINs. This data can be sold on the dark web, where other cybercriminals can use it for impersonation or commit fraudulent transactions. Financial records and browsing history are also valuable targets for fraudsters. To stay safe, avoid using public Wi-Fi when carrying out financial transactions, as this can expose your information to cybercriminals.",
        },
    ]
}