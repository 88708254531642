import { Typography } from '@mui/material'
import React from 'react'

const MiscellaneousProvisions = () => {
    return (
        <Typography sx={{
            mb: '10px',
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
        }}>
            <ol style={{ margin: 0 }}>
                <li>No waiver by either party or any breach of any provision of this Agreement by either party shall be considered as a waiver of any subsequent breach of the same or any other provision.</li>
                <li>No failure on the part of any party to exercise, or delay on its part in exercising, any right, power or remedy provided by this Agreement or by law shall operate as a waiver thereof, nor shall any single or partial exercise of any such right, power or remedy preclude any further or other exercise of that, or any other, right, power or remedy.</li>
                <li>This Agreement constitutes the entire agreement between the parties in relation to its subject matter and supersedes all prior agreements and understandings whether oral or written with respect to such subject matter and no variation of this Agreement shall be effective unless reduced to writing and duly executed by or on behalf of each of the parties.</li>
                <li>The headings in the contract shall not affect its interpretation.</li>
                <li>Whenever required by context, the use of the singular number shall be constructed to include the plural and the use of the plural the singular and the use of any gender shall include both genders.</li>
                <li>If any terms or provision in the contract shall be held to be illegal or unenforceable, in whole or in part, under any enactment or rule of law. Such term or provision or part shall to that extent be deemed not to form part of this contract but the validity and enforceability of the remainder of the contract shall not be affected.</li>
                <li>This Agreement shall be governed by the laws of Kenya and the parties agree to submit disputes arising out of or about this Agreement to the courts in Kenya.</li>
            </ol>
        </Typography>
    )
}

export default MiscellaneousProvisions