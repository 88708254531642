import React from 'react'
import SendMoney from './SendMoney'
import BuyAirtime from './BuyAirtime'
import Paybill from './Paybill'
import BuyFloat from './BuyFloat'

const Transacting = () => {
  return (
    <div>
        <SendMoney />
        <BuyAirtime />
        <Paybill />
        <BuyFloat />
    </div>
  )
}

export default Transacting