    import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, IconButton, Snackbar, Typography } from "@mui/material";
    import { FormInputText } from "../Common/FormInputText";
    import { useForm } from "react-hook-form";
    import React from "react";
    import CloseIcon from "@mui/icons-material/Close";
    import { ArrowForward } from "@mui/icons-material";

    const defaultValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        resume: null,
        coverLetter: null,
        portfolio: null,
        portfolioLink: "",
    };
    
    interface IFormInput {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
        resume: File | null;
        coverLetter: File | null;
        portfolio: File | null;
        portfolioLink: string;
    }

    const Appendix2Complaintform = () => {
        const { handleSubmit, reset, control, setValue, getValues, formState } =
        useForm<IFormInput>({
        defaultValues: defaultValues,
        });
        const [open, setOpen] = React.useState(false);
        const [loading, setLoading] = React.useState(false);
        const [snackbarMessage, setSnackbarMessage] = React.useState("");

        const onSubmit = async (data: IFormInput) => {
            if (loading) {
                return;
            }

        if (!data.coverLetter || !data.resume) {
        return;
        }

        const formData = new FormData();
        formData.append("position", "OOL");
        formData.append("name", `${data.firstName} ${data.lastName}`);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("resume", data.resume);
        formData.append("coverLetter", data.coverLetter);
        if (data.portfolio) formData.append("portfolio", data.portfolio);
        if (data.portfolioLink)
        formData.append("portfolioLink", data.portfolioLink);

        try {
        setLoading(true);

        const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
        const response = await fetch(`${REACT_APP_API_ENDPOINT}/mail/careers`, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const jsonResponse = await response.json();

        setSnackbarMessage("Your application has been sent successfully");
        setOpen(true);
        console.log(jsonResponse);
        } catch (error) {
        setSnackbarMessage("Failed to send application. Please try again later");
        setOpen(true);
        }

        setLoading(false);
    };


        const handleClose = (
            event: React.SyntheticEvent | Event,
            reason?: string,
        ) => {
            if (reason === "clickaway") {
            return;
            }
        
            setOpen(false);
        };

        const action = (
            <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
            </React.Fragment>
        );

        const styles = {
            snackbarStyleViaContentProps: {
            backgroundColor: "#fbf5ec",
            color: "black",
            },
            snackbarStyleViaNestedContent: {
            backgroundColor: "lightgreen",
            color: "black",
            },
        };
        
        return (
            <>
            <Box
                sx={{
                // marginLeft: { md: "24.25vw", sm: "8px" },
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: "20px",
                padding: "18px",
                backgroundColor:"#FCF7ED",
                border:"1px solid #E3D9C5",
                marginRight:"40px",
                
                }}
            >
               

                <Typography
                    sx={{
                    fontWeight: 500,
                    fontSize: "25px",
                    textTransform: "uppercase",
                    marginBottom: "25px",
                    color:"black",
                    }}
                >
                    STAGE 1
                </Typography>

                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={snackbarMessage}
                    action={action}
                    ContentProps={{
                    sx: styles.snackbarStyleViaContentProps,
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                />

                {/* Job Description */}
                <Box
                    sx={{
                    backgroundColor: "#FFF",
                    padding: "18px",
                    borderRadius: "10px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    
                    }}
                >
                    <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                    <Grid item xs={12} md={6}>
                        <Box>
                        <Typography
                            sx={{
                            fontWeight: 500,
                            fontSize: "21px",
                            textTransform: "uppercase",
                            color:"black"
                            }}
                        >
                            INFORMATION COMPLAINT
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            color:"black"
                        }}
                        >
                        
                           <Typography>
                           Where possible, complaints should be raised immediately with the member of staff most directly concerned, the aim being to resolve the problem directly and formally
                           at the earliest opportunity.
                           </Typography>
                        </Box>
                    </Grid>
                    </Grid>
                </Box>
                </Box>
                
               
    
                <Box
                sx={{
                // marginLeft: { md: "24.25vw", sm: "8px" },
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: "18px",
                padding: "18px",
                backgroundColor:"#FCF7ED",
                border:"1px solid #E3D9C5"  ,
                marginRight:"40px",
                
                }}
            >
               

                <Typography
                    sx={{
                    fontWeight: 500,
                    fontSize: "25px",
                    textTransform: "uppercase",
                    marginBottom: "25px",
                    color:"black",
                    }}
                >
                    STAGE 2
                </Typography>

                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={snackbarMessage}
                    action={action}
                    ContentProps={{
                    sx: styles.snackbarStyleViaContentProps,
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                />

                {/* Job Description */}
                <Box
                    sx={{
                    backgroundColor: "#FFF",
                    padding: "18px",
                    borderRadius: "10px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    }}
                >
                    <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                    <Grid item xs={12} md={6}>
                        <Box>
                        <Typography
                            sx={{
                            fontWeight: 500,
                            fontSize: "21px",
                            textTransform: "uppercase",
                            color:"black",
                            }}
                        >
                            HOW TO MAKE A FORMAL COMPLAINT
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            color:"black",
                        }}
                        >
                        
                           <Typography>
                            If you find that stage 1 does not solve your problem, or you feel it is of a serious
                            nature, you should make a formal complaint by completing the Complaints Form and
                            returning it to the Quality Assurance Team at Leja, 24, Chalbi Drive, Lavingtone, KilimaniNairobi (or emailing to complaints@asilimia.co.ke.
                            
                            You will receive an acknowledgement within 5 working days, and following an
                            investigation into your complaint, we will send a full response within 14 working days
                            of receiving your complaint (unless you are informed otherwise).
                           </Typography>
                        </Box>
                    </Grid>
                    </Grid>
                </Box>
                </Box>
        
                <Box
                sx={{
                // marginLeft: { md: "24.25vw", sm: "8px" },
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: "20px",
                padding: "18px",
                backgroundColor:"#FCF7ED",
                border:"1px solid #E3D9C5",
                marginRight:"40px",
                







                
                }}
            >
               

                <Typography
                    sx={{
                    fontWeight: 500,
                    fontSize: "25px",
                    textTransform: "uppercase",
                    marginBottom: "25px",
                    color:"black",
                    }}
                >
                    STAGE 3
                </Typography>

                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={snackbarMessage}
                    action={action}
                    ContentProps={{
                    sx: styles.snackbarStyleViaContentProps,
                    }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                />

                {/* Job Description */}
                <Box
                    sx={{
                    backgroundColor: "#FFF",
                    padding: "18px",
                    borderRadius: "10px",
                    marginTop: "15px",
                    marginBottom: "15px",
                    color:"black",
                    }}
                >
                    <Grid container spacing={3} sx={{ alignItems: "normal" }}>
                    <Grid item xs={12} md={6}>
                        <Box>
                        <Typography
                            sx={{
                            fontWeight: 500,
                            fontSize: "21px",
                            textTransform: "uppercase",
                            }}
                        >
                            HOW TO APPEAL
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                        >
                        
                           <Typography>
                           If you are dissatisfied with the response you receive, you may (within 14 working days
                           of the date on your response letter) appeal to the CEO of Leja in writing, stating your reasons and any action you are seeking. The CEO will constitute an Appeal Panel which will review your matter and notify you in writing of the result of your appeal
                           (normally within 14 working days of the firm receiving your appeal). The decision of
                           the appeal panel is representative of the position of the firm and is final.

                           </Typography>
                        </Box>
                    </Grid>
                    </Grid>
                </Box>
                </Box>

                </>

       )

    }

    export default Appendix2Complaintform;