import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import AboutUsHeroImage from '../../assets/AboutUs/about_us_hero_image.png';
import AboutUsHeroImage2 from '../../assets/AboutUs/about_us_hero_image_2.png';
import GetTheAppMobile from '../utils/GetTheAppMobile/GetTheAppMobile';
import SmallText from './SmallText';
import { motion } from "framer-motion";

const AboutUsHero = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{
      margin: {
        xs: "12px 16px 60px 16px",
        sm: "16px 24px 106px 24px",
        md: "0px 1.875vw 0px 1.875vw",
      },
    }}>
      <Box sx={{
        display: {
          xs: "flex",
        },
        flexDirection: {
          xs: "column",
        },
        gap: {
          xs: "16px",
          sm: "1.563vw",
          md: '0.75vw'
        },
      }}>
        <Box sx={{
          display: {
            xs: "block",
            md: "flex",
          },
          gap: {
            md: "1.875vw",
          },
        }}>
          <Box sx={{
            display: {
              xs: "none",
              md: "block",
            },
          }}>
            <SmallText />
          </Box>

          <motion.div
            initial={{ x: "-150px", clipPath: "inset(0px 100% 0px 0px)" }}
            animate={{ x: 0, clipPath: "inset(0px 0px 0px 0px)" }}
            transition={{ duration: 1.5, ease: "easeInOut", delay: 1.5 }}
          >
            <Typography sx={{
              fontSize: {
                xs: "11.111vw",
                sm: "9.766vw",
                md: '4.688vw',
              },
              fontWeight: 600,
              lineHeight: "100%",
              letterSpacing: "-2px",
              textTransform: "uppercase",
            }}>
              LEADING THE AFRICAN BUSINESS REVOLUTION
            </Typography>
          </motion.div>

        </Box>

        <Box sx={{
          display: {
            xs: "flex",
          },
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          gap: {
            xs: "12px",
            sm: "24px",
            md: "10vw",
          },
        }}>
          <Box sx={{
            display: {
              sm: "flex",
            },
            flexDirection: {
              sm: "column",
            },
            justifyContent: {
              sm: "space-between",
              md: "end",
            },
          }}>
            <Box sx={{
              display: {
                md: "none",
              },
            }}>
              <SmallText />
            </Box>

            <Box sx={{
              display: {
                xs: "none",
                sm: "inline-block",
              },
            }}>
              <motion.div
                initial={{ opacity: 0, x: "20vw" }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ type: "tween", duration: 2, delay: 1.5 }}
              >
                <img
                  style={{
                    objectFit: "cover",
                    width: (smDown ? "100%" : (mdDown ? "100%" : "14.563vw")),
                    height: (smDown ? "100%" : (mdDown ? "100%" : "26.222vh")),
                    borderRadius: "20px",
                  }}
                  src={AboutUsHeroImage2}
                  alt=""
                  loading={'lazy'}
                />
              </motion.div>
            </Box>
          </Box>

          <motion.div
            initial={{ scale: 0.5, opacity: 0 }} // Initial scale and opacity
            animate={{ scale: 1, opacity: 1 }} // Grow to full size and fade in
            transition={{ duration: 2 }} // Transition duration for scaling and fading
            style={{
              width: (smDown ? "100%" : (mdDown ? "472px" : "71.688vw")),
              height: (smDown ? "313px" : (mdDown ? "651px" : "49.111vh")),
              borderRadius: "12px",
              overflow: "hidden", // Hide overflow for smooth animation
              display: "flex",
              justifyContent: "center", // Center horizontally
              alignItems: "center", // Center vertically
            }}
          >
            <img
              src={AboutUsHeroImage}
              alt=""
              style={{
                objectFit: "cover",
                width: "100%", // Full size after animation
                height: "100%", // Full size after animation
                borderRadius: "20px", // Final border radius
              }}
              loading={'lazy'}
            />
          </motion.div>
        </Box>

      </Box>

      <Box sx={{
        mt: {
          xs: "16px",
          sm: "24px",
        },
      }}>
        <Box sx={{

          display: {
            xs: "block",
            sm: "none",
          },
        }}>
          {/* TODO: reuse the button in the other screens */}
          <GetTheAppMobile />
        </Box>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2.5, delay: 3 }}
        >
          <Typography
            sx={{
              textAlign: "justify",
              textTransform: "uppercase",
              fontWeight: 500,
              lineHeight: "100%",
              letterSpacing: {
                xs: "-0.72px",
                sm: "-0.6px",
              },
              fontSize: {
                xs: "24px",
                sm: "3.906vw",
                md: '1.875vw'
              },
              mt: {
                xs: "16px",
                sm: "24px",
                md: "3.333vh",
              },
            }}
          >
            We firmly believe in the vast potential of the African continent and its vast generation of entrepreneurs. 
            Nevertheless, the lack of access to funding has left their potential largely untapped and their aspirations unmet.
          </Typography>
        </motion.div>

      </Box >
    </Box>
  )
};

export default AboutUsHero;