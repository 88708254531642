import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import AzuImage from "../../assets/AboutUs/team/azu.jpg";
import BeckyImage from "../../assets/AboutUs/team/becky.jpg";
import RoseImage from "../../assets/AboutUs/team/Rose.jpg";
import TekwaneImage from "../../assets/AboutUs/team/tekwane.jpg";
import MorganeImage from '../../assets/AboutUs/team/morgane.jpg';
import DonaldImage from '../../assets/AboutUs/team/Donald.jpg'
import TekwaneCup from "../../assets/AboutUs/team/TekwaneCup.jpg"
import AbstractedStyles from "./AbstractedStyles";

const TheTeamLess = () => {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container columnSpacing={mdDown ? "24px" : "30px"} sx={{
      display: {
        xs: "none",
        sm: "flex",
      }
    }}>
      <Grid item md={6} sx={{
        width: "100%",
      }}>
        <Grid container columnSpacing={mdDown ? "24px" : "30px"}>
          <Grid item sm={4} sx={{
            visibility: "hidden",
            pt:0,
            mt:{md:'30px', sm:'24px'}
          }}>
            <Box sx={{
              height: "100%",
              bgcolor: "secondary.main",
              borderRadius: "20px",
            }}>

            </Box>
          </Grid>

          <Grid item sm={4} sx={{
            height: { md: '301px', sm: '301px' },
            '@media only screen and (min-width:1700px) and (max-width:2000px)': {
              height: '385px'
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            pt:0,
            mt:{md:'30px', sm:'24px'}
          }}>
            <Box sx={{
              height: "100%",
              borderRadius: "20px",
            }}>
              <AbstractedStyles
                imageUrl={MorganeImage}
                name="Morgane Kablan"
                role="Co-Founder & COS"
              />
            </Box>
          </Grid>

          <Grid item sm={4} sx={{
            height: { sm: '301px' },
            '@media only screen and (min-width:1700px) and (max-width:2000px)': {
              height: '385px'
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            pt:0,
            mt:{md:'30px', sm:'24px'}
          }}>
            <AbstractedStyles
              imageUrl={DonaldImage}
              name="Donald Chepkutwo"
              role="Project Manager"
            />
          </Grid>

          <Grid item sm={4} sx={{
            height: { md: '301px', sm: '301px' },
            '@media only screen and (min-width:1700px) and (max-width:2000px)': {
              height: '385px'
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            pt:0,
            mt:{md:'30px', sm:'24px'}
          }}>

            <AbstractedStyles
              imageUrl={RoseImage}
              name="RoseMary Nyaga"
              role="Human Resource Manager"
            />
          </Grid>

          <Grid item sm={4} sx={{
            height: { md: '301px', sm: '301px' },
            '@media only screen and (min-width:1700px) and (max-width:2000px)': {
              height: '385px'
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center", 
            pt:0,
            mt:{md:'30px', sm:'24px'} 
          }}>
            <AbstractedStyles
              imageUrl={AzuImage}
              name="Azadi Gathui"
              role="Product Designer"
            />
          </Grid>

          <Grid item sm={4} sx={{
            visibility: "hidden",
            pt:0,
            mt:{md:'30px', sm:'24px'}
          }}>
            <Box sx={{
              height: "100%",
              bgcolor: "secondary.main",
              borderRadius: "20px",
            }}>

            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={6} sx={{
        width: "100%",
      }}>
        <Grid container columnSpacing={mdDown ? "24px" : "30px"}>
          <Grid item sm={8} sx={{
            height: { md: '632px', sm: '632px' },
            '@media only screen and (min-width:1700px) and (max-width:2000px)': {
              height: '800px'
            },
            pt:0,
            mt:{md:'30px', sm:'24px'}
          }}>
            <AbstractedStyles
              imageUrl={TekwaneCup}
              name="Tekwane Mwendwa"
              role="CEO & Co-Founder"
            />
          </Grid>

          <Grid item sm={4} sx={{
            alignSelf: {
              sm: "end",
            },
            height: { md: '301px', sm: '301px' },
            '@media only screen and (min-width:1700px) and (max-width:2000px)': {
              height: '385px'
            },
                      display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center", 
           
          }}>
            <AbstractedStyles
              imageUrl={BeckyImage}
              name="Rebecca Kimani"
              role="Product Manager"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};

export default TheTeamLess;