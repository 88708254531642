import React from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import CareerAccordion from "./CareerAccordion";
import {sampleOpenCareers} from "../../models/open-career";

const CareersTabs = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (newValue: any) => {
        setValue(newValue);
    };

    return (
        <Box sx={{

        }}>
            {/* <Grid container sx={{ml:{md:'25vw'}}}> */}
            <Grid container spacing={3} sx={{pl:{md:'25.5vw', sm:'20px', xs:'16px'},pr:{md:'20px', sm:'20px', xs:'16px'},
                '@media only screen and (min-width:1800px)':{pl:'25vw'}
            }}>

                {/* Tab 1 */}

                    { sampleOpenCareers.map((item, index) => (
                        <>
                            <Grid item xs={6} sm={4} md={2}>
                                <Button
                                    variant='contained'
                                    onClick={() => handleChange(index)}
                                    sx={{
                                        // width: { xs: '41.6vw', sm: '28.873vw', md: '8.875vw' },
                                        width: '100%',
                                        height: '48px',
                                        color: value === index ? 'primary.dark' : '#FFF',
                                        bgcolor: value === index ? '#F5F7F2' : "primary.dark",
                                        '&:hover': {
                                            bgcolor: value === index ? '#F5F7F2 ' : "primary.dark",
                                        },
                                        boxShadow: 'none',
                                        borderRadius: '4px',
                                        border:'none',
                                        outline: 'none',
                                        padding:'5px 0px'
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '120%',
                                        textTransform: 'uppercase'
                                    }}>{item.title}</Typography>
                                </Button>
                            </Grid>
                        </>

                    ))}
            </Grid>

            <Box mt={2}>
                <Box>
                    <CareerAccordion
                        careers={sampleOpenCareers[value].careers}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default CareersTabs;
