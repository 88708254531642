import { Typography } from "@mui/material"
import { motion } from "framer-motion";

const SmallText = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 2.5 }}
    >
      <Typography sx={{
        fontWeight: 400,
        lineHeight: "130%",
        color: "primary.dark",
        width: {
          sm: "29.167vw",
          md: "22.688vw",
        },
        mt:'8px',
        textTransform: 'uppercase',
        fontSize: { md: '1vw', sm: '16px', xs: '16px' }
      }}>
        Our mission is to bring African entrepreneurs into the spotlight and grant them the rightful place they deserve in the economy.
      </Typography>
    </motion.div>
  )
}

export default SmallText