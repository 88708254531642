import { motion } from "framer-motion";
import { Box, useMediaQuery } from "@mui/material";
import { Theme } from '@mui/material/styles';

interface TransitionPageProps {
  onAnimationComplete: () => void;
}

const TransitionPage: React.FC<TransitionPageProps> = ({ onAnimationComplete }) => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <div style={{ height: '100vh' }}>
      <Box sx={{ overflow: 'hidden', width: '100%', borderRadius: '0', position: 'absolute', top: 0, zIndex: 1300 }}>
        {/* Red Circle */}
        <motion.div
          initial={
            isMobile ? { y: '-100vh', x: '-50vw', width: 'calc(100vw + 100vw)' } :
              isTablet ? { y: '-100vh', x: '-20vw', width: 'calc(100vw + 40vw)' } :
                { y: '-100vh' }
          }
          animate={
            isMobile ? { scaleX: 1.5, scaleY: 3.9, opacity: 1 } :
            isTablet ? { scaleX: 1, scaleY: 3.9, opacity: 1 } :
            { scaleX: 1.5, scaleY: 3.9, opacity: 1 }
          }
          transition={{ duration: 1.5, delay: 0.01, ease: 'easeInOut' }}
          style={{
            overflow: 'hidden',
            background: '#64002C',
            width: '100%',
            height: '100vh',
            borderBottomRightRadius: (isMobile ? '50%' : (isTablet ? '50%' : '70%')),
            borderBottomLeftRadius: (isMobile ? '50%' : (isTablet ? '50%' : '70%')),
          }}
          onAnimationComplete={onAnimationComplete}
        />
      </Box>
      <Box sx={{ overflow: 'hidden', width: '100%', borderRadius: '0', position: 'absolute', bottom: 0, zIndex: 1300 }}>
        {/* White Circle */}
        <motion.div
          initial={
            isMobile ? { y: '100vh', x: '-50vw', width: 'calc(100vw + 100vw)' } :
              isTablet ? { y: '100vh', x: '-20vw', width: 'calc(100vw + 40vw)' } :
                { y: '100vh' }
          }
          animate={
            isMobile ? {y: -20, scaleX: 1.5, scaleY: 3.9, opacity: 1 } :
            { y: 0, scaleX: 1.5, scaleY: 3.9, opacity: 1 }
          }
          exit={{ y: '-100vh' }}
          transition={{ duration: 2, delay: 0, ease: 'easeInOut' }}
          style={{
            overflow: 'hidden',
            background: '#FFF',
            width: '100%',
            height: '100vh',
            borderTopLeftRadius: (isMobile ? '50%' : (isTablet ? '50%' : '70%')),
            borderTopRightRadius: (isMobile ? '50%' : (isTablet ? '50%' : '70%')),
          }}
          onAnimationComplete={onAnimationComplete}
        />
      </Box>
    </div>
  );
};

export default TransitionPage;
