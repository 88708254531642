import { Typography } from '@mui/material'
import React from 'react'

const CustomerResponsibilities = () => {
    return (
        <Typography sx={{
            mb: '10px',
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
        }}>
            <ol style={{ margin: 0 }}>
                <li>You shall at your own expense provide and maintain in safe and efficient operating order your Equipment necessary for the purpose of accessing the Leja Platform and the Services provided therein.</li>
                <li>You shall be responsible for ensuring the proper performance of your Equipment. LEJA shall neither be responsible for any errors or failures caused by any malfunction of your Equipment, and nor any computer virus or related problems that may be associated with the use of the Leja Platform, the Services and the Equipment.</li>
                <li>You shall be responsible for charges due to any service provider providing you with connection to the Leja Platform and LEJA shall not be responsible for losses or delays caused by any such service provider.</li>
                <li>You shall follow all instructions, procedures and terms contained in this Agreement and any document provided by LEJA concerning the use of the System and the Services.</li>
                <li>You hereby agree and acknowledge that you shall be solely responsible for the safekeeping and proper use of your Equipment and for keeping your Credentials secret and secure. You shall ensure that your Credentials do not become known or come into possession of any unauthorised person. LEJA shall not be liable for any disclosure of your Credentials to any third party and you hereby agree to indemnify and hold LEJA harmless from any losses resulting from any disclosure of your Credentials.</li>
                <li>
                    You shall take all reasonable precautions to detect any unauthorised use and access of the System and the Services undertaken using your Equipment. To that end, you shall ensure that all communications from LEJA are examined and checked by you or on your behalf as soon as practicable after receipt by you in such a way that any unauthorised use of and access to the Leja Platform will be detected. You shall immediately inform LEJA in the event that:
                    <ul>
                        <li>You have reason to believe that your Credentials are or may be known to any person not authorised to know the same and/or have been compromised; and/or</li>
                        <li>You have reason to believe that unauthorised access and use of the Leja Platform and the Services has or may have occurred or could occur and a transaction may have been fraudulently input or compromised.</li>
                    </ul>
                </li>
                <li>You shall at all times follow the security procedures notified to you by LEJA from time to time or such other procedures as may be applicable to the Services from time to time. You acknowledge that any failure on your part to follow the recommended security procedures may result in a breach of your Personal Information, Relevant Information and such other information held by LEJA in connection to the Services and Requests made by you to LEJA in confidentiality. In particular, you shall ensure that the Services are not used or Requests are not issued or the relevant functions are not performed by anyone other than you or a person authorised by you to do so.</li>
                <li>You shall not at any time operate or use the Services in any manner that may be prejudicial to LEJA.</li>
                <li>You agree to indemnify LEJA against any losses incurred as a result of your failure to comply with the above responsibilities</li>
            </ol>
        </Typography>
    )
}

export default CustomerResponsibilities