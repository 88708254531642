import { Typography } from '@mui/material'
import React from 'react'

const AcceptanceOfTAndC = () => {
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        <ol style={{ margin: 0 }}>
            <li>Before applying for the Service you should carefully read and understand these Terms and Conditions which will govern the provision, use, operation and management of the Service.</li>
            <li>If you do not agree with these Terms and Conditions, please cancel the Registration by failing to click ACCEPT and do not confirm your National Identification number, Mobile Phone Number and Personal Details.</li>
            <li>
                You will be deemed to have read, understood and accepted these Terms and Conditions:-
                <ol>
                    <li>By Clicking Accept;</li>
                    <li>By continuing to use Leja services</li>
                </ol>
            </li>
            <li>By applying to access the Service with LEJA you agree to comply with and be bound by these Terms and Conditions for the time being and from time to time in force governing the operation of the Service and you affirm that these Terms and Conditions herein are without prejudice to any right that LEJA may have with respect to the Service in law or otherwise.</li>
            <li>These Terms and Conditions may be amended or varied by LEJA from time to time and the continued use, access, enjoyment of the Service constitutes your agreement to be bound by these terms and conditions or any such amendment, addition, deletion or variation.</li>
            <li>You acknowledge and accept that LEJA offers the Service only by electronic means via the Leja Platform.</li>
            <li>By using the Leja Platform or any of the Services, you consent to LEJA collecting and using technical information about the Equipment and related software, hardware and peripherals for Services that are internet-based or wireless to improve our products and to provide any Services to you. If you use these Services, you consent to us and our affiliates' and licensees' transmission, collection, retention, maintenance, processing and use of your data to determine our credit scoring services or to improve our Services and/or your experience while using the Leja Platform.</li>
        </ol>
    </Typography>
  )
}

export default AcceptanceOfTAndC