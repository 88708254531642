import { Box } from "@mui/material";
import PrivacyPolicy from "../../components/ComplaintsPolicy/ComplaintsPolicy";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const PrivacyPoilcy = () => {
  const location = useLocation()

  useEffect(
    () => {
      document.title = `Leja / ${location.pathname.slice(1)}`
    }, [location]
  );

  return (
    <PrivacyPolicy />
  )
};

export default PrivacyPoilcy;
