import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import { useEffect } from "react";

const NotFound = () => {
  const error: any = useRouteError();
  console.error(error);

  const navigate = useNavigate();

  const goBack = () => {
    navigate("../");
  };

  const location = useLocation()

  useEffect(
    () => {
      document.title = `Leja / ${location.pathname.slice(1)}`
    }, [location]
  );

  return (
    <Box sx={{
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <Box>
        <Typography component="h1" variant="h2">Oops!</Typography>
        <Typography>Sorry, an unexpected error has occurred.</Typography>
        <Typography>
          <i>{error?.statusText || error?.message}</i>
        </Typography>

        <Button
          onClick={() => goBack()}
          variant="contained"
          sx={{
            mt: 2,
            "&:hover": {
              bgcolor: "primary.light",
            },
          }}>
          Go Back
        </Button>
      </Box>
    </Box>
  )
};

export default NotFound;
