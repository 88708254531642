import {Accordion, AccordionDetails, AccordionSummary, IconButton, Typography} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import React from "react";

interface ComplainPolicyItemAppendixProps {
    index: number;
    title: string;
    children: React.ReactNode;
}
const ComplainPolicyItemAppendix = ({ title, children, index }: ComplainPolicyItemAppendixProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);

    return (
        <>
            <Accordion
                expanded={expanded}
                sx={{
                    boxShadow: 'none',
                    '&.Mui-expanded': {
                        marginBottom: 0,
                        marginTop: 0
                    },
                    background: 'transparent',
                    color:'white',
                }}
            >
                <AccordionSummary
                    id={`panel${1}-summary`}
                    sx={{ 
                        paddingLeft:0, 
                        mr: { md: '30px', sm: '24px', xs: '16px' },
                        ml: { md: '30px', sm: '24px', xs: '16px' },
                    }}
                    onClick={() => setExpanded(!expanded)}
                    expandIcon={
                        <IconButton  sx={{
                            color: "white"
                        }} >
                            {expanded ? <RemoveIcon /> : <AddIcon />}
                        </IconButton>
                    }
                >
                    <Typography sx={{
                        mr: { lg:'378px', md: '282px', sm: '94px', xs: '20px' },
                        fontSize: '14px',
                        fontWeight: '400',
                        color:'white'
                    }}>
                        { index > 9 ? `${index}` : `0${index}`}
                    </Typography>
                    <Typography sx={{
                        fontSize: { md: '21px', sm: '21px', xs: '18px' },
                        fontWeight: 500,
                        lineHeight: '120%',
                        letterSpacing: ' -0.54px',
                        textTransform: 'uppercase',
                        color:'white',
                        textAlign:'flex-start',
                    }}>
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{
                    color:'white',
                    padding:"0px"
                }}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default ComplainPolicyItemAppendix