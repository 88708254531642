import Box from "@mui/material/Box";
import {Grid, IconButton, Snackbar, SnackbarOrigin} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {ArrowForward} from "@mui/icons-material";
import UploadApplicationDocument from "./UploadApplicationDocument";
import Button from "@mui/material/Button";
import {TextFieldStyled} from "../Common/TextFieldStyled";
import {useForm} from "react-hook-form";
import {FormInputText} from "../Common/FormInputText";
import {Form} from "formik";
import {OpenCareer} from "../../models/open-career";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

interface IFormInput {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    resume: File | null;
    coverLetter: File | null;
    portfolio: File | null;
    portfolioLink: string;
}

const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    resume: null,
    coverLetter: null,
    portfolio: null,
    portfolioLink: ""
};

const styles = {
    snackbarStyleViaContentProps: {
        backgroundColor: "#fbf5ec",
        color: "black"
    },
    snackbarStyleViaNestedContent: {
        backgroundColor: "lightgreen",
        color: "black"
    }
};

const ApplyForRole = ({career}: {career: OpenCareer}) => {
    const { handleSubmit, reset, control, setValue, getValues, formState } = useForm<IFormInput>({
        defaultValues: defaultValues,
    });
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');

    const onSubmit = async (data: IFormInput) => {
        if (loading) {
            return;
        }

        if(!data.coverLetter || !data.resume) {
            return;
        }

        const formData = new FormData();
        formData.append('position', career.title)
        formData.append('name', `${data.firstName} ${data.lastName}`)
        formData.append('email', data.email)
        formData.append('phone', data.phone)
        formData.append('resume', data.resume)
        formData.append('coverLetter', data.coverLetter)
        if (data.portfolio)
            formData.append('portfolio', data.portfolio)
        if (data.portfolioLink)
            formData.append('portfolioLink', data.portfolioLink)

        try {
            setLoading(true)

            const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
            const response = await fetch(`${REACT_APP_API_ENDPOINT}/mail/careers`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const jsonResponse = await response.json();

            setSnackbarMessage("Your application has been sent successfully")
            setOpen(true)
            console.log(jsonResponse);
        } catch (error) {
            setSnackbarMessage("Failed to send application. Please try again later")
            setOpen(true)
        }

        setLoading(false)
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Button onClick={() => setOpen(true)}>Open Snackbar</Button>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
                ContentProps={{
                    sx: styles.snackbarStyleViaContentProps
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />

            {/* Deadline */}
            <Box sx={{
                backgroundColor: "#FFF",
                padding: '18px',
                borderRadius: '10px',
                marginTop: '15px',
                marginBottom: '15px'
            }}>
                <Grid container spacing={3} sx={{alignItems: 'center'}}>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography sx={{fontWeight: 500, fontSize: '21px', textTransform: 'uppercase'}}>
                                Deadline
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography sx={{fontWeight: 500, fontSize: '16px'}}>
                                22nd March 2024
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            { /* Job Description */ }
            <Box sx={{
                backgroundColor: "#FFF",
                padding: '18px',
                borderRadius: '10px',
                marginTop: '15px',
                marginBottom: '15px'
            }}>
                <Grid container spacing={3} sx={{alignItems: 'normal'}}>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography sx={{fontWeight: 500, fontSize: '21px', textTransform: 'uppercase'}}>
                                Full Name
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}>
                            <FormInputText
                                name="firstName"
                                control={control}
                                label="First Name"
                                rules={{
                                    required: 'First Name is required'
                                }}
                            />
                            <FormInputText
                                name="lastName"
                                control={control}
                                label="Last Name"
                                rules={{
                                    required: 'Last Name is required'
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            { /* Job Description */ }
            <Box sx={{
                backgroundColor: "#FFF",
                padding: '18px',
                borderRadius: '10px',
                marginTop: '15px',
                marginBottom: '15px'
            }}>
                <Grid container spacing={3} sx={{alignItems: 'normal'}}>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography sx={{fontWeight: 500, fontSize: '21px', textTransform: 'uppercase'}}>
                                Contacts
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}>
                            <FormInputText
                                name="phone"
                                control={control}
                                label="Phone number"
                                rules={{
                                    required: 'Phone number is required',
                                    pattern: {
                                        value: /^(\+?\d{1,3}|\d{1,4})\s?\d{9}$/,
                                        message: 'Invalid phone number'
                                    }
                                }}
                            />
                            <FormInputText
                                name="email"
                                control={control}
                                label="Emai address"
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Invalid email address'
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <UploadApplicationDocument
                title="Resume"
                description="Please provide an up-to-date resume, listing your professional experience and skills relevant to this role."
                onFileUpload={(file) => setValue('resume', file)}
                hasError={
                    formState.isSubmitted && getValues('resume') === null
                }
            />

            <UploadApplicationDocument
                title="Cover Letter"
                description="Please provide a cover letter detailing your motivations for applying for this role."
                onFileUpload={(file) => setValue('coverLetter', file)}
                hasError={
                    formState.isSubmitted && getValues('coverLetter') === null
                }
            />

            <UploadApplicationDocument
                title="Portfolio"
                description="Please provide a portfolio of your work, supporting your candidacy for this position, as a link or upload a PDF."
                onFileUpload={(file) => setValue('portfolio', file)}
            >
                <Box sx={{
                    mt: '15px'
                }}>
                    <FormInputText
                        name="portfolioLink"
                        control={control}
                        label="Link to Portfolio"
                        placeholder="https://my-portofolio.com"
                        rules={{
                            pattern: {
                                // Value is a regex pattern for URL validation (https://stackoverflow.com/a/3809435)
                                value: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
                                message: 'Invalid URL'
                            }
                        }}
                    />
                </Box>
            </UploadApplicationDocument>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'flex-end',
                marginTop: '15px'
            }}>
                <Box sx={{
                    width: {
                        xs: '100%',
                        sm: '50%'
                    },
                    paddingTop: '30px',
                    paddingLeft: '10px',
                    display: 'flex',
                }}>
                    <Button
                        variant='contained'
                        sx={{
                            height: '48px',
                            width: '100%',
                            boxShadow: 'none',
                            borderRadius: '8px',
                            border:'none',
                            outline: 'none',
                            bgcolor: "primary.main",
                            '&:hover': {
                                bgcolor: "primary.main",
                            },
                            color: '#FFF'
                        }}
                        type="submit"
                        disableElevation>
                        { loading ? (
                            <>
                                Submitting...
                                <CircularProgress
                                    style={{ display: true ? 'inline-block' : 'none', marginLeft: '4px', marginBottom: '4px' }}
                                    color="inherit"
                                    size={15}
                                    thickness={5}
                                />
                            </>
                        ) : (
                            <>
                                Submit
                                <ArrowForward/>
                            </>
                        ) }
                    </Button>
                </Box>

            </Box>

        </form>
    )
}

export default ApplyForRole;