import { Box, Link, Typography } from '@mui/material'
import Goat from '../../assets/Blog/Goat.svg'
import SharePost from './SharePost'
import Blog from '../Blog/Blog'
import BlogHero from '../Blog/BlogHero'
import twif from '../../assets/Blog/twif.jpg'

const TwifEvent = () => {
    return (
        <>
            <BlogHero imageUrl={twif}
                heading='Leja at TWIF'
                text="As the lead sponsor of TWIF 2024, Leja played a pivotal role in the success of the event, by providing essential resources and funding."
                // credits='Photocredits: [freepik]'
                date="16/02/24"
            />
            <Box sx={{ margin: { md: '80px 26.438vw 0px 26.375vw', sm: '40px 26px 0px 24px', xs: '62px 16px 0px 16px' } }}>
                <Typography
                    sx={{
                        color: '#012317',
                        fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                        lineHeight: '100%',
                        letterSpacing: '-1.5px',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        textTransform: 'uppercase',
                        //mb: { md: '30px', sm: '30px', xs: '16px' }
                    }}> 
                    Leja’s participation at the TWIF Nairobi Fintech happy hour 2024 as lead sponsor
                    </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ margin: { md: '30px 10vw 0px 26.375vw', sm: '30px 25px 0px 24px', xs: '16px' } }}>
                    <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                    }}>
                        TWIF (This Week in Fintech) Fintech Happy Hour took place at the vibrant Mawimbi SeaFood Restaurant & Cafe, in Nairobi, marking the third consecutive year for the TWIF event. The event brought together leading fintech professionals to celebrate innovation, share ideas, and build meaningful connections in an informal setting.
                    </Box>
                    <Box
                        sx={{
                            fontSize: { xs: '16px', md: '1vw' },
                            fontstyle: 'normal',
                            fontWeight: '300',
                            lineHeight: '130%',
                            color: '#012317',
                            mt: '16px',
                        }}>
                        As the lead sponsor of TWIF 2024, Leja played a pivotal role in the success of the event, by providing essential resources and funding. This support amassed widespread appreciation, reinforcing Leja's commitment to fostering innovation and collaboration within the fintech ecosystem and reaffirming its mission to provide accessible and affordable finance to MSMEs.
                    </Box>
                    <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                        mt: '16px',
                    }}>
                        A highlight of Leja's participation was the holographic presentation featuring <Link
                        target="_blank"
                        href={'https://www.youtube.com/@LejaApp'}
                        rel="noopener noreferrer"
                        sx={{ color: 'green' }}>customer testimonials</Link> and a Leja-branded photo booth, which was a major hit among event participants, allowing attendees to immerse themselves in the brand experience in a fun way while creating memorable moments. This was complemented by strategically placed merchandise, including branded notebooks, stickers, tote bags, and water bottles. Leja left a lasting impression on attendees, contributing to a memorable and impactful brand presence, which facilitated connections with potential investors and partners.
                    </Box>
                    <Box
                        sx={{
                            fontSize: { xs: '16px', md: '1vw' },
                            fontstyle: 'normal',
                            fontWeight: '300',
                            lineHeight: '130%',
                            color: '#012317',
                            mt: '16px',
                            mb: { md: '0px', sm: '30px', xs: '16px' }
                        }}>
                        Another key highlight at the TWIF 2024 event was our uniformity in branding, with all our attending employees dressed in company-branded attire. This cohesive representation not only underscored professionalism but also maximized brand visibility throughout the event.
                    </Box>
                </Box>
                <Box sx={{ mr: '1.875vw', display: { md: 'block', sm: 'none', xs: 'none' }, visibility: 'hidden' }}><img src={Goat} alt='a goat' loading={'lazy'}/></Box>
            </Box>
            <Box sx={{
                m: { md: '30px 26.438vw 30px 26.438vw', sm: '9px 25px 30px 25px', xs: '16px 17px 20px 16px', },
                bgcolor: 'primary.main',
                padding: '30px',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '0px',
                justifyContent: 'center',
                alignItems: 'center',

                // display:{md:'inline-flex', sm:'flex', xs:'flex'},

            }}>
                <Typography sx={{
                    fontSize: { xs: '16px', md: '1vw' },
                    fontstyle: 'normal',
                    fontWeight: '300',
                    lineHeight: '130%',
                    color: '#FFF',
                }}>
                    Overall, Leja's participation in the TWIF Nairobi 2024 event gained us significant exposure in the African fintech scene and  positioned us as a key player and partner within the fintech space both locally and internationally.
                </Typography>
            </Box>
            <Box sx={{
                display: { md: 'inline-flex', sm: 'flex', xs: 'flex' },
                alignItems: 'center',
                // flexDirection:{md:'row', sm:'row', xs:'column'},
                // justifyContent: 'center',
                gap: '17px',
                // mt: '60px',
                ml: { md: '26.438vw', sm: '24px', xs: '16px' },
            }}>
                <Typography sx={{
                    color: 'primary.main',
                    fontSize: { xs: '16px', md: '1rem' },
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '130%',
                    marginRight: '17px',

                }}>Share this post:</Typography>
                <SharePost />
            </Box>
            <Blog title='MORE NEWS' subtitle='[NEWS AND INSIGHTS]' parentSlug={'leja-at-twif'} />
        </>
    );
};

export default TwifEvent