import { TextField, styled } from "@mui/material";

export const TextFieldStyled = styled(TextField)(
    ({ theme }) => `
       .MuiInputBase-input {
            color: black;
        }

        label {
        }

        .Mui-focused {
            color: grey !important;
            border-color: grey !important;
        }
        
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: grey !important;
        }

        fieldset {
            border-color: white;
        }

        .Mui-focused fieldset {
            border-color: grey !important;
        }

        svg {
            color: white;
        }
    `
)