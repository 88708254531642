import { Box, Link, Typography } from '@mui/material'
import Goat from '../../assets/Blog/Goat.svg'
import man from '../../assets/Blog/man.png'
import Tekwane from '../../assets/Blog/Tekwane.svg'
import SharePost from './SharePost'
import registerPicture from '../../assets/registerPicture.jpg';
import Blog from '../Blog/Blog'
import BlogHero from '../Blog/BlogHero'
import LadyBlog from '../../assets/LadyBlog.png';

const RegisterBusiness = () => {
    return (
        <>
            <BlogHero imageUrl={LadyBlog}
            date="02/12/23"
                heading='registering a business'
                text="In today's digital age, registering your business has become convenient through the E-Citizen platform."
                credits='Photocredits: [freepik]'
            />
            <Box sx={{ margin: { md: '80px 26.438vw 0px 26.375vw', sm: '40px 26px 0px 24px', xs: '62px 16px 0px 16px' } }}>
                <Typography
                    sx={{
                        color: '#012317',
                        fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                        lineHeight: '100%',
                        letterSpacing: '-1.5px',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        textTransform: 'uppercase',
                        //mb: { md: '30px', sm: '30px', xs: '16px' }
                    }}> How to register a business in Kenya</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ margin: { md: '30px 10vw 0px 26.375vw', sm: '30px 25px 0px 24px', xs: '16px' } }}>
                    <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                    }}>
                        Starting a business in Kenya can be an exciting and rewarding venture. However, before you can officially operate, you need to go through the process of <Link
                            href='https://eregulations.invest.go.ke/procedure/153/103?l=en'
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: 'green' }}
                        >registering your business.</Link><br />In this article, we will explore the various aspects of business registration in Kenya, including the costs involved, the necessary requirements, the benefits of business registration and implications of not registering your business.
                    </Box>
                    <Box
                        sx={{
                            fontSize: { xs: '16px', md: '1vw' },
                            fontstyle: 'normal',
                            fontWeight: '300',
                            lineHeight: '130%',
                            color: '#012317',
                            mt: '16px',
                        }}>
                        In today's digital age, registering your business has become increasingly convenient. The eCitizen platform provides an online step-by-step guidance that allows you to register and submit the required documents electronically.
                    </Box>
                    <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                        mt: '16px',
                    }}>
                        Upon registering your business name, you gain access to the following advantages:
                        <ul>
                            <li style={{ fontWeight: 500 }}>Enhanced credibility:</li>
                            <ul>
                                <li>A registered business enjoys increased credibility, fostering trust among clients and partners.</li>
                                <li>Clients are more willing to engage in contracts, confident in your commitment to delivering without financial risks.</li>
                                <li>Registration facilitates eligibility for tender applications.</li>
                            </ul>
                            <li style={{ marginTop: '16px', fontWeight: 500 }}>Establishment of a business bank account:</li>
                            <ul>
                                <li>Registering your business name allows you to establish a dedicated business bank account.</li>
                                <li>The business bank account enhances the likelihood of securing quick loans.</li>
                            </ul>
                            <li style={{ marginTop: '16px', fontWeight: 500 }}>Increased borrowing power:</li>
                            <ul>
                                <li>Registration of your business name in Kenya elevates your borrowing power.</li>
                                <li>Financial institutions and banks have greater trust, leading to easier access to credit.</li>
                                <li>Registered businesses, particularly SMEs, benefit from favorable loan rates.</li>
                            </ul>
                        </ul>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: { xs: '16px', md: '1vw' },
                            fontstyle: 'normal',
                            fontWeight: '300',
                            lineHeight: '130%',
                            color: '#012317',
                            mb: { md: '0px', sm: '30px', xs: '16px' }
                        }}> Before registering your business, you need to have a valid identification document, such as a national ID or passport, and obtain a <Link
                            href='https://www.kra.go.ke/business/charities-religious-orgs/not-for-profit-pin-registration/pin-registration'
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: 'green' }}
                        >
                            Personal Identification Number (PIN)</Link> from the Kenya Revenue Authority (KRA). Additionally, choosing a business name can be both exciting and challenging. You must select a unique business name that reflects your brand identity and is memorable to your target audience.</Typography>

                </Box>
                <Box sx={{ mr: '1.875vw', display: { md: 'block', sm: 'none', xs: 'none' }, visibility: 'hidden' }}><img src={Goat} alt='a goat' loading={'lazy'} /></Box>
            </Box>
            {/* <Box sx={{
                m: { md: '30px 26.438vw 0px 26.438vw', sm: '9px 25px 0px 25px', xs: '16px 17px 0px 16px', },
                bgcolor: 'primary.main',
                padding: '30px',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '0px',
                justifyContent: 'center',
                alignItems: 'center',

                // display:{md:'inline-flex', sm:'flex', xs:'flex'},

            }}><Typography
                sx={{
                    fontSize: { md: '1.125vw', sm: '21px', xs: '18px' },
                    color: '#FFF',
                    fontWeight: '500',
                    lineHeight: '120%',
                    textTransform: 'uppercase',
                    '@media only screen and (max-width: 600px)': {
                        letterSpacing: '-0.54px',
                    }
                }}
            >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis libero, laborum, doloremque temporibus sint dolores omnis assumenda iure provident impedit aliquam exercitationem officiis labore ut perferendis sapiente id ea?
                </Typography>
                </Box> */}
            <Box sx={{
                m: { md: '0px 26.375vw 0px 26.375vw', sm: '9px 25px 0px 24px', xs: '16px' }
            }}><Typography sx={{
                color: '#012317',
                fontWeight: '300',
                fontSize: { xs: '16px', md: '1vw' },
                lineHeight: '130%',
            }}>
                    <br />However, it's important to adhere to the naming guidelines to ensure your name is acceptable for registration. Guidelines include avoiding names that are offensive, misleading, or similar to existing registered businesses.
                    <br />Now, let's talk about the registration fees. The cost of registering a business name in Kenya varies depending on the structure and the duration of registration. The fees are as follows:
                    <ol>
                        <li>Registration of business name: Ksh 800</li>
                        <li>Name reservation fee: Ksh 100</li>
                        <li>Ecitizen convenience fee: Ksh 50</li>
                    </ol>
                These fees can be paid through the <Link
                href='https://ebusiness.go.ke/ecitizen-services.html'
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'green' }}
            >
                E-citizen platform</Link>  , and It's also important to keep the payment receipts as they will be required during the registration process. <br />
                The duration of the business registration process in Kenya can vary. On average, it takes approximately 3-5 days to complete the registration, provided all the required documents are in order.
                </Typography>
                <Typography sx={{
                    color: '#012317',
                    fontWeight: '300',
                    fontSize: { xs: '16px', md: '1vw' },
                    lineHeight: '130%',
                }}>
                    <br />
                    Once your business is registered, it's important to be aware of the tax implications. As a registered business, you will be required to fulfill your
                    <Link
                        href='https://www.kra.go.ke/business/companies-partnerships/companies-partnerships-pin-taxes/company-partnerships-types-of-taxes'
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: 'green' }}
                    >
                        tax obligations</Link>
                    , including filing tax returns, paying income tax, and complying with Value Added Tax (VAT) regulations. It's recommended to seek the guidance of a tax professional to ensure compliance with tax laws and regulations.
                </Typography>
                <Typography sx={{
                    color: '#012317',
                    fontWeight: '300',
                    fontSize: { xs: '16px', md: '1vw' },
                    lineHeight: '130%',
                }}>
                    <br />As your business grows and evolves, you may need to update or make changes to your registration details. This can include changes in business name, address, ownership structure, or even deregistration. To make these changes, you will need to submit the necessary documents and pay the prescribed fees.


                    It's recommended to consult with a legal professional or visit the registrar's office for guidance on the specific requirements and procedures.
                </Typography>
                <Typography sx={{
                    color: '#012317',
                    fontWeight: '300',
                    fontSize: { xs: '16px', md: '1vw' },
                    lineHeight: '130%',
                }}>
                    <br />
                    Finally, let's discuss the consequences of not registering a business in Kenya.
                    <br />
                    Operating an unregistered business is illegal and can lead to severe penalties. If caught, you may face fines, closure of your business, or even imprisonment.
                    Additionally, an unregistered business lacks the legal protections and benefits that come with registration.
                </Typography>
                {/* <Typography sx={{
                    color: '#012317',
                    fontWeight: '300',
                    fontSize: { xs: '16px', md: '1vw' },
                    lineHeight: '130%',
                }}>
                    <br />
                    In conclusion, registering your business is a crucial step towards establishing a legitimate and thriving business.

                    By understanding the different business structures, meeting the requirements, and adhering to the guidelines, you can navigate the registration process smoothly.

                    Remember to consider the costs, tax implications, and industry-specific regulations to ensure compliance and set your business up for success.
                    <br />Best of luck on your entrepreneurial journey!
                </Typography> */}
            </Box>
            <Box sx={{
                m: { md: '30px 26.438vw 0px 26.438vw', sm: '9px 25px 0px 25px', xs: '16px 17px 0px 16px', },
                bgcolor: 'primary.main',
                padding: '30px',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '0px',
                justifyContent: 'center',
                alignItems: 'center',

                // display:{md:'inline-flex', sm:'flex', xs:'flex'},

            }}>
                <Typography sx={{
                    fontSize: { xs: '16px', md: '1vw' },
                    fontstyle: 'normal',
                    fontWeight: '300',
                    lineHeight: '130%',
                    color: '#FFF',
                }}>
                    <span style={{ fontWeight: 500}}> In conclusion:</span>
                    <br />
                    registering your business is a crucial step towards establishing a legitimate and thriving business.

                    By understanding the different business structures, meeting the requirements, and adhering to the guidelines, you can navigate the registration process smoothly.

                    Remember to consider the costs, tax implications, and industry-specific regulations to ensure compliance and set your business up for success.
                    <br />Best of luck on your entrepreneurial journey!
                </Typography>
            </Box>
            {/* <Box sx={{
                m: { md: '30px 26.375vw 0px 26.438vw', sm: '9px 25px 0px 25px', xs: '16px' }
            }}><Typography sx={{
                fontSize: { md: '1.313vw', sm: '21px', xs: '18px' },
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '120%',
                textTransform: 'uppercase',
                '@media only screen and (max-width: 600px)': {
                    letterSpacing: '-0.54px',
                }
            }}>
                   Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit accusantium alias nobis ipsa asperiores quia cum in dicta architecto, facilis exercitationem quo delectus recusandae corporis nulla rerum? Ad, quod aliquam!
                </Typography></Box>
            <Box sx={{
                m: { md: '30px 26.375vw 0px 26.438vw', sm: '30px 25px 0px 25px', xs: '16px' }
            }}>
                <img src={Tekwane} alt='Tekwane' style={{ width: '100%', objectFit: 'cover' }} />
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ position: 'absolute', flexShrink: 0, mt: '412px', ml: '1.938vw', display: { md: 'block', sm: 'none', xs: 'none' } }}><img src={man} alt='a man' /></Box>
                <Box sx={{
                    m: { sm: '30px 1.563vw 0px 1.5vw', xs: '16px', md: '30px 26.375vw 0px 26.375vw' }
                }}>
                    <Typography sx={{
                        color: '#012317',
                        fontSize: { xs: '16px', md: '1vw' },
                        fontStyle: 'normal',
                        fontWeight: 300,
                        lineHeight: '130%',
                        // width: '756px'
                    }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis voluptatibus reprehenderit porro soluta dolore nisi nobis non perferendis, numquam accusamus, nesciunt hic odio debitis? Explicabo corrupti officiis, eum odio quas, quo fuga vero ea a ipsa temporibus autem ipsam porro quae vel illo odit? Dolorum eveniet iste molestias dicta, accusantium maiores consequuntur veritatis non, ducimus ipsa deleniti vero recusandae nisi dolor ex officiis sunt labore. Dignissimos dolorum officia saepe odit nulla aliquid asperiores facilis rem tenetur dolore fugiat architecto, omnis, rerum voluptates ex quasi. A perferendis similique neque libero facere facilis optio, non eaque minus illo amet quis totam placeat rem, tempora in fuga sequi sint beatae, inventore vitae nemo dolorum aperiam. Vero quis deleniti ut odio molestiae sit maxime praesentium iste animi accusantium? Explicabo officia quaerat placeat ea doloremque necessitatibus fugiat vero commodi odit consequuntur ipsum reiciendis iure quidem, at magnam nam delectus blanditiis provident, expedita quibusdam iusto voluptate. Natus aut odio, velit eligendi delectus autem reprehenderit quam id, non repudiandae laborum aliquam est et eum ducimus eos totam reiciendis dolorem numquam? Voluptatibus facilis vel, iste aspernatur modi quo quis obcaecati ratione temporibus recusandae enim maiores at perferendis excepturi. Itaque soluta, voluptatibus similique tempore commodi quasi! Dolore, officia provident!
                    </Typography></Box>
            </Box>
            <Box sx={{
                m: { md: '32px 26.375vw 0px 26.375vw', sm: '32px 25px 0px 24px', xs: '16px' },
                bgcolor: 'primary.main',
                padding: { md: '1.875vw', xs: '30px' },
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '0px',
            }}>
                <Typography
                    sx={{
                        color: '#FFF',
                        fontSize: { md: '1.313vw', sm: '21px', xs: '18px' },
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '120%',
                        textTransform: 'uppercase',
                        '@media only screen and (max-width: 600px)': {
                            letterSpacing: '-0.54px',
                        }
                    }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati sed rerum porro consequatur quam illo vero, quisquam dolor eligendi quaerat sequi aspernatur placeat, beatae quo quidem repellat explicabo animi ipsa?
                </Typography>
            </Box>
            <Box sx={{
                m: { md: '32px 26.375vw 0px 26.375vw', sm: '32px 25px 0px 24px', xs: '16px' }
            }}>
                <Typography
                    sx={{
                        // width: '756px',
                        color: '#012317',
                        fontSize: { xs: '16px', md: '1vw' },
                        fontStyle: 'normal',
                        fontWeight: 300,
                        lineHeight: '130%'
                    }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati modi esse quibusdam voluptas architecto consectetur totam similique dolor at consequatur, ducimus, velit ipsum debitis. Laboriosam voluptatibus ratione dolor sit porro pariatur tempore ea obcaecati adipisci placeat tempora doloremque deserunt aut, error qui libero alias aliquam nisi iure iusto expedita quasi quidem ex? Dolor, atque cumque. Corrupti fugit a quae neque? Illum quam architecto unde, non neque nostrum earum optio delectus omnis similique tenetur repellendus, harum iure nam suscipit vero ex perspiciatis aspernatur provident et aliquam aliquid molestias rerum! Dolorem error voluptas animi cupiditate quibusdam temporibus, praesentium itaque non esse deleniti. Hic earum, ducimus facilis animi, non optio eum, blanditiis consequatur rerum corrupti iure. Libero minus tempore officia mollitia, asperiores culpa cupiditate aliquid commodi. Dolores reprehenderit ad fugit quas minus. Dolore, voluptatem dolor aliquam veritatis consequatur eos illo, at laboriosam corrupti sapiente repudiandae quam consectetur enim possimus obcaecati praesentium eaque repellendus nihil facere accusamus minus! Voluptate ducimus repellendus natus deserunt quam incidunt aut earum veniam inventore tempore? Consectetur non ex nihil voluptatem eius consequatur ducimus obcaecati expedita reprehenderit nostrum error, totam dicta, aperiam animi dignissimos mollitia sint iusto quam laudantium commodi et dolorem saepe asperiores. Voluptatum debitis a vero at odit.
                </Typography></Box> */}
            <Box sx={{
                m: { md: '32px 26.375vw 0px 26.375vw', sm: '32px 25px 0px 24px', xs: '16px' }
            }}>
                {/* <Typography
                    sx={{
                        // width: '756px',
                        fontStyle: 'normal',
                        fontSize: { md: '1.313vw', sm: '21px', xs: '18px' },
                        fontWeight: 500,
                        lineHeight: '120%',
                        textTransform: 'uppercase',
                        '@media only screen and (max-width: 600px)': {
                            letterSpacing: '-0.54px',
                        }
                    }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, perspiciatis aut qui aliquam labore, temporibus possimus, exercitationem nostrum debitis sit error voluptas omnis esse earum! Esse nulla consequuntur illo earum?
                </Typography> */}
            </Box>
            <Box sx={{
                display: { md: 'inline-flex', sm: 'flex', xs: 'flex' },
                alignItems: 'center',
                // flexDirection:{md:'row', sm:'row', xs:'column'},
                // justifyContent: 'center',
                gap: '17px',
                // mt: '60px',
                ml: { md: '26.438vw', sm: '24px', xs: '16px' },
            }}>
                <Typography sx={{
                    color: 'primary.main',
                    fontSize: { xs: '16px', md: '1rem' },
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '130%',
                    marginRight: '17px',

                }}>Share this post:</Typography>
                <SharePost />
            </Box>
            <Blog title='MORE NEWS' subtitle='[NEWS AND INSIGHTS]' parentSlug={'registering-a-business'}/>
        </>
    );
};

export default RegisterBusiness