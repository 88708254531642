import { Box, Link, Typography } from '@mui/material'
import Goat from '../../assets/Blog/Goat.svg'
import man from '../../assets/Blog/man.png'
import Tekwane from '../../assets/Blog/Tekwane.svg'
import SharePost from './SharePost'
import Blog from '../Blog/Blog'
import BlogHero from '../Blog/BlogHero'
import { playStoreLink } from '../utils/GetTheAppMobile/GetTheAppMobile'
import introducingLeja from '../../assets/introducingLeja.jpg';

const IntroducingLeja = () => {
    return (
        <>
            <BlogHero imageUrl={introducingLeja}
            date='03/01/24'
             heading='introducing leja' text="Leja is a tailored solution for the unique needs of African micro businesses."
             credits=''
           />
            <Box sx={{ margin: { md: '80px 26.438vw 0px 26.375vw', sm: '40px 26px 0px 24px', xs: '62px 16px 0px 16px' } }}>
                <Typography
                    sx={{
                        color: '#012317',
                        fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                        lineHeight: '100%',
                        letterSpacing: '-1.5px',
                        fontWeight: 500,
                        fontStyle: 'normal',
                        textTransform: 'uppercase',
                        //mb: { md: '30px', sm: '30px', xs: '16px' }
                    }}> Introducing Leja, the Ultimate Finance App for MSMES</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ margin: { md: '30px 10vw 0px 26.375vw', sm: '30px 25px 0px 24px', xs: '16px' } }}>
                    <Typography sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                        mb: '20px'
                    }}> In the ever-evolving landscape of financial technology,  <Link
                        href={playStoreLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: '#009166', }}>Leja</Link> emerges as a game-changer, designed to empower micro-businesses across Africa.
                        Leja App is a one-stop solution aimed to revolutionize the way MSMEs manage their finances, providing a secure, efficient, and convenient platform for growth.</Typography>
                    <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                    }}>

                        What makes Leja the ultimate finance app for African micro businesses?
                        <ul>
                            <li style={{ fontWeight: 500 }}>Secure and User-Friendly:</li>
                            Leja app, which is available on both Android and USSD (*429#), stands out for its robust security measures.<br />MSMES can confidently digitize their business transactions, thanks to the App's secure infrastructure. With multiple security layers in place, Leja ensures the safety of financial data, giving users peace of mind.
                            <li style={{ fontWeight: 500, marginTop: '15px' }}>Tailored for African MSMES:</li>
                            Leja is not just a financial app; it's a tailored solution for the unique needs of African micro businesses.<br />Serving over 1 million MSMEs in Kenya, Leja offers bespoke business solutions, such as flexible Mpesa float purchase, affordable send money transfers and seamless airtime purchases.<br />Leja is always committed to fostering the growth of local microbusinesses by providing essential tools and services.
                        </ul>
                    </Box>
                    <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                    }}>
                        <span style={{ fontWeight: 800 }}>Services Offered:</span>
                        <ol>
                            <li style={{ fontWeight: 500 }}>Efficient Bookkeeping:</li>
                            <ul>
                                <li>Keep track of day-to-day sales and cash flow in real-time.</li>
                                <li>Generate real-time reports of daily business transactions.</li>
                            </ul>
                            <li style={{ fontWeight: 500 }}>Send money transfers:</li>
                            <ul>
                                <li>Daily 3 free transactions below Kes 1000</li>
                                <li>Save up to 60% on transaction fees</li>
                                <li>Affordable transaction costs</li>
                            </ul>
                            <li style={{ fontWeight: 500 }}>Business Wallet:</li>
                            <ul>
                                <li>Keep business and personal expenses separate through the Leja business wallet.</li>
                            </ul>
                            <li style={{ fontWeight: 500 }}>Mpesa float purchase</li>
                            <ul>
                                <li>Affordable purchase rates</li>
                                <li>Convenient and instant top ups</li>
                            </ul>
                            <li style={{ fontWeight: 500 }}>Buy airtime</li>
                            <ul>
                                <li>Instant top-ups</li>
                                <li>Airtime discounts - up to 8% discounts</li>
                            </ul>
                        </ol>
                    </Box>
                    <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                    }}>
                        <span style={{ fontWeight: 800 }}>Testimonials:</span><br />
                        Curious about the impact  <Link
                            href={playStoreLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: '#009166' }}>Leja</Link> has had on businesses?<br />
                        Watch these testimonials on <Link
                            href={'https://www.youtube.com/@LejaApp'}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: '#009166' }}
                        >YouTube</Link> to hear directly from users who have transformed their financial operations with Leja.</Box>
                    <Typography sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                        mt: '15px'
                    }}>
                        <span style={{ fontWeight: 800 }}>Join the Movement:</span><br />
                        <Link underline="none"
                            href={playStoreLink}
                            target="_blank"
                            rel="noopener">Leja</Link> isn't just an app; it's a movement that aims to have a lasting impact on MSMES and local communities.<br />
                        By providing a financial operating system for Micro, Small & Medium Enterprises (MSMEs) in Africa, Leja is bridging the gap and empowering businesses to reach their full potential.
                    </Typography>
                    {/* <Typography sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#012317',
                        mt: '20px'
                    }}>In conclusion, if you run a biashara and want to do more and grow,  <Link
                        href={'https://www.youtube.com/@LejaApp'}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: '#009166', fontWeight: 700, }}>Leja</Link> is the simplest way to manage your business.
                        <br />Download  <Link
                            href={'https://www.youtube.com/@LejaApp'}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: '#009166', fontWeight: 700, }}>Leja</Link> today or dial *429# and witness the transformation firsthand!
                    </Typography> */}
                </Box>

                <Box sx={{ mr: '1.875vw', display: { md: 'block', sm: 'none', xs: 'none' },visibility:'hidden' }}><img src={Goat} alt='goat'  loading={'lazy'}/></Box>
            </Box>
            <Box sx={{
                m: { md: '30px 26.438vw 0px 26.438vw', sm: '9px 25px 0px 25px', xs: '16px 17px 0px 16px', },
                bgcolor: 'primary.main',
                padding: '30px',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '0px',
                justifyContent: 'center',
                alignItems: 'center',

                // display:{md:'inline-flex', sm:'flex', xs:'flex'},

            }}>
                <Box sx={{
                        fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#FFF',
                       
                    }}>
                        <Typography sx={{fontSize: { xs: '16px', md: '1vw' },
                        fontstyle: 'normal',
                        fontWeight: '300',
                        lineHeight: '130%',
                        color: '#FFF',}}>In conclusion:</Typography>If you run a biashara and want to do more and grow,  Leja is the simplest way to manage your business.
                        Download  <Link
                            href={playStoreLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: '#FFF', fontWeight: 700, }}>Leja</Link> today or dial *429# and witness the transformation firsthand!
                    </Box>
            </Box>
            {/* <Box sx={{
                m: { md: '30px 26.375vw 0px 26.375vw', sm: '9px 25px 0px 24px', xs: '16px' }
            }}><Typography sx={{
                color: '#012317',
                fontWeight: '300',
                fontSize: { xs: '16px', md: '1vw' },
                lineHeight: '130%',
            }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat totam explicabo est aut impedit eum magni iusto accusamus consequatur nobis animi recusandae tenetur sequi, ad, corporis voluptatem numquam ab quam perferendis unde a esse laborum. Dicta iusto quod debitis rem quo totam ratione voluptas nostrum veritatis, perspiciatis voluptate quia dignissimos modi magnam cupiditate dolor unde temporibus repellat, explicabo fugit vitae, odit perferendis voluptatum. Velit omnis tempore eveniet laudantium accusamus, reprehenderit dolorum error deserunt modi iure dolorem! Harum, necessitatibus? Eveniet labore eos iste quas tempora, ipsam, assumenda voluptate quasi et nam adipisci! Exercitationem, dolorem vel molestiae quod eius voluptatum! Sit repudiandae incidunt pariatur ipsa voluptatum rem consectetur impedit eum sequi! Minima, illo mollitia esse sed minus magni nesciunt expedita quaerat odit, quibusdam, doloribus vero odio ab consectetur repellendus laudantium aut hic sit nemo a fugit maiores obcaecati! Voluptates quasi commodi rerum natus asperiores labore voluptatum rem expedita harum repellendus maxime sequi, illo, quibusdam molestias perferendis cumque at quas? Recusandae, placeat! Id voluptates tenetur ex, quos sapiente eos dolores a eveniet? Nam temporibus id ut culpa sed inventore tenetur obcaecati, aliquid sapiente magni quia quaerat illo minima? Natus tenetur aliquid, quisquam ullam in dolore atque pariatur asperiores nemo illo ea voluptate? Delectus!
                </Typography></Box>
            <Box sx={{
                m: { md: '30px 26.375vw 0px 26.438vw', sm: '9px 25px 0px 25px', xs: '16px' }
            }}><Typography sx={{
                fontSize: { md: '1.313vw', sm: '21px', xs: '18px' },
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '120%',
                textTransform: 'uppercase',
                '@media only screen and (max-width: 600px)': {
                    letterSpacing: '-0.54px',
                }
            }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit accusantium alias nobis ipsa asperiores quia cum in dicta architecto, facilis exercitationem quo delectus recusandae corporis nulla rerum? Ad, quod aliquam!
                </Typography></Box>
            <Box sx={{
                m: { md: '30px 26.375vw 0px 26.438vw', sm: '30px 25px 0px 25px', xs: '16px' }
            }}>
                <img src={Tekwane} alt='Tekwane' style={{ width: '100%', objectFit: 'cover' }} />
            </Box>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ position: 'absolute', flexShrink: 0, mt: '412px', ml: '1.938vw', display: { md: 'block', sm: 'none', xs: 'none' } }}><img src={man} alt='man' /></Box>
                <Box sx={{
                    m: { sm: '30px 1.563vw 0px 1.5vw', xs: '16px', md: '30px 26.375vw 0px 26.375vw' }
                }}>
                    <Typography sx={{
                        color: '#012317',
                        fontSize: { xs: '16px', md: '1vw' },
                        fontStyle: 'normal',
                        fontWeight: 300,
                        lineHeight: '130%',
                        // width: '756px'
                    }}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis voluptatibus reprehenderit porro soluta dolore nisi nobis non perferendis, numquam accusamus, nesciunt hic odio debitis? Explicabo corrupti officiis, eum odio quas, quo fuga vero ea a ipsa temporibus autem ipsam porro quae vel illo odit? Dolorum eveniet iste molestias dicta, accusantium maiores consequuntur veritatis non, ducimus ipsa deleniti vero recusandae nisi dolor ex officiis sunt labore. Dignissimos dolorum officia saepe odit nulla aliquid asperiores facilis rem tenetur dolore fugiat architecto, omnis, rerum voluptates ex quasi. A perferendis similique neque libero facere facilis optio, non eaque minus illo amet quis totam placeat rem, tempora in fuga sequi sint beatae, inventore vitae nemo dolorum aperiam. Vero quis deleniti ut odio molestiae sit maxime praesentium iste animi accusantium? Explicabo officia quaerat placeat ea doloremque necessitatibus fugiat vero commodi odit consequuntur ipsum reiciendis iure quidem, at magnam nam delectus blanditiis provident, expedita quibusdam iusto voluptate. Natus aut odio, velit eligendi delectus autem reprehenderit quam id, non repudiandae laborum aliquam est et eum ducimus eos totam reiciendis dolorem numquam? Voluptatibus facilis vel, iste aspernatur modi quo quis obcaecati ratione temporibus recusandae enim maiores at perferendis excepturi. Itaque soluta, voluptatibus similique tempore commodi quasi! Dolore, officia provident!
                    </Typography></Box>
            </Box>
            <Box sx={{
                m: { md: '32px 26.375vw 0px 26.375vw', sm: '32px 25px 0px 24px', xs: '16px' },
                bgcolor: 'primary.main',
                padding: { md: '1.875vw', xs: '30px' },
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: '0px',
            }}>
                <Typography
                    sx={{
                        color: '#FFF',
                        fontSize: { md: '1.313vw', sm: '21px', xs: '18px' },
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '120%',
                        textTransform: 'uppercase',
                        '@media only screen and (max-width: 600px)': {
                            letterSpacing: '-0.54px',
                        }
                    }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati sed rerum porro consequatur quam illo vero, quisquam dolor eligendi quaerat sequi aspernatur placeat, beatae quo quidem repellat explicabo animi ipsa?
                </Typography>
            </Box>
            <Box sx={{
                m: { md: '32px 26.375vw 0px 26.375vw', sm: '32px 25px 0px 24px', xs: '16px' }
            }}>
                <Typography
                    sx={{
                        // width: '756px',
                        color: '#012317',
                        fontSize: { xs: '16px', md: '1vw' },
                        fontStyle: 'normal',
                        fontWeight: 300,
                        lineHeight: '130%'
                    }}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati modi esse quibusdam voluptas architecto consectetur totam similique dolor at consequatur, ducimus, velit ipsum debitis. Laboriosam voluptatibus ratione dolor sit porro pariatur tempore ea obcaecati adipisci placeat tempora doloremque deserunt aut, error qui libero alias aliquam nisi iure iusto expedita quasi quidem ex? Dolor, atque cumque. Corrupti fugit a quae neque? Illum quam architecto unde, non neque nostrum earum optio delectus omnis similique tenetur repellendus, harum iure nam suscipit vero ex perspiciatis aspernatur provident et aliquam aliquid molestias rerum! Dolorem error voluptas animi cupiditate quibusdam temporibus, praesentium itaque non esse deleniti. Hic earum, ducimus facilis animi, non optio eum, blanditiis consequatur rerum corrupti iure. Libero minus tempore officia mollitia, asperiores culpa cupiditate aliquid commodi. Dolores reprehenderit ad fugit quas minus. Dolore, voluptatem dolor aliquam veritatis consequatur eos illo, at laboriosam corrupti sapiente repudiandae quam consectetur enim possimus obcaecati praesentium eaque repellendus nihil facere accusamus minus! Voluptate ducimus repellendus natus deserunt quam incidunt aut earum veniam inventore tempore? Consectetur non ex nihil voluptatem eius consequatur ducimus obcaecati expedita reprehenderit nostrum error, totam dicta, aperiam animi dignissimos mollitia sint iusto quam laudantium commodi et dolorem saepe asperiores. Voluptatum debitis a vero at odit.
                </Typography></Box>
            <Box sx={{
                m: { md: '32px 26.375vw 0px 26.375vw', sm: '32px 25px 0px 24px', xs: '16px' }
            }}>
                <Typography
                    sx={{
                        // width: '756px',
                        fontStyle: 'normal',
                        fontSize: { md: '1.313vw', sm: '21px', xs: '18px' },
                        fontWeight: 500,
                        lineHeight: '120%',
                        textTransform: 'uppercase',
                        '@media only screen and (max-width: 600px)': {
                            letterSpacing: '-0.54px',
                        }
                    }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, perspiciatis aut qui aliquam labore, temporibus possimus, exercitationem nostrum debitis sit error voluptas omnis esse earum! Esse nulla consequuntur illo earum?
                </Typography>
            </Box> */}
            <Box sx={{
                display: { md: 'inline-flex', sm: 'flex', xs: 'flex' },
                alignItems: 'center',
                // flexDirection:{md:'row', sm:'row', xs:'column'},
                // justifyContent: 'center',
                gap: '17px',
                mt: '60px',
                ml: { md: '26.438vw', sm: '24px', xs: '16px' },
            }}>
                <Typography sx={{
                    color: 'primary.main',
                    fontSize: { xs: '16px', md: '1rem' },
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '130%',
                    marginRight: '17px',

                }}>Share this post:</Typography>
                <SharePost />
            </Box>
            <Blog title='MORE NEWS' subtitle='[NEWS AND INSIGHTS]' parentSlug={'introducing-leja'} />
        </>
    );
};

export default IntroducingLeja