import { Box, Typography, TextField, ThemeProvider, Button, useMediaQuery, Autocomplete} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import Sucess from './Sucess';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './Contact.css';
import { countryData } from './countryData';
import CircularProgress from '@mui/material/CircularProgress';


const customInputTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '--TextField-brandBorderFocusedColorMain': '#F5F7F2',
                    '--TextField-brandBorderFocusedColor30': 'rgba(245, 247, 242, 0.30)',
                    '--TextField-brandBorderFocusedColor60': 'rgba(245, 247, 242, 0.60)',
                    '& label.Mui-focused': {
                        color: 'var(--TextField-brandBorderFocusedColor60)',
                        
                    },
                    fontFamily: 'Rubik',
                }
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    '&::before': {
                        borderBottom: '0.5px solid var(--TextField-brandBorderColor)', 
                    },
                    '&:hover:not(.Mui-disabled, .Mui-error):before': {
                        borderBottom: '0.5px solid var(--TextField-brandBorderHoverColor)',
                    },
                    '&.Mui-focused:after': {
                        borderBottom: '0.5px solid var(--TextField-brandBorderFocusedColor30)',
                    },
                    fontFamily:'Rubik'
                },
            },
        },
    },
});


const isValid = (value: any, country: any) => {
    // Check for empty value
    if (!value) {
        return 'Phone number is required.';
    }

    // Remove leading/trailing whitespaces
    const trimmedValue = value.trim();

    // Regular expression for digits only
    const digitRegex = /^\d+$/;

    // Check if all characters are digits
    if (!digitRegex.test(trimmedValue)) {
        return 'Phone number must contain only digits.';
    }

    // Check for length between 10 and 15 digits
    const length = trimmedValue.length;
    if (length < 10 || length > 15) {
        return `Phone number must be between 10 and 15 digits long.`;
    }


    return true; // valid phone number
}

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Enter a valid email')
        .required('Email is required'),

    name: yup
        .string()
        .required('Please enter your name'),

    phoneNumber: yup.string()
        .matches(/^\+?[0-9]+$/, 'Phone number must contain only digits')
        .min(10, 'Phone number is too short')
        .max(15, 'Phone number is too long')
    //.test('isValid', 'Invalid phone number', (value, { form }) => isValid(value, form.country)),

});

const ContactForm = () => {
    const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    // console.log(REACT_APP_API_ENDPOINT);

    // const { REACT_APP_API_ENDPOINT } = process.env;
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            phoneNumber: '',
            country: {
                code: '',
                country: '',
            },
            message: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            // console.log(values);

            const payload: any = {
                name: values.name,
                email: values.email,
            };

            if (values.phoneNumber) payload["phoneNumber"] = values.phoneNumber;
            if (values.country) payload["country"] = values.country.country;
            if (values.message) payload["message"] = values.message;

            // Handle form submission logic here
            setLoading(true)
            fetch(`${REACT_APP_API_ENDPOINT}/mail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json()
                    }
                    else {
                        throw new Error('Form not submitted. Status code: ' + response.status);
                    }
                })
                .then((data) => {
                    console.log('Form submitted:', data);
                    // setLoading(true);
                    setSubmitted(true);
                    setLoading(true);
                    resetForm();
                })
                .catch((error) => {
                    // Handle error
                    console.error(error, error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    });

    const isTablet = useMediaQuery('(max-width: 960px)');
    const isMobile = useMediaQuery('(max-width: 600px)');
    const inputColor = '#F5F7F2';

    return (
        <>
            {submitted ? (<Sucess />) : (<Box id="contact">

                <Typography sx={{
                    textTransform: 'uppercase',
                    fontSize: isTablet ? '3.91vw' : isMobile ? '6.67vw' : '1.875vw',
                    fontWeight: 500,
                    lineHeight: '100%',
                    letterSpacing: '-0.04vw',
                    color: '#F5F7F2',
                    marginBottom: isTablet ? '3.125vw' : isMobile ? '5.56vw' : '1.875vw',
                    width: isTablet ? '83.59vw' : isMobile ? '91.11vw' : '40.125vw',
                }}>
                    get in touch
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                    <ThemeProvider theme={customInputTheme}>
                        <TextField
                            fullWidth
                            variant="standard"
                            label="Name*"
                            color="primary"
                            focused
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            sx={{
                                margin: "24px 0",
                                fontFamily:'Rubik'
                            }}
                            inputProps={{
                                style: {
                                    color: inputColor,
                                    fontFamily:'Rubik',
                                    fontWeight: 300,
                                    lineHeight: '130%',
                                    fontSize: isTablet ? '2.08vw' : isMobile ? '4.44vw' : '1vw',
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            label="E-mail*"
                            color="primary"
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            focused
                            sx={{
                                marginBottom: "24px"
                            }}
                            inputProps={{
                                style: {
                                    color: inputColor,
                                    fontWeight: 300,
                                    lineHeight: '130%',
                                    fontSize: isTablet ? '2.08vw' : isMobile ? '5vw' : '1vw',
                                },
                            }}
                        />
                        <PhoneInput
                            country={'ke'}
                            value={formik.values.phoneNumber}
                            onChange={value => formik.setFieldValue('phoneNumber', value)}
                            onBlur={formik.handleBlur}
                            inputProps={{
                                id: 'phoneNumber',
                                name: 'phoneNumber',
                                required: true,
                            }}
                            inputStyle={{
                                backgroundColor: 'transparent',
                                color: 'white',
                                borderColor: 'rgba(245, 247, 242, 0.30)',
                                borderWidth: '0.5px',
                                borderStyle: 'solid',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderRight: 'none',
                                borderRadius: '0px',
                                width: '100%',
                            }}
                            isValid={formik.errors.phoneNumber ? false : true}
                        //inputClassName="phone-input-class"
                        />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                            <Typography variant="body2" color="error">
                                {formik.errors.phoneNumber}
                            </Typography>
                        )}

                        <Autocomplete
                            fullWidth
                            value={countryData.find(country => country.country === formik.values.country.country) || null}
                            onChange={(event, value) => formik.setFieldValue('country', value ? value : '')}
                            id='country'
                            style={{ fontFamily:'Rubik'}}
                            options={countryData}
                            getOptionLabel={(option) => option.country}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='standard'
                                    label="Country"
                                    color="primary"
                                    name="country"
                                    focused
                                    sx={{
                                        marginBottom: "24px",
                                        mt: '20px',
                                       
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            color: inputColor,
                                            fontWeight: 300,
                                            lineHeight: '130%',
                                            fontSize: isTablet ? '2.08vw' : isMobile ? '4.44vw' : '1vw',
                                        },
                                    }}
                                />
                            )}
                        />


                        <TextField
                            fullWidth
                            variant="standard"
                            id='message'
                            label="Message"
                            name="message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            multiline
                            rows={3}
                            color="primary"
                            focused
                            inputProps={{
                                style: {
                                    height: "56px",
                                    color: inputColor,
                                    fontWeight: 300,
                                    lineHeight: '130%',
                                    fontSize: isTablet ? '2.08vw' : isMobile ? '4.44vw' : '1vw',
                                },
                            }}
                        />
                    </ThemeProvider>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            marginTop: { md: '30px', sm: '24px', xs: '24px' },
                            width: '100%',
                            height: '48px',
                            mr: { md: '0.5vw' },
                            bgcolor: 'primary.light',
                            "&:hover": {
                                bgcolor: "primary.light",
                                boxShadow: 'none'
                            },
                            boxShadow: 'none',
                            "&.Mui-disabled": {
                                backgroundColor: "primary.light",
                                color: "white"
                            },
                            position: 'relative' // Set position relative to contain the loader
                        }}

                        disabled={loading} // Disable the button when loading
                    >
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px', // Half of the size
                                    marginLeft: '-12px', // Half of the size
                                    color: 'white'
                                }}
                            />
                        )}
                        {!loading && (
                            <>
                                <Typography sx={{
                                    fontSize: { md: '16px', sm: '2.08vw', xs: '4.44vw' },
                                    mr: { md: '0.5vw', sm: '1.04vw', xs: '2.22vw' },
                                    textTransform: 'uppercase',
                                    fontWeight: 500
                                }}>
                                    Send Message
                                </Typography>
                                <ArrowForwardIcon sx={{
                                    width: { md: '1.75vw', sm: '3.65vw', xs: '7.78vw' },
                                    height: { md: '1.75vw', sm: '3.65vw', xs: '7.78vw' }
                                }} />
                            </>
                        )}
                    </Button>
                </form>
            </Box>)}

        </>
    );
}

export default ContactForm;
