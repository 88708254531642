import BlogMainHeading from "../../components/Blog/BlogMainHeading";
import JoinTheFuture from "../../components/JoinTheFuture/JoinTheFuture";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Blog = () => {
  const location = useLocation()

  useEffect(
    () => {
      document.title = `Leja / ${location.pathname.slice(1)}`
    }, [location]
  );
  return (
    <>
      <BlogMainHeading />
      <JoinTheFuture />
    </>
  )
};

export default Blog;
