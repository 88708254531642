import { Typography } from '@mui/material'
import React from 'react'

const DisclosureOfInformation = () => {
  return (
    <Typography sx={{
        mb: '10px',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
    }}>
        LEJA owns all intellectual Property rights relating to the Leja Platform and nothing in this agreement gives any right to any customer to the use or misuse of that intellectual property other than to access the services offered in the platform therein.
    </Typography>
  )
}

export default DisclosureOfInformation