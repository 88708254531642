import { Typography } from '@mui/material'
import React from 'react'

const ExclusionOfLiability = () => {
    return (
        <Typography sx={{
            mb: '10px',
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
        }}>
            <ol style={{ margin: 0 }}>
                <li>LEJA will endeavour to do its utmost to ensure that there are no delays or failures in offering the Loan. However, LEJA shall not be liable for any loss suffered by you due to, as a result of, or in connection with failures that may arise as a result of inefficiencies caused by the Mobile Network Operators or which are occasioned by strikes, riots or civil unrest, breakages, fire, unforeseen commercial delays, acts of God or permission requirements or licences that are required from Mobile Network Operators.</li>
                <li>LEJA shall not be liable for any losses incurred by you as a result of another person accessing your equipment whether by consent, theft or fraud or where you have been scammed by allowing other persons access to your equipment;</li>
                <li>You hereby expressly consent to waive any claim against LEJA for delays or failures to offer the Loan, Service or respond to any Request made by you due to any or all of the causes and events mentioned in clause 8(1) above.</li>
                <li>You hereby expressly waive any claim against LEJA for losses incurred as a result of being scammed whether knowingly or unknowingly by allowing another person access to your device or data or as described in clause 8(2) above.</li>
            </ol>
        </Typography>
    )
}

export default ExclusionOfLiability