import { Controller } from "react-hook-form";
import {FormInputProps} from "../../models/form-input-props";
import {TextFieldStyled} from "./TextFieldStyled";
import {makeStyles} from "@mui/styles";


const useStyles: any = makeStyles({
    helperText: {
        marginLeft: 0,
    },
});

export const FormInputText = ({ name, control, label, placeholder, rules }: FormInputProps) => {
    const classes = useStyles();

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                         field: { onChange, value },
                         fieldState: { error },
                         formState,
                     }) => (
                <TextFieldStyled
                    fullWidth
                    variant="filled"
                    helperText={error ? error.message : null}
                    error={!!error}
                    onChange={onChange}
                    value={value}
                    label={label}
                    placeholder={placeholder}
                    FormHelperTextProps={{ className: classes.helperText }}
                />
            )}
        />
    );
};